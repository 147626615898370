import { ref, computed } from "vue";
import useReferential from '@/modules/mdlReferential.js'; 

const axios = require("axios");

// Global variable that can be shared state with Composition API
// let users = ref([]);	// Array of uers

// Orders
let refOrdersLoading = ref(false);
let refOrdersRecs = ref([]);	// Array of records
let refOrdersRec = ref({});  // Current record
let refOrdersRecsNb = ref(0);
let refOrdersResp = ref({errCode:0, errMsg:'159'});
let refRetPhDeday = ref(90);		// confif: sKey=OrdRetPhDelay: nb days after delivery to Penghu to enable order return
let refRetTwDeday = ref(90);		// confif: sKey=OrdRetTwDelay: nb days after delivery to Taiwan to enable order return

export default function useOrdersApi() {
  
  const { refSlctMenu } = useReferential();
  

    /* ==================================================================================
   * Get random number between specified values
   *
   */
  function fctGetRndNumber(min, max) {  
	return Math.floor(
		Math.random() * (max - min) + min
	)
  }	// fctGetRndNumber

  function fctGetDateStr(objDate, mask) {
    if (objDate == null) objDate = new Date();  // Get current date and time

    // adjust 0 before single digit date
    let date = ("0" + objDate.getDate()).slice(-2); // current date
    let month = ("0" + (objDate.getMonth() + 1)).slice(-2); // current month
    let year = objDate.getFullYear(); // current year
    let hours = ("0" + (objDate.getHours() + 1)).slice(-2); // current hours
    let minutes = ("0" + (objDate.getMinutes() + 1)).slice(-2); // current minutes
    let seconds = ("0" + (objDate.getSeconds() + 1)).slice(-2); // current seconds

    let dateStr;
    switch(mask) {
        case 'YYYMMDDhhmmssxxxx':
          dateStr = year + month + date + hours + minutes + seconds;
          dateStr += fctGetRndNumber(1000, 9999);
          break;
        case 'YYYMMDDhhmmss':
          dateStr = year + month + date + hours + minutes + seconds;
          break;
        case 'YYYMMDD':
          dateStr = year + month + date;
          break;
        case 'hhmmss':
          dateStr = hours + minutes + seconds;
          break;
        case '_YYYMMDD':
          dateStr = year + "-" + month + "-" + date;
          break;
        case '_hhmmss':
          dateStr = hours + ":" + minutes + ":" + seconds;
          break;
        case '_hhmm':
          dateStr = hours + ":" + minutes;
          break;
        default:
          dateStr = year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds;
          break;
    }
    return dateStr;
  }


  function isMenuOrdersValid(myObj) {
    const fctName = 'svcOrdersApi.isMenuOrdersValid';
    
    let isValid = false;
    
    // refSlctMenu = ref({topMenuCode:'', topMenuName:'', subMenuCode:'', subMenuName:'', fctCallback:'' });
    if (refSlctMenu.value.topMenuCode == myObj.sType1Code) {
      
      
      if (refSlctMenu.value.subMenuCode != '*') {
        // Both topMenu and subMenu
        if (refSlctMenu.value.subMenuCode == myObj.sType2Code) {
          // console.log(fctName+': DEBUG: subMenu=true: subMenuCode='+refSlctMenu.value.topMenuCode+', subMenuCode='+refSlctMenu.value.subMenuCode+'/'+myObj.sType2Code);  // prov_debug
          isValid = true;
        } else {
          // console.log(fctName+': DEBUG: subMenu=false: subMenuCode='+refSlctMenu.value.topMenuCode+', subMenuCode='+refSlctMenu.value.subMenuCode+'/'+myObj.sType2Code);  // prov_debug
        }
        
        // isValid = true; // prov_debug
        
      } else {
        // Only topMenuCode
        // console.log(fctName+': DEBUG: topMenu=true: topMenuCode='+refSlctMenu.value.topMenuCode+', subMenuCode='+refSlctMenu.value.subMenuCode);  // prov_debug
        isValid = true;
      }
      
    } else {
      // console.log(fctName+': DEBUG: false: topMenuCode='+refSlctMenu.value.topMenuCode+'/'+myObj.sType1Code);  // prov_debug
    }
    
    return isValid;
  } // isMenuOrdersValid
  
  function isCatalogOrdersValid(pgName, myObj) {
    const fctName = 'svcOrdersApi.isCatalogOrdersValid';
    
    let isValid = true;
    
    // sFlgMenu: 1234567: flg to show Orders in corresponding menu
    //    refRec.value.sMenu02Discount = (refRec.value.sFlgMenu.indexOf('2') >= 0)? true:false;
    //    refRec.value.sMenu03NewProducts = (refRec.value.sFlgMenu.indexOf('3') >= 0)? true:false; 
    //    refRec.value.sMenu04Exclusive = (refRec.value.sFlgMenu.indexOf('4') >= 0)? true:false; 
    //    refRec.value.sMenu05BestSellers = (refRec.value.sFlgMenu.indexOf('5') >= 0)? true:false; 
    //    refRec.value.sMenu06Wholesale = (refRec.value.sFlgMenu.indexOf('6') >= 0)? true:false; 
    //    refRec.value.sMenu07Distribution = (refRec.value.sFlgMenu.indexOf('7') >= 0)? true:false; 
    
    let filterValue = '';
    switch(pgName) {
      case 'myMenu02Discount':      filterValue = '2'; break;
      case 'myMenu03NewProducts':   filterValue = '3'; break;
      case 'myMenu04Exclusive':     filterValue = '4'; break;
      case 'myMenu05BestSellers':   filterValue = '5'; break;
      case 'myMenu06Wholesale':     filterValue = '6'; break;
      case 'myMenu07Distribution':  filterValue = '7'; break;
    }
    if (myObj.sFlgMenu.indexOf(filterValue) >= 0) isValid = true;
    
    console.log(fctName+': DEBUG: pgName='+pgName+', sFlgMenu='+myObj.sFlgMenu+'/'+filterValue+', isValid='+isValid);
    
    return isValid;
  } // isCatalogOrdersValid
  
    
    
  /* ==================================================================================
   * ascOrdersGetRecs: Request REST API services for an array of records
   * reqParams { criteria: "sFlgMenu LIKE '%2%'", } :Input parameters
   */
  async function ascOrdersGetRecs(reqParams, fctCallback) {
    const fctName = 'svcOrdersApi.ascOrdersGetRecs';
    const reqUrl = '/api/shopping/getOrdRecs';
    
    // let reqParams = {};
    refOrdersLoading.value = true;
    try {
      console.log(fctName+': DEBUG: reqParamsStr='+JSON.stringify(reqParams));  // debug
      const { data } = await axios.post(
        reqUrl,
        reqParams
      );
      // console.log(fctName+': DEBUG: retDataStr='+JSON.stringify(data));  // debug
      // ==> retDataStr={"errCode":1,"errMsg":"OK","rec":{},"recsNb":1,"recs":[{"id":1,"iStatus":1,"dtmCreate":"2020-01-01T23:00:00.000Z","dtmModif":null,"dtmValidMob":null,"dtmValidEmail":null,"sPassword":"adm123","sMobile":"123456789","sEmail":"jpn_email@yahoo.com","sGenderCode":"M","sFirstName":"Jack","sLastName":"SMITH","sAddr1":"1 bd Elysée","sAddr2":"1 FL","sZipCode":"99000","sCity":"NewCity","sProvince":"County","sMsgMode":"E","sCheckCode":null,"sLevel":"A","sNote":null}]}      
      
      refOrdersResp.value.errCode = data.errCode; 
      refOrdersResp.value.errMsg = data.errMsg;
      switch(data.errCode) {
        case 1: // OK: rec found
          // Get data
          refOrdersRecs.value = data.recs;
          refOrdersRecsNb.value = refOrdersRecs.value.length;
          break;
        default:
          refOrdersRec.value = [{}];
          refOrdersRecsNb.value = 0;
          break;
      }
      
      if (fctCallback) {
        // fctCallback has been defined
        // console.log(fctName+': DEBUG: to fctCallback retDataStr='+JSON.stringify(data));  // debug
        let resp = {resp:refOrdersResp.value, recs: refOrdersRecs.value};
        fctCallback(resp);
      } else {
        // console.log(fctName+': DEBUG: no callback retDataStr='+JSON.stringify(data));  // debug
      }
      
    } catch (ex) {
      if (ex.response) {
        // Request made and server responded
        console.log(fctName+': EXC: exStatus='+ex.response.status+', exData='+ex.response.data);
        // console.log('exrespdata:'+ex.response.data);  // 9002:DB Exception:2021-04-08 16:59:36 - mdlDbMysql.dbFind
        // console.log('exrespstatus:'+ex.response.status);  // 500
        // console.log('exrespheader:'+ex.response.headers);
      } else {
        console.log(fctName+': EXC: ex='+ex.stack);
      }
    } finally {
      refOrdersLoading.value = false;
    }
  } // ascOrdersGetRecs
  
  /* ==================================================================================
   * ascOrdersGetItemRecs: Request REST API services for an array of records with item data
   * reqParams { criteria: "sFlgMenu LIKE '%2%'", } :Input parameters
   */
  async function ascOrdersGetItemRecs(reqParams, fctCallback) {
    const fctName = 'svcOrdersApi.ascOrdersGetItemRecs';
    const reqUrl = '/api/shopping/getItmRecs';
    
    // let reqParams = {};
    refOrdersLoading.value = true;
    try {
      // console.log(fctName+': DEBUG: reqParamsStr='+JSON.stringify(reqParams));  // debug
      const { data } = await axios.post(
        reqUrl,
        reqParams
      );
      // console.log(fctName+': DEBUG: retDataStr='+JSON.stringify(data));  // debug
      // ==> retDataStr={"errCode":1,"errMsg":"OK","rec":{},"recsNb":1,"recs":[{"id":1,"iStatus":1,"dtmCreate":"2020-01-01T23:00:00.000Z","dtmModif":null,"dtmValidMob":null,"dtmValidEmail":null,"sPassword":"adm123","sMobile":"123456789","sEmail":"jpn_email@yahoo.com","sGenderCode":"M","sFirstName":"Jack","sLastName":"SMITH","sAddr1":"1 bd Elysée","sAddr2":"1 FL","sZipCode":"99000","sCity":"NewCity","sProvince":"County","sMsgMode":"E","sCheckCode":null,"sLevel":"A","sNote":null}]}      
      
      refOrdersResp.value.errCode = data.errCode; 
      refOrdersResp.value.errMsg = data.errMsg;
      switch(data.errCode) {
        case 1: // OK: rec found
          // Get data
          refOrdersRecs.value = data.recs;
          refOrdersRecsNb.value = refOrdersRecs.value.length;
          break;
        default:
          refOrdersRec.value = [{}];
          refOrdersRecsNb.value = 0;
          break;
      }
      
      if (fctCallback) {
        // fctCallback has been defined
        // console.log(fctName+': DEBUG: to fctCallback retDataStr='+JSON.stringify(data));  // debug
        let resp = {resp:refOrdersResp.value, recs: refOrdersRecs.value};
        fctCallback(resp);
      } else {
        // console.log(fctName+': DEBUG: no callback retDataStr='+JSON.stringify(data));  // debug
      }
      
    } catch (ex) {
      if (ex.response) {
        // Request made and server responded
        console.log(fctName+': EXC: exStatus='+ex.response.status+', exData='+ex.response.data);
        // console.log('exrespdata:'+ex.response.data);  // 9002:DB Exception:2021-04-08 16:59:36 - mdlDbMysql.dbFind
        // console.log('exrespstatus:'+ex.response.status);  // 500
        // console.log('exrespheader:'+ex.response.headers);
      } else {
        console.log(fctName+': EXC: ex='+ex.stack);
      }
    } finally {
      refOrdersLoading.value = false;
    }
  } // ascOrdersGetItemRecs
  


  /* ==================================================================================
   * ascConfigGetRec: Request REST API services for the record having the specified key
   * reqParams { key: value, } :Input parameters
   * fctCallback :Callback function, if defined
   */
  async function ascConfigGetRec(reqParams, fctCallback) {
    const fctName = 'svcOrdersApi.ascConfigGetRec';
    const reqUrl = '/api/referential/getCfgRec';
    
    refOrdersLoading.value = true;
    try {
      // # const { data } is the name set by axios.post. Can not be renamed.
      const { data } = await axios.post(
        reqUrl,
        reqParams   // { fct: myFct, rec: refRec.value, }
      );
      
      // console.log(fctName+': DEBUG: dataStr='+JSON.stringify(data));  // prov_debug
      // ==> dataStr={"errCode":1,"errMsg":"OK","rec":{"sKey":"OrdRetDelay","dtmData":null,"sData":null,"iData":3},"recsNb":0,"recs":[{}]}
      
      // errCode = [1 ... 99] :OK message, [100 ... 999] :Error message, value = [1000 ... 999] :Process aborted
      let tmpResp = {
        errCode: data.errCode,
        errMsg: data.errMsg
      };
      let tmpRec;
      switch(data.errCode) {
        case 1: // OK: rec found
          // Get data
          tmpRec = data.rec;
          // console.log(fctName+': DEBUG: tmpRecStr='+JSON.stringify(tmpRec));  // prov_debug
          // ==> tmpRecStr={"sKey":"OrdRetDelay","dtmData":null,"sData":null,"iData":3}
          // You may alter data here
          break;
        case 2: // Record not found
          tmpRec = {};
          break;
        default: // Error occured at server sidebar
          tmpRec = {};
          break;
      }
      if (fctCallback) {
        // fctCallback has been defined
        // console.log(fctName+': DEBUG: to fctCallback');  // debug
        let resp = {resp:tmpResp, rec: tmpRec};
          // console.log(fctName+': DEBUG: respStr='+JSON.stringify(resp));  // prov_debug
          // ==> respStr={"resp":{"errCode":1,"errMsg":"OK"},"rec":{"sKey":"OrdRetDelay","dtmData":null,"sData":null,"iData":3}}
        fctCallback(resp);
      } else {
        console.log(fctName+': DEBUG: no callback');  // prov_debug
      }

    } catch (ex) {
      if (ex.response) {
        // Request made and server responded
        console.log(fctName+': EXC: exStatus='+ex.response.status+', exData='+ex.response.data);
        // console.log('exrespdata:'+ex.response.data);  // 9002:DB Exception:2021-04-08 16:59:36 - mdlDbMysql.dbFind
        // console.log('exrespstatus:'+ex.response.status);  // 500
        // console.log('exrespheader:'+ex.response.headers);
      } else {
        console.log(fctName+': EXC: ex='+ex.stack);
      }
    } finally {
      refOrdersLoading.value = false;
    }

  } // ascConfigGetRec



  /* ==================================================================================
   * ascOrdersFindRec: Request REST API services for the record having the specified id
   * reqParams { fct: myFct, rec: refRec.value, } :Input parameters
   * fctCallback :Callback function, if defined
   */
  async function ascOrdersFindRec(reqParams, fctCallback) {
    const fctName = 'svcOrdersApi.ascOrdersFindRec';
    const reqUrl = '/api/shopping/findOrdRec';
    
    refOrdersLoading.value = true;
    try {
      // # const { data } is the name set by axios.post. Can not be renamed.
      const { data } = await axios.post(
        reqUrl,
        reqParams   // { fct: myFct, rec: refRec.value, }
      );
      
      // console.log(fctName+': DEBUG: dataStr='+JSON.stringify(data));  // prov_debug
      // ==> dataStr={"errCode":1,"errMsg":"OK","rec":{"id":1,"iStatus":1,"dtmCreate":"2020-01-01T23:00:00.000Z","dtmModif":null,"dtmValidMob":null,"dtmValidEmail":null,"sPassword":"adm123","sMobile":"123456789","sEmail":"jpn_email@yahoo.com","sGenderCode":"M","sFirstName":"Jack","sLastName":"SMITH","sAddr1":"1 bd Elysée","sAddr2":"1 FL","sZipCode":"99000","sCity":"NewCity","sProvince":"County","sMsgMode":"E","sCheckCode":null,"sLevel":"A","sNote":null},"recsNb":0,"recs":[{}]}
      
      // errCode = [1 ... 99] :OK message, [100 ... 999] :Error message, value = [1000 ... 999] :Process aborted
      refOrdersResp.value.errCode = data.errCode; 
      refOrdersResp.value.errMsg = data.errMsg;
      switch(data.errCode) {
        case 1: // OK: rec found
          // Get data
          refOrdersRec.value = data.rec;
          // You may alter data here
          break;
        case 2: // Record not found
          refOrdersRec.value = {};
          break;
        default: // Error occured at server sidebar
          refOrdersRec.value = {};
          break;
      }
      if (fctCallback) {
        // fctCallback has been defined
        // console.log(fctName+': DEBUG: to fctCallback');  // debug
        let resp = {resp:refOrdersResp.value, rec: refOrdersRec.value};
        fctCallback(resp);
      } else {
        console.log(fctName+': DEBUG: no callback');  // prov_debug
      }

    } catch (ex) {
      if (ex.response) {
        // Request made and server responded
        console.log(fctName+': EXC: exStatus='+ex.response.status+', exData='+ex.response.data);
        // console.log('exrespdata:'+ex.response.data);  // 9002:DB Exception:2021-04-08 16:59:36 - mdlDbMysql.dbFind
        // console.log('exrespstatus:'+ex.response.status);  // 500
        // console.log('exrespheader:'+ex.response.headers);
      } else {
        console.log(fctName+': EXC: ex='+ex.stack);
      }
    } finally {
      refOrdersLoading.value = false;
    }

  } // ascOrdersFindRec
  

  /* ==================================================================================
   * ascOrdersSaveRec: Send a record to save to REST API services
   */
   async function ascOrdersSaveRec(reqParams, fctCallback) {
    const fctName = 'svrOrdersApi.ascOrdersSaveRec';
    const reqUrl = '/api/shopping/saveRec';
    
    // console.log(fctName+': DEBUG: reqParamsStr='+JSON.stringify(reqParams));  // debug
    // ==> reqParamsStr={"id":"STA031"}

    refOrdersLoading.value = true;
    try {
      // # const { data } is the name set by axios.post. Can not be renamed.
      const { data } = await axios.post(
        reqUrl,
        reqParams
      );
      
      // console.log(fctName+': DEBUG: dataStr='+JSON.stringify(data));  // debug
      // ==> dataStr={"errCode":201,"errMsg":"Invalide function"}
      refOrdersResp.value.errCode = data.errCode; 
      refOrdersResp.value.errMsg = data.errMsg;
      
      if (fctCallback) {
        // fctCallback has been defined
        console.log(fctName+': DEBUG: callback: dataStr='+JSON.stringify(data));  // prov_debug
        let resp = {resp:refOrdersResp.value};
        fctCallback(resp);
      } else {
        console.log(fctName+': DEBUG: no callback retDataStr='+JSON.stringify(data));  // prov_debug
      }
      
      
    } catch (ex) {
      if (ex.response) {
        // Request made and server responded
        console.log(fctName+': EXC: exStatus='+ex.response.status+', exData='+ex.response.data);
        // console.log('exrespdata:'+ex.response.data);  // 9002:DB Exception:2021-04-08 16:59:36 - mdlDbMysql.dbFind
        // console.log('exrespstatus:'+ex.response.status);  // 500
        // console.log('exrespheader:'+ex.response.headers);
      } else {
        console.log(fctName+': EXC: ex='+ex.stack);
      }
    } finally {
      refOrdersLoading.value = false;
    }
  } // ascOrdersSaveRec
  
  /* ==================================================================================
   * ascOrdersDeleteRec: Send request to delete a record to REST API services
   */
   async function ascOrdersDeleteRec(reqParams, fctCallback) {
    const fctName = 'svrOrdersApi.ascOrdersDeleteRec';
    const reqUrl = '/api/shopping/deleteRec';
    
    // console.log(fctName+': DEBUG: reqParamsStr='+JSON.stringify(reqParams));  // debug
    // ==> reqParamsStr={"id":"STA031"}

    refOrdersLoading.value = true;
    try {
      // # const { data } is the name set by axios.post. Can not be renamed.
      const { data } = await axios.post(
        reqUrl,
        reqParams
      );
      
      // console.log(fctName+': DEBUG: dataStr='+JSON.stringify(data));  // debug
      // ==> dataStr={"errCode":201,"errMsg":"Invalide function"}
      refOrdersResp.value.errCode = data.errCode; 
      refOrdersResp.value.errMsg = data.errMsg;
      
      if (fctCallback) {
        // fctCallback has been defined
        console.log(fctName+': DEBUG: callback: dataStr='+JSON.stringify(data));  // prov_debug
        let resp = {resp:refOrdersResp.value};
        fctCallback(resp);
      } else {
        console.log(fctName+': DEBUG: no callback retDataStr='+JSON.stringify(data));  // prov_debug
      }
      
      
    } catch (ex) {
      if (ex.response) {
        // Request made and server responded
        console.log(fctName+': EXC: exStatus='+ex.response.status+', exData='+ex.response.data);
        // console.log('exrespdata:'+ex.response.data);  // 9002:DB Exception:2021-04-08 16:59:36 - mdlDbMysql.dbFind
        // console.log('exrespstatus:'+ex.response.status);  // 500
        // console.log('exrespheader:'+ex.response.headers);
      } else {
        console.log(fctName+': EXC: ex='+ex.stack);
      }
    } finally {
      refOrdersLoading.value = false;
    }
  } // ascOrdersDeleteRec
  
  /* ==================================================================================
   * ascOrdersSetStaffId: Assign staff ID to prepare, deliver, or return the order
   * reqParams
   *     orderId : ID of the order
   *     ordStatus = 0:idel, 1:Pending, 2:Packaging, 3:Ready, 4:Cancel, 5:Delivering, 6:Delivered, 7:retRequest, 8:returning, 9:retDone
   *     staffId : ID of the staff
   *     ordType = 1:Prepare, 2:Deliver, 3:Return
   *     invoiceCode = null, else Invoice code
   */
   async function ascOrdersSetStaffId(reqParams, fctCallback) {
    const fctName = 'svrOrdersApi.ascOrdersSetStaffId';
    const reqUrl = ' /api/shopping/setOrdStaffId';
    
    // console.log(fctName+': DEBUG: reqParamsStr='+JSON.stringify(reqParams));  // debug
    // ==> reqParamsStr={"id":"STA031"}

    refOrdersLoading.value = true;
    try {
      // # const { data } is the name set by axios.post. Can not be renamed.
      const { data } = await axios.post(
        reqUrl,
        reqParams
      );
      
      // console.log(fctName+': DEBUG: dataStr='+JSON.stringify(data));  // debug
      // ==> dataStr={"errCode":201,"errMsg":"Invalide function"}
      refOrdersResp.value.errCode = data.errCode; 
      refOrdersResp.value.errMsg = data.errMsg;
      
      if (fctCallback) {
        // fctCallback has been defined
        console.log(fctName+': DEBUG: callback: dataStr='+JSON.stringify(data));  // prov_debug
        let resp = {resp:refOrdersResp.value};
        fctCallback(resp);
      } else {
        console.log(fctName+': DEBUG: no callback retDataStr='+JSON.stringify(data));  // prov_debug
      }
      
      
    } catch (ex) {
      if (ex.response) {
        // Request made and server responded
        console.log(fctName+': EXC: exStatus='+ex.response.status+', exData='+ex.response.data);
        // console.log('exrespdata:'+ex.response.data);  // 9002:DB Exception:2021-04-08 16:59:36 - mdlDbMysql.dbFind
        // console.log('exrespstatus:'+ex.response.status);  // 500
        // console.log('exrespheader:'+ex.response.headers);
      } else {
        console.log(fctName+': EXC: ex='+ex.stack);
      }
    } finally {
      refOrdersLoading.value = false;
    }
  } // ascOrdersSetStaffId
  
  /* ==================================================================================
   * ascDeliverOrdItms: Update qty of items to deliver
   */
   async function ascDeliverOrdItms(myPrm1, ordItemRecs, fctCallback) {
    const fctName = 'svrOrdersApi.ascDeliverOrdItms';
    const reqUrl = '/api/shopping/updateOrdItms';

    try {

      // ordRec.sDelCode = tlsGetDateStr(null, 'YYYMMDDhhmmssxxxx').substring(2); // (objDate, mask);
      
      // console.log(fctName+': DEBUG: ordRecStr='+JSON.stringify(ordRec));  // debug
      // ==> 
      
/*
      let myPrm1 = {
        procFct: 'test',			// procFct = setDelivery / setReturn
        procPrm: ordRec
      }
*/
      console.log(fctName+': DEBUG: myPrm1Str='+JSON.stringify(myPrm1));  // debug
      // ==> 

      
      let reqParams = {
        rec: myPrm1,
        itemRecs: ordItemRecs,
      };
      const { data } = await axios.post(
        reqUrl,
        reqParams
      );
      
      console.log(fctName+': DEBUG: dataStr='+JSON.stringify(data));  // debug
      // ==> dataStr={"errCode":1,"errMsg":"","rec":{},"recsNb":3,"recs":[[{"msgDebug":"DEBUG: Remove pending order: id=1 ..."}],{"fieldCount":0,"affectedRows":0,"insertId":0,"serverStatus":42,"warningCount":0,"message":"","protocol41":true,"changedRows":0},[{"retcode":null}]]}
      // refCartResp.value.errCode = data.errCode; 
      // refCartResp.value.errMsg = data.errMsg;
      
      if (fctCallback) {
        // fctCallback has been defined
        // console.log(fctName+': DEBUG: callback: dataStr='+JSON.stringify(data));  // prov_debug
        // ==> dataStr={
        
        // efCartResp.value.errMsg = data.errMsg;
        let resp = {resp:{errCode:1,errMsg:''}};
        fctCallback(resp);
      } else {
        console.log(fctName+': DEBUG: no callback retDataStr='+JSON.stringify(data));  // prov_debug
      }
      
      
    } catch (ex) {
      if (ex.response) {
        // Request made and server responded
        console.log(fctName+': EXC: exMessage='+ex.message+', exStatus='+ex.response.status+', exData='+ex.response.data);
        // ==> exStatus=500, exData=Error: errCode=9002
        if (fctCallback) {
          let resp = {errCode:501, errMsg:ex.message};
          fctCallback({resp:resp});
        }
      } else {
        console.log(fctName+': EXC: ex='+ex.stack);
      }
    } finally {
      // refCartLoading.value = false;
    }
  } // ascDeliverOrdItms
  
  
  /* ==================================================================================
   * ascOrdersConfirm: Send notification to confirm order
   * reqParams { fct: myFct, rec: refRec.value, } :Input parameters
   * fctCallback :Callback function, if defined
   */
  async function ascOrdersConfirm(reqParams, fctCallback) {
    const fctName = 'svcClientApi.ascOrdersConfirm';
    /*const reqUrl = '/api/client/findRec';*/
	const reqUrlSendNotification = '/api/msg/sendNotification';
    
    /*refClientLoading.value = true;*/

      let msgParams;
      let data2;
      
      msgParams = {
			msgId: 'ordConfirm',	//  Message ID: pswReset, pswChanged, usrLogin, etc defined in refmsg_xx.json
			msgMode: reqParams.clientRec.sMsgMode,     // 'E':Email, 'M':SMS
			usrEmail: reqParams.clientRec.sEmail,   // User's Email
			usrMobile: reqParams.clientRec.sMobile, // User's Mobile
      orderId: reqParams.ordId,
          };
          
      data2 = await axios.post(
			reqUrlSendNotification,
			msgParams   // { fct: myFct, rec: refRec.value, }
      );
      
      
      if (fctCallback) {
        // fctCallback has been defined
        // console.log(fctName+': DEBUG: to fctCallback');  // debug
        let resp = {resp:{}, rec: reqParams.clientRec};
        fctCallback(resp);
      } else {
        console.log(fctName+': DEBUG: no callback');  // prov_debug
      }

    /*} catch (ex) {
      if (ex.response) {
        // Request made and server responded
        console.log(fctName+': EXC: exStatus='+ex.response.status+', exData='+ex.response.data);
        // console.log('exrespdata:'+ex.response.data);  // 9002:DB Exception:2021-04-08 16:59:36 - mdlDbMysql.dbFind
        // console.log('exrespstatus:'+ex.response.status);  // 500
        // console.log('exrespheader:'+ex.response.headers);
      } else {
        console.log(fctName+': EXC: ex='+ex.stack);
      }
    } finally {
      refClientLoading.value = false;
    }*/

  } // ascOrdersConfirm

  return  {
      refOrdersResp: computed(() => refOrdersResp.value),
      refOrdersLoading: computed(() => refOrdersLoading.value),
      refOrdersRecs: computed(() => refOrdersRecs.value),
      refOrdersRecsNb: computed(() => refOrdersRecsNb.value),
      refOrdersRec,
      refRetPhDeday,  // confif: sKey=OrdRetPhDelay: nb days after delivery to Penghu to enable order return
      refRetTwDeday,  // confif: sKey=OrdRetTwDelay: nb days after delivery to Taiwan to enable order return
      fctGetRndNumber,
      fctGetDateStr,
      ascOrdersGetRecs,
      ascOrdersGetItemRecs,
      ascConfigGetRec,  // ascConfigGetRec: Request REST API services for the record having the specified key
      ascOrdersFindRec,
      ascOrdersSaveRec,
      ascOrdersDeleteRec,  // ascOrdersDeleteRec: Send request to delete a record to REST API services
      ascOrdersSetStaffId,
      ascDeliverOrdItms,
      ascOrdersConfirm,
  }
}


