<template>
  
  <div>
    <center>
    <br>
    
    <span v-if="refIsLoading"><br><br>Loading ...<br><br></span>
    <span v-else>
      <form novalidate @submit.prevent=" onSubmit" v-on:keyup="frmEvtKeyUp" >
        <center>
        <div class="divHor02Shadow">
          <table border="0">
            <tr>
              <td align="center" colspan="2">
                <h1>{{ refDico.tleResetPassword }}</h1>
                <!-- // ==> errCode = [1 ... 99]:OK message, [100 ... 899]:Error message, [900 ... 999]:Error message, [[1000 ... 999]:Process aborted -->
                <span v-if="(refResp.errCode > 0) && (refResp.errCode <= 99)"><div class="divOk">{{ refResp.errMsg }}<br>&nbsp;</div></span>
                <span v-if="(refResp.errCode > 100) && (refResp.errCode <= 499)"><div class="divOk">{{ refResp.errMsg }}<br>&nbsp;</div></span>
                <span v-if="refResp.errCode == 901"><div class="divError"> {{ refDico.errInvalidLoginId }}<br>&nbsp;</div></span>
                <span v-if="refResp.errCode >= 500"><div class="divError">{{ refResp.errMsg }}</div></span>
              </td>
            </tr>

              <tr>
                <td>{{ refDico.lblUsrPassword }} </td>
                <td><input id="sPassword" ref="refFocus" v-model="refRec.sPassword" v-bind:placeholder="refDico.infoPassword" type="password" /></td>
              </tr>
              <tr>
                <td>{{ refDico.lblUsrPassword2 }} </td>
                <td><input id="sPassword2" v-model="refRec.sPassword2" v-bind:placeholder="refDico.lblUsrPassword2" type="password" /></td>
              </tr>
              <tr>
                <td colspan="2">{{ refDico.lblUsrPswSize }}</td>
              </tr>

            <tr>
              <td align="center" colspan="2">
                <br>
                <a class="btnStd01" v-on:click="frmAction('resetPsw')"><i class="fa fa-sign-in fa-1x"></i>&nbsp;{{ refDico.tleResetPassword }}</a>
                <br>&nbsp;
              </td>
            </tr>
          </table>
        </div>
        </center>
      </form>
    </span>
    
    <br>&nbsp;
    </center>
  </div>  
  
</template>

<script>
  import {
    ref, refs, computed, onActivated, onBeforeMount, onBeforeUnmount, onBeforeUpdate,
    onDeactivated, onErrorCaptured, onMounted, onUnmounted, onUpdated, onRenderTracked, onRenderTriggered
  } from 'vue';
  import router from '@/router/index.js'
  import { useRoute } from 'vue-router';
  import myApp from '@/myApp.vue';
  import useReferential from '@/modules/mdlReferential.js';
  import useTools from '@/utils/vjsTools.js'; 
  import useClientApi from '@/services/svcClientApi.js';
  import useCartApi from '@/services/svcCartApi.js';
  
  export default {
    setup() {
      const route = useRoute();
      const { refDicoLang, refDicoAttr, refPageAttr, ascSetLang } = useReferential();
      const { tlsCheckElementKey } = useTools();
      let { ascClientLogin, ascClientSetPsw, ascClientResetPsw, refClientPswReset, ascClientFindRec, ascClientSaveRec, ascClientDeleteRec, refClientResp, refClientLoading, refClientRec } = useClientApi();
      let { ascCartGetRecs, ascCartGetItemRecs, ascCartFindRec, fctCartGetCliId, fctCartSetCliId, ascCartSaveRec, ascCartDeleteRec, refCartResp, refCartLoading, refCartRec, refCartItemRecs, fctCartDelItem } = useCartApi();
      
      
      let refDico = ref({});
      
      let refResp = ref({});
      let refRec = ref({loginId:'', loginPsw:''});
      let refIsLoading = ref(true);
      
      let refFocus = ref(null);
      
      // onBeforeMount(() => console.log('before mount'));
      // onMounted(() => console.log('mounted'));
      // onBeforeUpdate(() => console.log('before update'));
      // onUpdated(() => console.log('updated'));
      // onBeforeUnmount(() => console.log('before unmount'));
      // onUnmounted(() => console.log('unmounted'));
      // ??? onActivated(() => console.log('activated'));                // ???
      // ??? onDeactivated(() => console.log('deactivated'));            // ???
      // ??? onErrorCaptured(() => console.error('error captured'));     // ???
      // ??? onRenderTracked(() => console.log('render tracked'));       // ???
      // ??? onRenderTriggered(() => console.log('render triggered'));   // ???
      
      onBeforeMount(async () => {
        console.log('vwsClientSetPsw.onBeforeMount: pgAction='+route.params.pgAction+',id='+refClientRec.value.id+'/'+refClientRec.value.sMobile+'/'+refClientRec.value.sEmail);
      }); // onBeforeMount
      
      onMounted(async () => {
        console.log('vwsClientSetPsw.onMounted: pgAction='+route.params.pgAction+',id='+refClientRec.value.id+'/'+refClientRec.value.sMobile+'/'+refClientRec.value.sEmail);
        
        refDico.value = refDicoAttr.value;
        refPageAttr.value.title = refDico.value.menu115NewPassword;
        
        frmAction('new');
        
      }); // onMounted

      /**** Without callback (begin) ****
      // Without callback, the record can not be altered at loading
      refResp = computed(() => {
        // console.log('vwsClientSetPsw.computed: DEBUG: refResp=refClientRespStr='+JSON.stringify(refClientResp.value));  // debug
        return refClientResp.value;
      });
      refRec = computed(() => {
        // console.log('vwsClientSetPsw.computed: DEBUG: refRec=refClientRecStr='+JSON.stringify(refClientRec.value));  // debug
        return refClientRec.value;
      });
      **** Without callback (end) ****/
      
      /**** With callback (begin) ****/
      // Callback is required if altering the record is required at loading
      function reqCallback(resp) {
        // Set data of the record
        refResp.value = resp.resp;
        // refRec.value = resp.rec;
        // console.log('vwsClientSetPsw.reqCallback: DEBUG: respStr='+JSON.stringify(resp));  // prov_debug
        // ==> respStr={"resp":{"errCode":101,"errMsg":"Record not found"},"rec":{}}
        // 
        
        if (refResp.value.errCode == 1) {
          // Record loaded correctely. Reset error message
          refResp.value.errCode = 0;
          refResp.value.errMsg = '';
        }
        
        // You may alter the record here
        // ...
        
        // console.log('vwsClientSetPsw.reqCallback: DEBUG: refRecStr='+JSON.stringify(refRec.value));  // prov_debug
        // ==> 
        
      }
      /**** With callback (end) ****/
      
      
      function frmEvtKeyUp(evt) {
        // console.log('vwsClientSetPsw.frmEvtKeyUp: DEBUG: evt='+evt.keyCode);
        if (evt.keyCode == 13) {
          // ENTER
          frmAction('resetPsw');
        }
        
      } // frmEvtKeyUp
      
      function openPage(pgName) {
        console.log('vwsClientSetPsw.openPage: pgName='+pgName);
        
        router.push({name:pgName,params:{pgAction:'init',prmId:''}});
      }
      
      function frmActionDone(myResp) {
        // console.log('vwsClientSetPsw.frmActionDone: myRespStr='+JSON.stringify(myResp)); // prov_debug
        // ==> myRespStr={"resp":{"errCode":1,"errMsg":"OK"},
		//	"rec":{"id":1,"iStatus":1,"dtmCreate":"2020-01-02 00:00:00","dtmModif":"2021-05-16 16:50:12","dtmValidMob":null,"dtmValidEmail":null,"sPassword":"tmp123",
		//	"sMobile":"cli123456789","sEmail":"jpn_email@yahoo.com",
		//	"sGenderCode":"M","sFirstName":"Cli JackBis","sLastName":"CLI SMITH","sAddr1":"1 bd Elysée","sAddr2":"1 FL","sZipCode":"99000","sCity":"cty0110","sProvince":"dep010","sMsgMode":"E","sCheckCode":null,"sLevel":"A","sNote":null,"sComCode":"Cli001b","sComName":"Société 001b","sComTel":null,"sComFax":null,"sDepartment":"dep001","sPassword2":"tmp123"}}
        
		let msgValue;
        switch(myResp.resp.errCode) {
          case 1: // OK: done successfully
            
            // fctCartSetCliId(refClientRec.value.id); // Record clientId into the cookies
            refResp.value.errCode = myResp.resp.errCode;
            refResp.value.errMsg = myResp.resp.errMsg;

            // openPage('pgCart1');
			/*
			if ((myResp.rec.sMobile != null) && (myResp.rec.sMobile != "")) {
				// Sent reset msg by SMS
				console.log('vwsClientSetPsw.frmActionDone: SMS refClientRecStr='+JSON.stringify(refClientRec.value)); // prov_debug
			} else if ((myResp.rec.sMobile != null) && (myResp.rec.sMobile != "")) {
				// Sent reset msg by Email
				console.log('vwsClientSetPsw.frmActionDone:Email refClientRecStr='+JSON.stringify(refClientRec.value)); // prov_debug
			} else {
				refResp.value.errCode = 901;
				console.log('vwsClientSetPsw.frmAction: No SMS&Email: '+ myResp.resp.errCode +': '+myResp.errMsg); // prov_debug
			}
			*/

			if (myResp.rec.sMsgMode == "E") {
				msgValue = myResp.rec.sEmail;
				refClientPswReset.value.msgValue = msgValue.substring(0, 2) + "...@..." + msgValue.substring(msgValue.length - 6);
			} else {
				msgValue = myResp.rec.sMobile;
				refClientPswReset.value.msgValue = msgValue.substring(0, 2) + "......" + msgValue.substring(msgValue.length - 2);
			}
			refClientPswReset.value.msgMode = myResp.rec.sMsgMode;
			refClientRec.value = {}; // Reset client's data
			
			console.log('vwsClientSetPsw.frmActionDone: msgMode='+refClientPswReset.value.msgMode+', msgValue='+refClientPswReset.value.msgValue); // prov_debug
			openPage('pgClientLogin');
            break;
          // case 501: // errMsg501 = 'Invalide function';       // Unexpected function
          default:
            refResp.value.errCode = 901;
            console.log('vwsClientSetPsw.frmAction: Ko: '+ myResp.resp.errCode +': '+myResp.errMsg); // prov_debug
            break;
        }
      } // frmActionDone
      
      function frmAction(myFct) {
        // myFct = new, insert, update, delete
        let isValid = true;
        let myParams;
        
        console.log('vwsClientSetPsw.frmAction: DEBUG: myFct='+myFct+', loginId='+refClientRec.value.id+'/'+refClientRec.value.sMobile+'/'+refClientRec.value.sEmail); // prov_debug
        switch(myFct) {
          case 'resetPsw':
            if ((refClientRec.value.sMobile == '') && (refClientRec.value.sEmail == '')) {
              isValid = false;
              refResp.value.errCode = 901;
            }
            if ((refRec.value.sPassword == '') || (refRec.value.sPassword2 == '') || 
                (refRec.value.sPassword != refRec.value.sPassword) || (refRec.value.sPassword.length < 8)) {
              isValid = false;
              refResp.value.errCode = 901;
            }
            if (isValid == true) {
              refRec.value.errCode = 0; // Reset errCode

              let tblCriteria = " sMobile = '"+refClientRec.value.sMobile+"' OR sEmail = '"+refClientRec.value.sEmail+"' ";
              let myParams = {
                tblCriteria: tblCriteria,
                psw: refRec.value.sPassword
              };
             console.log('vwsClientSetPsw.frmAction: DEBUG: tblCriteria='+tblCriteria); // prov_debug
              // ascClientResetPsw(myParams, frmActionDone); // Callback is required if altering the record is required at loading
              ascClientSetPsw(myParams, frmActionDone); // Callback is required if altering the record is required at loading
              
            } else {
              // console.log('vwsClientSetPsw.frmAction: Ko: myFct='+myFct+',loginId='+refRec.value.loginId+',psw='+refRec.value.loginPsw); // prov_debug
              refRec.value.errCode = 901; // 901: Invalid ID
            }
            break;
        }
        refFocus.value.focus();
      } // frmAction
      
      return {
        refDico,
        refIsLoading: computed(() => refClientLoading.value),
        refResp,
        refRec,
        refFocus,
        frmEvtKeyUp,
        openPage,
        frmAction,
      }
    }, // onMounted
  } // setup
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .divOk {
    color: #00FF00;
  }
  .divError {
    color: #FF0000;
  }
  input:disabled {
    background-color: #F0F0F0;
  }
  .link:hover {
    cursor: pointer;
    color: #90150D;
  }

</style>