<template>
  <span v-if="refCartItemRecs.length == 0"><br><br>Empty cart<br><br></span>
  <span v-else>
     
  
    <!-- Steps (begin) -->
    <center>
      <br>
      
        <div class="stepContainer">
          <div class="stepLink">
            <div class="stepItem">
              <a id="btnStepA" class="btnStepOn" title="stepCartInfo" style="cursor:pointer" v-on:click="openPage('pgCart1');">{{ refDico.stepCart1 }}</a>
            </div>
            <div class="stepItem">
              <a id="btnStepB" class="btnStepOn" title="stepCartDeliveryInfo"  style="pointer-events: none">{{ refDico.stepCart2 }}</a>
            </div>
            <div class="stepItem">
              <a id="btnStepC" class="btnStepOff" title="stepCartPaymentInfo"  style="pointer-events: none">{{ refDico.stepCart3 }}</a>
            </div>
            <div class="stepItem">
              <a id="btnStepD" class="btnStepOff" title="stepCartValidationInfo"  style="pointer-events: none">{{ refDico.stepCart4 }}</a>
            </div>
            <div class="stepItem">
              <a id="btnStepE" class="btnStepOff" title="stepCartSummaryInfo"  style="pointer-events: none">{{ refDico.stepCart5 }}</a>
            </div>
          </div>
        </div>
        <br>
    </center>
    <!-- Steps (end) -->
  
  
  <div style="width:100%; text-align:center;">
    
    <div style="display:inline-block; vertical-align:top; " >
      <!-- Content (begin) -->
      <center>
        
        <div class="myCartDiv" style="display: inline-block;">
          <form novalidate @submit.prevent="onSubmit" v-on:change="frmChanged()" >
          <b>{{ refDico.tleCart2 }}</b><br>&nbsp;
          <br>
          
          <!-- Delivery mode='d': Home delivery -->
          <table style="max-width: 500px;" border="0">
            <thead>
              <tr>
                <td colspan="2">
                <!-- input type="radio" name="delivery" id="deliveryd" value="d" v-model="refCartRec.deliveryMode" --> 
				<b style=" font-size: 25px;">&nbsp;{{ refDico.lbldomicile }}</b>
                </td>
              </tr>
              <tr>&nbsp;</tr>
            </thead>
            <tbody>
              <tr>
                <td width="40%">{{ refDico.lblDestinyName }} (*)</td>
                <td width="60%">
                  <select id="sGenderCode" v-model="refCartRec.sDelGenderCode" style="width:60px;">
                    <option value="M">{{ refDico.lblUsrGenderM }}</option>
                    <option value="F">{{ refDico.lblUsrGenderF }}</option>
                  </select>
                  <input type="text" id="sFirstName" v-model="refCartRec.sDelFirstName" v-bind:placeholder="refDico.infoFirstName" style="width:95px;" />
                </td>
              </tr>
              
              <tr>
                <td>{{ refDico.lblMobile }} (*)</td>
                <td><input type="text" id="sMobile" v-model="refCartRec.sDelMobile" /></td>
              </tr>
              
              <tr>
                <td>{{ refDico.lblProvince }} / {{ refDico.lblCity }}</td>
                <td colspan="3" nowrap="1">
                  <select v-model="refCartRec.sDelProvince" :key="refState" style="width:50%;" v-on:change="selectState()">
                    <option v-for="(name, value) in refState" :key="value" v-bind:value="value">{{ name }}</option>
                  </select>
                  <select v-model="refCartRec.sDelCity" :key="refCity" style="width:50%;" >
                    <option v-for="(name, value) in refCity" :key="value" v-bind:value="value">{{ name }}</option>
                  </select>
                </td>
              </tr>
              
              <tr>
                <td>
                  {{ refDico.lblZipCode }}
                </td>
                <td>
                  <input type="text" id="sZipCode" v-model="refCartRec.sDelZipCode" style="width:60px;" />
                </td>
              </tr>
              
              <tr>
                <td>{{ refDico.lblAddr1 }}</td>
                <td colspan="3"><input type="text" id="sAddr1" v-model="refCartRec.sDelAddr1" style="width:98%;" /></td>
              </tr>
              <tr>
                <td style="white-space: normal;" colspan="4" class="tddescription">
                  <br><br>{{ refDico.lbldescriptiondomicile1 }}
                  <br>{{ refDico.lbldescriptiondomicile2 }}
                  <br>{{ refDico.lbldescriptiondomicile3 }}
                  <br>{{ refDico.lbldescriptiondomicile4 }}
                  <br><br>{{ refDico.lbldescriptiondomicile5 }}
                  <br>{{ refDico.lbldescriptiondomicile6 }}
                  <br><br>{{ refDico.lbldescriptiondomicile7 }}
                </td>
              </tr>
              <tr><td>&nbsp;</td></tr>
            </tbody>
          </table>
          <br>
          <!-- Delivery mode='d': Home delivery -->

          </form>
        </div>

      </center>
      <!-- Content (end) -->
    </div>
    
    <div style="display:inline-block; vertical-align:top;">
      <cpnCart />
    </div>
    <br>
    <br>

      <a class="btnStd01" v-on:click="verifyOrder();" >{{ refDico.lblValidate }}</a>

    <br>
    <br>
    <br>&nbsp;
    
  </div>
    
  </span>
</template>

<script>
  import { ref, computed, onBeforeMount, onMounted, onUpdated } from 'vue';
  import router from '@/router/index.js';
  import { useRoute } from 'vue-router';
  import useReferential from '@/modules/mdlReferential.js'; 
  import useTools from '@/utils/vjsTools.js'; 
  import useClientApi from '@/services/svcClientApi.js';
  import useCartApi from '@/services/svcCartApi.js';
  import cpnCart from '@/components/cpnCart';

  export default {
    components: {
      cpnCart,
    },
    setup() {
      const route = useRoute();
      const { refDicoLang, refDicoAttr, refPageAttr, refItmTypeAttr, ascSetLang, refSlctMenu, fctExtractMenu, fctExtractSubmenu, refDepartmentAttr, fctExtractState, fctExtractCity } = useReferential();
      const { tlsCheckElementKey } = useTools();
      let { ascClientFindRec, ascClientSaveRec, ascClientDeleteRec, refClientResp, refClientLoading, refClientRec } = useClientApi();
      let { ascCartGetRecs, ascCartGetItemRecs, ascCartFindRec, ascCartSaveRec, ascCartDeleteRec, refCartResp, refCartLoading, refCartRec, refCartItemRecs, fctCartDelItem, calcCartTotal } = useCartApi();
      
      let refDico = ref({});
      let refState = ref({});
      let refCity = ref({});
      
      let refResp = ref({});
      let refRec = ref({});
      
      let refStateLbl = ref('');
      
      onBeforeMount(() => {
      
        console.log('vwsCart2.onBeforeMount: DEBUG: refClientRecId='+refClientRec.value.id);  // prov_debug
        if (refClientRec.value.id) {
          refPageAttr.value.pgAction = 'init';
          refPageAttr.value.pgName = 'pgCart2';
        } else {
          // Client need to login before adding preferred item
          openPage('pgClientLogin');
        }
      });
      
      onMounted(async () => {
        window.scrollTo(0, 0);
        refDico.value = refDicoAttr.value;
        refPageAttr.value.title = refDico.value.tleCart;
        
        refState.value = fctExtractState();
        
        let reqParams = { 
              criteria: "idParent = "+refClientRec.value.id, 
              orderby: "id ASC", // "sItmCode1 ASC",
              };
        // KO ascCartGetItemRecs(reqParams);  // KO // Without callback, the record can not be altered at loading
        
        
        ascCartGetItemRecs(reqParams, reqCallback);  // Callback is required if altering the record is required at loading
      });
      
      /**** Without callback (begin) ****
      // Without callback, the record can not be altered at loading
      refResp = computed(() => {
        console.log('vwsCart2.computed: DEBUG: refResp=refItemRespStr='+JSON.stringify(refItemResp.value));  // prov_debug
        return refItemResp.value;
      });
      **** Without callback (end) ****/
      
      /**** With callback (begin) ****/
      // Callback is required if altering the record is required at loading

      function reqCallback(myResp) {
        console.log('vwsCart2.reqCallback: DEBUG: respStr='+JSON.stringify(myResp.resp));  // prov_debug
        // ==> respStr={"resp":{"errCode":1,"errMsg":"OK"},"recs":[{"id":154,"sLabel":"統一布丁（大）","iStatus":null,"dtmCreate":"2021-04-15 12:00:38","dtmModif":"2021-04-15  ...
        
        // updateCatalogRecs(refPageAttr.value.pgName, false);  // =true:catalog with topMemu/subMenu, =false:other catalog filter
        
        // Set client's data to the shopping cart
        // refCartRec: API:rtsApiCart.myTblCartOrd
        // refClientRec: API:rtsApiClient.myTblClient
        refCartRec.value.idClient = refClientRec.value.id;
        refCartRec.value.sDelProvince = refClientRec.value.sProvince;
        refCartRec.value.sDelCity = refClientRec.value.sCity;
        refCartRec.value.sDelZipCode = refClientRec.value.sZipCode;
        refCartRec.value.sDelAddr1 = refClientRec.value.sAddr1;
        refCartRec.value.sDelMobile = refClientRec.value.sMobile;
        refCartRec.value.sDelGenderCode = refClientRec.value.sGenderCode;
        refCartRec.value.sDelFirstName = refClientRec.value.sFirstName;
        refCartRec.value.sComCode = refClientRec.value.sComCode;
        refCartRec.value.sAssociation = "1";
        refCartRec.value.deliveryMode = "d";	// ='b':Shop pickup, 'd':Home delivery
        refCartRec.value.iOrdTaxRate = 5;
        updateSelect();
        calcCartTotal();
      }
      /**** With callback (end) ****/
      
      onUpdated( () => {
        // console.log('vwsCart2.updated: deliveryMode='+refCartRec.value.deliveryMode);
        // console.log('vwsCart2.updated: sDelProvince='+refCartRec.value.sDelProvince);
        //calcCartDelivery();
        calcCartTotal();
        
        
      });                // Event triggered on open page
      
      function openPage(pgName) {
        console.log('cpnHeader.openPage: pgName='+pgName);
        // this.$router.push({name:pgName,params:{pgAction:'init',prmId:''}});
        router.push({name:pgName,params:{pgAction:'init',prmId:''}});
      }
      
      function updateSelect() {
        // List of department
        // console.log('vwsCart2.updateSelect: DEBUG: refDepartmentAttrStr='+JSON.stringify(refDepartmentAttr.value));  // debug
        // ==> refDepartmentAttrStr={"dep001":"Sales services","dep002":"Dairy services","dep003":"Sauce services","dep004":"Marketing services"}
        
        // Set default sDepartment if not found
        // refCartRec.value.sDelDepartment = tlsCheckElementKey(refDepartmentAttr.value, refCartRec.value.sDelDepartment, true);  // true:return first department if not found
        
        // Set default sProvince if not found
        refCartRec.value.sDelProvince = tlsCheckElementKey(refState.value, refCartRec.value.sDelProvince, true);  // true:return first stateCode if not found
        selectState();	// Update tax rate according to the sDelProvince
        
        // Get refCity related to the sProvince
        refCity.value = fctExtractCity(refCartRec.value.sDelProvince);
        // Set default sCity if not found
        refCartRec.value.sDelCity = tlsCheckElementKey(refCity.value, refCartRec.value.sDelCity, true);  // true:return first stateCode if not found
      }
      
      function frmChanged() {
        console.log('vwsCart2.frmChanged: DEBUG: sProvince='+refRec.value.sProvince); // prov_debug
        // ==> sProvince=dep020
        
        // Get refCity related to the sProvince
        refCity.value = fctExtractCity(refCartRec.value.sDelProvince);
        console.log('vwsCart2.frmChanged: DEBUG: refCityStr='+JSON.stringify(refCity.value));  // debug
        // Set default sCity if not found
        refCartRec.value.sDelCity = tlsCheckElementKey(refCity.value, refCartRec.value.sDelCity, true);  // true:return first stateCode if not found
        
      } // frmChanged

      function selectState(){
        console.log('vwsCart2.frmChanged: DEBUG: refCartRec.sDelProvince='+refCartRec.sDelProvince);  // debug
         if(refCartRec.value.sDelProvince == "dep010"){
          refCartRec.value.iOrdTaxRate = 0;
        }else{
          refCartRec.value.iOrdTaxRate = 5;
        }
      } //selectState

      function verifyOrder() {
        let tmpStates = fctExtractState();
        let index = 0;
        refStateLbl.value = tmpStates[refCartRec.value.sDelProvince];
        // console.log('vwsCart2.verifyOrder: DEBUG: refStateLbl['+refCartRec.value.sDelProvince+']='+refStateLbl.value+', tmpStatesStr='+JSON.stringify(tmpStates));
        // console.log('vwsCart2.frmChanged: DEBUG: refCartItemRecs='+JSON.stringify(refCartItemRecs.value));  // debug
        
        for (index; index < refCartItemRecs.value.length; index++){          
          console.log('vwsCart2.frmChanged: DEBUG: refCartItemRecs.value[index]='+JSON.stringify(refCartItemRecs.value[index]));  // debug
          
          if (refCartItemRecs.value[index].sGrpCode.indexOf('P') >= 0 && refCartRec.value.sDelProvince != "dep010"){
            console.log('vwsCart2.verifyOrder: DEBUG: tmpRec product only available in Penghu');
            alert(refDicoAttr.value.msgCart2PopUp);

            return;
          } 
        }
        openPage('pgCart3');
      } // verifyOrder


      return {
        refDico,
        refPageAttr,
        refItmTypeAttr,
        refState,
        refCity,
        refCartResp, 
        refCartLoading, 
        refCartRec,
        refCartItemRecs,
        openPage,
        frmChanged,
        verifyOrder,
        selectState,
        
      }
    },

  };

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>