import { ref, computed } from "vue";
import useReferential from '@/modules/mdlReferential.js'; 

const axios = require("axios");

// Global variable that can be shared state with Composition API
let users = ref([]);	// Array of uers

// Preferred
let refPreferredLoading = ref(false);
let refPreferredRecs = ref([]);	// Array of records
let refPreferredRec = ref({});  // Current record
let refPreferredRecsNb = ref(0);
let refPreferredResp = ref({errCode:0, errMsg:'159'});

export default function usePreferredApi() {
  
  const { refSlctMenu } = useReferential();
  
  function isMenuPreferredValid(myObj) {
    const fctName = 'svcPreferredApi.isMenuPreferredValid';
    
    let isValid = false;
    
    // refSlctMenu = ref({topMenuCode:'', topMenuName:'', subMenuCode:'', subMenuName:'', fctCallback:'' });
    if (refSlctMenu.value.topMenuCode == myObj.sType1Code) {
      
      
      if (refSlctMenu.value.subMenuCode != '*') {
        // Both topMenu and subMenu
        if (refSlctMenu.value.subMenuCode == myObj.sType2Code) {
          // console.log(fctName+': DEBUG: subMenu=true: subMenuCode='+refSlctMenu.value.topMenuCode+', subMenuCode='+refSlctMenu.value.subMenuCode+'/'+myObj.sType2Code);  // prov_debug
          isValid = true;
        } else {
          // console.log(fctName+': DEBUG: subMenu=false: subMenuCode='+refSlctMenu.value.topMenuCode+', subMenuCode='+refSlctMenu.value.subMenuCode+'/'+myObj.sType2Code);  // prov_debug
        }
        
        // isValid = true; // prov_debug
        
      } else {
        // Only topMenuCode
        // console.log(fctName+': DEBUG: topMenu=true: topMenuCode='+refSlctMenu.value.topMenuCode+', subMenuCode='+refSlctMenu.value.subMenuCode);  // prov_debug
        isValid = true;
      }
      
    } else {
      // console.log(fctName+': DEBUG: false: topMenuCode='+refSlctMenu.value.topMenuCode+'/'+myObj.sType1Code);  // prov_debug
    }
    
    return isValid;
  } // isMenuPreferredValid
  
  function isCatalogPreferredValid(pgName, myObj) {
    const fctName = 'svcPreferredApi.isCatalogPreferredValid';
    
    let isValid = true;
    
    // sFlgMenu: 1234567: flg to show preferred in corresponding menu
    //    refRec.value.sMenu02Discount = (refRec.value.sFlgMenu.indexOf('2') >= 0)? true:false;
    //    refRec.value.sMenu03NewProducts = (refRec.value.sFlgMenu.indexOf('3') >= 0)? true:false; 
    //    refRec.value.sMenu04Exclusive = (refRec.value.sFlgMenu.indexOf('4') >= 0)? true:false; 
    //    refRec.value.sMenu05BestSellers = (refRec.value.sFlgMenu.indexOf('5') >= 0)? true:false; 
    //    refRec.value.sMenu06Wholesale = (refRec.value.sFlgMenu.indexOf('6') >= 0)? true:false; 
    //    refRec.value.sMenu07Distribution = (refRec.value.sFlgMenu.indexOf('7') >= 0)? true:false; 
    
    let filterValue = '';
    switch(pgName) {
      case 'myMenu02Discount':      filterValue = '2'; break;
      case 'myMenu03NewProducts':   filterValue = '3'; break;
      case 'myMenu04Exclusive':     filterValue = '4'; break;
      case 'myMenu05BestSellers':   filterValue = '5'; break;
      case 'myMenu06Wholesale':     filterValue = '6'; break;
      case 'myMenu07Distribution':  filterValue = '7'; break;
    }
    if (myObj.sFlgMenu.indexOf(filterValue) >= 0) isValid = true;
    
    console.log(fctName+': DEBUG: pgName='+pgName+', sFlgMenu='+myObj.sFlgMenu+'/'+filterValue+', isValid='+isValid);
    
    return isValid;
  } // isCatalogPreferredValid
  
    
    
  /* ==================================================================================
   * ascPreferredGetRecs: Request REST API services for an array of records
   * reqParams { criteria: "sFlgMenu LIKE '%2%'", } :Input parameters
   */
  async function ascPreferredGetRecs(reqParams, fctCallback) {
    const fctName = 'svcPreferredApi.ascPreferredGetRecs';
    const reqUrl = '/api/preferred/getRecs';
    
    // let reqParams = {};
    refPreferredLoading.value = true;
    try {
      const { data } = await axios.post(
        reqUrl,
        reqParams
      );
      // console.log(fctName+': DEBUG: retDataStr='+JSON.stringify(data));  // debug
      // ==> retDataStr={"errCode":1,"errMsg":"OK","rec":{},"recsNb":1,"recs":[{"id":1,"iStatus":1,"dtmCreate":"2020-01-01T23:00:00.000Z","dtmModif":null,"dtmValidMob":null,"dtmValidEmail":null,"sPassword":"adm123","sMobile":"123456789","sEmail":"jpn_email@yahoo.com","sGenderCode":"M","sFirstName":"Jack","sLastName":"SMITH","sAddr1":"1 bd Elysée","sAddr2":"1 FL","sZipCode":"99000","sCity":"NewCity","sProvince":"County","sMsgMode":"E","sCheckCode":null,"sLevel":"A","sNote":null}]}      
      
      refPreferredResp.value.errCode = data.errCode; 
      refPreferredResp.value.errMsg = data.errMsg;
      switch(data.errCode) {
        case 1: // OK: rec found
          // Get data
          refPreferredRecs.value = data.recs;
          refPreferredRecsNb.value = refPreferredRecs.value.length;
          break;
        default:
          refPreferredRec.value = [{}];
          refPreferredRecsNb.value = 0;
          break;
      }
      
      if (fctCallback) {
        // fctCallback has been defined
        // console.log(fctName+': DEBUG: to fctCallback retDataStr='+JSON.stringify(data));  // debug
        let resp = {resp:refPreferredResp.value, recs: refPreferredRecs.value};
        fctCallback(resp);
      } else {
        // console.log(fctName+': DEBUG: no callback retDataStr='+JSON.stringify(data));  // debug
      }
      
    } catch (ex) {
      if (ex.response) {
        // Request made and server responded
        console.log(fctName+': EXC: exStatus='+ex.response.status+', exData='+ex.response.data);
        // console.log('exrespdata:'+ex.response.data);  // 9002:DB Exception:2021-04-08 16:59:36 - mdlDbMysql.dbFind
        // console.log('exrespstatus:'+ex.response.status);  // 500
        // console.log('exrespheader:'+ex.response.headers);
      } else {
        console.log(fctName+': EXC: ex='+ex.stack);
      }
    } finally {
      refPreferredLoading.value = false;
    }
  } // ascPreferredGetRecs
  
  /* ==================================================================================
   * ascPreferredGetItemRecs: Request REST API services for an array of records with item data
   * reqParams { criteria: "sFlgMenu LIKE '%2%'", } :Input parameters
   */
  async function ascPreferredGetItemRecs(reqParams, fctCallback) {
    const fctName = 'svcPreferredApi.ascPreferredGetItemRecs';
    const reqUrl = '/api/preferred/getItemRecs';
    
    // let reqParams = {};
    refPreferredLoading.value = true;
    console.log(fctName+': DEBUG: reqParamsStr='+JSON.stringify(reqParams));  // debug
    try {
      let { data } = await axios.post(
        reqUrl,
        reqParams
      );
      // console.log(fctName+': DEBUG: retDataStr='+JSON.stringify(data));  // debug
      // ==> retDataStr={"errCode":1,"errMsg":"OK","rec":{},"recsNb":1,"recs":[{"id":1,"iStatus":1,"dtmCreate":"2020-01-01T23:00:00.000Z","dtmModif":null,"dtmValidMob":null,"dtmValidEmail":null,"sPassword":"adm123","sMobile":"123456789","sEmail":"jpn_email@yahoo.com","sGenderCode":"M","sFirstName":"Jack","sLastName":"SMITH","sAddr1":"1 bd Elysée","sAddr2":"1 FL","sZipCode":"99000","sCity":"NewCity","sProvince":"County","sMsgMode":"E","sCheckCode":null,"sLevel":"A","sNote":null}]}      
      
      refPreferredResp.value.errCode = data.errCode; 
      refPreferredResp.value.errMsg = data.errMsg;
      switch(data.errCode) {
        case 1: // OK: rec found
          // Get data

          // Copy array from data.recs to refPreferredRecs
          // refPreferredRecs.value = [];
          refPreferredRecs.value.length = 0;
          console.log(fctName+': DEBUG: data.recsLen='+data.recs.length);  // debug
          // refPreferredRecs.value = data.recs;
          data.recs.map(function(objElement, objIdx, objArray) {
            refPreferredRecs.value.push(objElement);
          });
          refPreferredRecsNb.value = refPreferredRecs.value.length;
          console.log(fctName+': DEBUG: refPreferredRecsLen='+refPreferredRecs.value.length);  // debug
          break;
        default:
          refPreferredRecs.value.length = 0;
          refPreferredRecs.value = [];
          refPreferredRecsNb.value = 0;
          break;
      }
      
      if (fctCallback) {
        // fctCallback has been defined
        // console.log(fctName+': DEBUG: to fctCallback retDataStr='+JSON.stringify(data));  // debug
        console.log(fctName+': DEBUG: fctCallback refPreferredRecsLen='+refPreferredRecs.value.length);  // debug
        // let resp = {resp:refPreferredResp.value, recs: refPreferredRecs.value};
        let resp = {resp:refPreferredResp.value};
        fctCallback(resp);
      } else {
        // console.log(fctName+': DEBUG: no callback retDataStr='+JSON.stringify(data));  // debug
      }
      
    } catch (ex) {
      if (ex.response) {
        // Request made and server responded
        console.log(fctName+': EXC: exStatus='+ex.response.status+', exData='+ex.response.data);
        // console.log('exrespdata:'+ex.response.data);  // 9002:DB Exception:2021-04-08 16:59:36 - mdlDbMysql.dbFind
        // console.log('exrespstatus:'+ex.response.status);  // 500
        // console.log('exrespheader:'+ex.response.headers);
      } else {
        console.log(fctName+': EXC: ex='+ex.stack);
      }
    } finally {
      refPreferredLoading.value = false;
    }
  } // ascPreferredGetItemRecs
  
  
  /* ==================================================================================
   * ascPreferredFindRec: Request REST API services for the record having the specified id
   * reqParams { fct: myFct, rec: refRec.value, } :Input parameters
   * fctCallback :Callback function, if defined
   */
  async function ascPreferredFindRec(reqParams, fctCallback) {
    const fctName = 'svcPreferredApi.ascPreferredFindRec';
    const reqUrl = '/api/preferred/findRec';
    
    refPreferredLoading.value = true;
    try {
      // # const { data } is the name set by axios.post. Can not be renamed.
      const { data } = await axios.post(
        reqUrl,
        reqParams   // { fct: myFct, rec: refRec.value, }
      );
      
      console.log(fctName+': DEBUG: dataStr='+JSON.stringify(data));  // prov_debug
      // ==> dataStr={"errCode":1,"errMsg":"OK","rec":{"id":1,"iStatus":1,"dtmCreate":"2020-01-01T23:00:00.000Z","dtmModif":null,"dtmValidMob":null,"dtmValidEmail":null,"sPassword":"adm123","sMobile":"123456789","sEmail":"jpn_email@yahoo.com","sGenderCode":"M","sFirstName":"Jack","sLastName":"SMITH","sAddr1":"1 bd Elysée","sAddr2":"1 FL","sZipCode":"99000","sCity":"NewCity","sProvince":"County","sMsgMode":"E","sCheckCode":null,"sLevel":"A","sNote":null},"recsNb":0,"recs":[{}]}
      
      // errCode = [1 ... 99] :OK message, [100 ... 999] :Error message, value = [1000 ... 999] :Process aborted
      refPreferredResp.value.errCode = data.errCode; 
      refPreferredResp.value.errMsg = data.errMsg;
      switch(data.errCode) {
        case 1: // OK: rec found
          // Get data
          refPreferredRec.value = data.rec;
          // You may alter data here
          refPreferredRec.value.sPassword = '';   // Reset password. It must be entered again by the user
          refPreferredRec.value.sPassword2 = '';
          break;
        case 2: // Record not found
          refPreferredRec.value = {};
          break;
        default: // Error occured at server sidebar
          refPreferredRec.value = {};
          break;
      }
      if (fctCallback) {
        // fctCallback has been defined
        // console.log(fctName+': DEBUG: to fctCallback');  // debug
        let resp = {resp:refPreferredResp.value, rec: refPreferredRec.value};
        fctCallback(resp);
      } else {
        console.log(fctName+': DEBUG: no callback');  // prov_debug
      }

    } catch (ex) {
      if (ex.response) {
        // Request made and server responded
        console.log(fctName+': EXC: exStatus='+ex.response.status+', exData='+ex.response.data);
        // console.log('exrespdata:'+ex.response.data);  // 9002:DB Exception:2021-04-08 16:59:36 - mdlDbMysql.dbFind
        // console.log('exrespstatus:'+ex.response.status);  // 500
        // console.log('exrespheader:'+ex.response.headers);
      } else {
        console.log(fctName+': EXC: ex='+ex.stack);
      }
    } finally {
      refPreferredLoading.value = false;
    }

  } // ascPreferredFindRec
  

  /* ==================================================================================
   * ascPreferredSaveRec: Send a record to save to REST API services
   */
   async function ascPreferredSaveRec(reqParams, fctCallback) {
    const fctName = 'svrPreferredApi.ascPreferredSaveRec';
    const reqUrl = '/api/preferred/saveRec';
    
    // console.log(fctName+': DEBUG: reqParamsStr='+JSON.stringify(reqParams));  // debug
    // ==> reqParamsStr={"id":"STA031"}

    refPreferredLoading.value = true;
    try {
      // # const { data } is the name set by axios.post. Can not be renamed.
      const { data } = await axios.post(
        reqUrl,
        reqParams
      );
      
      // console.log(fctName+': DEBUG: dataStr='+JSON.stringify(data));  // debug
      // ==> dataStr={"errCode":201,"errMsg":"Invalide function"}
      refPreferredResp.value.errCode = data.errCode; 
      refPreferredResp.value.errMsg = data.errMsg;
      
      if (fctCallback) {
        // fctCallback has been defined
        console.log(fctName+': DEBUG: callback: dataStr='+JSON.stringify(data));  // prov_debug
        let resp = {resp:refPreferredResp.value};
        fctCallback(resp);
      } else {
        console.log(fctName+': DEBUG: no callback retDataStr='+JSON.stringify(data));  // prov_debug
      }
      
      
    } catch (ex) {
      if (ex.response) {
        // Request made and server responded
        console.log(fctName+': EXC: exStatus='+ex.response.status+', exData='+ex.response.data);
        // console.log('exrespdata:'+ex.response.data);  // 9002:DB Exception:2021-04-08 16:59:36 - mdlDbMysql.dbFind
        // console.log('exrespstatus:'+ex.response.status);  // 500
        // console.log('exrespheader:'+ex.response.headers);
      } else {
        console.log(fctName+': EXC: ex='+ex.stack);
      }
    } finally {
      refPreferredLoading.value = false;
    }
  } // ascPreferredSaveRec
  
  /* ==================================================================================
   * ascPreferredDeleteRec: Send request to delete a record to REST API services
   */
   async function ascPreferredDeleteRec(reqParams, fctCallback) {
    const fctName = 'svrPreferredApi.ascPreferredDeleteRec';
    const reqUrl = '/api/preferred/deleteRec';
    
    // console.log(fctName+': DEBUG: reqParamsStr='+JSON.stringify(reqParams));  // debug
    // ==> reqParamsStr={"id":"STA031"}

    refPreferredLoading.value = true;
    try {
      // # const { data } is the name set by axios.post. Can not be renamed.
      const { data } = await axios.post(
        reqUrl,
        reqParams
      );
      
      // console.log(fctName+': DEBUG: dataStr='+JSON.stringify(data));  // debug
      // ==> dataStr={"errCode":201,"errMsg":"Invalide function"}
      refPreferredResp.value.errCode = data.errCode; 
      refPreferredResp.value.errMsg = data.errMsg;
      
      if (fctCallback) {
        // fctCallback has been defined
        console.log(fctName+': DEBUG: callback: dataStr='+JSON.stringify(data));  // prov_debug
        let resp = {resp:refPreferredResp.value};
        fctCallback(resp);
      } else {
        console.log(fctName+': DEBUG: no callback retDataStr='+JSON.stringify(data));  // prov_debug
      }
      
      
    } catch (ex) {
      if (ex.response) {
        // Request made and server responded
        console.log(fctName+': EXC: exStatus='+ex.response.status+', exData='+ex.response.data);
        // console.log('exrespdata:'+ex.response.data);  // 9002:DB Exception:2021-04-08 16:59:36 - mdlDbMysql.dbFind
        // console.log('exrespstatus:'+ex.response.status);  // 500
        // console.log('exrespheader:'+ex.response.headers);
      } else {
        console.log(fctName+': EXC: ex='+ex.stack);
      }
    } finally {
      refPreferredLoading.value = false;
    }
  } // ascPreferredDeleteRec
  

  return  {
      refPreferredResp: computed(() => refPreferredResp.value),
      refPreferredLoading: computed(() => refPreferredLoading.value),
      // refPreferredRecs: computed(() => refPreferredRecs.value),
      refPreferredRecs,
      refPreferredRecsNb: computed(() => refPreferredRecsNb.value),
      refPreferredRec,
      ascPreferredGetRecs,
      ascPreferredGetItemRecs,
      ascPreferredFindRec,
      ascPreferredSaveRec,
      ascPreferredDeleteRec,  // ascPreferredDeleteRec: Send request to delete a record to REST API services
  }
}
