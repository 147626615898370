<template>
  <div >
    
    <cpnItmLst />
    
  </div>
</template>

<script>
  import { onBeforeMount } from 'vue';
  import useReferential from '@/modules/mdlReferential.js'; 
  import cpnItmLst from '@/components/cpnItmLst'

  export default {
    components: {
      cpnItmLst,
    },
    setup() {
      const { refDicoAttr, refPageAttr, refSlctMenu } = useReferential();

      onBeforeMount(() => {
        refPageAttr.value.pgAction = 'init';
        refPageAttr.value.pgName = 'myMenu04Exclusive';
        refPageAttr.value.title = refDicoAttr.value.menu04Exclusive;
        refSlctMenu.value.topMenuName = refPageAttr.value.title;
        refSlctMenu.value.subMenuName = '';
      });

      return {
      }
    },

  };

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>