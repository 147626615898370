<template>

  <div>
    <!-- Steps (begin) -->
    <center>
    Page not found (404)
    </center>
  </div>
  
</template>

<script>
  import { ref, computed, onBeforeMount, onMounted } from 'vue';
  import router from '@/router/index.js';
  import { useRoute } from 'vue-router';
  import useReferential from '@/modules/mdlReferential.js'; 

  export default {
    setup() {
      const route = useRoute();
      const { refDicoLang, refDicoAttr, refPageAttr, refItmTypeAttr, ascSetLang, refSlctMenu, fctExtractMenu, fctExtractSubmenu } = useReferential();
      
      onBeforeMount(() => {
        console.log('vwsCart5.onBeforeMount: DEBUG routeParamsStr='+JSON.stringify(route.params));  // prov_debug
      });
      
      onMounted(async () => {
        console.log('vwsCart5.onMounted: DEBUG routeParamsStr='+JSON.stringify(route.params));  // prov_debug
      });
      
 
      function openPage(pgName) {
        console.log('cpnHeader.openPage: pgName='+pgName);
        // this.$router.push({name:pgName,params:{pgAction:'init',prmId:''}});
        router.push({name:pgName,params:{pgAction:'init',prmId:''}});
      }
      
      
      return {
        openPage,
      }
    },

  };

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>