import { createApp } from 'vue'
import myApp from './myApp.vue'
import router from '@/router';
// import vueCookies from 'vue-cookie';   // npm install vue-cookie --save


/*
mainApp = createApp(myApp)
mainApp.use(router);
mainApp.mount('#app');
*/
createApp(myApp).use(router).mount('#app');

