<template>
  <span v-if="refCartItemRecs.length == 0"><br><br>Empty cart<br><br></span>
  <span v-else>
      
  <div>
    <!-- Steps (begin) -->
    <center>
      <br>
      
        <div class="stepContainer">
          <div class="stepLink">
            <div class="stepItem">
              <a id="btnStepA" class="btnStepOn" title="stepCartInfo" style="cursor:pointer" v-on:click="openPage('pgCart1');">{{ refDico.stepCart1 }}</a>
            </div>
            <div class="stepItem">
              <a id="btnStepB" class="btnStepOn" title="stepCartDeliveryInfo"  style="cursor:pointer" v-on:click="openPage('pgCart2');">{{ refDico.stepCart2 }}</a>
            </div>
            <div class="stepItem">
              <a id="btnStepC" class="btnStepOn" title="stepCartPaymentInfo"  style="cursor:pointer" v-on:click="openPage('pgCart3');">{{ refDico.stepCart3 }}</a>
            </div>
            <div class="stepItem">
              <a id="btnStepD" class="btnStepOn" title="stepCartValidationInfo"  style="pointer-events: none">{{ refDico.stepCart4 }}</a>
            </div>
            <div class="stepItem">
              <a id="btnStepE" class="btnStepOff" title="stepCartSummaryInfo"  style="pointer-events: none">{{ refDico.stepCart5 }}</a>
            </div>
          </div>
        </div>
        <br>
    </center>
    <!-- Steps (end) -->
  </div>
  
  
  <div style="width:100%;">
      <center>
      <b style="text-align:center;">{{ refDico.tleCart4 }}</b><br>&nbsp;
      <br>
      <div style="display:inline-block; vertical-align:top; margin: 0px 20px 40px 20px; text-align:left; border: 2px solid #D0D0D0;">
        <!-- Content (begin) -->
        
        <table class="contacttable" border="0">
          <!-- thead>
            <tr style="background-color: #C0C0C0;" ><th colspan="2">{{ refDico.tleCart2 }}</th></tr>
          </thead -->
          
          <tbody v-if="refCartRec.deliveryMode == 'b'" style="font-size: 12px; color:#808080;">
            <tr>
              <td style="color:#000000;"  colspan="2">
                <b>{{ refDico.lblboutique }}</b>
              </td>
            </tr>
            <tr>
              <td style="width:70%;"><b>{{ refDico.lblcommercialbank }}</b></td>
              <td>&nbsp;{{ refDico.lblbusinesshour }}</td>
            </tr>
            <tr>
              <td style="white-space: normal;">澎湖縣馬公市中山路 23 號</td>
              <td>{{ refDico.lblmondayfriday }}<br>7:00-17:00</td>
            </tr>
            <tr>
             <td>{{ refDico.lblUsrTel}}:(06)9272155</td>
             <td>{{ refDico.lblsaturday }}<br>7:00-12:00</td>
            </tr>
            <tr>
             <td>{{ refDico.lblUsrFax}}:(06)9275462</td>
             <td>{{ refDico.lblsunday }}</td>
            </tr>
            <tr>
              <td style="white-space: normal;"  colspan="2">{{ refDico.lbldescriptionboutique }}</td>
            </tr>
          </tbody>
          
          <tbody v-else-if="refCartRec.deliveryMode == 'd'" >
            <tr>
              <td colspan="2" style="color:#000000; background-color: #E0E0E0;">
                <b>{{ refDico.lbldomicile }}</b>
              </td>
            </tr>
            <tr>
              <td width="30%">{{ refDico.lblUsrName }} (*)</td>
              <td width="80%">&nbsp;{{ refCartRec.sDelFirstName }}</td>
            </tr>
            <tr>
              <td>{{ refDico.lblMobile }}</td>
              <td>&nbsp;{{ refCartRec.sDelMobile }}</td>
            </tr>
            <tr>
              <td>{{ refDico.lblProvince }} / {{ refDico.lblCity }}</td>
              <td>&nbsp;{{ refStateLbl }} / {{ refCityLbl }}</td>
            </tr>
            <tr>
              <td>{{ refDico.lblZipCode }}</td>
              <td>&nbsp;{{ refCartRec.sDelZipCode }}</td>
            </tr>
            <tr>
              <td>{{ refDico.lblAddr1 }}</td>
              <td>&nbsp;{{ refCartRec.sDelAddr1 }}</td>
            </tr>
          </tbody>
          
          <tbody v-else>
            <tr><td>error</td></tr>
          </tbody>
        </table>
        <br>
        
        <table class="contacttable" border="0">
          <thead>
            <tr style="background-color: #E0E0E0;"><th>{{ refDico.lblpaymentmethod }}</th></tr>
          </thead>
          
          <tbody>
            <tr v-if="refCartRec.paymentMode == '1'">
              {{ refDico.lblpayment1 }}
            </tr>
            <tr v-else-if="refCartRec.paymentMode == '2'">
              {{ refDico.lblpayment2 }}
            </tr>
            <tr v-else-if="refCartRec.paymentMode == '3' || refCartRec.paymentMode == '5'">
              <img src="/img/visa.jpg">
            </tr>
            <tr v-else-if="refCartRec.paymentMode == '4' || refCartRec.paymentMode == '6'">
              <img src="/img/taiwanpay.jpg">
            </tr>
            <tr v-else>
              error
            </tr>
          </tbody>
        </table>
        <br>
        
        <table class="contacttable" border="0" background-color="#FBD603">
          <thead>
            <tr style="background-color: #E0E0E0;"><th>{{ refDico.lblInvoiceInformation }}</th></tr>
          </thead>
          
          <tbody>
            <tr v-if="refCartRec.invoiceMode == '1'">
              <td colspan="2">{{ refDico.lblinvoice1 }}</td>
            </tr>
            <tr v-else-if="refCartRec.invoiceMode == '2'">
              <td>{{ refDico.lblinvoice2 }}</td>
              <td>&nbsp;{{ refDico.lbltitleinvoice2 }}: &nbsp; {{ refCartRec.sPInvoice2 }}</td>
            </tr>
            <tr v-else-if="refCartRec.invoiceMode == '3'">
              <td>{{ refDico.lblinvoice3 }}</td>
              <td>&nbsp;{{ refDico.lbltitleinvoice3 }}: &nbsp; {{ refCartRec.sComCode }}</td>
            </tr>
            <tr v-else-if="refCartRec.invoiceMode == '4'">
              <td>{{ refDico.lblinvoice4 }}</td>
              <td>&nbsp;{{ refDico.lbltitleinvoice4 }}: &nbsp; {{ valueAssociate }}</td>
            </tr>
            <tr v-else>
              <td colspan="2">error</td>
            </tr>
          </tbody>
        </table>
        
        
        
        
        <!-- Content (end) -->
      </div>
    
      <div style="display:inline-block; vertical-align:top;">
        <cpnCart />
      </div>
      <br>
      <br>
      <a class="btnStd01" v-on:click="confirmOrder();" >{{ refDico.lblValidate }}</a>
      <br>
      <br>
      
      <br>&nbsp;
    </center>
  </div>

  </span>
</template>

<script>
  import { ref, computed, onBeforeMount, onMounted } from 'vue';
  import router from '@/router/index.js';
  import { useRoute } from 'vue-router';
  import useReferential from '@/modules/mdlReferential.js'; 
  import useClientApi from '@/services/svcClientApi.js';
  import useCartApi from '@/services/svcCartApi.js';
  import cpnCart from '@/components/cpnCart';
  import useTools from '@/utils/vjsTools.js'; 
  const axios = require("axios");

  export default {
    components: {
      cpnCart,
    },
    setup() {
      const route = useRoute();
      const { refDicoLang, refDicoAttr, refPageAttr, refItmTypeAttr, ascSetLang, refSlctMenu, fctExtractMenu, fctExtractSubmenu, refDepartmentAttr, fctExtractState, fctExtractCity } = useReferential();
      
      let { ascClientFindRec, ascClientSaveRec, ascClientDeleteRec, refClientResp, refClientLoading, refClientRec } = useClientApi();
      let { ascCartGetRecs, ascCartGetItemRecs, ascCartFindRec, ascCartSaveRec, ascCartCreateOrd, ascCartDeleteRec, refCartResp, refCartLoading, refCartRec, refCartItemRecs, refCartItemFreeRecs, fctCartDelItem, calcReqToken, ascOnlinePaym, refOrdType, refOrdId } = useCartApi();
      
      let { tlsGetDateStr } = useTools();
      
      let refDico = ref({});
      let refStateLbl = ref('');
      let refCityLbl = ref('');
      
      
      let refResp = ref({});
      let refRec = ref({});
      let tabAssociate = [refDicoAttr.value.lblAssociate1, refDicoAttr.value.lblAssociate2, refDicoAttr.value.lblAssociate3, refDicoAttr.value.lblAssociate4, refDicoAttr.value.lblAssociate5];
      let valueAssociate = tabAssociate[1*refCartRec.value.sAssociation - 1];
      
      console.log('vwsCart4.setup: DEBUG: tabAssociate='+JSON.stringify(tabAssociate));  // prov_debug
      console.log('vwsCart4.setup: DEBUG: sAssociation='+refCartRec.value.sAssociation);
      console.log('vwsCart4.setup: DEBUG: valueAssociate='+valueAssociate);
      
      onBeforeMount(() => {
      
        console.log('vwsCart4.onBeforeMount: DEBUG: refClientRecId='+refClientRec.value.id);  // prov_debug
        if (refClientRec.value.id) {
          refPageAttr.value.pgAction = 'init';
          refPageAttr.value.pgName = 'pgCart4';
        } else {
          // Client need to login before adding preferred item
          openPage('pgClientLogin');
        }
      });
      
      onMounted(async () => {
        window.scrollTo(0, 0);
        refDico.value = refDicoAttr.value;
        refPageAttr.value.title = refDico.value.tleCart;
        
        let reqParams = { 
              criteria: "idParent = "+refClientRec.value.id, 
              orderby: "id ASC", // "sItmCode1 ASC",
              };
        // KO ascCartGetItemRecs(reqParams);  // KO // Without callback, the record can not be altered at loading
        ascCartGetItemRecs(reqParams, reqCallback);  // Callback is required if altering the record is required at loading
      });
      
      /**** Without callback (begin) ****
      // Without callback, the record can not be altered at loading
      refResp = computed(() => {
        console.log('vwsCart4.computed: DEBUG: refResp=refItemRespStr='+JSON.stringify(refItemResp.value));  // prov_debug
        return refItemResp.value;
      });
      **** Without callback (end) ****/
      
      /**** With callback (begin) ****/
      // Callback is required if altering the record is required at loading
      function reqCallback(myResp) {
        console.log('vwsCart4.reqCallback: DEBUG: respStr='+JSON.stringify(myResp.resp));  // prov_debug
        // ==> respStr={"resp":{"errCode":1,"errMsg":"OK"},"recs":[{"id":154,"sLabel":"統一布丁（大）","iStatus":null,"dtmCreate":"2021-04-15 12:00:38","dtmModif":"2021-04-15  ...
        
        // updateCatalogRecs(refPageAttr.value.pgName, false);  // =true:catalog with topMemu/subMenu, =false:other catalog filter
        
        // Get list of Provinces: refCartRec.sDelProvince
        let tmpStates = fctExtractState();
        refStateLbl.value = tmpStates[refCartRec.value.sDelProvince];
        // console.log('vwsCart4.reqCallback: DEBUG: refStateLbl['+refCartRec.value.sDelProvince+']='+refStateLbl.value+', tmpStatesStr='+JSON.stringify(tmpStates));

        // Get refCity related to the sProvince: refCartRec.sDelCity
        let tmpCities = fctExtractCity(refCartRec.value.sDelProvince);
        refCityLbl.value = tmpCities[refCartRec.value.sDelCity];
        console.log('vwsCart4.reqCallback: DEBUG: refCityLbl['+refCartRec.value.sDelCity+']='+refCityLbl.value+', tmpCitiesStr='+JSON.stringify(tmpCities));

      }
      /**** With callback (end) ****/
      
      function openPage(pgName) {
        console.log('cpnHeader.openPage: pgName='+pgName);
        // this.$router.push({name:pgName,params:{pgAction:'init',prmId:''}});
        router.push({name:pgName,params:{pgAction:'init',prmId:''}});
      }
      
	
      function onlinePaymSubmit(resp) {
        // console.log('vwsCart4.onlinePaymSubmit: DEBUG: respStr='+JSON.stringify(resp));  // prov_debug
        
        // console.log('vwsCart4.onlinePaymSubmit: DEBUG: refCartRecStr='+JSON.stringify(refCartRec.value));  // prov_debug
        // ==> refCartRecStr={"idClient":1,"iSubTotal":18,"iDelivery":0,"iTotalHT":18,"iTaxRate":0,"iTotalTax":0,"iTotalTTC":18,"sDelProvince":"dep010","sDelCity":"cty0110","sDelZipCode":"99000","sDelAddr1":"1 bd Elysée","sDelMobile":"cli123456789","sDelGenderCode":"M","sDelFirstName":"Cli JackBis","sComCode":"Cli001b","sAssociation":"1","deliveryMode":"b","paymentMode":"3","invoiceMode":"1","sOrdCode":"202207120456029799","iItmNb":1}
        
        // refPayUrl.value = resp.postUrl;
        // refPayParams.value = resp.postParams;
        
        console.log('vwsCart4.onlinePaymSubmit: DEBUG: windowLocation: '+resp.postUrl + '?' + resp.postParams); // prov_debug
        
        window.location = resp.postUrl + '?' + resp.postParams;
      }
        
      function onlinePayment(paymType) {
        
        console.log('vwsCart4.onlinePayment: DEBUG: paymType= '+paymType+', ordCode='+refCartRec.value.sOrdCode); // prov_debug
        ascOnlinePaym(paymType, onlinePaymSubmit);
        
        /*
        let bizKey = "B202112110750C069272155D12345678";  // B202112110750C069272155D12345678
        const acqBank = "007";
        const merchandId = "007816565129001";
        const terminalId = "90010001";
        const merID = "81656512";
        const respUrl = "https://dev01.daozhu.tw/api/shopping/paymAck"; // "https://dev01.daozhu.tw/apiPaym/";
        
        // let nowStr = tlsGetDateStr(null, 'YYYMMDDhhmmss'); // (objDate, mask)
        let strDate = tlsGetDateStr(null, 'YYYMMDD'); // (objDate, mask)
        let strTime = tlsGetDateStr(null, 'hhmmss'); // (objDate, mask)
        
        // **** prov_debug ****
        let ordCode = "O"+strDate+strTime;
        let ordTotal = 251;
        // **** prov_debug ****
        
        let ordParams = {
          fct: paymType,  // =creditCard, taiwanPay
          ordId: ordCode, 
          ordTotal: ordTotal,
          callbackUrl: respUrl,
          sDate: strDate,
          sTime: strTime
        }
        let reqToken = calcReqToken(ordParams);
        
        let postUrl;
        let postParams;
        if (paymType == "creditCard") {
          postUrl = "https://www.focas-test.fisc.com.tw/FOCAS_WEBPOS/online/";
          postParams = "MerchantID=" + merchandId +
                          "&TerminalID=" + terminalId +
                          "&merID=" + merID +
                          "&purchAmt=" + ordTotal +
                          "&lidm=" + ordCode +
                          "&AuthResURL=" + respUrl +
                          "&reqToken=" + reqToken +
                          "&LocalDate=" + strDate +
                          "&LocalTime=" + strTime +
                          "&PayType=0&AutoCap=1";
        } else {
          postUrl = "https://www.focas-test.fisc.com.tw/FOCAS_WEBPOS/QR_PAGE";
          postParams = "AcqBank=" + acqBank +
                          "&AuthResURL=" + respUrl +
                          "&lidm=" + ordCode +
                          "&MerchantID=" + merchandId +
                          "&purchAmt=" + ordTotal +
                          "&reqToken=" + reqToken +
                          "&TerminalID=" + terminalId +
                          "&timeoutSecs=600" +
                          "&txnDateLocal=" + strDate +
                          "&txnTimeLocal=" + strTime;
        }

        console.log('vwsCart4.onlinePayment: DEBUG: windowLocation: '+postUrl + '?' + postParams); // prov_debug
        // ==> https://www.focas-test.fisc.com.tw/FOCAS_WEBPOS/online/?MerchantID=007816565129001&TerminalID=90010001&merID=81656512&purchAmt=251&lidm=O20220712061804&AuthResURL=https://dev01.daozhu.tw/api/shopping/paymAck&reqToken=82ED2DB0884EBDCFB8279F7B0A431A20C5116DE686FC9EFF4BF88381D348B9A9&LocalDate=20220712&LocalTime=061804&PayType=0&AutoCap=1
        
        window.location = postUrl + '?' + postParams;
        */
		
      }
      

      function createOrderDone(myResp) {
        const keyOrdCode = "sOrdCode=";
        let iPos;
        
        console.log('vwsCart4.createOrderDone: DEBUG: paymentMode='+refCartRec.value.paymentMode+', respStr='+JSON.stringify(myResp.resp));  // prov_debug
        // ==> paymentMode=1, respStr={"errCode":501,"errMsg":"Request failed with status code 500"}
        // ==> paymentMode=1, respStr={"errCode":1,"errMsg":"idOrder=9, sOrdCode=211129111519101"}
        // ==> paymentMode=3, respStr={"errCode":1,"errMsg":"idOrder=47, sOrdCode=230718110305101"}

        if (myResp.resp.errCode == 1) {  
          iPos = myResp.resp.errMsg.indexOf(keyOrdCode);
          if (iPos > 0) {
            // OK: Extract orderCode (begin)
            refCartRec.value.sOrdCode = myResp.resp.errMsg.substring(iPos+keyOrdCode.length);
            refOrdType.value = "XX";
            refOrdId.value = refCartRec.value.sOrdCode;
            console.log('vwsCart4.createOrderDone: DEBUG: sOrdCode='+refCartRec.value.sOrdCode);

          switch(refCartRec.value.paymentMode) {
            case '3': // CreditCard online payment (FOCAS)
            case '5': // CreditCard online payment (FOCAS) failed (default value)
              onlinePayment("creditCard");
              break;
              
            case '4': // TaiwanPay: QR code (FOCAS)
            case '6': // TaiwanPay: QR code (FOCAS) failed (default value)
              onlinePayment("taiwanPay");
              break;
              
            // case '1':
            // case '2':
            default: 
              openPage('pgCart5');
              break;
          }

            // OK: Extract orderCode (begin)
          } else {
            // ERROR: Could not extract orderCode
            console.log('vwsCart4.createOrderDone: ERROR: Could not get the order code: respStr='+JSON.stringify(myResp.resp));
            alert('ERROR: Could not get the order code');
          }
        } else {
          alert('vwsCart4.createOrderDone: ERROR: Could not save the order');
        }
        
      }
      function confirmOrder() {
        if (confirm(refDicoAttr.value.msgconfirmOrder) == true){
          createOrder();
          
          
        }
      }
      
      
      function createOrder() {
        // refCartRec, refCartItemRecs
        console.log('vwsCart4.createOrder: DEBUG: refCartRecStr='+JSON.stringify(refCartRec.value));  // prov_debug
        // ==> refCartRecStr={"idClient":1,"iSubTotal":72,"iDelivery":20,"iTotalHT":92,"iTaxRate":0,"iTotalTax":0,"iTotalTTC":92,"sDelProvince":"dep010","sDelCity":"cty0110","sDelZipCode":"99000","sDelAddr1":"1 bd Elysée","sDelMobile":"0908823043","sDelGenderCode":"M","sDelFirstName":"Cli JackBis","sComCode":"Cli001b","sAssociation":"1","deliveryMode":"d","paymentMode":"2","invoiceMode":"1","sOrdCode":"220714075053101","iItmNb":1}
        
        console.log('vwsCart4.createOrder: DEBUG: refCartItemRecsStr='+JSON.stringify(refCartItemRecs.value));  // prov_debug
        // ==> refCartItemRecsStr=[{"idItem":154,"iQtyOrd":4,"id":154,"sItmCode1":"4710088430755","sSizeCode":"180g","sTypeCode":"uni019","sType1Code":"mnu060","sType2Code":"snu0710","sLabel":"統一布丁（大）","sDescr":"統一布丁獨特焦糖風味與香Q滑嫩口感成就經典口味的國民甜點，不管是舀著吃、攪著吃、冰凍吃或邊玩邊吃，吃統一布丁的時候，就是最開心的時候！\n生鮮食品不享有7天鑑賞期，因冷藏食品或生鮮商品屬消耗性商品，商品屬性特殊或有保存期限等問題，除商品本身瑕疵或運送過程而造成的損毀，才可接受您的退貨申請。非商品瑕疵恕無法為您辦理換貨！一經拆封、食用、失溫及保存不良等情形而導致商品變質，則無法處理您的換貨申請，敬請見諒！","sImg":"","iPrice1":17,"iPrice2":18,"sFlgMenu":"234","iTotalOrd":72}]
        
        // Set default value
        if (refCartRec.value.paymentMode == '3') refCartRec.value.paymentMode = '5'; // CreditCard online payment (FOCAS) failed (default value)
        if (refCartRec.value.paymentMode == '4') refCartRec.value.paymentMode = '6'; // TaiwanPay: QR code (FOCAS) failed (default value)
        // Save the order data to the DB
        refCartRec.value.sOrdCode = ''; // Reset orderCode
        ascCartCreateOrd(refCartRec.value, refCartItemRecs.value, refCartItemFreeRecs.value, createOrderDone);
        
      }
      
      
      
      return {
        refDico,
        refPageAttr,
        refItmTypeAttr,
        refCartResp, 
        refCartLoading, 
        refCartRec,
        refCartItemRecs,
        openPage,
        confirmOrder,
        createOrder,
        valueAssociate,
        refStateLbl,
        refCityLbl,
      }
    },

  };

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>