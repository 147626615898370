<template>
  
  <div>
    
    <center>
    <br>
    
    <span v-if="refIsLoading"><br><br>Loading ...<br><br></span>
    <span v-else>
      <span v-if="refRec.sItmCode1">
      <form novalidate @submit.prevent="onSubmit" v-on:change="frmChanged()" >
      
        <table border="0">
            
          <tr v-if="refRec.iIsPromo || refRec.iPrice2" style="background-color: #FF0000; color: white; text-align: center;">
            <td colspan="3" >
              {{ refRec.sFreeItmTitle }} &nbsp;
            </td>
          </tr>
          
          <tr>
            <td valign="top" >
              <!-- Item image (begin) -->
              <div style="margin: 5px 0px 5px 0px;	/* top right bottom left */">
                <img class="itmIntroCellImg3" v-bind:src="'/appimg/'+refRec.sItmCode1+'.jpg'">
              </div>
              <!-- Item image (end) -->
            </td>
            
            <td nowrap="1" >&nbsp;&nbsp;</td>
            
            <td valign="top" >
              <!-- Item data (begin) -->
              <div class="myCatItm2" >
                <table border="0" >
                  <tr><td style="white-space: normal;"><b>{{ refRec.sLabel }}</b></td></tr>   <!-- name -->
                  <tr><td style="font-size: 12px;">{{ refRec.sItmCode1 }}</td></tr>   <!-- code bar -->
                  <tr><td>{{ refRec.sSizeCode }} </td></tr> <!-- quantity -->
                  
                  <tr><td>
                    <span v-if="refRec.iPrice2">
                      <!-- Promo price -->
                      <span style="font-size: 12px;" class="regularPriceOff">{{ refRec.iPrice1 }} {{ refDico.lblDevise }}&nbsp;/&nbsp; {{ refItmTypeAttr[refRec.sTypeCode] }}</span>
                      &nbsp;
                      <span class="promoPriceOn">{{ refRec.iPrice2 }} {{ refDico.lblDevise }}&nbsp;/&nbsp; {{ refItmTypeAttr[refRec.sTypeCode] }}</span>
                    </span>
                    <span v-else>
                      <!-- Regular price -->
                      <span class="regularPriceOn">{{ refRec.iPrice1 }} {{ refDico.lblDevise }}&nbsp;/&nbsp; {{ refItmTypeAttr[refRec.sTypeCode] }}</span>
                    </span>
                  </td></tr>
                  
                  <tr>
                    <td class="textItm">
                      <span v-if="refRec.sGrpCode.includes('P')">
                        {{ refDico.lblGrpPenghu}}
                      </span>
                      <span v-else>
                        &nbsp;
                      </span>
                    </td>
                  </tr>
                  
                  <tr>
                    <td class="textItm">
                      <span v-if="refRec.sGrpCode.includes('R')">
                        &nbsp;
                      </span>
                      <span v-else>
                        {{ refDico.lblItmNotReturn}}
                      </span>
                    </td>
                  </tr>
                  
                  <!--  <tr><td colspan="2">sType1Code= {{ refRec.sType1Code }}</td></tr> --> 
                  <!--  <tr><td colspan="2">sType2Code={{ refRec.sType2Code }}</td></tr> -->
                  <tr>
                    <td>
                    <table border="0">
                      <tr>
                    <td>
                      &nbsp;<a v-on:click="addPreferred(refRec.sItmCode1)" ><i class="fa fa-heart fa-1x"></i></a>&nbsp;&nbsp;
                    </td>
                    <td width="100px;" align="left" nowrap v-if="(refFlgCheckIry == false) || (refRec.iInvQty && refRec.iInvQty > 0)">
                      <a v-on:click="qtyMinus(refRec.sItmCode1)" style="cursor: pointer;" >-</a>
                      <input type="number" style="text-align:center; width:60px;" v-model="refRec.iQty" v-on:change="qtySet(refRec.sItmCode1)" />
                      <a v-on:click="qtyPlus(refRec.sItmCode1)" style="cursor: pointer;" >+</a>
                      &nbsp;<a v-on:click="addCart(refRec.sItmCode1)" ><i class="fa fa-shopping-cart fa-1x"></i></a>
                    </td>
                    <td align="center" width="100px;" v-else style="color:#FF0000;">
                      {{ refDico.errCartItmSoldOut }}
                    </td>
                    <td width="99%">&nbsp;</td>
                      </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </div>
              
              <div>
                <span v-if="refRec.iPrice2">
                  {{ refDico.itmOfferEnd }} : {{ formatDate(refRec.dtmDiscEnd) }}
                </span>
              </div>
              
              <!-- Item data (end) -->
            </td>
          </tr>
          <tr v-if="refRec.sFreeItmCode1">
            <td colspan="3">
              <table>
                <tr>
                  <td colspan="2">
                    <b>{{ refDico.itmGiveaway }}</b>
                  </td>
                </tr>
                <tr style="font-size: 12px;">
                  <td>
                    {{ refDico.itmOfferEnd }} : {{ formatDate(refRec.dtmDiscEnd) }}
                  </td>
                </tr>
                <tr>
                  <td>
                    <img class="itmIntroCellImg4" v-bind:src="'/appimg/'+refRec.sFreeItmCode1+'.jpg'">
                  </td>
                  <td>
                    <table border="0">
                      <tr><b>{{ refRec.sFreeItmLabel }}</b></tr>   <!-- name -->
                      <tr style="font-size: 12px;">[{{ refRec.sFreeItmCode1 }}]</tr>   <!-- code bar -->
                      <tr>{{ refRec.sFreeItmSize }}</tr>    <!-- quantity -->
                      <tr>{{ refRec.iFreeQty }}&nbsp;/&nbsp;{{ refItmTypeAttr[refRec.sFreeTypeCode] }}</tr>
                    </table>
                   </td>
                 </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <textarea class="itmDescr" v-model="refRec.sDescr" disabled ></textarea>
            </td>
          </tr>
        </table>
      
      </form>
      </span>
    </span>
    </center>
    <br>
    <br>
    <br>
    <br>&nbsp;
  </div>  
  
</template>

<script>
  import {
    ref, computed, onActivated, onBeforeMount, onBeforeUnmount, onBeforeUpdate,
    onDeactivated, onErrorCaptured, onMounted, onUnmounted, onUpdated, onRenderTracked, onRenderTriggered
  } from 'vue';
  import { useRoute } from 'vue-router';
  import myApp from '@/myApp.vue';
  import useReferential from '@/modules/mdlReferential.js';
  import useTools from '@/utils/vjsTools.js'; 
  import usePreferredApi from '@/services/svcPreferredApi.js';
  import useItemApi from '@/services/svcItemApi.js';
  import useClientApi from '@/services/svcClientApi.js';
  import useCartApi from '@/services/svcCartApi.js';
  
  export default {
    methods: {
      formatDate: function(sDate) {
        if (!sDate) { return ''; }
        // return moment(String(sDate)).format('YYYY/MM/DD');
        let dtDate = new Date(sDate);
        // console.log('vwsPromoLst.formatDate: date='+sDate+' / '+dtDate);
        // console.log('vwsPromoLst.formatDate: year='+dtDate.getFullYear());
        return dtDate.getFullYear() + '/' + 
                   ((dtDate.getMonth() < 9) ? '0' : '') + (dtDate.getMonth() + 1) + '/' +
                   ((dtDate.getDate() < 10) ? '0' : '') + dtDate.getDate();
      }
    },
    setup() {
      const { refFlgCheckIry, refDicoLoading, refDicoLang, refDicoAttr, refPageAttr, refItmTypeAttr, ascSetLang, refSlctMenu, fctExtractMenu, fctExtractSubmenu, refPgHomeReady } = useReferential();
      const { tlsCheckElementKey } = useTools();
      let { tlsNavTo, tlsNavBack } = useTools();
      let { fctCartAddItem, ascCartGetItemRecs, ascCartFindOrdRec, ascCartSaveRec, ascCartDeleteRec, refCartResp, refCartLoading, refCartRec, refCartItemRecs } = useCartApi();
      
      let { ascPreferredFindRec, ascPreferredSaveRec, ascPreferredDeleteRec, refPreferredResp, refPreferredLoading, refPreferredRec } = usePreferredApi();
      let { ascItemFindRec, ascItemSaveRec, ascItemDeleteRec, fctRfshItem, refItemResp, refItemLoading, refItemRec, refItemRecs, refMenuRecs } = useItemApi();
      let { ascClientFindRec, ascClientSaveRec, ascClientDeleteRec, refClientResp, refClientLoading, refClientRec } = useClientApi();
      const route = useRoute();
      

      let refDico = ref({});
      let refMenu = ref({});
      let refSubmenu = ref({});
      // let refDepartment = ref({});
      // let refItmType = ref({});
      // let refSupplier = ref({});
      
      let refResp = ref({});
      let refRec = ref({});
      let refIsLoading = ref(true);

      // onBeforeMount(() => console.log('before mount'));
      // onMounted(() => console.log('mounted'));
      // onBeforeUpdate(() => console.log('before update'));
      // onUpdated(() => console.log('updated'));
      // onBeforeUnmount(() => console.log('before unmount'));
      // onUnmounted(() => console.log('unmounted'));
      // ??? onActivated(() => console.log('activated'));                // ???
      // ??? onDeactivated(() => console.log('deactivated'));            // ???
      // ??? onErrorCaptured(() => console.error('error captured'));     // ???
      // ??? onRenderTracked(() => console.log('render tracked'));       // ???
      // ??? onRenderTriggered(() => console.log('render triggered'));   // ???
      
      onBeforeMount(async () => {
        console.log('vwsItemFrm.onBeforeMount: pgAction='+route.params.pgAction+',id='+route.params.prmId);
      }); // onBeforeMount
      
      onMounted(async () => {
        console.log('vwsItemFrm.onMounted: pgAction='+route.params.pgAction+',id='+route.params.prmId);
        // pgAction=init,id=
        // pgAction=init,id=2
        
        refDico.value = refDicoAttr.value;
        refPageAttr.value.title = refDico.value.tleAdmItmFrm;
        
        refMenu.value = fctExtractMenu();
        
        if (route.params.prmId && (route.params.prmCode != '')) {
          // Open the record
          // Submit request to remote REST API services to get record matching the provided id
          
          console.log('vwsItemFrm.onMounted: DEBUG: refItemRecsLen='+refItemRecs.value.length);
          console.log('vwsItemFrm.onMounted: DEBUG: refMenuRecsLen='+refMenuRecs.value.length);
          console.log('vwsItemFrm.onMounted: DEBUG: prmId='+route.params.prmId+', prmCode='+route.params.prmCode);

          let index2 = refMenuRecs.value.findIndex(function(item){ return item.sItmCode1 == route.params.prmCode});
          if (index2 >= 0) {
            refRec.value = refMenuRecs.value[index2];
            console.log('vwsItemFrm.onMounted: DEBUG: refMenuRecs.sItmCode1='+refRec.value.sItmCode1+', id='+refRec.value.id+'/'+route.params.prmId+', qty='+refRec.value.iQty);  // prov_debug

            let myParams = {
              id: route.params.prmId,
            };
            ascItemFindRec(myParams, reqCallback); // Callback is required if altering the record is required at loading

          } else {
            // Should never occured
            console.log('vwsItemFrm.onMounted: ERROR: invalid prmCode='+route.params.prmCode);
            frmAction('new');
          }

        } else {
          // Just reset the form
          console.log('vwsItemFrm.onMounted: ERROR: invalid prmCode='+route.params.prmCode);
          frmAction('new');
        }
        
      }); // onMounted

      /**** Without callback (begin) ****
      // Without callback, the record can not be altered at loading
      refResp = computed(() => {
        // console.log('vwsItemFrm.computed: DEBUG: refResp=refItemRespStr='+JSON.stringify(refItemResp.value));  // debug
        return refItemResp.value;
      });
      refRec = computed(() => {
        // console.log('vwsItemFrm.computed: DEBUG: refRec=refItemRecStr='+JSON.stringify(refItemRec.value));  // debug
        return refItemRec.value;
      });
      **** Without callback (end) ****/
      
      /**** With callback (begin) ****/
      // Callback is required if altering the record is required at loading
      function reqCallback(prmResp) {
        // Set data of the record
        // refResp.value = prmResp.resp;
        // refRec.value = resp.rec;
        console.log('vwsItemFrm.reqCallback: DEBUG: respStr='+JSON.stringify(prmResp));  // debug
        // ==> respStr={"resp":{"errCode":101,"errMsg":"Record not found"},"rec":{}}
        // ==> respStr={"resp":{"errCode":1,"errMsg":"OK"},"rec":{"id":144,"sLabel":"xxx","iStatus":null,"dtmCreate":"2021-04-15 11:51:53","dtmModif":"2023-02-27 15:18:28","dtmDiscBegin":null,"dtmDiscEnd":null,"sDiscCode":"","sItmCode1":"4710088432513","sItmCode2":"30060","sSupCode":"3","sCatCode":"dep001","sSizeCode":"1858ml","sTypeCode":"uni003","sType1Code":"mnu060","sType2Code":"snu0690","sDescr":"xxx","sImg":"","iPrice":58,"iPrice1":58,"iPrice2":null,"iQtyMax":null,"iQtyMin":10,"iQty":null,"sGrpCode":"_PA","sFlgReturn":"","sFlgActif":"","sFlgMenu":"_","iInvQty":7,"iFreeItmId":null,"sFreeItmCode1":null,"sFreeItmLabel":null,"iFreeQty":null,"sFreeSizeCode":null,"sFreeItmSize":null,"sFreeTypeCode":null,"sFreeItmType":null,"iIsPromo":0,"iPromoQty":null,"iQtyCnt":null,"iPrice4":null,"iFlgFzn":1,"iFlgFreeFzn":0,"sFreeItmTitle":null,"sPassword":"","sPassword2":""}}
        
        if (prmResp.resp.errCode == 1) {
          // Record loaded from the server. Update data of the record (begin)
          console.log('vwsItemFrm.reqCallback1: DEBUG: refRecStr='+JSON.stringify(prmResp.rec));  // prov_debug
          // ==> 
          // You may alter the record here

          refRec.value = fctRfshItem(refRec.value, prmResp.rec);
          console.log('vwsItemFrm.reqCallback2: DEBUG: refRecStr='+JSON.stringify(prmResp.rec));  // prov_debug
          // Record loaded from the server. Update data of the record (end)
        }
        
      }
      /**** With callback (end) ****/
      
      function updateSelect() {
        // sGrpCode: E:Exclusive, P:Penghu only
        refRec.value.sGrpPenghu = (refRec.value.sGrpCode.indexOf('P') >= 0)? true:false; 
        refRec.value.sGrpReturn = (refRec.value.sGrpCode.indexOf('R') >= 0)? true:false;
        refRec.value.sGrpActif = (refRec.value.sGrpCode.indexOf('A') >= 0)? true:false;
        // sFlgMenu: 1234567: flg to show item in corresponding menu
        refRec.value.sMenu02Discount = (refRec.value.sFlgMenu.indexOf('2') >= 0)? true:false;
        refRec.value.sMenu03NewProducts = (refRec.value.sFlgMenu.indexOf('3') >= 0)? true:false; 
        refRec.value.sMenu04Exclusive = (refRec.value.sFlgMenu.indexOf('4') >= 0)? true:false; 
        refRec.value.sMenu05BestSellers = (refRec.value.sFlgMenu.indexOf('5') >= 0)? true:false; 
        refRec.value.sMenu06Wholesale = (refRec.value.sFlgMenu.indexOf('6') >= 0)? true:false; 
        refRec.value.sMenu07Distribution = (refRec.value.sFlgMenu.indexOf('7') >= 0)? true:false;
        
        let index = refItemRecs.value.findIndex(function(item){ return item.id == refRec.value.id});

        // console.log('vwsItemFrm.updateSelect: DEBUG: refItemRecs['+index+']='+JSON.stringify(refItemRecs.value[index]));  // prov_debug
        // ==>
        
        // console.log('vwsItemFrm.updateSelect: DEBUG: sItmCode1='+refItemRecs.value[index].sItmCode1+', iQty='+refItemRecs.value[index].iQty);  // prov_debug
        refRec.value.iQty = refItemRecs.value[index].iQty;
      }
      
      function frmChanged() {
        console.log('vwsItemFrm.frmChanged: DEBUG: ');
        /*
        // Get refSubmenu related to the sType1Code
        refSubmenu.value = fctExtractSubmenu(refRec.value.sType1Code);
        console.log('vwsItemFrm.frmChanged: DEBUG: refSubmenuStr='+JSON.stringify(refSubmenu.value));  // debug
        // Set default sType2Code if not found
        refRec.value.sType2Code = tlsCheckElementKey(refSubmenu.value, refRec.value.sType2Code, true);  // true:return first stateCode if not found
        */
      } // frmChanged
      
      
      function frmActionDone(myResp) {
        console.log('vwsItemFrm.frmActionDone: myRespStr='+JSON.stringify(myResp)); // prov_debug
        // ==> myRespStr={"resp":{"errCode":501,"errMsg":"Invalide function"}}
        // ==> myRespStr={"resp":{"errCode":502,"errMsg":"502:Could not save record"}}
        // ==> myRespStr={"resp":{"errCode":1,"errMsg":"OK"}}
        
        switch(myResp.resp.errCode) {
          case 1: // OK: done successfully
            refResp.value.errCode = myResp.resp.errCode;
            refResp.value.errMsg = myResp.resp.errMsg;
            break;
          case 501: // errMsg501 = 'Invalide function';       // Unexpected function
            alert('ERROR: '+myResp.errMsg); // prov_debug
            break;
        }
        
        
      } // frmActionDone
      function frmAction(myFct) {
        // myFct = new, insert, update, delete
        let isValid = true;
        let updateForm = true;
        let myParams;
        
        // console.log('vwsItemFrm.frmAction: DEBUG: myFct='+myFct); // prov_debug
        switch(myFct) {
          case 'new':
            refRec.value = {};
            refResp.value.errCode = 0;
            refResp.value.errMsg = '';
            break;
          case 'insert':
          case 'update':
            updateForm = false;
            
            // sGrpCode: E:Exclusive, P:Penghu only
            refRec.value.sGrpCode = '';
            if (refRec.value.sGrpPenghu) refRec.value.sGrpCode += 'P';
            if (refRec.value.sGrpReturn) refRec.value.sGrpCode += 'R';
            if (refRec.value.sGrpActif) refRec.value.sGrpCode += 'A';
            // sFlgMenu: 1234567: flg to show item in corresponding menu
            refRec.value.sFlgMenu = '';
            if (refRec.value.sMenu02Discount) refRec.value.sFlgMenu += '2';
            if (refRec.value.sMenu03NewProducts) refRec.value.sFlgMenu += '3';
            if (refRec.value.sMenu04Exclusive) refRec.value.sFlgMenu += '4';
            if (refRec.value.sMenu05BestSellers) refRec.value.sFlgMenu += '5';
            if (refRec.value.sMenu06Wholesale) refRec.value.sFlgMenu += '6';
            if (refRec.value.sMenu07Distribution) refRec.value.sFlgMenu += '7';
          
            // console.log('vwsItemFrm.frmAction: DEBUG: sGrpCode='+refRec.value.sGrpCode+', sFlgMenu='+refRec.value.sFlgMenu); // debug
            
            if (isValid == true) {
              refRec.value.errCode = 0; // Reset errCode
              console.log('vwsItemFrm.frmAction: DEBUG: myFct='+myFct+',id='+refRec.value.id); // prov_debug
              
              // Submit request to remote REST API services to insert or update the record matching the provided id
              myParams = {
                  fct: myFct,
                  rec: refRec.value,
              };
              
              ascItemSaveRec(myParams, frmActionDone); // Callback is required if altering the record is required at loading
            } else {
              console.log('vwsItemFrm.frmAction: Ko: myFct='+myFct+',id='+refRec.value.id);
              refRec.value.errCode = 901; // 901: Invalid data
            }
            break;
          case 'delete':
            updateForm = false;
            isValid = confirm('Do you want to delete this record ?');
            if (isValid == true) {
              refRec.value.errCode = 0; // Reset errCode
              console.log('vwsItemFrm.frmAction: DEBUG: myFct='+myFct+',id='+refRec.value.id); // prov_debug
              
              // Submit request to remote REST API services to get record matching the provided id
              myParams = {
                  fct: myFct,
                  rec: refRec.value,
              };
              
              ascItemDeleteRec(myParams, frmActionDone); // Callback is required if altering the record is required at loading
            }
            break;
        }
        
        if (updateForm) {
          updateSelect();
        }
                
      } // frmAction
      
      function qtyMinus(itmCode) {
        console.log('vwsItemFrm.qtyMinus: DEBUG: itmCode='+itmCode);  // prov_debug

        let index2 = refMenuRecs.value.findIndex(function(item){ return item.sItmCode1 == itmCode});
        refMenuRecs.value[index2].iQty = refRec.value.iQty-1;
        if (refMenuRecs.value[index2].iQty < 1) {
          refMenuRecs.value[index2].iQty = 1;
        }
        refRec.value.iQty = refMenuRecs.value[index2].iQty;

        console.log('vwsItemFrm.qtyMinus: DEBUG: qty='+refMenuRecs.value[index2].iQty); // prov_debug
      } // qtyMinus
      
      function qtyPlus(itmCode) {
        console.log('vwsItemFrm.qtyPlus: DEBUG: itmCode='+itmCode+'/'+route.params.itmCode);  // prov_debug
        // console.log('vwsItemFrm.qtyPlus: DEBUG: refRecsStr='+JSON.stringify(refItemRecs.value));  // debug

        let index2 = refMenuRecs.value.findIndex(function(item){ return item.sItmCode1 == itmCode});
        console.log('vwsItemFrm.qtyPlus: DEBUG: itmCode='+itmCode+', index2='+index2);  // prov_debug
        console.log('vwsItemFrm.qtyPlus: DEBUG: iQty='+refRec.value.iQty);  // prov_debug

        refMenuRecs.value[index2].iQty = refRec.value.iQty+1;
        refRec.value.iQty = refMenuRecs.value[index2].iQty;
        console.log('vwsItemFrm.qtyPlus: DEBUG: qty='+refMenuRecs.value[index2].iQty); // prov_debug
        
      } // qtyPlus
      
      function qtySet(itmCode) {
        console.log('vwsItemFrm.qtySet: DEBUG: itmCode='+itmCode);  // prov_debug
        
        let index2 = refMenuRecs.value.findIndex(function(item){ return item.sItmCode1 == itmCode});
        refMenuRecs.value[index2].iQty = refRec.value.iQty;
        if (refMenuRecs.value[index2].iQty < 1) {
          refMenuRecs.value[index2].iQty = 1;
        }
        refRec.value.iQty = refMenuRecs.value[index2].iQty;
        console.log('vwsItemFrm.qtySet: DEBUG: qty='+refMenuRecs.value[index2].iQty); // prov_debug
      } // qtySet

      function addCartDone(myResp) {
        let myParams;
        
        // console.log('vwsItemFrm.addCartDone: myRespStr='+JSON.stringify(myResp)); // prov_debug
        // ==> myRespStr={"resp":{"errCode":501,"errMsg":"Invalide function"}}
        // ==> myRespStr={"resp":{"errCode":502,"errMsg":"502:Could not save record"}}
        // ==> myRespStr={"resp":{"errCode":101,"errMsg":"Record not found"},"rec":{}}
        // ==> myRespStr={"resp":{"errCode":1,"errMsg":"OK"}}
        
        switch(myResp.resp.errCode) {
          case 1: // OK: done successfully
            refResp.value.errCode = myResp.resp.errCode;
            refResp.value.errMsg = myResp.resp.errMsg;
            alert(refDicoAttr.value.msgCartAdded);
            break;
          case 101: // myRespStr={"errCode":101,"errMsg":"Record not found"}
            // The shopping cart of the client not yet created. Create it
            console.log('vwsItemFrm.addCartDone: create cart refClientRecId='+refClientRec.value.id);
            myParams = {
              fct: 'insert',
              rec: {idClient: refClientRec.value.id},
            };
            break;
          case 501: // errMsg501 = 'Invalide function';       // Unexpected function
            alert(refDicoAttr.value.errCartAdd);
            break;
        }
      } // addCartDone
      
      function addCart(itmCode) {
        console.log('vwsItemFrm.addCart: DEBUG: itmCode='+itmCode+', clientId='+refClientRec.value.id);  // prov_debug
        
        let index2 = refMenuRecs.value.findIndex(function(item){ return item.sItmCode1 == itmCode});
        let iQty = refMenuRecs.value[index2].iQty;
        // console.log('vwsItemFrm.addCart: DEBUG: itmCode='+itmCode+', iQty='+iQty);  // prov_debug
        
        refMenuRecs.value[index2].iQty = 1;
        let myParams = {
          fct: 'insert',
          idClient: refClientRec.value.id,    // Client's ID
          idItem: refMenuRecs.value[index2].id,                      // Item's ID
          qtyItem: iQty,                      // Nb items
        };
        fctCartAddItem(myParams);
        ascCartSaveRec(myParams, addCartDone); // Callback is required if altering the record is required at loading
        
      } // addCart


      function addPreferredDone(myResp) {
        console.log('cpnItmLst.addPreferredDone: myRespStr='+JSON.stringify(myResp)); // prov_debug
        // ==> myRespStr={"resp":{"errCode":501,"errMsg":"Invalide function"}}
        // ==> myRespStr={"resp":{"errCode":502,"errMsg":"502:Could not save record"}}
        // ==> myRespStr={"resp":{"errCode":1,"errMsg":"OK"}}
        
        switch(myResp.resp.errCode) {
          case 1: // OK: done successfully
            refResp.value.errCode = myResp.resp.errCode;
            refResp.value.errMsg = myResp.resp.errMsg;
            alert(refDicoAttr.value.msgPreferredAdded);
            break;
          case 501: // errMsg501 = 'Invalide function';       // Unexpected function
            alert(refDicoAttr.value.errPreferredAdd);
            break;
        }
      } // addPreferredDone
      
      function addPreferred(itmCode) {
        console.log('cpnItmLst.addPreferred: DEBUG: itmCode='+itmCode+', clientId='+refClientRec.value.id);  // prov_debug
        
        let index2 = refMenuRecs.value.findIndex(function(item){ return item.sItmCode1 == itmCode});
        refMenuRecs.value[index2].iQty = refRec.value.iQty;

        if (refClientRec.value.id && index2 >= 0) {
          let tmpRec = {};
          tmpRec.errCode = 0; // Reset errCode
          tmpRec.idParent = refClientRec.value.id;
          tmpRec.idItem = refMenuRecs.value[index2].id;
          // Submit request to remote REST API services to get record matching the provided id
          let myParams = {
              fct: 'insert',
              rec: tmpRec,
          };
          
          ascPreferredSaveRec(myParams, addPreferredDone); // Callback is required if altering the record is required at loading
        } else {
          // Client need to login before adding preferred item
          // openPage('pgClientLogin');
          let query = {name:'pgClientLogin',params:{pgAction:'init',prmId:''}};
          tlsNavTo(query);
        }
      } // addPreferred
      

      return {
        refFlgCheckIry,
        refDico,
        refMenu,
        refSubmenu,
        // refDepartment,
        refItmTypeAttr, // refItmType,
        // refSupplier,
        refIsLoading: computed(() => refItemLoading.value),
        refResp,
        refRec,
        frmChanged,
        frmAction,
        qtyMinus,
        qtyPlus,
        qtySet,
        addCart,
        addPreferred,
      }
    }, // onMounted
  } // setup
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .divOk {
    color: #00FF00;
  }
  .divError {
    color: #FF0000;
  }
  input:disabled {
    background-color: #F0F0F0;
  }
  .itmDescr {
    border: 1px solid #D0D0D0;
    width: 100%;
    height: 100px;
  }
  /* input[type="number"] {
    width: 50px;
  } */

</style>
