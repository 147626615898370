<template>
  <span v-if="refPgHomeReady">

  <center>
  <br>

  <!-- Slideshow container -->
	<div id="slideshow01Container" class="slideshow-container">
		<div class="mySlides" v-for="slideRec in refSlideRecs" :key="slideRec.imgidx" v-bind:style="{display: slideRec.valDisplay}" v-on:click="openItm(slideRec.imgidx);">
      <!-- div class="numbertext" >{{ slideRec.imgidx+1 }} / {{ refSlideRecs.length }}</div -->
      <img class="mySlidesImg" v-bind:src="'/appimg/'+slideRec.sImg" />
      <!-- div class="text">{{ slideRec.sLabel }}</div -->
    </div>
	</div>
	<br>
	<div id="slideshow01Dot" style="text-align:center">
     <span class="dot" v-for="slideRec in refSlideRecs" :key="slideRec.imgidx" v-on:click="currentSlide(slideRec.imgidx)" v-bind:class="{ active: slideRec.isActive}" ></span>
	</div>

  <br>
  <hr>
  <br>
  
  <!-- Link_1 (begin) -->
  <div>
  
    <div class="myCatItm" >
      <center>
      <a v-on:click="openPage('pgMenu02Discount')" style="text-align:center" ><img class="itmIntroCellImg" src="/appimg/menu2.jpg"></a>
      </center>
    </div>
    <div class="myCatItm" >
      <center>
      <a v-on:click="openPage('pgMenu03NewProducts')" style="text-align:center" ><img class="itmIntroCellImg" src="/appimg/menu3.jpg"></a>
      </center>
    </div>
    <div class="myCatItm" >
      <center>
      <a v-on:click="openPage('pgMenu04Exclusive')" style="text-align:center" ><img class="itmIntroCellImg" src="/appimg/menu4.jpg"></a>
      </center>
    </div>
    
    <div class="myCatItm" >
      <center>
      <a v-on:click="openPage('pgMenu05BestSellers')" style="text-align:center" ><img class="itmIntroCellImg" src="/appimg/menu5.jpg"></a>
      </center>
    </div>
    <div class="myCatItm" >
      <center>
      <a v-on:click="openPage('pgMenu06Wholesale')" style="text-align:center" ><img class="itmIntroCellImg" src="/appimg/menu6.jpg"></a>
      </center>
    </div>
    <div class="myCatItm" >
      <center>
      <a v-on:click="openPage('pgMenu07Distribution')" style="text-align:center" ><img class="itmIntroCellImg" src="/appimg/menu7.jpg"></a>
      </center>
    </div>
    
  </div>
  <!-- Link_1 (end)-->
  
  <!--
  <div id="myLstWholeSale">
    <cpnItmLst2 v-bind:flgReady="flgMenu05BestSellersReady" v-model:refItmLst="refMenu05BestSellers"></cpnItmLst2>
  </div>
  <div id="myLstDistribution">
    <cpnItmLst2 v-bind:refItmLst="refMenu07Distribution"></cpnItmLst2>
  </div>
  -->
  
  <!--
  <br>
  <br>
    <router-link to="{name:'home', params:{lang:'tw'}}" tag="button" class="enwButton">About (tata)</router-link>
    
    <hr>
      <img src="http://mail.enw.io:13080/api/bc/createBc?fct=bc128&id=123456789&ordid=ord123" />
      <br>
      <img src="http://mail.enw.io:13080/api/bc/createQr?fct=bc128&id=123456789&ordid=ord123" />
    <hr>
    
    <br><a href="myP1">{{ refDico.tleShopHome }}</a>
    
    <br>
    <a href="JavaScript:myClientSide('myPrm01');">Calling client_side script: myClientSide(...)</a>
	
    <br>
    <button id="btnId" name="btnName" class="enwButton" v-on:click="goHome('hello')">Calling server-side script: goHome('...)</button>
  -->
  
  </center>
  </span>
  <span v-else><br><br>Loading ...<br><br></span>
</template>

<script>
  import {
    ref, computed, onActivated, onBeforeMount, onBeforeUnmount, onBeforeUpdate,
    onDeactivated, onErrorCaptured, onMounted, onUnmounted, onUpdated, onRenderTracked, onRenderTriggered
  } from 'vue';
  import router from '@/router/index.js';
  import { useRoute } from 'vue-router';
  import useReferential from '@/modules/mdlReferential.js';
  import useTools from '@/utils/vjsTools.js'; 
  import useItemApi from '@/services/svcItemApi.js';
  // import cpnItmLst2 from '@/components/cpnItmLst2'
  
  export default {
    components: {
      // cpnItmLst2,
    },
    setup() {
      const route = useRoute();
      const { refDicoLoading, refDicoLang, refDicoAttr, refPageAttr, refItmTypeAttr, ascSetLang, refSlctMenu, fctExtractMenu, fctExtractSubmenu, refPgHomeReady } = useReferential();
      const { tlsIsMobile, tlsNavTo, tlsNavBack } = useTools();
      const { ascItemGetRecs, getMenuRecs, refItemLoading, refItemResp, refItemRecs, refMenuLoading, refMenuRecs, updateMenuRecs } = useItemApi();
      
      console.log('vwsHome.setup: DEBUG: ');  // debug
      
      let refDico = ref({});
      let refSlideRecs = ref([]);
      let slideIdx = 0;  // ID of the active slide
      let slideDelay = 3000;  // =3000: 3000ms
      let slideTimer;
      
      let flgMenu05BestSellersReady = false;
      let refMenu05BestSellers = ref([]);
      let refMenu07Distribution = ref([]);
      
      // onBeforeMount(() => console.log('before mount'));       // Event triggered on open page
      // onMounted(() => console.log('mounted'));             // Event triggered on open page
      // onBeforeUpdate(() => console.log('before update'));     // Event triggered on open page
      // onUpdated(() => console.log('updated'));                // Event triggered on open page
      // onBeforeUnmount(() => console.log('before unmount'));   // Event triggered on leaving page
      
      // onActivated(() => console.log('activated'));
      // onDeactivated(() => console.log('deactivated'));
      // KO onErrorCaptured(() => console.error('error captured'));
      // onRenderTracked(() => console.log('render tracked'));
      // onRenderTriggered(() => console.log('render triggered'));
      
      // onUnmounted(() => console.log('unmounted'));            // Event triggered on leaving page
      onUnmounted(async () => {
        console.log('vwsHome.unmounted');
        refPgHomeReady.value = false;
        if (slideTimer) clearTimeout(slideTimer);
      });
      
      onMounted(async () => {
        refDico.value = refDicoAttr.value;
        refPageAttr.value.title = refDico.value.tleShopHome;
        
        // console.log('vwsHome.onMounted: DEBUG: respStr='+JSON.stringify(resp));  // debug
        // console.log('vwsHome.onMounted: DEBUG: refDicoLoading='+refDicoLoading+', tleShopHome='+refDico.value.tleShopHome);  // debug
        // console.log('vwsHome.onMounted: DEBUG: router.params.id='+router.params.id); // prov_debug
        
        // if back button is pressed
        window.onpopstate = function(event) {
          console.log('vwsHome.BackButton: ' + document.location + ', state: ' + JSON.stringify(event.state));
          // ==> cpnHeader.BackButton: http://mail.enw.io:13080/, state: {"back":"/","current":"/","forward":"/","replaced":false,"position":191,"scroll":{"left":0,"top":0}}
          // history.go(0);
          tlsNavBack();
        };
        
        slideIdx = 0;  // ID of the active slide
        refSlideRecs.value = [
          {imgidx:0, sItmCode1:"20200923085412", sImg:"pub002.jpg", sLabel:"", valDisplay:"block", isActive:true, pgName:"pgMenu00Items", topMenu:"mnu020", subMenu:"snu0220"},
          {imgidx:1, sItmCode1:"20200923085413", sImg:"pub003.jpg", sLabel:"", valDisplay:"none", isActive:false, pgName:"pgMenu00Items", topMenu:"mnu060", subMenu:"snu0640"},
          {imgidx:2, sItmCode1:"20200923085414", sImg:"pub004.jpg", sLabel:"", valDisplay:"none", isActive:false, pgName:"pgMenu00Items", topMenu:"mnu010", subMenu:"snu0110"},
          {imgidx:3, sItmCode1:"20200923085414", sImg:"pub005.jpg", sLabel:"", valDisplay:"none", isActive:false, pgName:"pgMenu00Items", topMenu:"mnu040", subMenu:"snu0440"}
          ];
        slideTimer = setTimeout(nextSlide, slideDelay);
        
        // console.log('vwsHome.onMounted: DEBUG: pgAction='+refPageAttr.value.pgAction);
        
        // sFlgMenu: 1234567: flg to show item in corresponding menu
        let reqParams = { criteria:"sFlgMenu LIKE '%2%'", orderby:"sItmCode1 ASC"};
        /*
        switch(refPageAttr.value.pgName) {
          case 'myMenu02Discount':      reqParams.criteria = "sFlgMenu LIKE '%2%'"; break;
          case 'myMenu03NewProducts':   reqParams.criteria = "sFlgMenu LIKE '%3%'"; break;
          case 'myMenu04Exclusive':     reqParams.criteria = "sFlgMenu LIKE '%4%'"; break;
          case 'myMenu05BestSellers':   reqParams.criteria = "sFlgMenu LIKE '%5%'"; break;
          case 'myMenu06Wholesale':     reqParams.criteria = "sFlgMenu LIKE '%6%'"; break;
          case 'myMenu07Distribution':  reqParams.criteria = "sFlgMenu LIKE '%7%'"; break;
          default: break;
        }
        */

        if (!refPageAttr.value.pgName) {
          // pgName=myMenu01Products, flgTopMenu=true, flgAllItems=true
          refPageAttr.value.pgName = "pgMenu00Items";
          reqParams.pgName = "myMenu01Products";
          reqParams.flgTopMenu = true;
          reqParams.flgAllItems = true;
        }
        console.log('vwsHome.onMounted: DEBUG: criteria='+reqParams.criteria+', pgName='+refPageAttr.value.pgName); // prov_debug
        // ascItemGetRecs(reqParams);  // Without callback, the record can not be altered at loading
        ascItemGetRecs(reqParams, reqCallback);  // Callback is required if altering the record is required at loading
        
      });
      
      /**** With callback (begin) ****/
      // Callback is required if altering the record is required at loading
      function reqCallback(myResp) {
        console.log('vwsHome.reqCallback: DEBUG: myRespStr='+JSON.stringify(myResp.resp));  // prov_debug
        // ==> myRespStr={"errCode":101,"errMsg":"Record not found"}
        
        // refResp.value = myResp.resp;
        // You may alter the record here
        // ...
        
        let limitRecNb = 5;
        if (tlsIsMobile()) limitRecNb = 2;
        
        refMenu05BestSellers.value = getMenuRecs("2", limitRecNb);
        flgMenu05BestSellersReady = true;
        console.log('vwsHome.reqCallback: DEBUG: refMenu05BestSellersLen='+refMenu05BestSellers.value.length+', limitRecNb='+limitRecNb);  // prov_debug
        
        refMenu07Distribution.value = getMenuRecs("7", limitRecNb);
        console.log('vwsHome.reqCallback: DEBUG: refMenu07DistributionLen='+refMenu07Distribution.value.length+', limitRecNb='+limitRecNb);  // prov_debug
        
        refPgHomeReady.value = true;
      }
      /**** With callback (end) ****/
      
      function currentSlide(newSlideId) {
        console.log('vwsHome.currentSlide: DEBUG: slideIdx='+slideIdx+', newSlideId='+newSlideId);
        refSlideRecs.value[slideIdx].valDisplay = "none";
        refSlideRecs.value[slideIdx].isActive = false;
        slideIdx = newSlideId;  // ID of the active slide
        refSlideRecs.value[slideIdx].valDisplay = "block";
        refSlideRecs.value[slideIdx].isActive = true;
      }
      
      function nextSlide() {
        let deltaIdx = 1;
        // console.log('vwsHome.currentSlide: DEBUG: slideIdx='+slideIdx+', deltaIdx='+deltaIdx); // debug
        
        refSlideRecs.value[slideIdx].valDisplay = "none";
        refSlideRecs.value[slideIdx].isActive = false;
        slideIdx += deltaIdx;
        if (slideIdx < 0) slideIdx = refSlideRecs.value.length - 1;
        if (slideIdx >= refSlideRecs.value.length) slideIdx = 0;
        refSlideRecs.value[slideIdx].valDisplay = "block";
        refSlideRecs.value[slideIdx].isActive = true;
        
        slideTimer = setTimeout(nextSlide, slideDelay);
      }
      
      function openItm(imgIdx) {
        let pgName = refSlideRecs.value[imgIdx].pgName;
        let topMenu = refSlideRecs.value[imgIdx].topMenu;
        let subMenu = refSlideRecs.value[imgIdx].subMenu;
        console.log('vwsHome.openItm: pgName='+pgName+', topMenu='+topMenu+', subMenu='+subMenu);
        
        if (topMenu) {
          if (subMenu) {
            router.push({ name: pgName, params:{prmTopMenu:topMenu,prmSubMenu:subMenu}});
          } else {
            router.push({ name: pgName, params:{prmTopMenu:topMenu}});
          }
        } else {
          router.push({ name: pgName});
        }
      }
      
      function openPage(pgName) {
        // console.log('vwsHome.openPage: pgName='+pgName);
        // // this.$router.push({name:pgName,params:{pgAction:'init',prmId:''}});
        // router.push({name:pgName,params:{pgAction:'init',prmId:''}});
        
        let query = {name:pgName,params:{pgAction:'init',prmId:''}};
        tlsNavTo(query);
      }
      
      return {
        refDico,
        refDicoLoading,
        refSlideRecs,
        currentSlide,
        nextSlide,
        openItm,
        openPage,
        flgMenu05BestSellersReady,
        refMenu05BestSellers,
        refMenu07Distribution,
        refPgHomeReady,
      }
    },
  }
    

</script>

<style scoped>
    h1, h2 {
        font-weight: normal;
    }
    /* Slideshow container */
.slideshow-container {
  /* z-index: -1; */
  width: 90%;
  position: relative;
  margin: 0px 0px 0px 0px;				/* top right bottom left */
  padding: 0px 0px 0px 0px;				/* top right bottom left */
  /* background-color: #FFFFFF; */
}


/* Hide the images by default */
.mySlides {
  display: block;
}

.mySlidesImg {
  width: 100%;
  /*
  animation: fadeIn 2s;
  -webkit-animation: fadeIn 2s;
  -moz-animation: fadeIn 2s;
  -o-animation: fadeIn 2s;
  -ms-animation: fadeIn 2s;
  */
  /* Cross fading images: http://css3.bradshawenterprises.com/cfimg/ */
  /*
  -webkit-transition: opacity 3s ease-in-out;
  -moz-transition: opacity 3s ease-in-out;
  -o-transition: opacity 3s ease-in-out;
  transition: opacity 3s ease-in-out;
  */
}

/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: rgba(144,21,13,0.8);
  color: #FFFFFF;
}

/* Caption text */
.text {
  color: #FFFFFF;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #FFFFFF;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active, .dot:hover {
  background-color: #90150D;
}

</style>