<template>
  <span v-if="refDicoLoading == false">
  <div id="cpnCart" style="min-width: 250px; border: 2px solid #D0D0D0; margin:0 20px 0 20px; /* top right bottom left */ /* OK */">
    <center>

    <table border="0" style="text-align: left; background-color: #C0C0C0;">
      <thead>
        <tr width="100%" style="background-color: #C0C0C0;" >
          <th nowrap="1"  width="99%">{{ refDico.lbltitleArticle }}</th>
          <th nowrap="1" style="text-align: center;">{{ refDico.lbltitleQuantity }}&nbsp;&nbsp;&nbsp;&nbsp;</th>
          <th nowrap="1" style="text-align: center;">{{ refDico.lbltitleTotal }}</th>
        </tr>
      </thead>
      <tbody>
        <!-- ----------- Ordered items - STD ---------- -->
        <tr v-for="tmpRec in refCartItemStdRecs" :key="tmpRec.id"  style="background-color: #FFFFFF;">
         <td nowrap>
           <span v-if="tmpRec.iIsPromo == 1">{{ tmpRec.sLabel }} {{ refDico.flgPromo }}</span>
           <span v-else>{{ tmpRec.sLabel }}</span>
         </td>
         <td style="text-align: center;" nowrap>{{ tmpRec.iQtyOrd }} {{ refItmTypeAttr[tmpRec.sTypeCode] }}</td>
         <td style="text-align: right;" nowrap>{{ tmpRec.iTotalOrd }} {{ refDico.lblDevise }}</td>
        </tr>
        <!-- ----------- Ordered items - FZN ---------- -->
        <tr v-for="tmpRec in refCartItemFznRecs" :key="tmpRec.id"  style="background-color: #FFFFFF;">
         <td nowrap>
           <span v-if="tmpRec.iIsPromo == 1">{{ tmpRec.sLabel }} {{ refDico.flgPromo }}</span>
           <span v-else>{{ tmpRec.sLabel }}</span>
         </td>
         <td style="text-align: center;" nowrap>{{ tmpRec.iQtyOrd }} {{ refItmTypeAttr[tmpRec.sTypeCode] }}</td>
         <td style="text-align: right;" nowrap>{{ tmpRec.iTotalOrd }} {{ refDico.lblDevise }}</td>
        </tr>
        <!-- ----------- Free items - STD ---------- -->
        <tr v-for="tmpRec in refCartItemFreeStdRecs" :key="tmpRec.id"  style="background-color: #FFFFFF;">
         <td nowrap>{{ tmpRec.sLabel }} &nbsp;</td>
         <td style="text-align: center;" nowrap>{{ tmpRec.iQtyOrd }} {{ refItmTypeAttr[tmpRec.sTypeCode] }}</td>
         <td style="text-align: center;" nowrap>{{ refDico.lblItmFree }}</td>
        </tr>
        <!-- ----------- Free items - FZN ---------- -->
        <tr v-for="tmpRec in refCartItemFreeFznRecs" :key="tmpRec.id"  style="background-color: #FFFFFF;">
         <td nowrap>{{ tmpRec.sLabel }} &nbsp;</td>
         <td style="text-align: center;" nowrap>{{ tmpRec.iQtyOrd }} {{ refItmTypeAttr[tmpRec.sTypeCode] }}</td>
         <td style="text-align: center;" nowrap>{{ refDico.lblItmFree }}</td>
        </tr>

        <!-- ----------- Total ---------- -->
        <tr style="background-color: #E0E0E0;">
          <td colspan="2" nowrap>{{ refDico.lblTotalRoomProducts }}</td>
          <td style="text-align: right;" nowrap>{{ refCartRec.iSubTotalNormalProduct }} {{ refDico.lblDevise }}</td>
        </tr>
        <tr style="background-color: #E0E0E0;">
          <td colspan="2" nowrap>{{ refDico.lblTotalFrozenProducts }}</td>
          <td style="text-align: right;" nowrap>{{ refCartRec.iSubTotalFznProduct }} {{ refDico.lblDevise }}</td>
        </tr>
        <tr style="background-color: #E0E0E0;">
          <td colspan="2" nowrap>{{ refDico.lblSubTotal }}</td>
          <td style="text-align: right;" nowrap><b>{{ refCartRec.iSubTotal }} {{ refDico.lblDevise }}</b></td>
        </tr>
        <tr style="background-color: #E0E0E0;">
          <td colspan="2" nowrap>
            {{ refDico.lblDiscountCode }}
            <span v-if="refPageAttr.pgName == 'pgCart2' ">
              <input type="text" id="sPromoCode" v-model="refCartRec.sPromoCode" style="width:60px;" />
              &nbsp;<a class="btnStd01" v-on:click="checkPromoCode();" >{{ refDico.lblPromoCheck }}</a>
            </span>
            <span v-else>
              &nbsp;{{ refCartRec.sPromoCode }}
            </span>
          </td>
          <td style="text-align: right;" nowrap>
            <span v-if="refCartRec.iPromoTotal  > 0" > - {{ refCartRec.iPromoTotal }} {{ refDico.lblDevise }}</span>
            <span v-else>&nbsp;</span>
          </td>
        </tr>

        <tr style="background-color: #E0E0E0;" v-if="refCartRec.sPromoCode && refCartRec.sPromoCode.length > 0 && refCartRec.iPromoTotal == 0">
          <td colspan="3" style="color:#FF0000;">
            {{ refDico.lblDiscountMiniReq }} : {{ refCartRec.iPromoMini }} {{ refDico.lblDevise }}
          </td>
        </tr>
        <!-- tr v-if="refCartRec.sDelProvince == 'dep010'" style="background-color: #E0E0E0;" -->
        <tr v-if="refCartRec.iOrdTaxRate == 0" style="background-color: #E0E0E0;">
            <td colspan="2" nowrap>{{ refDico.lblCost }}</td>
            <td style="text-align: right;" nowrap>{{ refCartRec.iDelivery }} {{ refDico.lblDevise }}</td>
        </tr>
        <tr v-else style="background-color: #E0E0E0;">
            <td colspan="2" nowrap>{{ refDico.lblCostRoomProducts }}
            <br>
            {{ refDico.lblCostFrozenProducts }}</td>
            <td style="text-align: right;" nowrap>{{ refCartRec.iDeliveryNormalProduct }} {{ refDico.lblDevise }}
            <br>
            {{ refCartRec.iDeliveryFznProduct }} {{ refDico.lblDevise }}</td>
        </tr>
        <tr style="background-color: #E0E0E0;">
          <td width="100%" colspan="3" style="border-bottom: 2px solid #D0D0D0;"></td>
        </tr>
        <tr style="background-color: #E0E0E0;">
          <td colspan="2" nowrap>{{ refDico.lblTotal }}</td>
          <td style="text-align: right;" nowrap><b>{{ refCartRec.iTotalTax }} {{ refDico.lblDevise }}</b></td>
        </tr>
        <tr v-if="refCartRec.iOrdTaxRate == 0 || refCartRec.sDelProvince == 'dep010'" style="background-color: #E0E0E0;">
          <td colspan="3" >{{ refDico.lblDutyFree }}</td>
        </tr>
        <tr v-else style="background-color: #E0E0E0;">
          <td nowrap colspan="3" >{{ refDico.lblTaxable }}
          <br>
          {{ refDico.lblTax }} {{ refCartRec.iOrdTaxRate }}% : {{ refCartRec.iTaxPrice }} {{ refDico.lblDevise }}   {{ refDico.lblHT }}: {{ refCartRec.iTotalHT }} {{ refDico.lblDevise }}</td>
        </tr>

      </tbody>
    </table>

    </center>
  </div>
    
  
  
  </span>
  <span v-else><br><br>Loading ...<br><br></span>
</template>

<script>
  import {
    ref, computed, onActivated, onBeforeMount, onBeforeUnmount, onBeforeUpdate,
    onDeactivated, onErrorCaptured, onMounted, onUnmounted, onUpdated, onRenderTracked, onRenderTriggered
  } from 'vue';
  import useReferential from '@/modules/mdlReferential.js';
  import router from '@/router/index.js';
  import useCartApi from '@/services/svcCartApi.js';
  
  export default {
    setup() {
      const { refDicoLoading, refDicoLang, refDicoAttr, refPageAttr, refDepartmentAttr, refItmTypeAttr, ascSetLang, fctExtractState, fctExtractCity } = useReferential();
      let { ascCartGetRecs, ascCartGetItemRecs, ascCartFindRec, ascCartSaveRec, ascCartDeleteRec, refCartResp, refCartLoading, refCartRec, refCartItemRecs, refCartItemStdRecs, refCartItemFznRecs, refCartItemFreeStdRecs, refCartItemFreeFznRecs, fctCartDelItem, fctCartCreateOrder, ascCartCheckPromo, calcCartTotal } = useCartApi();
      
      console.log('cpnCart:setup: ');
      
      let refDico = ref({});
      
      onMounted(async () => {
        console.log('cpnCart.onMounted: pgName='+refPageAttr.value.pgName);
        
        // await ascSetLang(refDicoLang.value, reqCallback);
      }); // onMounted
      
      /**** With callback (begin) ****/
      function reqCallback(resp) {
        // console.log('cpnCart.reqCallback: DEBUG: respStr='+JSON.stringify(resp));  // debug
        refDico.value = resp.recDico;
        // console.log('cpnCart.reqCallback: refDicoLoading='+refDicoLoading+', tleShopHome='+refDico.value.tleShopHome);
      }
      /**** With callback (end) ****/
      function openPage(pgName) {
        console.log('cpnCart.openPage: pgName='+pgName);
        router.push({name:pgName,params:{pgAction:'init',prmId:''}});
      }
        

      function checkPromoDone(myResp) {
        let tmpPromoRec;
        // console.log('cpnCart.checkPromoDone: myRespStr='+JSON.stringify(myResp)); // prov_debug
        // ==> myRespStr={"resp":{"errCode":101,"errMsg":"Record not found"},"rec":{}}
        // ==> myRespStr={"resp":{"errCode":1,"errMsg":"OK"},"rec":{"id":1,"sPromoCode":"NOV2022","dtmPromoBegin":"2023-01-01 00:00:00","dtmPromoEnd":"2023-01-10 00:00:00","iPromoMini":1000,"iPromoRate":12,"sPromoGroup":"EDF"}}

        switch(myResp.resp.errCode) {
          case 1: // OK: done successfully
            // refResp.value.errCode = myResp.resp.errCode;
            // refResp.value.errMsg = myResp.resp.errMsg;
            tmpPromoRec = myResp.rec;
            refCartRec.value.iPromoRate = 1 * tmpPromoRec.iPromoRate;
            calcCartTotal();

            console.log('cpnCart.checkPromoDone: tmpPromoRecStr='+JSON.stringify(tmpPromoRec)); // prov_debug
            // ==> 
            alert(refDicoAttr.value.lblPromoValid);	// lblPromoValid / lblPromoInvalid
            break;
          case 101: // Record not found
          case 500: // errMsg500 = 'Generic error';          // Default server-side error
          case 501: // errMsg501 = 'Invalide function';       // Unexpected function
            refCartRec.value.sPromoCode = '';
            refCartRec.value.iPromoRate = 0;
            calcCartTotal();
            // alert('ERROR: '+myResp.resp.errMsg); // prov_debug
            alert(refDicoAttr.value.lblPromoInvalid);	// lblPromoValid / lblPromoInvalid
            break;
        }
      }

      function checkPromoCode() {
        let flgValid = false;
        refCartRec.value.iPromoRate = 0;
        console.log('cpnCart.openPage: sPromoCode='+refCartRec.value.sPromoCode);
        if (refCartRec.value.sPromoCode && (refCartRec.value.sPromoCode != "")) {
          // Submit request to remote REST API services to verify the promo code
          let myParams = {
              fct: 'chkPromoCode',
              promocode: refCartRec.value.sPromoCode,
          };
              
          ascCartCheckPromo(myParams, checkPromoDone); // Callback is required if altering the record is required at loading
          // refCartRec.value.sPromoCode
        } else {
            refCartRec.value.sPromoCode = '';
            refCartRec.value.iPromoRate = 0;
            calcCartTotal();
          alert(refDicoAttr.value.lblPromoInvalid);	// lblPromoValid / lblPromoInvalid
        }
      }


      return {
        refDicoLoading,
        // refDico,
        refDico: computed(() => refDicoAttr.value),
        openPage,
        refCartItemRecs,
        refCartRec,
        refCartItemStdRecs, 
        refCartItemFznRecs, 
        refCartItemFreeStdRecs, 
        refCartItemFreeFznRecs, 
        refPageAttr,
        refItmTypeAttr,
        checkPromoCode,
      }
    },
    
  }
    
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  a {
    color: #FFFFFF;
    text-decoration:none;
    cursor: pointer;
  }
</style>
