<template>
  <span >

  <a class="btnStd01" id="btnMenuMin" v-on:click="closeMenu();" ><i class="fa fa-arrow-left fa-1x"></i></a>
  <a class="btnStd01" id="btnMenuMax" v-on:click="openMenu();" ><i class="fa fa-arrow-right fa-1x"></i></a>
      
  <div style="display: flex;" >
    
    <div id="pgMenu" class="pgMenu"	>
      <li>
        <div>
          <div class="pgTopMenu" id="pgTopMenu"><a v-on:click="openMainMenu()">{{ refDicoAttr.menu01mnu00 }}</a></div>
        </div>
      </li>
      
      <li v-for="(topName, topValue) in refMenu" :key="topValue" v-bind:value="topValue">
        <div>
          <div class="pgTopMenu" id="pgTopMenu"><a v-on:click="openTopMenu(topValue)">{{ topName }}</a></div>
          
          <span v-if="refSubmenu != '' && refSlctMenu.topMenuCode != '' && topValue == refSlctMenu.topMenuCode && refSlctMenu.subMenuCode != '' ">
              <li v-for="(subName, subValue) in refSubmenu" :key="subValue" v-bind:value="subValue">
                <div id="pgSubMenu2" ><a v-on:click="openSubMenu(subValue)">&nbsp;{{ subName }}</a></div>
              </li>
          </span>
        </div>
      </li>    
    </div>
    
    <div id="pgContent" class="pgContent" style="flex-wrap: nowrap; width:100%;" >
      <cpnItmLst />
    </div>

  </div>
  
  </span>
</template>

<script>
  import { ref, computed, onBeforeMount, onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  import useReferential from '@/modules/mdlReferential.js'; 
  import useTools from '@/utils/vjsTools.js'; 
  import cpnItmLst from '@/components/cpnItmLst'
  import useItemApi from '@/services/svcItemApi.js';

      let { refItemLoading, refItemResp, ascItemGetRecs, updateCatalogRecs } = useItemApi();

  export default {
    components: {
      cpnItmLst,
    },
    /*
    methods: {
      selectTopMenu: function(menuCode) {
        console.log('vwsMenu00Items.selectTopMenu: menuCode='+menuCode);
        // this.$router.push({name:pgName,params:{pgAction:'init',prmId:''}});
      }
    },
    */
    setup() {
      const route = useRoute();
      let { refDicoLang, refDicoAttr, refPageAttr, ascSetLang, refSlctMenu, fctExtractMenu, fctExtractSubmenu } = useReferential();
      const { tlsCheckElementKey } = useTools();
      
      let refMenu = ref({});
      let refSubmenu = ref({});
      
      let refResp = ref({});
      
      onBeforeMount(() => {
      
        console.log('vwsMenu00Items.onBeforeMount: DEBUG routeParamsStr='+JSON.stringify(route.params));  // prov_debug
        // ==> routeParamsStr={"prmTopMenu":"mnu020","prmSubMenu":"snu0220"}
        
        // console.log('vwsMenu00Items.onBeforeMount: prmMainMenu='+route.params.prmMainMenu+', prmTopMenu='+route.params.prmTopMenu); // prov_debug
        // ==> prmMainMenu=undefined, prmTopMenu=mnu020
        
        // this.$router.push({name:'pgItemFrm',params:{pgAction:'init',itmLstFilter:'myItmLstFilter'}});
        refPageAttr.value.pgAction = 'init';
        refPageAttr.value.pgName = 'myMenu01Products';
        refPageAttr.value.title = refDicoAttr.value.menu01Products;
        refSlctMenu.value.topMenuName = refPageAttr.value.title;
console.log('vwsMenu00Items.onBeforeMount: TODO refSlctMenuStr='+JSON.stringify(refSlctMenu.value));  // prov_debug
        
        updateMenuInfo();
      });
      
      onMounted(async () => {
        // params: {"pgAction":"init","prmTopMenu":"mnu010"}
        // refItemLoading.value = true;
        
        console.log('vwsMenu00Items.onMounted: prmMainMenu='+route.params.prmMainMenu+', prmTopMenu='+route.params.prmTopMenu); // prov_debug
        // ==> prmMainMenu=undefined, prmTopMenu=mnu010
        
        
        reqCallback({"errCode":1,"errMsg":"OK"}) ;
        
      });
      
      /**** Without callback (begin) ****
      // Without callback, the record can not be altered at loading
      refResp = computed(() => {
        console.log('vwsMenu00Items.computed: DEBUG: refResp=refItemRespStr='+JSON.stringify(refItemResp.value));  // prov_debug
        return refItemResp.value;
      });
      **** Without callback (end) ****/
      
      /**** With callback (begin) ****/
      // Callback is required if altering the record is required at loading
      function reqCallback(myResp) {
        // console.log('vwsMenu00Items.reqCallback: DEBUG: respStr='+JSON.stringify(myResp.resp));  // prov_debug
        // ==> respStr={"resp":{"errCode":1,"errMsg":"OK"},"recs":[{"id":154,"sLabel":"統一布丁（大）","iStatus":null,"dtmCreate":"2021-04-15 12:00:38","dtmModif":"2021-04-15  ...
        
        // updateMenuInfo();

        if (refSlctMenu.value.fctCallback == openMainMenu) {
          console.log('vwsMenu00Items.reqCallback: DEBUG: openMainMenu');
          openMainMenu();
        } else if (refSlctMenu.value.fctCallback == openSubMenu) {
          console.log('vwsMenu00Items.reqCallback: openSubMenu: subMenuCode='+refSlctMenu.value.subMenuCode+',subMenuName='+refSlctMenu.value.subMenuName);  // prov_debug
          // Get submenu related to the topMenuCode
          refSubmenu.value = fctExtractSubmenu(refSlctMenu.value.topMenuCode);
          console.log('vwsMenu00Items.reqCallback: DEBUG: openSubMenu');
          openSubMenu(refSlctMenu.value.subMenuCode);
        } else {
          console.log('vwsMenu00Items.reqCallback: DEBUG: openTopMenu');
          openTopMenu(refSlctMenu.value.topMenuCode);
        }
        
        let winWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        console.log('vwsMenu00Items.reqCallback: DEBUG: winWidth='+winWidth);  // prov_debug
        if (winWidth >= 980) {
          // Desktop
          console.log('vwsMenu00Items.reqCallback: DEBUG: Desktop openMenu');
          openMenu();
          document.getElementById("btnMenuMin").style.display = 'none';
          document.getElementById("btnMenuMax").style.display = 'none';
        } else {
          // Mobile
          console.log('vwsMenu00Items.reqCallback: DEBUG: Mobile closeMenu');
          closeMenu();
        }
        // refItemLoading.value = false;
      }
      /**** With callback (end) ****/
      
      function updateMenuInfo() {
        console.log('vwsMenu00Items.updateMenuInfo: prmMainMenu='+route.params.prmMainMenu+', prmTopMenu='+route.params.prmTopMenu);  // prov_debug
        
        let descrMenu;
        
        refMenu.value = fctExtractMenu();
        if (route.params.prmMainMenu == 1) {
          // All items
          console.log('vwsMenu00Items.updateMenuInfo: prmMainMenu='+route.params.prmMainMenu);  // prov_debug
          refSubmenu.value = {};
          refSlctMenu.value.topMenuCode = '';
          refSlctMenu.value.topMenuName = '';
          refSlctMenu.value.subMenuCode = '';
          refSlctMenu.value.subMenuName = '';
          refSlctMenu.value.fctCallback = openMainMenu;
          
          console.log('vwsMenu00Items.updateMenuInfo: prmMainMenu='+route.params.prmMainMenu);
        } else {
          // Open topmenu & submenu requested: http://mail.enw.io:13080/?pg=items&topmenu=mnu020&submenu=snu0220
          console.log('vwsMenu00Items.updateMenuInfo: prmMainMenu='+route.params.prmMainMenu+',prmTopMenu='+route.params.prmTopMenu+',prmSubMenu='+route.params.prmSubMenu);  // prov_debug
          // ==> prmTopMenu=mnu020,prmSubMenu=snu0220
          
          refSubmenu.value = {};
          refSlctMenu.value.topMenuCode = route.params.prmTopMenu;
          refSlctMenu.value.topMenuCode = tlsCheckElementKey(refMenu.value, refSlctMenu.value.topMenuCode, true);  // true:return first menu if not found
          refSlctMenu.value.topMenuName = refMenu.value[refSlctMenu.value.topMenuCode];
          
          if (route.params.prmSubMenu) {
            // Open topMenu & subMenu
            // http://mail.enw.io:13080/?pg=items&topmenu=mnu020&submenu=snu0220
            refSlctMenu.value.subMenuCode = tlsCheckElementKey(refSubmenu.value, route.params.prmSubMenu, true);  // true:return first menu if not found
            refSlctMenu.value.subMenuName = refSubmenu.value[refSlctMenu.value.subMenuCode];
            refSlctMenu.value.fctCallback = openSubMenu;
            console.log('vwsMenu00Items.updateMenuInfo: openSubMenu: prmTopMenu='+route.params.prmTopMenu+',prmSubMenu='+route.params.prmSubMenu);  // prov_debug
            // ==> prmTopMenu=mnu020,prmSubMenu=snu0220
          } else {
            // Open topMenu
            // http://mail.enw.io:13080/?pg=items&topmenu=mnu020
            refSlctMenu.value.subMenuCode = '*';
            refSlctMenu.value.subMenuName = '';
            refSlctMenu.value.fctCallback = openTopMenu;
            console.log('vwsMenu00Items.updateMenuInfo: openTopMenu: prmMenu='+route.params.prmMenu+',prmSubMenu='+route.params.prmSubMenu);  // prov_debug
          }
        }
        refSlctMenu.value.fctCallbackMainMenu = openMainMenu;
        refSlctMenu.value.fctCallbackTopMenu = openTopMenu;
      }
      
      function openMainMenu() {
        console.log('vwsMenu00Items.openMainMenu: '); // prov_debug
        refSubmenu.value = '';
        refSlctMenu.value.topMenuCode = '';
        refSlctMenu.value.topMenuName = '全部商品';
        refSlctMenu.value.subMenuCode = '';
        refSlctMenu.value.subMenuName = '';
        
        updateCatalogRecs(refPageAttr.value.pgName, true, true);  // =true:catalog with topMemu/subMenu, =false:other catalog filter, =true:all items
        window.scrollTo(0,0); // Scroll to top of the page
      }
      
      function openTopMenu(menuCode) {
        console.log('vwsMenu00Items.openTopMenu: menuCode='+refSlctMenu.value.topMenuCode+'/'+menuCode+', subMenuCode='+refSlctMenu.value.subMenuCode); // prov_debug
      
        if ((refSlctMenu.value.topMenuCode != menuCode) || (refSlctMenu.value.subMenuCode == '')) {
          // Open submenu
          refSlctMenu.value.topMenuCode = tlsCheckElementKey(refMenu.value, menuCode, true);  // true:return first menu if not found
          refSlctMenu.value.topMenuName = refMenu.value[refSlctMenu.value.topMenuCode];
          // Get submenu related to the topMenuCode
          refSubmenu.value = fctExtractSubmenu(refSlctMenu.value.topMenuCode);
          console.log('vwsMenu00Items.openTopMenu: DEBUG: refSubmenuStr='+JSON.stringify(refSubmenu.value));  // prov_debug
          // ==> refSubmenuStr={"snu0110":"Mineral water","snu0120":"Energy water"}
          refSlctMenu.value.subMenuCode = '*';
          refSlctMenu.value.subMenuName = '';
          
          updateCatalogRecs(refPageAttr.value.pgName, true, false);  // =true:catalog with topMemu/subMenu, =false:other catalog filter, =false:not all items
          
          console.log('vwsMenu00Items.openTopMenu: open menuCode='+menuCode+', topMenuCode='+refSlctMenu.value.topMenuCode+', subMenuCode='+refSlctMenu.value.subMenuCode);
          // this.$router.push({name:pgName,params:{pgAction:'init',prmId:''}});
        }  else {
          // Close submenu
          refSlctMenu.value.subMenuCode = '';
          refSlctMenu.value.subMenuName = '';
          refSubmenu.value = {};
          
          console.log('vwsMenu00Items.openTopMenu: close menuCode='+menuCode+', topMenuCode='+refSlctMenu.value.topMenuCode+', subMenuCode='+refSlctMenu.value.subMenuCode);
          // this.$router.push({name:pgName,params:{pgAction:'init',prmId:''}});
        }
        window.scrollTo(0,0); // Scroll to top of the page
        
      }
      function openSubMenu(submenuCode) {
        console.log('vwsMenu00Items.openSubMenu: open submenuCode='+submenuCode); // prov_debug
        
        refSlctMenu.value.subMenuCode = tlsCheckElementKey(refSubmenu.value, submenuCode, true);  // true:return first menu if not found
        refSlctMenu.value.subMenuName = refSubmenu.value[refSlctMenu.value.subMenuCode];
        console.log('vwsMenu00Items.openSubMenu: submenuCode='+submenuCode+', topMenuCode='+refSlctMenu.value.topMenuCode+', subMenuCode='+refSlctMenu.value.subMenuCode);
          // this.$router.push({name:pgName,params:{pgAction:'init',prmId:''}});  // prov_debug
        
        // updateMenuRecs();
        updateCatalogRecs(refPageAttr.value.pgName, true, false);  // =true:catalog with topMemu/subMenu, =false:other catalog filter, =false:not all items
        window.scrollTo(0,0); // Scroll to top of the page
      }
      
      function closeMenu() {
        console.log('vwsMenu00Items.closeMenu: BEGIN'); // prov_debug
        document.getElementById("pgMenu").style.display = 'none';
        document.getElementById("btnMenuMin").style.display = 'none';
        document.getElementById("btnMenuMax").style.display = '';
        console.log('vwsMenu00Items.closeMenu: END'); // prov_debug
      }
      function openMenu() {
        console.log('vwsMenu00Items.openMenu: BEGIN'); // prov_debug
        document.getElementById("pgMenu").style.display = 'block';
        document.getElementById("btnMenuMin").style.display = '';
        document.getElementById("btnMenuMax").style.display = 'none';
        console.log('vwsMenu00Items.openMenu: END'); // prov_debug
      }
      
      return {
        refDicoAttr,
        refMenu,
        refSubmenu,
        refSlctMenu,
        refPageAttr,
        openMainMenu,
        openTopMenu,
        openSubMenu,
        closeMenu,
        openMenu,
      }
    },

  };

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .pgMenu {
    flex-wrap: nowrap; 
    position: fixed; 
    left:0px; 
    min-width: 150px;
    /* overflow-y: auto; */
  }
  .pgMenu li {
    list-style-type: none;
  }
  .pgMenu li a {
    color: #000000;
    cursor: pointer;
    text-decoration:none;
    font-weight: bold;
  }
  .pgMenu li a:hover {
    color: #90150D;
  }
  
  .pgTopMenu {
    height: 30px;
    width: 100%;
    padding: 10px 0px 0px 20px; /* top right bottom left */
    background-color:#E0E0E0;
    border-top: 2px solid #90150D;
  }
  .pgSubMenu {
    display: inline-block;
    width: 100%;
    margin: 0px 0px 0px 20px; /* top right bottom left */
    background-color:#D0D0D0;
  }
  .pgSubMenu li {
    width: 100%;
    height: 20px;
    padding: 10px 10px 10px 10px; /* top right bottom left */
    border-top: 2px solid #FFFFFF;
  }
  .pgSubMenu li a {
    color: #404040;
    font-weight: normal;
  }

</style>