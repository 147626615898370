<template>

  <div >
    
    <center>
    <br>
    
    <span v-if="refOrdersLoading"><br><br>Loading ...<br><br></span>
    <span v-else>

        <center>

      <h1>{{ refDico.tleCliOrders }}</h1>
      <br>
      <div id="cpnLst01div">
      <table class="cpnLst01" >
          <thead>
          <tr>
              <th> &nbsp; </th>
              <!-- th>{{ refDico.lblOrdKey }}</th -->
              <!-- th>{{ refDico.lblCliName }}</th -->
              <th>{{ refDico.lblDtOrder }}</th>
              <th>{{ refDico.lblOrdId }}</th>
              <th>{{ refDico.lblTotal }}</th>
              <th>{{ refDico.lblInvId }}</th>
              <th>{{ refDico.lblOrdStatus }}</th>
              <th>{{ refDico.lblOrdCancelDate }}</th>
              <th>{{ refDico.lbkDtReturn1 }}</th>
          </tr>
          </thead>
          <tbody>
            <!-- tr v-for="tmpRec in refOrdersRecs" :key="tmpRec.id" v-on:click="openForm(tmpRec.id)" -->
            <tr v-for="tmpRec in refOrdersRecs" :key="tmpRec.id" >
                <td align="center">
                    <a class="btnStd01" v-on:click="openForm(tmpRec.id);" v-bind:title="refDico.lblOrdOpen" >&nbsp;<i class="fa fa-file-o fa-1x"></i>&nbsp;</a>
                </td>
                <!-- td>{{ tmpRec.id }}</td -->
                <!-- td>{{ tmpRec.idClient }}</td -->
                <td align="center">{{ formatDate(tmpRec.dtmCreate) }}</td>
                <td align="center">{{ tmpRec.sOrdCode }}</td>
                <td align="right">{{ tmpRec.iTotalTax }}</td>
                <td align="center">{{ tmpRec.sEtkInvCode }}</td>
                <td align="center">
                  <span v-if=" ((1*tmpRec.paymentMode) == 5) || ((1*tmpRec.paymentMode) == 6) " >{{ refDico.lblpaymentKo }}</span>
                  <span v-else>
                    <span v-if="tmpRec.iOrdTaxRate > 0 && tmpRec.iStatus == 6" >{{ refDico.lblOrdStatus6tw }}</span>
                    <span v-else>{{ refOrdStatus[ tmpRec.iStatus ] }}</span>
                  </span>
                </td>
                <td align="center" nowrap>
                  <span v-if=" ((1*tmpRec.paymentMode) == 5) || ((1*tmpRec.paymentMode) == 6) " >&nbsp;</span>
                  <span v-else>
                    <span v-if="tmpRec.dtmCancel" >
                      &nbsp;{{ tmpRec.dtmCancel }}&nbsp;
                    </span>
                    <span v-else>
                      <span v-if="(tmpRec.iStatus >= 1 && tmpRec.iStatus < 4)" >
                        <a class="btnStd01" v-on:click="cancelOrder(tmpRec.id);" ><i class="fa fa-remove fa-1x"></i> {{ refDico.lblOrdCancel }}</a>
                      </span>
                    </span>
                   </span>
                </td>
                <td align="center" nowrap>
                  <!-- 0:idel, 1:Pending, 2:Packaging, 3:Ready, 4:Cancel, 5:Delivering, 6:Delivered, 7:retRequest, 8:returning, 9:retDone -->
                  <span v-if="tmpRec.dtmReturn1" >
                    &nbsp;{{ formatDate(tmpRec.dtmReturn1) }}&nbsp;
                  </span>
                  <span v-else>

                      <span v-if="(tmpRec.iStatus == 6) && (isReturnEnabled(tmpRec.sOrdCode, tmpRec.dtmDelivery, tmpRec.dtmDel1, tmpRec.dtmDel2) == true)" >
                        <a class="btnStd01" v-on:click="openReturnForm(tmpRec.id);" ><i class="fa fa-reply fa-1x"></i> {{ refDico.lblOrdReturn }}</a>
                      </span>
                      <span v-else>
                        &nbsp;
                      </span>

                  </span>
                </td>
            </tr>
          </tbody>
      </table>
      </div>

      <br>&nbsp;
        </center>
    
      <br>&nbsp;

    </span>
    
    </center>

  </div>
  
</template>

<script>
  import { ref, computed, onBeforeMount, onMounted } from 'vue';
  import router from '@/router/index.js';
  import { useRoute } from 'vue-router';
  import useReferential from '@/modules/mdlReferential.js'; 
  import useClientApi from '@/services/svcClientApi.js';
  import useOrdersApi from '@/services/svcOrdersApi.js';

  export default {
    methods: {
      openForm: function(recId) {
        console.log('vwsOrderLst.openForm: recId='+recId);
        this.$router.push({name:'pgOrderFrm',params:{pgAction:'init',prmId:recId}});
      },
      openReturnForm: function(recId) {
        console.log('vwsOrderLst.openForm: recId='+recId);
        this.$router.push({name:'pgOrderFrm',params:{pgAction:'return',prmId:recId}});
      },
      formatDate: function(sDate) {
        if (!sDate) { return ''; }
        // return moment(String(sDate)).format('YYYY/MM/DD');
        let dtDate = new Date(sDate);
        // console.log('vwsPromoLst.formatDate: date='+sDate+' / '+dtDate);
        // console.log('vwsPromoLst.formatDate: year='+dtDate.getFullYear());
        return dtDate.getFullYear() + '/' + 
                   ((dtDate.getMonth() < 9) ? '0' : '') + (dtDate.getMonth() + 1) + '/' +
                   ((dtDate.getDate() < 10) ? '0' : '') + dtDate.getDate();
      }
    },
    setup() {
      const route = useRoute();
      const { refDicoLang, refDicoAttr, refPageAttr, refItmTypeAttr, ascSetLang, refSlctMenu, fctExtractMenu, fctExtractSubmenu } = useReferential();
      let { ascClientFindRec, ascClientSaveRec, ascClientDeleteRec, refClientResp, refClientLoading, refClientRec } = useClientApi();
      let { refRetPhDeday, refRetTwDeday, ascOrdersGetRecs, ascOrdersGetItemRecs, ascConfigGetRec, ascOrdersFindRec, ascOrdersSaveRec, ascOrdersDeleteRec, ascOrdersSetStaffId, refOrdersResp, refOrdersLoading, refOrdersRecs } = useOrdersApi();
      
      let refDico = ref({});
      let refResp = ref({});
      let refRec = ref({});
      let refOrdStatus = ref([]);
      
      onBeforeMount(() => {
        // this.$router.push({name:'pgItemFrm',params:{pgAction:'init',itmLstFilter:'myItmLstFilter'}});
        
        console.log('vwsOrderLst.onBeforeMount: DEBUG: refClientRecId='+refClientRec.value.id);  // prov_debug
        if (refClientRec.value.id) {
          refPageAttr.value.pgAction = 'init';
          refPageAttr.value.pgName = 'pgOrderLst';
        } else {
          // Client need to login before displaying orders
          openPage('pgClientLogin');
        }
      });
      
      onMounted(async () => {
        refDico.value = refDicoAttr.value;
        refPageAttr.value.title = refDico.value.tleCliOrders;
        
        refOrdStatus.value.length = 0;
        refOrdStatus.value.push(refDico.value.lblOrdStatus0);
        refOrdStatus.value.push(refDico.value.lblOrdStatus1);
        refOrdStatus.value.push(refDico.value.lblOrdStatus2);
        refOrdStatus.value.push(refDico.value.lblOrdStatus3);
        refOrdStatus.value.push(refDico.value.lblOrdStatus4);
        refOrdStatus.value.push(refDico.value.lblOrdStatus5);
        refOrdStatus.value.push(refDico.value.lblOrdStatus6);
        refOrdStatus.value.push(refDico.value.lblOrdStatus7);
        refOrdStatus.value.push(refDico.value.lblOrdStatus8);
        refOrdStatus.value.push(refDico.value.lblOrdStatus9);
        

        if (refRetPhDeday.value >= 90) {
          // Get value from DB:config
          getRetPhDeday();
        } else {
          getRetOrderRecs();
        }

      });

    function getRetPhDeday() {
        // Get value from DB:config
        let reqParams = { 
              key: 'OrdRetPhDelay',
        };
        ascConfigGetRec(reqParams, reqCfgPhCallback);  // Callback is required if altering the record is required at loading
    }

    function getRetTwDeday() {
        // Get value from DB:config
        let reqParams = { 
              key: 'OrdRetTwDelay',
        };
        ascConfigGetRec(reqParams, reqCfgTwCallback);  // Callback is required if altering the record is required at loading
    }

    function getRetOrderRecs() {
        // console.log('vwsOrderLst.onMounted: DEBUG: CliId='+refClientRec.value.id+', CliMobile='+refClientRec.value.sMobile);  // prov_debug
        let reqParams = { 
              criteria: "idClient = "+refClientRec.value.id, 
              orderby: "dtmCreate DESC",
              };
        ascOrdersGetRecs(reqParams, reqCallback);  // Callback is required if altering the record is required at loading
    }

      /**** Without callback (begin) ****
      // Without callback, the record can not be altered at loading
      refResp = computed(() => {
        console.log('vwsOrderLst.computed: DEBUG: refResp=refItemRespStr='+JSON.stringify(refItemResp.value));  // prov_debug
        return refItemResp.value;
      });
      **** Without callback (end) ****/
      
      /**** With callback (begin) ****/
      // Callback is required if altering the record is required at loading

      function reqCfgPhCallback(myResp) {
        // console.log('vwsOrderLst.reqCfgPhCallback: DEBUG: respStr='+JSON.stringify(myResp.resp));  // prov_debug
        // ==> respStr={"errCode":1,"errMsg":"OK"}
        // console.log('vwsOrderLst.reqCfgPhCallback: DEBUG: respStr='+JSON.stringify(myResp.rec));  // prov_debug
        // ==> respStr={"sKey":"OrdRetDelay","dtmData":null,"sData":null,"iData":3}
        if (myResp.resp.errCode == 1) {
          refRetPhDeday.value = myResp.rec.iData;
          // console.log('vwsOrderLst.reqCfgPhCallback: DEBUG: refRetPhDeday='+refRetPhDeday.value);  // prov_debug
        }
        getRetTwDeday();
      }

      function reqCfgTwCallback(myResp) {
        // console.log('vwsOrderLst.reqCfgTwCallback: DEBUG: respStr='+JSON.stringify(myResp.resp));  // prov_debug
        // ==> respStr={"errCode":1,"errMsg":"OK"}
        // console.log('vwsOrderLst.reqCfgTwCallback: DEBUG: respStr='+JSON.stringify(myResp.rec));  // prov_debug
        // ==> respStr={"sKey":"OrdRetDelay","dtmData":null,"sData":null,"iData":3}
        if (myResp.resp.errCode == 1) {
          refRetTwDeday.value = myResp.rec.iData;
          // console.log('vwsOrderLst.reqCfgTwCallback: DEBUG: refRetTwDeday='+refRetTwDeday.value);  // prov_debug
        }
        getRetOrderRecs();
      }

      function reqCallback(myResp) {
        console.log('vwsOrderLst.reqCallback: DEBUG: respStr='+JSON.stringify(myResp.resp));  // prov_debug
        // ==> respStr=
        
        
      }
      /**** With callback (end) ****/
      
      /*
      function delOrderDone(myResp) {
        console.log('vwsOrderLst.delOrdersDone: myRespStr='+JSON.stringify(myResp)); // prov_debug
        // ==> myRespStr={"resp":{"errCode":501,"errMsg":"Invalide function"}}
        // ==> myRespStr={"resp":{"errCode":502,"errMsg":"502:Could not save record"}}
        // ==> myRespStr={"resp":{"errCode":1,"errMsg":"OK"}}
        
        console.log('vwsOrderLst.delOrderDone: DEBUG: CliId='+refClientRec.value.id+', CliMobile='+refClientRec.value.sMobile);  // prov_debug
        let reqParams = { 
              criteria: "idParent = "+refClientRec.value.id, 
              orderby: "dtmCreate DESC",
              };
        switch(myResp.resp.errCode) {
          case 1: // OK: done successfully
            refResp.value.errCode = myResp.resp.errCode;
            refResp.value.errMsg = myResp.resp.errMsg;
            
            ascOrdersGetRecs(reqParams, reqCallback);  // Callback is required if altering the record is required at loading
        
            alert(refDico.value.msgOrdersDeleted);
            break;
          case 501: // errMsg501 = 'Invalide function';       // Unexpected function
            alert(refDico.value.errOrdersDel);
            break;
        }
      } // delOrderDone
      function delOrder(orderId) {
        console.log('vwsOrderLst.delOrder: DEBUG: orderId='+orderId+', clientId='+refClientRec.value.id);  // prov_debug
        
        refRec.value.errCode = 0; // Reset errCode
        refRec.value.id = orderId;
        // Submit request to remote REST API services to get record matching the provided id
        let myParams = {
            fct: 'delete',
            rec: refRec.value,
        };
        
        ascOrderDeleteRec(myParams, delOrderDone); // Callback is required if altering the record is required at loading
      } // delOrder
      */
      
      function cancelOrderDone(){
        console.log('vwsOrderLst.cancelOrderDone: DEBUG: CliId='+refClientRec.value.id+', CliMobile='+refClientRec.value.sMobile);  // prov_debug
        let reqParams = { 
              criteria: "idClient = "+refClientRec.value.id, 
              orderby: "dtmCreate DESC",
              };
        ascOrdersGetRecs(reqParams, reqCallback);  // Callback is required if altering the record is required at loading
      }
      
      
      function cancelOrder(orderId) {
        console.log('vwsOrderLst.cancelOrder: DEBUG: orderId='+orderId+', clientId='+refClientRec.value.id);  // prov_debug
        if (confirm(refDicoAttr.value.msgRequestCancelOrder) == true){
          console.log("!!!!! Order will be canceled");
          
          //if (refRec.value.idStaffDel > 0) {
            let myParams = {
                  orderId: orderId,
                  ordStatus: 4, // 0:idel, 1:Pending, 2:Packaging, 3:Ready, 4:Cancel, 5:Delivering, 6:Delivered, 7:retRequest, 8:returning, 9:retDone
                  staType: 0,	// =0:Idle, 1:Prepare, 2:Deliver, 3:Return
            };
          console.log('2vwsOrderLst.cancelOrder: DEBUG: myParamsStr='+JSON.stringify(myParams));  // debug
          ascOrdersSetStaffId(myParams, cancelOrderDone);	// Assign staff ID to prepare, deliver, or return the order
          
        //}
      }
    }// cancelOrder


      function returnOrder(orderId) {
        console.log('vwsOrderLst.returnOrder: DEBUG: orderId='+orderId+', clientId='+refClientRec.value.id);  // prov_debug
        confirm(refDicoAttr.value.msgRequestReturnlOrder);
      } // returnOrder


      function isReturnEnabled(sOrdCode, sDelivery, sDelivery1, sDelivery2) {
        let retEnabled = false;
        console.log('vwsOrderLst.isReturnEnabled: DEBUG: sOrdCode='+sOrdCode+', sDelivery='+sDelivery+' - '+sDelivery1+' - '+sDelivery2);  // prov_debug
        // ==> sDelivery=2023-03-11 09:24:11

/*
        let dtDelivery = new Date(sDelivery);
        let dtNow = new Date();  // Get current date and time
        let nbSecDiff = dtNow.getTime() - dtDelivery.getTime();
        let nbDays = parseInt(nbSecDiff / (1000*60*60*24));

        if (nbDays > refRetPhDeday.value) {
           retEnabled = true;
        }
        console.log('vwsOrderLst.isReturnEnabled: DEBUG: nbDays='+nbDays+' / '+refRetPhDeday.value+', retEnabled='+retEnabled);  // prov_debug
*/

        let dtDelivery;
        let nbSecDiff;
        let nbDays;
        let dtNow = new Date();  // Get current date and time

        if (sDelivery1) {
          dtDelivery = new Date(sDelivery1);
          nbSecDiff = dtNow.getTime() - dtDelivery.getTime();
          nbDays = parseInt(nbSecDiff / (1000*60*60*24));
          if (nbDays <= refRetTwDeday.value) {
             retEnabled = true;
          }
          console.log('vwsOrderLst.isReturnEnabled: DEBUG: nbDays1='+nbDays+' / '+refRetTwDeday.value+', retEnabled='+retEnabled);  // prov_debug
        } else if (sDelivery2) {
          dtDelivery = new Date(sDelivery2);
          nbSecDiff = dtNow.getTime() - dtDelivery.getTime();
          nbDays = parseInt(nbSecDiff / (1000*60*60*24));
          if (nbDays <= refRetTwDeday.value) {
             retEnabled = true;
          }
          console.log('vwsOrderLst.isReturnEnabled: DEBUG: nbDays2='+nbDays+' / '+refRetTwDeday.value+', retEnabled='+retEnabled);  // prov_debug
        } else if (sDelivery) {
          dtDelivery = new Date(sDelivery);
          nbSecDiff = dtNow.getTime() - dtDelivery.getTime();
          nbDays = parseInt(nbSecDiff / (1000*60*60*24));
          if (nbDays <= refRetPhDeday.value) {
             retEnabled = true;
          }
          console.log('vwsOrderLst.isReturnEnabled: DEBUG: nbDays='+nbDays+' / '+refRetPhDeday.value+', retEnabled='+retEnabled);  // prov_debug
        }
        return retEnabled;
      }

      function openPage(pgName) {
        console.log('vwsOrderLst.openPage: pgName='+pgName);
        // this.$router.push({name:pgName,params:{pgAction:'init',prmId:''}});
        router.push({name:pgName,params:{pgAction:'init',prmId:''}});
      }
      
      return {
        refDico,
        refOrdStatus,
        refPageAttr,
        refItmTypeAttr,
        refOrdersResp,
        refOrdersLoading,
        refOrdersRecs,
        cancelOrder,
        returnOrder,
        isReturnEnabled,
      }
    },

  };

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>