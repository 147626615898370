import { ref, computed } from "vue";
import useTools from '@/utils/vjsTools.js';

const axios = require("axios");

// Global variable that can be shared state with Composition API

// Dico
let refDicoLoading = ref(false);
let refDicoResp = ref({});
let refDicoLang = ref('');  // ='tw':Traditionnal chinese, 'en':English
let refDicoRec = ref({});  // List of key/value

export default function useDicoApi() {
    
  /*
   * ascStaffGetRecs: Request REST API services for an array of records
   */
  async function ascDicoLoad(prmLang) {
    const fctName = 'svcDicoApi.ascDicoLoad';
    const reqUrl = '/api/referential/getDico';
    const {tlsDecodeUri} = useTools();
    
    console.log(fctName+': BEGIN: prmLang='+prmLang);
    
    refDicoLoading.value = true;
    try {
      let reqParams = {lang:prmLang};
      const { data } = await axios.post(
        reqUrl,
        reqParams
      );
      
      // console.log(fctName+': DEBUG: retDataStr='+JSON.stringify(data));  // debug
      // ==> retDataStr={"errCode":1,"errMsg":"OK","rec":{"menuHome":"Home","menuShopping":"Shopping","menuShoLst":"Shopping orders","menuShoFrmNew":"New order","menuCliLst":"List of clients","menuCliFrmNew":"New client","menuPurchase":"Purchase","menuPurLst":"Purchase orders","menuPurFrmNew":"New purchase","menuSupLst":"List of suppliers","menuSupFrmNew":"New supplier","menuSetup":"Setup"},"recsNb":0,"recs":[{}]}

      let strJson;
      let strDecoded;
      
      // let objKey;
      // let objValue;
      
      let reqResp = data;
      
      console.log(fctName+': DEBUG: errCode='+reqResp.errCode+', errMsg='+reqResp.errMsg);  // prov_debug
      
      // errCode = [1 ... 99] :OK message, [100 ... 999] :Error message, value = [1000 ... 999] :Process aborted
      refDicoResp.value.errCode = reqResp.errCode; 
      refDicoResp.value.errMsg = reqResp.errMsg;
      switch(reqResp.errCode) {
        case 1: // OK: rec found
          // Get response (begin)
          
          /*
          for (const prop in reqResp.rec) {
            // Decode value of each element of the JSON object (begin)
            // from element:  "btnMoreItems":"%E6%9B%B4%E5%A4%9A%E9%A0%85%E7%9B%AE"
            // to element:    "btnMoreItems":"更多項目"
            objKey = prop;                    // objKey="menuHome" 
            objValue = reqResp.rec[objKey];   // objValue=Home
            reqResp.rec[objKey] = decodeURI(objValue);
            // console.log('vwsStaffLst.computed: DEBUG: objKey='+JSON.stringify(objKey)+', objValue='+objValue);  // debug
            // Decode value of each element of the JSON object (begin)
          }
          // console.log('vwsStaffLst.computed: DEBUG: reqResp.recStr='+JSON.stringify(reqResp.rec));  // debug
          // ==> 
          */
          
          tlsDecodeUri(reqResp.rec);
          // console.log('vwsStaffLst.computed: DEBUG: reqResp.recStr='+JSON.stringify(reqResp.rec));  // debug
          // ==> 
          
          // refDicoRec.value = reqResp.rec;
          refDicoRec.value = JSON.parse(JSON.stringify(reqResp.rec)); // Clone the object
          
          // You may alter data of the record here
          // ...

          // Get response (end)
          break;
        case 2: // Record not found
          refDicoRec.value = {};
          break;
        default: // Error occured at server sidebar
          refDicoRec.value = {};
          break;
      }
      
      // console.log(fctName+': DEBUG: errCode='+reqResp.errCode+', refDicoRecStr='+JSON.stringify(refDicoRec.value));  // debug
      // ==> refDicoRecStr={"menuHome":"Home","menuShopping":"Shopping","menuShoLst":"Shopping orders","menuShoFrmNew":"New order","menuCliLst":"List of clients","menuCliFrmNew":"New client","menuPurchase":"Purchase","menuPurLst":"Purchase orders","menuPurFrmNew":"New purchase","menuSupLst":"List of suppliers","menuSupFrmNew":"New supplier","menuSetup":"Setup"}

    } catch (ex) {
      if (ex.response) {
        // Request made and server responded
        console.log(fctName+': EXC: exStatus='+ex.response.status+', exData='+ex.response.data);
        // console.log('exrespdata:'+ex.response.data);  // 9002:DB Exception:2021-04-08 16:59:36 - mdlDbMysql.dbFind
        // console.log('exrespstatus:'+ex.response.status);  // 500
        // console.log('exrespheader:'+ex.response.headers);
      } else {
        console.log(fctName+': EXC: ex='+ex.stack);
      }
    } finally {
      refDicoLoading.value = false;
    }
    
    console.log(fctName+': RET: prmLang='+prmLang);
    
  } // ascDicoLoad
  
  return  {
      refDicoLoading: computed(() => refDicoLoading.value),
      refDicoRec: computed(() => refDicoRec.value),
      ascDicoLoad,
  }
}
