<template>
  
  <div>
    
    
    <center>
    <br>
    
    <span v-if="refIsLoading"><br><br>Loading ...<br><br></span>
    <span v-else>
      <form novalidate @submit.prevent=" onSubmit" v-on:keyup="frmEvtKeyUp" >
        <center>
        <div class="divHor02Shadow">
          <table border="0">
            <tr>
              <td align="center">
                <h1>{{ refDico.tleLogin }}</h1>
                <!-- // ==> errCode = [1 ... 99]:OK message, [100 ... 899]:Error message, [900 ... 999]:Error message, [[1000 ... 999]:Process aborted -->
                <span v-if="(refResp.errCode > 0) && (refResp.errCode <= 99)"><div class="divOk">{{ refResp.errMsg }}<br>&nbsp;</div></span>
                <span v-if="(refResp.errCode > 100) && (refResp.errCode <= 499)"><div class="divOk">{{ refResp.errMsg }}<br>&nbsp;</div></span>
                <span v-if="refResp.errCode == 901"><div class="divError"> {{ refDico.errInvalidLoginData }}<br>&nbsp;</div></span>
                <span v-if="refResp.errCode >= 500"><div class="divError">{{ refResp.errMsg }}</div></span>
              </td>
            </tr>
            <tr>
              <td align="center">
                {{ refDico.msgNewSingin1 }}
                <a class="link" v-on:click="openPage('pgClientRegister')">&nbsp;{{ refDico.msgNewSingin2 }}</a>
              </td>
            </tr>
            <tr>
              <td align="center">
                <br>
                {{ refDico.lblLoginId }}
                <br><input type="text" id="loginId" ref="refLoginId" v-model="refRec.loginId" />

                <br>
                {{ refDico.lblLoginPsw }}
                <br><input id="loginPsw" v-model="refRec.loginPsw" v-bind:placeholder="refDico.infoPassword" type="password" autocomplete="on" />
              </td>
            </tr>
            <tr>
              <td align="center">
				<a class="link" v-on:click="openPage('pgClientResetPsw')">{{ refDico.msgResetPassword }}</a>
              </td>
            </tr>
            <tr>
              <td align="center">
                <br>
                <a class="btnStd01" v-on:click="frmAction('login')"><i class="fa fa-sign-in fa-1x"></i>&nbsp;{{ refDico.lblLogin }}</a>
                <br>&nbsp;
              </td>
            </tr>
          </table>
        </div>
        </center>
      </form>
    </span>
    
    <br>&nbsp;
    </center>
  </div>  
  
</template>

<script>
  import {
    ref, refs, computed, onActivated, onBeforeMount, onBeforeUnmount, onBeforeUpdate,
    onDeactivated, onErrorCaptured, onMounted, onUnmounted, onUpdated, onRenderTracked, onRenderTriggered
  } from 'vue';
  import router from '@/router/index.js'
  import { useRoute } from 'vue-router';
  import myApp from '@/myApp.vue';
  import useReferential from '@/modules/mdlReferential.js';
  import useTools from '@/utils/vjsTools.js'; 
  import useClientApi from '@/services/svcClientApi.js';
  import useCartApi from '@/services/svcCartApi.js';
  import usePreferredApi from '@/services/svcPreferredApi.js';

      let { ascCartGetRecs, ascCartGetItemRecs, ascCartFindRec, fctCartGetCliId, fctCartSetCliId, ascCartSaveRec, ascCartDeleteRec, refCartResp, refCartLoading, refCartRec, refCartItemRecs, fctCartDelItem } = useCartApi();
      let { ascPreferredGetRecs, ascPreferredGetItemRecs, ascPreferredFindRec, ascPreferredSaveRec, ascPreferredDeleteRec, refPreferredResp, refPreferredLoading, refPreferredRecs } = usePreferredApi();

  export default {
    setup() {
      const route = useRoute();
      const { refDicoLang, refDicoAttr, refPageAttr, ascSetLang } = useReferential();
      const { tlsCheckElementKey } = useTools();
      let { ascClientLogin, ascClientFindRec, ascClientSaveRec, ascClientDeleteRec, refClientResp, refClientLoading, refClientRec } = useClientApi();
      
      let refDico = ref({});
      
      let refResp = ref({});
      let refRec = ref({loginId:'', loginPsw:''});
      let refIsLoading = ref(true);
      
      let refLoginId = ref(null);
      
      // onBeforeMount(() => console.log('before mount'));
      // onMounted(() => console.log('mounted'));
      // onBeforeUpdate(() => console.log('before update'));
      // onUpdated(() => console.log('updated'));
      // onBeforeUnmount(() => console.log('before unmount'));
      // onUnmounted(() => console.log('unmounted'));
      // ??? onActivated(() => console.log('activated'));                // ???
      // ??? onDeactivated(() => console.log('deactivated'));            // ???
      // ??? onErrorCaptured(() => console.error('error captured'));     // ???
      // ??? onRenderTracked(() => console.log('render tracked'));       // ???
      // ??? onRenderTriggered(() => console.log('render triggered'));   // ???
      
      onBeforeMount(async () => {
        console.log('vwsClientLogin.onBeforeMount: pgAction='+route.params.pgAction+',id='+route.params.prmId);
      }); // onBeforeMount
      
      onMounted(async () => {
        console.log('vwsClientLogin.onMounted: pgAction='+route.params.pgAction+',id='+route.params.prmId);
        
        refDico.value = refDicoAttr.value;
        refPageAttr.value.title = refDico.value.menu111Login;
        
        frmAction('new');
        
      }); // onMounted

      /**** Without callback (begin) ****
      // Without callback, the record can not be altered at loading
      refResp = computed(() => {
        // console.log('vwsClientLogin.computed: DEBUG: refResp=refClientRespStr='+JSON.stringify(refClientResp.value));  // debug
        return refClientResp.value;
      });
      refRec = computed(() => {
        // console.log('vwsClientLogin.computed: DEBUG: refRec=refClientRecStr='+JSON.stringify(refClientRec.value));  // debug
        return refClientRec.value;
      });
      **** Without callback (end) ****/
      
      /**** With callback (begin) ****/
      // Callback is required if altering the record is required at loading
      function reqCallback(resp) {
        // Set data of the record
        refResp.value = resp.resp;
        // refRec.value = resp.rec;
        // console.log('vwsClientLogin.reqCallback: DEBUG: respStr='+JSON.stringify(resp));  // prov_debug
        // ==> respStr={"resp":{"errCode":101,"errMsg":"Record not found"},"rec":{}}
        // 
        
        if (refResp.value.errCode == 1) {
          // Record loaded correctely. Reset error message
          refResp.value.errCode = 0;
          refResp.value.errMsg = '';
        }
        
        // You may alter the record here
        // ...
        
        // console.log('vwsClientLogin.reqCallback: DEBUG: refRecStr='+JSON.stringify(refRec.value));  // prov_debug
        // ==> 
        
      }
      /**** With callback (end) ****/
      
      
      function frmEvtKeyUp(evt) {
        // console.log('vwsClientLogin.frmEvtKeyUp: DEBUG: evt='+evt.keyCode);
        if (evt.keyCode == 13) {
          // ENTER
          frmAction('login');
        }
        
      } // frmEvtKeyUp
      
      function openPage(pgName) {
        console.log('vwsClientLogin.openPage: pgName='+pgName);
        
        router.push({name:pgName,params:{pgAction:'init',prmId:''}});
      }

      function fctPreferredCallback(myResp) {
        console.log('vwsClientLogin.fctPreferredCallback: myRespStr='+JSON.stringify(myResp)); // prov_debug
        openPage('pgCart1');
      }

      function frmActionDone(myResp) {
        let sPassword2;
        console.log('vwsClientLogin.frmActionDone: myRespStr='+JSON.stringify(myResp)); // prov_debug
        // ==> myRespStr={"resp":{"errCode":501,"errMsg":"Invalide function"}}
        // ==> myRespStr={"resp":{"errCode":502,"errMsg":"502:Could not save record"}}
        // ==> myRespStr={"resp":{"errCode":1,"errMsg":"OK"}}
        
        switch(myResp.resp.errCode) {
          case 1: // OK: done successfully
            console.log('vwsClientLogin.frmActionDone: refClientRecStr='+JSON.stringify(refClientRec.value)); // prov_debug
            // ==> myRespStr={"resp":{"errCode":1,"errMsg":"OK"},"rec":{"id":1,"iStatus":1,"dtmCreate":"2020-01-02 00:00:00","dtmModif":"2023-02-20 12:36:29","dtmValidMob":null,"dtmValidEmail":null,"sPassword":"tmp123","sMobile":"0908823043","sEmail":"jpn_email@yahoo.com","sGenderCode":"M","sFirstName":"Cli JackBis","sLastName":"CLI SMITH","sAddr1":"1 rue ncndd","sAddr2":"1 FL","sZipCode":"99000","sCity":"cty2110","sProvince":"dep210","sMsgMode":"E","sCheckCode":null,"sLevel":"A","sNote":null,"sComCode":"Cli001b","sComName":"Société 001b","sComTel":"0123456789","sComFax":"1234567891","sDepartment":"dep001","sPassword2":"tmp123","dtmExpPsw2":"2023-02-20 13:01:53","sBizAddr1":"2 bd Foch","sBizAddr2":null,"sBizZipCode":"19123","sBizCity":"cty0310","sBizProvince":"dep030","ssoKey":"69bdd608663f2b374e0a6accd2da28fd"}}
            
            fctCartSetCliId(refClientRec.value.id); // Record clientId into the cookies
            refResp.value.errCode = myResp.resp.errCode;
            refResp.value.errMsg = myResp.resp.errMsg;
            sPassword2 = myResp.rec.sPassword2;
            console.log('vwsClientLogin.frmActionDone: sPassword2='+sPassword2 +' / '+ refRec.value.loginPsw); // prov_debug

            if (sPassword2 && (sPassword2 == refRec.value.loginPsw)) {
              // Login has been done with temporary password. Change it. (begin)
              openPage('pgClientSetPsw');
              // Login has been done with temporary password. Change it. (begin)
            } else {
              if (!refPreferredRecs.value || refPreferredRecs.value.length == 0) {
                let reqParams = { 
                  criteria: "idParent = "+refClientRec.value.id, 
                  orderby: "sItmCode1 ASC",
                };
                ascPreferredGetItemRecs(reqParams, fctPreferredCallback);  // Callback is required if altering the record is required at loading
              } else {
                openPage('pgCart1');
              }
            }
            break;
          // case 501: // errMsg501 = 'Invalide function';       // Unexpected function
          default:
            refResp.value.errCode = 901;
            console.log('vwsClientLogin.frmAction: Ko: '+ myResp.resp.errCode +': '+myResp.errMsg); // prov_debug
            break;
        }
      } // frmActionDone
      
      function frmAction(myFct) {
        // myFct = new, insert, update, delete
        let isValid = true;
        let myParams;
        
        console.log('vwsClientLogin.frmAction: DEBUG: myFct='+myFct); // prov_debug
        switch(myFct) {
          case 'new':
            refRec.value = {loginId:'', loginPsw:''};
            refResp.value.errCode = 0;
            refResp.value.errMsg = '';
            break;
          case 'login':
            refRec.value.loginId = refRec.value.loginId.trim();
            refRec.value.loginPsw = refRec.value.loginPsw.trim();
            if ((refRec.value.loginId == '') || (refRec.value.loginPsw == '')) {
              isValid = false;
              refResp.value.errCode = 901;
            }
            if (isValid == true) {
              refRec.value.errCode = 0; // Reset errCode
              // console.log('vwsClientLogin.frmAction: DEBUG: myFct='+myFct+',loginId='+refRec.value.loginId+',psw='+refRec.value.loginPsw); // prov_debug
              
              // let tblCriteria = " (sLoginId = '"+refRec.value.loginId+"' OR sEmail = '"+refRec.value.loginId+"') AND sPassword = '"+refRec.value.loginPsw+"' ";
              let myParams = {
                  sLoginId: refRec.value.loginId,
                  sLoginPsw: refRec.value.loginPsw,
              };
              ascClientLogin(myParams, frmActionDone); // Callback is required if altering the record is required at loading
              
            } else {
              // console.log('vwsClientLogin.frmAction: Ko: myFct='+myFct+',loginId='+refRec.value.loginId+',psw='+refRec.value.loginPsw); // prov_debug
              refRec.value.errCode = 901; // 901: Invalid password
            }
            break;
        }
        refLoginId.value.focus();
      } // frmAction
      
      return {
        refDico,
        refIsLoading: computed(() => refClientLoading.value),
        refResp,
        refRec,
        refLoginId,
        frmEvtKeyUp,
        openPage,
        frmAction,
      }
    }, // onMounted
  } // setup
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .divOk {
    color: #00FF00;
  }
  .divError {
    color: #FF0000;
  }
  input:disabled {
    background-color: #F0F0F0;
  }
  .link:hover {
    cursor: pointer;
    color: #90150D;
  }

</style>