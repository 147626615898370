import { ref, computed } from "vue";
import useReferential from '@/modules/mdlReferential.js'; 
import usePreferredApi from '@/services/svcPreferredApi.js';

const axios = require("axios");

// Global variable that can be shared state with Composition API
let users = ref([]);	// Array of uers

// Item
let refItemLoading = ref(false);
let refItemRecs = ref([]);	// Array of records
let refItemRec = ref({});  // Current record
let refItemRecsNb = ref(0);
let refItemResp = ref({errCode:0, errMsg:'159'});

let refMenuLoading = ref(true);
let refMenuRecs = ref([]);	// Array of records to display in menu/submenu page
let refFlgPreferred = ref(false); // Flag =1:list of preferred items; else:liste of items

let refSearchCriteria = ref('');

      let { refPreferredRecs } = usePreferredApi();

export default function useItemApi() {
  
  let { referentialGetVersion, refSlctMenu, refDescrMenus } = useReferential();
  
  function isMenuItemValid(myObj) {
    const fctName = 'svcItemApi.isMenuItemValid';
    
    let isValid = false;
    
    // refSlctMenu = ref({topMenuCode:'', topMenuName:'', subMenuCode:'', subMenuName:'', fctCallback:'' });
    if (refSlctMenu.value.topMenuCode == myObj.sType1Code) {
      
      
      if (refSlctMenu.value.subMenuCode != '*') {
        // Both topMenu and subMenu
        if (refSlctMenu.value.subMenuCode == myObj.sType2Code) {
          // console.log(fctName+': DEBUG: subMenu=true: subMenuCode='+refSlctMenu.value.topMenuCode+', subMenuCode='+refSlctMenu.value.subMenuCode+'/'+myObj.sType2Code);  // prov_debug
          isValid = true;
        } else {
          // console.log(fctName+': DEBUG: subMenu=false: subMenuCode='+refSlctMenu.value.topMenuCode+', subMenuCode='+refSlctMenu.value.subMenuCode+'/'+myObj.sType2Code);  // prov_debug
        }
        
        // isValid = true; // prov_debug
        
      } else {
        // Only topMenuCode
        // console.log(fctName+': DEBUG: topMenu=true: topMenuCode='+refSlctMenu.value.topMenuCode+', subMenuCode='+refSlctMenu.value.subMenuCode);  // prov_debug
        isValid = true;
      }
      
    } else {
      // console.log(fctName+': DEBUG: false: topMenuCode='+refSlctMenu.value.topMenuCode+'/'+myObj.sType1Code);  // prov_debug
    }
    
    return isValid;
  } // isMenuItemValid
  
  function isCatalogItemValid(pgName, myObj) {
    const fctName = 'svcItemApi.isCatalogItemValid';
    
    let isValid = false;
    
    // sFlgMenu: 1234567: flg to show item in corresponding menu
    //    refRec.value.sMenu02Discount = (refRec.value.sFlgMenu.indexOf('2') >= 0)? true:false;
    //    refRec.value.sMenu03NewProducts = (refRec.value.sFlgMenu.indexOf('3') >= 0)? true:false; 
    //    refRec.value.sMenu04Exclusive = (refRec.value.sFlgMenu.indexOf('4') >= 0)? true:false; 
    //    refRec.value.sMenu05BestSellers = (refRec.value.sFlgMenu.indexOf('5') >= 0)? true:false; 
    //    refRec.value.sMenu06Wholesale = (refRec.value.sFlgMenu.indexOf('6') >= 0)? true:false; 
    //    refRec.value.sMenu07Distribution = (refRec.value.sFlgMenu.indexOf('7') >= 0)? true:false; 
    
    let filterValue = 'x';
    switch(pgName) {
      case 'myMenu02Discount':      filterValue = '2'; break;
      case 'myMenu03NewProducts':   filterValue = '3'; break;
      case 'myMenu04Exclusive':     filterValue = '4'; break;
      case 'myMenu05BestSellers':   filterValue = '5'; break;
      case 'myMenu06Wholesale':     filterValue = '6'; break;
      case 'myMenu07Distribution':  filterValue = '7'; break;
      default:
        console.log(fctName+': ERROR: Unexpected pgName='+pgName);
        break;
    }
    if (myObj.sFlgMenu.indexOf(filterValue) >= 0) isValid = true;
    
    // console.log(fctName+': DEBUG: pgName='+pgName+', sFlgMenu='+myObj.sFlgMenu+'/'+filterValue+', isValid='+isValid);
    
    return isValid;
  } // isCatalogItemValid
  
  function setSearchCriteria(criteria) {
    const fctName = 'svcItemApi.setSearchCriteria';
    refSearchCriteria.value = criteria;
  }
  
  function updateCatalogRecs(pgName, flgTopMenu, flgAllItems) {
    const fctName = 'svcItemApi.updateCatalogRecs';
    let eleId;
    
/*
      let refVer = referentialGetVersion();
        console.log(fctName+': TODO: refVer='+refVer);
      if (refSlctMenu && refSlctMenu.value) {
        console.log(fctName+': TODO: refSlctMenuStr='+JSON.stringify(refSlctMenu.value));
      } else {
        console.log(fctName+': TODO: refSlctMenu undefined');
      }
*/

    console.log(fctName+': DEBUG: pgName='+pgName+', flgTopMenu='+flgTopMenu+', flgAllItems='+flgAllItems);  // prov_debug
    // ==> pgName=myMenu01Products, flgTopMenu=true, flgAllItems=true
    // ==> pgName=myMenu01Products, flgTopMenu=true, flgAllItems=false
    // ==> pgName=myMenu02Discount, flgTopMenu=false, flgAllItems=undefined
    
    if (flgTopMenu) {
      // Catalog with topMenu/subMenu
      // console.log(fctName+': DEBUG: In: flgTopMenu=TRUE, refSlctMenuStr='+JSON.stringify(refSlctMenu.value+', refItemRecsLen='+refItemRecs.value.length));  // prov_debug
      // ==> refSlctMenuStr[{"topMenuCode":"mnu020","topMenuName":"常溫飲料","subMenuCode":"*","subMenuName":"","fctCallback":""}
      
      if (flgAllItems) {
        eleId = "mnu000";
      } else {
        eleId = refSlctMenu.value.topMenuCode;
      }
      console.log(fctName+': DEBUG: pgName='+pgName+', flgTopMenu='+flgTopMenu+', flgAllItems='+flgAllItems+', eleId='+eleId);  // prov_debug
      // ==> pgName=myMenu01Products, flgTopMenu=true, flgAllItems=false, eleId=mnu105

      
      let descrMenu = refDescrMenus.value.find(myEle => myEle.id == eleId);
      if (descrMenu) {
        console.log(fctName+': DEBUG in flgTopMenu descrMenu='+JSON.stringify(descrMenu));
        // ==> descrMenu={"id":"mnu010","img":"mnu010.jpg","descr":"..."}

        // refSlctMenu.value.img = "TODO1_descrMenu.img"; // descrMenu.img;
        // refSlctMenu.value.descr = "TODO1_descrMenu.descr"; // del_prov descrMenu.descr;
        refSlctMenu.value.img = eleId + '.jpg';
      }
    } else {
      // Catalog without topMenu/subMenu
      // console.log(fctName+': DEBUG: In: flgTopMenu=FALSE, refItemRecsLen='+refItemRecs.value.length);  // prov_debug
      // ==> 
      
      refSlctMenu.value.img = '';
      refSlctMenu.value.descr = '';
      refFlgPreferred.value = false;
      
      switch(pgName) {
        case "myMenu02Discount":      eleId = "titre02"; break;
        case "myMenu03NewProducts":   eleId = "titre03"; break;
        case "myMenu04Exclusive":     eleId = "titre04"; break;
        case "myMenu05BestSellers":   eleId = "titre05"; break;
        case "myMenu06Wholesale":     eleId = "titre06"; break;
        case "myMenu07Distribution":  eleId = "titre07"; break;
        default: 
          eleId = '';
          console.log(fctName+': ERROR: Unexpected pgName='+pgName);
          break;
      }
      if (eleId != '') {
        /*
        let descrMenu = refDescrMenus.value.find(myEle => myEle.id == eleId);
        if (descrMenu) {
          console.log(fctName+': DEBUG NOT flgTopMenu descrMenu='+JSON.stringify(descrMenu));
          // ==> descrMenu={"id":"titre02","img":"titre02.jpg","descr":"精選促銷商品，隨時享受超低折扣優惠，購物省錢更開心。"}

          // refSlctMenu.value.img = "TODO2_descrMenu.img";	// descrMenu.img;
          // refSlctMenu.value.descr = "TODO2_descrMenu.descr";	// descrMenu.descr;
        }
        */
        refSlctMenu.value.img = eleId + '.jpg';
      }
      
    }
    
    /*
    console.log(fctName+': DEBUG: refMenuRecsLen='+refMenuRecs.value.length +
                        ', refItemRecsLen='+refItemRecs.value.length +
                        ', topMenuCode='+refSlctMenu.value.topMenuCode +
                        ', refSearchCriteria='+refSearchCriteria.value
                        ); // prov_debug
    // ==> refMenuRecsLen=171, refItemRecsLen=171, topMenuCode=, refSearchCriteria=
    */
    
    console.log(fctName+': DEBUG: pgName='+pgName+', flgAllItems='+flgAllItems+', flgTopMenu='+flgTopMenu);
    // ==> pgName=myMenu02Discount, flgAllItems=undefined, flgTopMenu=false
    
    /**** Intercept the function (begin) ****/
    // Copy array from refItemRecs to refMenuRecs
    refMenuRecs.value.length = 0;
    refMenuRecs.value = [];
    refItemRecs.value.map(function(objElement, objIdx, objArray) {
        // objIdx = [0 ... newObj.length[
        // console.log(fctName+': DEBUG: objElementStr['+objIdx+'/'+objArray.length+']='+JSON.stringify(objElement));  // debug
        // ==> 
        if  (objElement.sGrpCode.indexOf('A') >= 0) {
          // Keep only active items: sGrpCode LIKE '%A%' (begin)
          objElement.iQty = 1;

        objElement.idItem = objElement.id;
        objElement.isPreferred = false;
        if (refPreferredRecs.value && refPreferredRecs.value.length > 0) {
          let preferredIdx = refPreferredRecs.value.findIndex(function(item){ 
            return item.idItem == objElement.idItem;
          });
          objElement.isPreferred = (preferredIdx >= 0)? true : false;
        }

          if (flgAllItems) {
            // All items
            if (refSearchCriteria.value != '') {
              if (objElement.sLabel.indexOf(refSearchCriteria.value) >= 0) {
                refMenuRecs.value.push(objElement); 
              } else if (objElement.sItmCode1.indexOf(refSearchCriteria.value) >= 0) {
                refMenuRecs.value.push(objElement);
              } else if (objElement.sItmCode2.indexOf(refSearchCriteria.value) >= 0) {
                refMenuRecs.value.push(objElement);
              }
            } else {
              refMenuRecs.value.push(objElement);
            }
          } else {
            // Items matching topMenu / subMenu (begin)
            if (flgTopMenu) {
              // Catalog with topMenu/subMenu (begin)
              if (isMenuItemValid(objElement)) {
                // Add the item to the catalog
                // console.log(fctName+': DEBUG: valid: objElementStr['+objIdx+'/'+objArray.length+']='+JSON.stringify(objElement));  // prov_debug
                // ==> 
                refMenuRecs.value.push(objElement);
              } else {
                // Discard the item
                // console.log(fctName+': DEBUG: invalid: objElementStr['+objIdx+'/'+objArray.length+']='+JSON.stringify(objElement));  // prov_debug
              }
              // Catalog with topMenu/subMenu (end)
            } else {
              // Catalog without topMenu/subMenu (begin)
              if (isCatalogItemValid(pgName, objElement)) {
                refMenuRecs.value.push(objElement);
              } else {
                // Discard the item
              }
              // Catalog without topMenu/subMenu (End)
            }
            // Items matching topMenu / subMenu (end)
          }
          // Keep only active items: sGrpCode LIKE '%A%' (begin)
        }
        
      });
    /**** Intercept the function (end) ****/
    
    console.log(fctName+': DEBUG: ret refMenuRecsLen='+refMenuRecs.value.length+', refItemRecsLen='+refItemRecs.value.length); // prov_debug
    
    return refMenuRecs;
  } // updateCatalogRecs

  /* ==================================================================================
   * ascItemGetIryRecs: Request REST API services for an array of records matching the list of sItmCode1
   * Usage:
        let reqParams = { 
          criteria:"sItmCode1 IN (" + lstItmCode + ")", 
          orderby:"sItmCode1 ASC"};
        ascItemGetIryRecs(reqParams, fctCallback);
   */
  async function ascItemGetIryRecs(reqParams, fctCallback) {
    const fctName = 'svcItemApi.ascItemGetIryRecs';
    const reqUrl = '/api/item/getRecs';
    
        // Load items from remote REST API (begin)
        refItemLoading.value = true;

        try {
          const { data } = await axios.post(
            reqUrl,
            {"orderby":"sItmCode1 ASC"}
          );

          // console.log(fctName+': DEBUG: retDataStr='+JSON.stringify(data));  // debug
          // ==> retDataStr={"errCode":1,"errMsg":"OK","rec":{},"recsNb":1,"recs":[{"id":1,"iStatus":1,"dtmCreate":"2020-01-01T23:00:00.000Z","dtmModif":null,"dtmValidMob":null,"dtmValidEmail":null,"sPassword":"adm123","sMobile":"123456789","sEmail":"jpn_email@yahoo.com","sGenderCode":"M","sFirstName":"Jack","sLastName":"SMITH","sAddr1":"1 bd Elysée","sAddr2":"1 FL","sZipCode":"99000","sCity":"NewCity","sProvince":"County","sMsgMode":"E","sCheckCode":null,"sLevel":"A","sNote":null}]}      
          
          refItemResp.value.errCode = data.errCode; 
          refItemResp.value.errMsg = data.errMsg;
          // updateCatalogRecs(reqParams.pgName, reqParams.flgTopMenu, reqParams.flgAllItems);  // =true:catalog with topMemu/subMenu, =false:other catalog filter, =true:all items
          
          if (fctCallback) {
            // fctCallback has been defined
            // console.log(fctName+': DEBUG: to fctCallback retDataStr='+JSON.stringify(data));  // prov_debug
            let resp = {resp:refItemResp.value, recs: data.recs};
            fctCallback(resp);
          } else {
            console.log(fctName+': DEBUG: no callback retDataStr='+JSON.stringify(data));  // prov_debug
          }
          
        } catch (ex) {
          if (ex.response) {
            // Request made and server responded
            console.log(fctName+': EXC: exStatus='+ex.response.status+', exData='+ex.response.data);
            // console.log('exrespdata:'+ex.response.data);  // 9002:DB Exception:2021-04-08 16:59:36 - mdlDbMysql.dbFind
            // console.log('exrespstatus:'+ex.response.status);  // 500
            // console.log('exrespheader:'+ex.response.headers);
          } else {
            console.log(fctName+': EXC: ex='+ex.stack);
          }
        } finally {
          refItemLoading.value = false;
        }
        // Load items from remote REST API (end)
    
  } // ascItemGetIryRecs


  /* ==================================================================================
   * ascItemGetRecs: Request REST API services for an array of records
   * reqParams { criteria: "sFlgMenu LIKE '%2%'", } :Input parameters
   */
  async function ascItemGetRecs(reqParams, fctCallback) {
    const fctName = 'svcItemApi.ascItemGetRecs';
    const reqUrl = '/api/item/getRecs';
    
    if (refItemRecs.value.length == 0) {
        // Load items from remote REST API (begin)
        console.log(fctName+': DEBUG: loading... refItemRecsLen='+refItemRecs.value.length+',retDataStr='+JSON.stringify(reqParams));  // debug
        // ==> refItemRecsLen=0,retDataStr={"criteria":"sFlgMenu LIKE '%2%'","orderby":"sItmCode1 ASC"}
        
        refItemLoading.value = true;
        /*
        let sCriteria = "sGrpCode LIKE '%A%'";
        try {
          const { data } = await axios.post(
            reqUrl,
            {"orderby":"sItmCode1 ASC", "criteria":sCriteria}
          );
          */
        try {
          const { data } = await axios.post(
            reqUrl,
            {"orderby":"sItmCode1 ASC"}
          );

          // console.log(fctName+': DEBUG: retDataStr='+JSON.stringify(data));  // debug
          // ==> retDataStr={"errCode":1,"errMsg":"OK","rec":{},"recsNb":1,"recs":[{"id":1,"iStatus":1,"dtmCreate":"2020-01-01T23:00:00.000Z","dtmModif":null,"dtmValidMob":null,"dtmValidEmail":null,"sPassword":"adm123","sMobile":"123456789","sEmail":"jpn_email@yahoo.com","sGenderCode":"M","sFirstName":"Jack","sLastName":"SMITH","sAddr1":"1 bd Elysée","sAddr2":"1 FL","sZipCode":"99000","sCity":"NewCity","sProvince":"County","sMsgMode":"E","sCheckCode":null,"sLevel":"A","sNote":null}]}      
          
          refItemResp.value.errCode = data.errCode; 
          refItemResp.value.errMsg = data.errMsg;
          switch(data.errCode) {
            case 1: // OK: rec found
              // Get data
              refItemRecs.value = data.recs;
              refItemRecsNb.value = refItemRecs.value.length;
              break;
            default:
              refItemRec.value = [{}];
              refItemRecsNb.value = 0;
              break;
          }
          // setRefMenuRecs(reqParams);
          updateCatalogRecs(reqParams.pgName, reqParams.flgTopMenu, reqParams.flgAllItems);  // =true:catalog with topMemu/subMenu, =false:other catalog filter, =true:all items
          
          if (fctCallback) {
            // fctCallback has been defined
            // console.log(fctName+': DEBUG: to fctCallback retDataStr='+JSON.stringify(data));  // prov_debug
            let resp = {resp:refItemResp.value, recs: refItemRecs.value};
            fctCallback(resp);
          } else {
            console.log(fctName+': DEBUG: no callback retDataStr='+JSON.stringify(data));  // prov_debug
          }
          
        } catch (ex) {
          if (ex.response) {
            // Request made and server responded
            console.log(fctName+': EXC: exStatus='+ex.response.status+', exData='+ex.response.data);
            // console.log('exrespdata:'+ex.response.data);  // 9002:DB Exception:2021-04-08 16:59:36 - mdlDbMysql.dbFind
            // console.log('exrespstatus:'+ex.response.status);  // 500
            // console.log('exrespheader:'+ex.response.headers);
          } else {
            console.log(fctName+': EXC: ex='+ex.stack);
          }
        } finally {
          refItemLoading.value = false;
        }
        // Load items from remote REST API (end)
    } else {
      // items already loaded
      console.log(fctName+': DEBUG: loaded refItemRecsLen='+refItemRecs.value.length+',retDataStr='+JSON.stringify(reqParams));  // debug
      // ==> refItemRecsLen=334,retDataStr={"criteria":"","orderby":"sItmCode1 ASC","pgName":"myMenu01Products","flgTopMenu":true,"flgAllItems":false}

      // setRefMenuRecs(reqParams);
      updateCatalogRecs(reqParams.pgName, reqParams.flgTopMenu, reqParams.flgAllItems);  // =true:catalog with topMemu/subMenu, =false:other catalog filter, =true:all items
      
      refItemResp.value.errCode = 1; 
      refItemResp.value.errMsg = "OK";
      if (fctCallback) {
        // fctCallback has been defined
        // console.log(fctName+': DEBUG: to fctCallback retDataStr='+JSON.stringify(data));  // debug
        let resp = {resp:refItemResp.value, recs: refItemRecs.value};
        fctCallback(resp);
      } else {
        // console.log(fctName+': DEBUG: no callback retDataStr='+JSON.stringify(data));  // debug
      }
    }
    

    
  } // ascItemGetRecs
  
  /* ==================================================================================
   * getMenuRecs: Request for an array of records
   * sFlgMenuFilter: "2" :Filter criteria
   * limitRecNb: nb max of records to return
   */
  function getMenuRecs(sFlgMenuFilter, limitRecNb) {
    const fctName = 'svcItemApi.getMenuRecs';
    
    let recs = [];
    if (refItemRecs.value.length > 0) {
      /*
      let sFlgMenuFilter = "";
      if (reqParams.criteria.indexOf('%2%') > 0) {
        // myMenu02Discount
        sFlgMenuFilter = "2";
      } else if (reqParams.criteria.indexOf('%3%') > 0) {
        // myMenu03NewProducts
        sFlgMenuFilter = "3";
      } else if (reqParams.criteria.indexOf('%4%') > 0) {
        // myMenu04Exclusive
        sFlgMenuFilter = "4";
      } else if (reqParams.criteria.indexOf('%5%') > 0) {
        // myMenu05BestSellers
        sFlgMenuFilter = "5";
      } else if (reqParams.criteria.indexOf('%6%') > 0) {
        // myMenu06Wholesale
        sFlgMenuFilter = "6";
      } else if (reqParams.criteria.indexOf('%7%') > 0) {
        // myMenu07Distribution
        sFlgMenuFilter = "7";
      }
      */
    
      refItemRecs.value.map(function(objElement, objIdx, objArray) {
        // objIdx = [0 ... newObj.length[
        // console.log(fctName+': DEBUG: objElementStr['+objIdx+'/'+objArray.length+']='+JSON.stringify(objElement));  // debug
        // ==> 
        
        if (recs.length < limitRecNb) {
          if (sFlgMenuFilter != "") {
            // Get items matching the sFlgMenuFilter (begin)
            if (objElement.sFlgMenu.indexOf(sFlgMenuFilter) >= 0) {
              // Add the item to the catalog
              // console.log(fctName+': DEBUG: valid: objElementStr['+objIdx+'/'+objArray.length+']='+JSON.stringify(objElement));  // prov_debug
              // ==> 
              recs.push(objElement);
            } else {
              // Discard the item
              // console.log(fctName+': DEBUG: invalid: objElementStr['+objIdx+'/'+objArray.length+']='+JSON.stringify(objElement));  // prov_debug
            }
            // Get items matching the sFlgMenuFilter (end)
          } else {
            // No filter
            recs.push(objElement);
          }
        }
        
      });
    }
    
    return recs;
  }
  
  
  /* ==================================================================================
   * ascItemFindRec: Request REST API services for the record having the specified id
   * reqParams { fct: myFct, rec: refRec.value, } :Input parameters
   * fctCallback :Callback function, if defined
   */
  async function ascItemFindRec(reqParams, fctCallback) {
    const fctName = 'svcItemApi.ascItemFindRec';
    const reqUrl = '/api/item/findRec';
    
    refItemLoading.value = true;
    try {
      // # const { data } is the name set by axios.post. Can not be renamed.
      const { data } = await axios.post(
        reqUrl,
        reqParams   // { fct: myFct, rec: refRec.value, }
      );
      
      // console.log(fctName+': DEBUG: dataStr='+JSON.stringify(data));  // prov_debug
      // ==> dataStr={"errCode":1,"errMsg":"OK","rec":{"id":1,"iStatus":1,"dtmCreate":"2020-01-01T23:00:00.000Z","dtmModif":null,"dtmValidMob":null,"dtmValidEmail":null,"sPassword":"adm123","sMobile":"123456789","sEmail":"jpn_email@yahoo.com","sGenderCode":"M","sFirstName":"Jack","sLastName":"SMITH","sAddr1":"1 bd Elysée","sAddr2":"1 FL","sZipCode":"99000","sCity":"NewCity","sProvince":"County","sMsgMode":"E","sCheckCode":null,"sLevel":"A","sNote":null},"recsNb":0,"recs":[{}]}
      
      // errCode = [1 ... 99] :OK message, [100 ... 999] :Error message, value = [1000 ... 999] :Process aborted
      refItemResp.value.errCode = data.errCode; 
      refItemResp.value.errMsg = data.errMsg;
      switch(data.errCode) {
        case 1: // OK: rec found
          // Get data
          refItemRec.value = data.rec;
          // You may alter data here
          // refItemRec.value.sPassword = '';   // Reset password. It must be entered again by the user
          // refItemRec.value.sPassword2 = '';
          break;
        case 2: // Record not found
          refItemRec.value = {};
          break;
        default: // Error occured at server sidebar
          refItemRec.value = {};
          break;
      }
      if (fctCallback) {
        // fctCallback has been defined
        // console.log(fctName+': DEBUG: to fctCallback');  // debug
        let resp = {resp:refItemResp.value, rec: refItemRec.value};
        fctCallback(resp);
      } else {
        console.log(fctName+': DEBUG: no callback');  // prov_debug
      }

    } catch (ex) {
      if (ex.response) {
        // Request made and server responded
        console.log(fctName+': EXC: exStatus='+ex.response.status+', exData='+ex.response.data);
        // console.log('exrespdata:'+ex.response.data);  // 9002:DB Exception:2021-04-08 16:59:36 - mdlDbMysql.dbFind
        // console.log('exrespstatus:'+ex.response.status);  // 500
        // console.log('exrespheader:'+ex.response.headers);
      } else {
        console.log(fctName+': EXC: ex='+ex.stack);
      }
    } finally {
      refItemLoading.value = false;
    }

  } // ascItemFindRec
  

  /* ==================================================================================
   * ascItemSaveRec: Send a record to save to REST API services
   */
   async function ascItemSaveRec(reqParams, fctCallback) {
    const fctName = 'svrItemApi.ascItemSaveRec';
    const reqUrl = '/api/item/saveRec';
    
    // console.log(fctName+': DEBUG: reqParamsStr='+JSON.stringify(reqParams));  // debug
    // ==> reqParamsStr={"id":"STA031"}

    refItemLoading.value = true;
    try {
      // # const { data } is the name set by axios.post. Can not be renamed.
      const { data } = await axios.post(
        reqUrl,
        reqParams
      );
      
      // console.log(fctName+': DEBUG: dataStr='+JSON.stringify(data));  // debug
      // ==> dataStr={"errCode":201,"errMsg":"Invalide function"}
      refItemResp.value.errCode = data.errCode; 
      refItemResp.value.errMsg = data.errMsg;
      
      if (fctCallback) {
        // fctCallback has been defined
        console.log(fctName+': DEBUG: callback: dataStr='+JSON.stringify(data));  // prov_debug
        let resp = {resp:refItemResp.value};
        fctCallback(resp);
      } else {
        console.log(fctName+': DEBUG: no callback retDataStr='+JSON.stringify(data));  // prov_debug
      }
      
      
    } catch (ex) {
      if (ex.response) {
        // Request made and server responded
        console.log(fctName+': EXC: exStatus='+ex.response.status+', exData='+ex.response.data);
        // console.log('exrespdata:'+ex.response.data);  // 9002:DB Exception:2021-04-08 16:59:36 - mdlDbMysql.dbFind
        // console.log('exrespstatus:'+ex.response.status);  // 500
        // console.log('exrespheader:'+ex.response.headers);
      } else {
        console.log(fctName+': EXC: ex='+ex.stack);
      }
    } finally {
      refItemLoading.value = false;
    }
  } // ascItemSaveRec
  
  /* ==================================================================================
   * ascItemDeleteRec: Send request to delete a record to REST API services
   */
   async function ascItemDeleteRec(reqParams, fctCallback) {
    const fctName = 'svrItemApi.ascItemDeleteRec';
    const reqUrl = '/api/item/deleteRec';
    
    // console.log(fctName+': DEBUG: reqParamsStr='+JSON.stringify(reqParams));  // debug
    // ==> reqParamsStr={"id":"STA031"}

    refItemLoading.value = true;
    try {
      // # const { data } is the name set by axios.post. Can not be renamed.
      const { data } = await axios.post(
        reqUrl,
        reqParams
      );
      
      // console.log(fctName+': DEBUG: dataStr='+JSON.stringify(data));  // debug
      // ==> dataStr={"errCode":201,"errMsg":"Invalide function"}
      refItemResp.value.errCode = data.errCode; 
      refItemResp.value.errMsg = data.errMsg;
      
      if (fctCallback) {
        // fctCallback has been defined
        console.log(fctName+': DEBUG: callback: dataStr='+JSON.stringify(data));  // prov_debug
        let resp = {resp:refItemResp.value};
        fctCallback(resp);
      } else {
        console.log(fctName+': DEBUG: no callback retDataStr='+JSON.stringify(data));  // prov_debug
      }
      
      
    } catch (ex) {
      if (ex.response) {
        // Request made and server responded
        console.log(fctName+': EXC: exStatus='+ex.response.status+', exData='+ex.response.data);
        // console.log('exrespdata:'+ex.response.data);  // 9002:DB Exception:2021-04-08 16:59:36 - mdlDbMysql.dbFind
        // console.log('exrespstatus:'+ex.response.status);  // 500
        // console.log('exrespheader:'+ex.response.headers);
      } else {
        console.log(fctName+': EXC: ex='+ex.stack);
      }
    } finally {
      refItemLoading.value = false;
    }
  } // ascItemDeleteRec

  /* ==================================================================================
   * fctRfshItem: Update data of the item
   */
  function fctRfshItem(itmRec, srvRec) {
    const fctName = 'svrItemApi.fctRfshItem';
    let index1;
    let index2 = -1;

    index1 = refItemRecs.value.findIndex(function(item){ return item.sItmCode1 == srvRec.sItmCode1});
    if (itmRec) index2 = refMenuRecs.value.findIndex(function(item){ return item.sItmCode1 == srvRec.sItmCode1});

    if (refItemRecs.value) {
      console.log(fctName+' DEBUG: refItemRecsLen='+refItemRecs.value.length+', index1='+index1+', index2='+index2);
    } else {
      console.log(fctName+' DEBUG: refItemRecs undefined');
    }

    if (index1 >= 0) {
        // "srvRec":{"id":144,"sLabel":"xxx","iStatus":null,"dtmCreate":"2021-04-15 11:51:53","dtmModif":"2023-02-27 15:18:28","dtmDiscBegin":null,"dtmDiscEnd":null,"sDiscCode":"","sItmCode1":"4710088432513","sItmCode2":"30060","sSupCode":"3","sCatCode":"dep001","sSizeCode":"1858ml","sTypeCode":"uni003","sType1Code":"mnu060","sType2Code":"snu0690","sDescr":"xxx","sImg":"","iPrice":58,"iPrice1":58,"iPrice2":null,"iQtyMax":null,"iQtyMin":10,"iQty":null,"sGrpCode":"_PA","sFlgReturn":"","sFlgActif":"","sFlgMenu":"_","iInvQty":7,"iFreeItmId":null,"sFreeItmCode1":null,"sFreeItmLabel":null,"iFreeQty":null,"sFreeSizeCode":null,"sFreeItmSize":null,"sFreeTypeCode":null,"sFreeItmType":null,"iIsPromo":0,"iPromoQty":null,"iQtyCnt":null,"iPrice4":null,"iFlgFzn":1,"iFlgFreeFzn":0,"sFreeItmTitle":null,"sPassword":"","sPassword2":""}
        // refItemRecs.value[index1].iPrice = (srvRec.iPrice)? 100 + srvRec.iPrice : null;
        // refItemRecs.value[index1].iPrice1 = (srvRec.iPrice1)? 200 + srvRec.iPrice1: null;
        // refItemRecs.value[index1].iPrice2 = (srvRec.iPrice2)? 300 + srvRec.iPrice2: null;

        refItemRecs.value[index1].iPrice = (srvRec.iPrice)? srvRec.iPrice : null;
        refItemRecs.value[index1].iPrice1 = (srvRec.iPrice1)? srvRec.iPrice1: null;
        refItemRecs.value[index1].iPrice2 = (srvRec.iPrice2)? srvRec.iPrice2: null;

        refItemRecs.value[index1].sGrpCode = (srvRec.sGrpCode)? srvRec.sGrpCode : null;
        refItemRecs.value[index1].sFlgActif = (srvRec.sFlgActif)? srvRec.sFlgActif : null;
        refItemRecs.value[index1].iInvQty = (srvRec.iInvQty)? srvRec.iInvQty : null;
        refItemRecs.value[index1].iFreeItmId = (srvRec.iFreeItmId)? srvRec.iFreeItmId : null;
        refItemRecs.value[index1].sFreeItmCode1 = (srvRec.sFreeItmCode1)? srvRec.sFreeItmCode1 : null;
        refItemRecs.value[index1].sFreeItmLabel = (srvRec.sFreeItmLabel)? srvRec.sFreeItmLabel : null;
        refItemRecs.value[index1].iFreeQty = (srvRec.iFreeQty)? srvRec.iFreeQty : null;
        refItemRecs.value[index1].sFreeSizeCode = (srvRec.sFreeSizeCode)? srvRec.sFreeSizeCode : null;
        refItemRecs.value[index1].sFreeItmSize = (srvRec.sFreeItmSize)? srvRec.sFreeItmSize : null;
        refItemRecs.value[index1].sFreeTypeCode = (srvRec.sFreeTypeCode)? srvRec.sFreeTypeCode : null;
        refItemRecs.value[index1].sFreeItmType = (srvRec.sFreeItmType)? srvRec.sFreeItmType : null;
        refItemRecs.value[index1].iIsPromo = (srvRec.iIsPromo)? srvRec.iIsPromo : null;
        refItemRecs.value[index1].iPromoQty = (srvRec.iPromoQty)? srvRec.iPromoQty : null;
        refItemRecs.value[index1].iQtyCnt = (srvRec.iQtyCnt)? srvRec.iQtyCnt : null;
        refItemRecs.value[index1].iPrice4 = (srvRec.iPrice4)? srvRec.iPrice4 : null;
        refItemRecs.value[index1].iFlgFzn = (srvRec.iFlgFzn)? srvRec.iFlgFzn : null;
        refItemRecs.value[index1].iFlgFreeFzn = (srvRec.iFlgFreeFzn)? srvRec.iFlgFreeFzn : null;
        refItemRecs.value[index1].sFreeItmTitle = (srvRec.sFreeItmTitle)? srvRec.sFreeItmTitle : null;

        // Once refItemRecs.value[index1] ==> refMenuRecs.value[index2] is automatically updated
        if (index2 >= 0) return refMenuRecs.value[index2]; // Once refItemRecs.value[index1] ==> refMenuRecs.value[index2] is automatically updated
        return;
    }
    // console.log(fctName+': DEBUG: index2='+index2+',srvRecStr='+JSON.stringify(srvRec));  // prov_debug
    
    return itmRec;
  } // fctRfshItem


  return  {
      refItemResp: computed(() => refItemResp.value),
      refItemLoading: computed(() => refItemLoading.value),
      refItemRecs: computed(() => refItemRecs.value),
      refItemRecsNb: computed(() => refItemRecsNb.value),
      refItemRec,
      refMenuLoading,
      refMenuRecs,	// Array of records to display in menu/submenu page
      refFlgPreferred,	 // Flag =1:list of preferred items; else:liste of items
      setSearchCriteria,
      updateCatalogRecs,
      ascItemGetIryRecs,		// ascItemGetIryRecs: Request REST API services for an array of records matching the list of sItmCode1
      ascItemGetRecs,
      getMenuRecs,
      ascItemFindRec,
      ascItemSaveRec,
      ascItemDeleteRec,  // ascItemDeleteRec: Send request to delete a record to REST API services
      fctRfshItem, // fctRfshItem: Update data of the item
  }
}
