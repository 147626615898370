<template>
    <span v-if="refCartItemRecs.length == 0"><br><br>Empty cart<br><br></span>
    <span v-else>

  <div>
    <!-- Steps (begin) -->
    <center>
      <br>
      
        <div class="stepContainer">
          <div class="stepLink">
            <div class="stepItem">
              <a id="btnStepA" class="btnStepOn" title="stepCartInfo" style="cursor:pointer" v-on:click="openPage('pgCart1');">{{ refDico.stepCart1 }}</a>
            </div>
            <div class="stepItem">
              <a id="btnStepB" class="btnStepOn" title="stepCartDeliveryInfo"  style="cursor:pointer" v-on:click="openPage('pgCart2');">{{ refDico.stepCart2 }}</a>
            </div>
            <div class="stepItem">
              <a id="btnStepC" class="btnStepOn" title="stepCartPaymentInfo"  style="pointer-events: none">{{ refDico.stepCart3 }}</a>
            </div>
            <div class="stepItem">
              <a id="btnStepD" class="btnStepOff" title="stepCartValidationInfo"  style="pointer-events: none">{{ refDico.stepCart4 }}</a>
            </div>
            <div class="stepItem">
              <a id="btnStepE" class="btnStepOff" title="stepCartSummaryInfo"  style="pointer-events: none">{{ refDico.stepCart5 }}</a>
            </div>
          </div>
        </div>
        <br>
    </center>
    <!-- Steps (end) -->
  </div>
  
  <div style="width:100%; text-align:center;">
  

  <center>
    <div class="myCartDiv2" style="display: inline-block;">
    <!-- Content (begin) -->
      <table style="text-align:left; max-width: 500px;" border="0">
        <tr>
          <td align="center">
            <b>{{ refDico.tleCart3 }}</b><br>&nbsp;
          </td>
        </tr>
        <tr>
          <td>
            <!-- ------------- Select Payment ---------------------- -->
      <table style="text-align:left; width:100%; " border="0">
        <thead>
          <b>{{ refDico.lblpaymentmethod }}</b>
        </thead>
        <tbody>

          <tr><td>
            <input type="radio" name="payment" id="payment2" value="2" v-model="refCartRec.paymentMode">&nbsp;{{ refDico.lblpayment2 }} 
          </td></tr>
          <tr><td>
            <input type="radio" name="payment" id="payment3" value="3" v-model="refCartRec.paymentMode"  style="vertical-align:top;">&nbsp;<img src="/img/visa.jpg">
          </td></tr>
          <tr><td>
            <input type="radio" name="payment" id="payment4" value="4" v-model="refCartRec.paymentMode" style="vertical-align:top;">&nbsp;<img src="/img/taiwanpay.jpg">
          </td></tr>
        </tbody>
      </table>
          </td>
        </tr>
        <tr>
          <td>
            <!-- ------------- Details of the Payment ---------------------- -->
            <br>
      <table style="text-align:left; width:100%; " border="0">
        <thead>
          <b>{{ refDico.lblInvoiceInformation }}</b>
        </thead>
        <tbody>
          <tr><td><input type="radio" name="invoice" id="invoice1" value="1" v-model="refCartRec.invoiceMode" v-on:click="selectInvoice('1')" />&nbsp;{{ refDico.lblinvoice1 }}</td></tr>
          <tr>
            <td nowrap>
              <input type="radio" name="invoice" id="invoice2" value="2" v-model="refCartRec.invoiceMode" v-on:click="selectInvoice('2')" />&nbsp;{{ refDico.lblinvoice2 }} 
              <span style="display:none;" id="invoicedata2">
                <br>&nbsp;&nbsp;&nbsp;&nbsp;
                {{ refDico.lbltitleinvoice2 }}&nbsp;
                <input type="text" id="sInvoice2" v-model="refCartRec.sPInvoice2" style="width:120px;" /> 
              </span>
            </td>
          </tr>
          <tr>
            <td nowrap>
              <input type="radio" name="invoice" id="invoice3" value="3" v-model="refCartRec.invoiceMode" v-on:click="selectInvoice('3')" />&nbsp;{{ refDico.lblinvoice3 }} 
             <span style="display:none;" id="invoicedata3">
               <br>&nbsp;&nbsp;&nbsp;&nbsp;
                {{ refDico.lbltitleinvoice3 }}
                 &nbsp; <input type="text" id="sComCode" v-model="refCartRec.sComCode"  style="width:120px;" />
              </span>
            </td>
            </tr>
          <tr>
            <td nowrap>
              <input type="radio" name="invoice" id="invoice4" value="4" v-model="refCartRec.invoiceMode" v-on:click="selectInvoice('4')" />&nbsp;{{ refDico.lblinvoice4 }} 
              <span style="display:none;" id="invoicedata4">
                <br>&nbsp;&nbsp;&nbsp;&nbsp;
                {{ refDico.lbltitleinvoice4 }}&nbsp;
                <select v-model="refCartRec.sAssociation" style="max-width:200px;" >
                  <option value="1">{{ refDico.lblAssociate1 }}</option>
                  <option value="2">{{ refDico.lblAssociate2 }}</option>
                  <option value="3">{{ refDico.lblAssociate3 }}</option>
                  <option value="4">{{ refDico.lblAssociate4 }}</option>
                  <option value="5">{{ refDico.lblAssociate5 }}</option>
                </select>
              </span>
            </td>
          </tr>
        </tbody>
      </table>

          </td>
        </tr>
      </table>
      
      <!-- Content (end) -->
      <br>&nbsp;
    </div>
    
    
    <div style="display:inline-block; vertical-align:top;">
      <cpnCart />
    </div>
    <br>
    <br>
    <a class="btnStd01" v-on:click="openPage('pgCart4');" >{{ refDico.lblValidate }}</a>
    <br>
    <br>
    <br>&nbsp;
    </center>
  </div>
  
  </span>
</template>

<script>
  import { ref, computed, onBeforeMount, onMounted, onUpdated } from 'vue';
  import router from '@/router/index.js';
  import { useRoute } from 'vue-router';
  import useReferential from '@/modules/mdlReferential.js'; 
  import useClientApi from '@/services/svcClientApi.js';
  import useCartApi from '@/services/svcCartApi.js';
  import cpnCart from '@/components/cpnCart';

  export default {
    components: {
      cpnCart,
    },
    setup() {
      const route = useRoute();
      const { refDicoLang, refDicoAttr, refPageAttr, refItmTypeAttr, ascSetLang, refSlctMenu, fctExtractMenu, fctExtractSubmenu } = useReferential();
      let { ascClientFindRec, ascClientSaveRec, ascClientDeleteRec, refClientResp, refClientLoading, refClientRec } = useClientApi();
      let { ascCartGetRecs, ascCartGetItemRecs, ascCartFindRec, ascCartSaveRec, ascCartDeleteRec, refCartResp, refCartLoading, refCartRec, refCartItemRecs, fctCartDelItem } = useCartApi();
      
      let refDico = ref({});
      let refResp = ref({});
      let refRec = ref({});
      
      onBeforeMount(() => {
      
        console.log('vwsCart3.onBeforeMount: DEBUG: refClientRecId='+refClientRec.value.id);  // prov_debug
        if (refClientRec.value.id) {
          refPageAttr.value.pgAction = 'init';
          refPageAttr.value.pgName = 'pgCart3';
        } else {
          // Client need to login before adding preferred item
          openPage('pgClientLogin');
        }
      });
      
      onMounted(async () => {
        window.scrollTo(0, 0);
        refDico.value = refDicoAttr.value;
        refPageAttr.value.title = refDico.value.tleCart;
        
        console.log('vwsCart3.onMounted: sAssociation='+refCartRec.value.sAssociation);
        
        let reqParams = { 
              criteria: "idParent = "+refClientRec.value.id, 
              orderby: "id ASC", // "sItmCode1 ASC",
              };
        // KO ascCartGetItemRecs(reqParams);  // KO // Without callback, the record can not be altered at loading
        ascCartGetItemRecs(reqParams, reqCallback);  // Callback is required if altering the record is required at loading
      });
      
      /**** Without callback (begin) ****
      // Without callback, the record can not be altered at loading
      refResp = computed(() => {
        console.log('vwsCart3.computed: DEBUG: refResp=refItemRespStr='+JSON.stringify(refItemResp.value));  // prov_debug
        return refItemResp.value;
      });
      **** Without callback (end) ****/
      
      /**** With callback (begin) ****/
      // Callback is required if altering the record is required at loading
      function reqCallback(myResp) {
        console.log('vwsCart3.reqCallback: DEBUG: respStr='+JSON.stringify(myResp.resp));  // prov_debug
        // ==> respStr={"resp":{"errCode":1,"errMsg":"OK"},"recs":[{"id":154,"sLabel":"統一布丁（大）","iStatus":null,"dtmCreate":"2021-04-15 12:00:38","dtmModif":"2021-04-15  ...
        
        // updateCatalogRecs(refPageAttr.value.pgName, false);  // =true:catalog with topMemu/subMenu, =false:other catalog filter
        
        refCartRec.value.paymentMode = "2";	// 1:Payment at the store, 2:Cash on delivery, 3:Visa card, 4:TaiwanPay
        refCartRec.value.invoiceMode = "1";
        refCartRec.value.sPInvoice2 = refClientRec.value.sInvoice2;
        
        
      }
      /**** With callback (end) ****/
      
      onUpdated( () => {
        console.log('vwsCart3.updated: paymentMode='+refCartRec.value.paymentMode);
        console.log('vwsCart3.updated: invoiceMode='+refCartRec.value.invoiceMode);
      }); 
      
      function openPage(pgName) {
        console.log('vwsCart3.openPage: pgName='+pgName);
        
        console.log('vwsCart3.openPage: sAssociation='+refCartRec.value.sAssociation);
        
        // this.$router.push({name:pgName,params:{pgAction:'init',prmId:''}});
        router.push({name:pgName,params:{pgAction:'init',prmId:''}});
      }
      
      
      function selectInvoice(invoicevalue) {
        console.log('vwsCart3.delCartItm: DEBUG: invoicevalue='+invoicevalue);  // prov_debug
        switch(invoicevalue) {
          case '2': 
            document.getElementById("invoicedata2").style.display=''; 
            document.getElementById("invoicedata3").style.display='none';
            document.getElementById("invoicedata4").style.display='none';
            break;
          case '3': 
            document.getElementById("invoicedata2").style.display='none'; 
            document.getElementById("invoicedata3").style.display=''; 
            document.getElementById("invoicedata4").style.display='none';
            break;
          case '4': 
            document.getElementById("invoicedata2").style.display='none';
            document.getElementById("invoicedata3").style.display='none'; 
            document.getElementById("invoicedata4").style.display='';
            break;
          default: 
            document.getElementById("invoicedata2").style.display='none';
            document.getElementById("invoicedata3").style.display='none'; 
            document.getElementById("invoicedata4").style.display='none';
            break;
        }
        
        
      }
      
      return {
        refDico,
        refPageAttr,
        refItmTypeAttr,
        refCartResp, 
        refCartLoading, 
        refCartRec,
        refCartItemRecs,
        openPage,
        selectInvoice,
      }
    },

  };

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>