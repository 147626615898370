<template>

  <div >
    
    <center>
    <br>
    
    <span v-if="refOrdersLoading"><br><br>Loading ...<br><br></span>
    <span v-else>

        <center>

      <form novalidate @submit.prevent="onSubmit" >
        <table border="0">
          
          <tr>
            <td>
              <!-- order (begin)-->
              <table border="0" width="100%">
                <tr>
                  <td nowrap>{{ refDico.lblDtOrder }} : </td>
                  <td nowrap>{{ refRec.dtmCreate }}</td>
                  <td width="99%">&nbsp;</td>
                  <td nowrap>{{ refDico.lblpaymentmethod }} : </td>
                  <td nowrap>{{ refRec.paymentMode }} - {{ refPaymMethods[ refRec.paymentMode ] }}</td>
                </tr>
                <tr>
                  <td nowrap>{{ refDico.lblOrdId }} : </td>
                  <td nowrap>{{ refRec.sOrdCode }}</td>
                  <td>&nbsp;</td>
                  <td nowrap>{{ refDico.lblInvCode }} : </td>
                  <td nowrap>{{ refRec.sEtkInvCode }}</td>
                </tr>
                <tr>
                  <td nowrap>{{ refDico.lbkDtReturn1 }} : </td>
                  <td nowrap>{{ refRec.dtmReturn1 }}</td>
                  <td>&nbsp;</td>
                  <td nowrap>{{ refDico.lbkDtReturn2 }} : </td>
                  <td nowrap>{{ refRec.dtmReturn2 }}</td>
                </tr>
                <tr>
                  <td nowrap>{{ refDico.tleCart2 }} : </td>
                  <td colspan="4">{{ refRec.sDelAddr1 }}&nbsp;</td>
                </tr>

              </table>
              <!-- order (end)-->
            </td>
          </tr>
          
          <tr>
            <td>
              <!-- items (begin) -->
              <table  class="cpnLst01" width="100%">
                <thead>
                <tr>
                  <th colspan="6">{{ refDico.tleShopOrder }}</th>
                  <th colspan="2">{{ refDico.tleShopReturn }}</th>
                </tr>
                <tr>
                  <th>&nbsp;</th>
                  <th>{{ refDico.lblItmCode1 }}</th>
                  <th>{{ refDico.lblItmLabel }}</th>
                  <th>{{ refDico.lblItmQty }}</th>
                  <th>{{ refDico.lblItmPrice }}</th>
                  <th>{{ refDico.lblItmTotal }}</th>
                  <th>{{ refDico.lblItmQty }}</th>
                  <th>{{ refDico.lblItmTotal }}</th>
                </tr>
                </thead>

                <tbody>
                  <tr v-for="(tmpRec, tmpRecIdx) in refItmRecs" :key="tmpRec.id" >
                    <td align="center">{{ tmpRecIdx+1 }}</td>
                    <td>{{ tmpRec.sItmCode1 }}</td>
                    <td>{{ tmpRec.sLabel }}&nbsp;</td>
                    <td align="right">&nbsp;{{ tmpRec.iQtyOrd }}</td>
                    <td align="right">&nbsp;{{ tmpRec.iPrice }}</td>
                    <td align="right">&nbsp;{{ tmpRec.iTotalOrd }}</td>
                    <td align="right">
                      <span v-if="refRec.dtmDelivery" >
                        <input type="text" style="width:40px;" v-model=" tmpRec.iQtyRet" v-on:change="updateQtyRet(tmpRecIdx, tmpRec.id, tmpRec.iQtyOrd, tmpRec.iQtyRet, tmpRec.iPrice);" />
                       </span>
                       <span v-else>
                         &nbsp;{{ tmpRec.iQtyRet }}
                       </span>
                    </td>
                    <td align="right">&nbsp;{{ tmpRec.iTotalRet }}</td>
                  </tr>

                  <tr style="background-color: #E0E0E0;">
                    <td colspan="5" align="right">{{ refDico.lblOrdAmount }}</td>
                    <td align="right">&nbsp;{{ refRec.iOrdSubTot }}</td>
                    <td align="right" nowrap>{{ refDico.lblOrdAmount }}</td>
                    <td align="right">&nbsp;{{ refRec.iRetSubTot }}</td>
                  </tr>
                  <tr style="background-color: #E0E0E0;">
                    <td colspan="5" align="right">{{ refDico.lblOrdDelivery }}</td>
                    <td align="right">&nbsp;{{ refRec.iOrdCost }}</td>
                    <td align="right" nowrap>{{ refDico.lblOrdDelivery }}</td>
                    <td align="right">&nbsp;{{ refRec.iRetCost }}</td>
                  </tr>
                  <tr style="background-color: #E0E0E0;">
                    <td colspan="5" align="right">{{ refDico.lblOrdTotal }}</td>
                    <td align="right">&nbsp;{{ refRec.iOrdTotal }}</td>
                    <td align="right" nowrap>{{ refDico.lblOrdTotal }}</td>
                    <td align="right">&nbsp;{{ refRec.iRetTotal }}</td>
                  </tr>

                  <tr>
                    <td>{{ refDico.lblOrdRetType }}</td>
                    <td colspan="7">
                      <span v-if="refRec.iRetTotal > 0" >
                    <select v-model="refRec.returnType" :key="refOrdRetTypes" style="width:100%;" >
                      <option v-for="(name, value) in refOrdRetTypes" :key="value" v-bind:value="value">{{ name }}</option>
                    </select>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="8" align="center">
                      <br>&nbsp;
                      <span v-if="refRec.iRetTotal > 0 && refRec.dtmReturn == null" >
                      <a class="btnStd01" v-on:click="submitOrdRet();" >{{ refDico.lblSubmitRet }}</a>
                      </span>
                    </td>
                  </tr>

                </tbody>
              </table>

              <!-- items (end) -->
            </td>
          </tr>
          <!-- items (end) -->
          
        </table>

      </form>

      <br>&nbsp;
        </center>
    
      <br>&nbsp;

    </span>
    
    </center>

  </div>
  
</template>

<script>
  import { ref, computed, onBeforeMount, onMounted } from 'vue';
  import router from '@/router/index.js';
  import { useRoute } from 'vue-router';
  import useTools from '@/utils/vjsTools.js';
  import useReferential from '@/modules/mdlReferential.js'; 
  import useClientApi from '@/services/svcClientApi.js';
  import useOrdersApi from '@/services/svcOrdersApi.js';

  export default {
    /* methods: {
      popAlert: function (message) {
        alert(message);
      }
    }, */
    setup() {
      const route = useRoute();
      const { refDicoLang, refDicoAttr, refPageAttr, refItmTypeAttr, ascSetLang, refSlctMenu, fctExtractMenu, fctExtractSubmenu } = useReferential();
      let { tlsGetDateStr } = useTools();
      let { ascClientFindRec, ascClientSaveRec, ascClientDeleteRec, refClientResp, refClientLoading, refClientRec } = useClientApi();
      let { ascOrdersGetRecs, ascOrdersGetItemRecs, ascOrdersFindRec, ascOrdersSaveRec, ascOrdersDeleteRec, refOrdersResp, refOrdersLoading, refOrdersRecs, refOrdersRec } = useOrdersApi();
      
      let refDico = ref({});
      let refResp = ref({});
      let refRec = ref({});
      let refItmRecs = ref([]);
      let refItmFznRecs = ref([]);
      let refFreeItmRecs = ref([]);
      let refFreeItmFznRecs = ref([]);
      let refOrdStatus = ref([]);
      let refPaymMethods = ref([]);	// paymentMode=[1..4] :Payment methods
      // let refDelMethods = ref([]);		// deliveryMode=[] :Type of delivery
      let refOrdRetTypes = ref([]);		// returnMode :Raison of return
      
      onBeforeMount(() => {
        // this.$router.push({name:'pgItemFrm',params:{pgAction:'init',itmLstFilter:'myItmLstFilter'}});
        
        console.log('vwsReturnFrm.onBeforeMount: DEBUG: refClientRecId='+refClientRec.value.id);  // prov_debug
        if (refClientRec.value.id) {
          refPageAttr.value.pgAction = 'init';
          refPageAttr.value.pgName = 'pgOrders';
        } else {
          // Client need to login before displaying orders
          openPage('pgClientLogin');
        }
      });
      
      onMounted(async () => {
        refDico.value = refDicoAttr.value;
        refPageAttr.value.title = refDico.value.tleCliOrders;
        
        refOrdStatus.value.length = 0;
        refOrdStatus.value.push(refDico.value.lblOrdStatus0);
        refOrdStatus.value.push(refDico.value.lblOrdStatus1);
        refOrdStatus.value.push(refDico.value.lblOrdStatus2);
        refOrdStatus.value.push(refDico.value.lblOrdStatus3);
        refOrdStatus.value.push(refDico.value.lblOrdStatus4);
        refOrdStatus.value.push(refDico.value.lblOrdStatus5);
        refOrdStatus.value.push(refDico.value.lblOrdStatus6);
        refOrdStatus.value.push(refDico.value.lblOrdStatus7);
        refOrdStatus.value.push(refDico.value.lblOrdStatus8);
        refOrdStatus.value.push(refDico.value.lblOrdStatus9);
        
        refPaymMethods.value.length = 0;
        refPaymMethods.value.push(" ");	// idx 0
        refPaymMethods.value.push(refDico.value.lblpayment1);	// idx 1: Pay at the store
        refPaymMethods.value.push(refDico.value.lblpayment2);	// idx 2: Cash on delivery
        refPaymMethods.value.push(refDico.value.lblpayment3);	// idx 3: Credit card 
        refPaymMethods.value.push(refDico.value.lblpayment4);	// idx 4: Taiwan Pay
        
        refOrdRetTypes.value = {
          '0': ' ',
          '1': refDico.value.lblOrdRetType1,
          '2': refDico.value.lblOrdRetType2,
          '3': refDico.value.lblOrdRetType3,
          '4': refDico.value.lblOrdRetType4,
        };
        
        console.log('vwsReturnFrm.onMounted: DEBUG: ordId='+route.params.prmId);  // prov_debug
        let reqParams = { 
              id: route.params.prmId,
        };
        ascOrdersFindRec(reqParams, reqCallback);  // Callback is required if altering the record is required at loading
      });
      
      /**** Without callback (begin) ****
      // Without callback, the record can not be altered at loading
      refResp = computed(() => {
        console.log('vwsReturnFrm.computed: DEBUG: refResp=refItemRespStr='+JSON.stringify(refItemResp.value));  // prov_debug
        return refItemResp.value;
      });
      **** Without callback (end) ****/
      
      /**** With callback (begin) ****/
      // Callback is required if altering the record is required at loading
      function reqCallback(myResp) {
        
        refResp.value = myResp.resp;
        refRec.value = refOrdersRec.value;
        // console.log('vwsReturnFrm.reqCallback: DEBUG: refRecStr='+JSON.stringify(refRec.value));  // prov_debug
        // ==> efRecStr={"id":111,"idClient":1,"sLabel":null,"iStatus":null,"dtmCreate":"2022-07-12 14:32:38","dtmModif":null,"sOrdCode":"220712143238101","iOrdSubTotal":18,"iOrdCost":0,"iOrdTaxRate":0,"iOrdTax":0,"iOrdTotal":18,"iItmNb":1,"sDelProvince":"\"dep010\"","sDelCity":"\"cty0110\"","sDelZipCode":"\"99000\"","sDelAddr1":"\"1 bd Elysée\"","sDelAddr2":null,"sDelMobile":"\"cli123456789\"","sDelGenderCode":"\"M\"","sDelFirstName":"\"Cli JackBis\"","sDelLastName":null,"deliveryMode":"\"b\"","sAssociation":"\"1\"","paymentMode":"\"4\"","invoiceMode":"\"1\"","dtmDelivery":null,"sDelCode":null,"dtmEtkInv":null,"sEtkInvCode":null,"dtmReturn":null,"dtmEtkCancel":null,"dtmEtkInv2":null,"sEtkInvCode2":null,"dtmCancel":null}

        console.log('vwsReturnFrm.reqCallback: DEBUG: respStr='+JSON.stringify(myResp.resp));  // prov_debug
        // ==> respStr={"errCode":1,"errMsg":"OK"}
        console.log('vwsReturnFrm.reqCallback: DEBUG: errCode='+refResp.value.errCode+', ordId='+refRec.value.id);  // prov_debug
        // ==> errCode=1, ordId=110

          if (refResp.value.errCode == 1) {
            // Record loaded correctely. Reset error message
            refResp.value.errCode = 0;
            refResp.value.errMsg = '';
          
            let myParams = {
              id: refRec.value.id,
            };
            ascOrdersGetItemRecs(myParams, reqCallbackItems);
        }

      }
      /**** With callback (end) ****/
      
      function reqCallbackItems(myResp) {
        // Set data of the record
        refResp.value = myResp.resp;
        // refItmRecs.value = myResp.recs;
        
        console.log('vwsReturnFrm.reqCallbackItems: DEBUG: myRespStr='+JSON.stringify(myResp));  // prov_debug
        // ==> myRespStr={"resp":{"errCode":1,"errMsg":"OK"},"recs":[{"id":115,"idParent":110,"idItem":149,"sLabel":null,"iStatus":null,"dtmCreate":"2022-07-12 14:28:58","dtmModif":null,"sItmCode1":"4710088435767","iQtyOrd":1,"iQtyDel":null,"iQtyRet":null,"iPrice":18,"iTotalOrd":18,"iTotalRet":null}]}
        
        switch(refResp.value.errCode) {
          case 1: // OK
            refItmRecs.value.length = 0;
            refItmFznRecs.value.length = 0;
            refFreeItmRecs.value.length = 0;
            refFreeItmFznRecs.value.length = 0;
            // refItmRecs.value = myResp.recs;
            myResp.recs.map(function(objElement, objIdx, objArray) {
              console.log('vwsReturnFrm.reqCallbackItems: DEBUG: objElementStr='+JSON.stringify(objElement));  // prov_debug

              if (objElement.iQtyOrd == 0) {
                // Free items (begin)
                if (objElement.iFlgFzn == 1) {
                  refFreeItmFznRecs.value.push(objElement);
                } else {
                  refFreeItmRecs.value.push(objElement);
                }
                // Free items (end)
              } else {
                // Ordered items (begin)
                if (objElement.iFlgFzn == 1) {
                  refItmFznRecs.value.push(objElement);
                } else {
                  refItmRecs.value.push(objElement);
                }
                // Ordered items (end)
              }

            });
            break;
          default:  // Error occurred
            refItmRecs.value.length = 0;
            refItmFznRecs.value.length = 0;
            refFreeItmRecs.value.length = 0;
            refFreeItmFznRecs.value.length = 0;
            break;
        }
      }	// reqCallbackItems
      
      function updateQtyRet(recIdx, itmId, itmQtyOrd, itmQtyRet, itmPrice) {
        console.log('vwsReturnFrm.updateQtyRet: DEBUG: recIdx='+recIdx+', itmId='+itmId+', itmQtyOrd='+itmQtyOrd+', itmQtyRet='+itmQtyRet);  // prov_debug
        if (itmQtyRet > itmQtyOrd) {
          itmQtyRet = itmQtyOrd;
          refItmRecs.value[recIdx].iQtyRet = itmQtyRet;
        }
        let iTotalRet = 1 * itmQtyRet * itmPrice;
        if (iTotalRet > 0) {
          refItmRecs.value[recIdx].iTotalRet = 1 * itmQtyRet * itmPrice;
        } else {
          refItmRecs.value[recIdx].iTotalRet = null;
        }

      let iSubTot = 0;
      refItmRecs.value.map(function(objElement, objIdx, objArray) {
        // objIdx = [0 ... newObj.length[
        iSubTot += objElement.iTotalRet;
        });
      refRec.value.iRetSubTot = iSubTot;
      refRec.value.iRetTotal = iSubTot;

        // console.log('vwsReturnFrm.updateQtyRet: DEBUG: recIdx='+recIdx+', itmId='+itmId+', itmId='+itmQtyRet);  // prov_debug
      }  // updateQtyRet
      
      function frmActionDone(myResp) {
        console.log('vwsReturnFrm.frmActionDone: myRespStr='+JSON.stringify(myResp)); // prov_debug
        // ==> myRespStr={"resp":{"errCode":500,"errMsg":"ERROR"}}
        // ==> myRespStr={"resp":{"errCode":501,"errMsg":"Invalide function"}}
        // ==> myRespStr={"resp":{"errCode":502,"errMsg":"502:Could not save record"}}
        // ==> myRespStr={"resp":{"errCode":1,"errMsg":"OK"}}
        
        
        switch(myResp.resp.errCode) {
          case 1: // OK: done successfully
            refResp.value.errCode = myResp.resp.errCode;
            refResp.value.errMsg = myResp.resp.errMsg;
            break;
          case 500: // errMsg500 = 'Generic error';          // Default server-side error
          case 501: // errMsg501 = 'Invalide function';       // Unexpected function
            alert('ERROR: '+myResp.resp.errMsg); // prov_debug
            break;
        }
        
        
      } // frmActionDone

      function submitOrdRet() {
        console.log('vwsReturnFrm.submitOrdRet: DEBUG: orderId='+refRec.value.id);  // prov_debug

        // let nowStr = tlsGetDateStr(null, null);  // 2021-08-24 09:11:25
        let objDate = new Date();  // Get current date and time
        let date = ("0" + objDate.getDate()).slice(-2); // current date
        let month = ("0" + (objDate.getMonth() + 1)).slice(-2); // current month
        let year = objDate.getFullYear(); // current year
        let hours = ("0" + (objDate.getHours() + 1)).slice(-2); // current hours
        let minutes = ("0" + (objDate.getMinutes() + 1)).slice(-2); // current minutes
        let seconds = ("0" + (objDate.getSeconds() + 1)).slice(-2); // current seconds
        let nowStr = year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds;

        refRec.value.dtmReturn = nowStr;
            let myParams = {
                  fct: 'update',
                  rec: refRec.value,
                  recs: refItmRecs.value,
            };
            ascOrdersSaveRec(myParams, frmActionDone);

      }  // submitOrdRet
      
      function openOrder(orderId) {
        console.log('vwsReturnFrm.openOrder: DEBUG: orderId='+orderId+', clientId='+refClientRec.value.id);  // prov_debug
      } // openOrder
      
      function cancelOrder(orderId) {
        console.log('vwsReturnFrm.cancelOrder: DEBUG: orderId='+orderId+', clientId='+refClientRec.value.id);  // prov_debug
      } // cancelOrder

      function returnOrder(orderId) {
        console.log('vwsReturnFrm.returnOrder: DEBUG: orderId='+orderId+', clientId='+refClientRec.value.id);  // prov_debug
      } // returnOrder

      function openPage(pgName) {
        console.log('vwsReturnFrm.openPage: pgName='+pgName);
        // this.$router.push({name:pgName,params:{pgAction:'init',prmId:''}});
        router.push({name:pgName,params:{pgAction:'init',prmId:''}});
      }
      
      return {
        refDico,
        refOrdStatus,
        refPaymMethods,
        refOrdRetTypes,
        refPageAttr,
        refItmTypeAttr,
        refOrdersResp,
        refOrdersLoading,
        refOrdersRec,
        refResp,
        refRec,
        refItmRecs,
        refItmFznRecs,
        refFreeItmRecs,
        refFreeItmFznRecs,
        updateQtyRet,
        submitOrdRet,
        openOrder,
        cancelOrder,
        returnOrder,
      }
    },

  };

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>