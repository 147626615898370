import { ref, computed } from "vue";
import useTools from '@/utils/vjsTools.js';

const axios = require("axios");

// Global variable that can be shared state with Composition API

// Department
let refDepartmentLoading = ref(false);
let refDepartmentResp = ref({});
let refDepartmentLang = ref('');  // ='tw':Traditionnal chinese, 'en':English
let refDepartmentRec = ref({});  // List of key/value

export default function useDepartmentApi() {
    
  /*
   * ascStaffGetRecs: Request REST API services for an array of records
   */
  async function ascDepartmentLoad(prmLang) {
    const fctName = 'svcDepartmentApi.ascDepartmentLoad';
    const reqUrl = '/api/referential/getDepartment';
    const {tlsDecodeUri} = useTools();
    
    console.log(fctName+': BEGIN: prmLang='+prmLang);
    
    refDepartmentLoading.value = true;
    try {
      let reqParams = {lang:prmLang};
      const { data } = await axios.post(
        reqUrl,
        reqParams
      );
      
      // console.log(fctName+': DEBUG: retDataStr='+JSON.stringify(data));  // debug
      // ==> retDataStr={"errCode":1,"errMsg":"OK","rec":[{"dep010":"Penghu","submenu":[{"cty0110":"Makung"},{"cty0120":"Xiyu"}]},{"dep020":"Taiwan","submenu":[{"cty0210":"Taipei"},{"cty0220":"Taichung"},{"cty0230":"Tainan"}]}],"recsNb":0,"recs":[{}]}
      
      let strJson;
      let strDecoded;
      
      let objKey;
      let objValue;
      
      let reqResp = data;
      let reqRec;
      
      // errCode = [1 ... 99] :OK message, [100 ... 999] :Error message, value = [1000 ... 999] :Process aborted
      refDepartmentResp.value.errCode = reqResp.errCode; 
      refDepartmentResp.value.errMsg = reqResp.errMsg;
      switch(reqResp.errCode) {
        case 1: // OK: rec found
          // Get response (begin)
          
          reqRec = reqResp.rec;
          // console.log('vwsStaffLst.computed: DEBUG: reqRecStr(1)='+JSON.stringify(reqRec));  // debug
          // ==> reqRecStr(1)=[{"dep010":"Penghu","submenu":[{"cty0110":"Makung"},{"cty0120":"Xiyu"}]},{"dep020":"Taiwan","submenu":[{"cty0210":"Taipei"},{"cty0220":"Taichung"},{"cty0230":"Tainan"}]}]
          
          /*
          for (const prop in reqRec) {
            // Decode value of each element of the JSON object (begin)
            // from element:  "btnMoreItems":"%E6%9B%B4%E5%A4%9A%E9%A0%85%E7%9B%AE"
            // to element:    "btnMoreItems":"更多項目"
            objKey = prop;                          // objKey="menuHome" 
            objValue = reqRec[objKey];             // objValue=Home
            reqRec[objKey] = decodeURI(objValue);
            // console.log('vwsStaffLst.computed: DEBUG: objKey='+JSON.stringify(objKey)+', objValue='+objValue);  // debug
            // Decode value of each element of the JSON object (end)
          }
          */
          tlsDecodeUri(reqRec);
          // console.log('vwsStaffLst.computed: DEBUG: reqRecStr(2)='+JSON.stringify(reqRec));  // debug
          // ==> 
          
          refDepartmentRec.value = reqRec;
          
          // You may alter data of the record here
          // ...

          // Get response (end)
          break;
        case 2: // Record not found
          refDepartmentRec.value = {};
          break;
        default: // Error occured at server sidebar
          refDepartmentRec.value = {};
          break;
      }
      
      // console.log(fctName+': DEBUG: refDepartmentRecStr='+JSON.stringify(refDicoRec.value));  // debug
      // ==> 
      
    } catch (ex) {
      if (ex.response) {
        // Request made and server responded
        console.log(fctName+': EXC: exStatus='+ex.response.status+', exData='+ex.response.data);
        // console.log('exrespdata:'+ex.response.data);  // 9002:DB Exception:2021-04-08 16:59:36 - mdlDbMysql.dbFind
        // console.log('exrespstatus:'+ex.response.status);  // 500
        // console.log('exrespheader:'+ex.response.headers);
      } else {
        console.log(fctName+': EXC: ex='+ex.stack);
      }
    } finally {
      refDepartmentLoading.value = false;
    }
    
    console.log(fctName+': RET: prmLang='+prmLang); 
    
  } // ascDepartmentLoad
  
  return  {
      refDepartmentLoading: computed(() => refDepartmentLoading.value),
      refDepartmentRec: computed(() => refDepartmentRec.value),
      ascDepartmentLoad,
  }
}
