<template>
    <span v-if="refCartItemRecs && refCartItemRecs.length > 0">
    
    <!-- Steps (begin) -->
    <center>
      <br>
      
        <div class="stepContainer">
          <div class="stepLink">
            <div class="stepItem">
              <a id="btnStepA" class="btnStepOn" title="stepCartInfo" style="pointer-events: none">{{ refDico.stepCart1 }}</a>
            </div>
            <div class="stepItem">
              <a id="btnStepB" class="btnStepOff" title="stepCartDeliveryInfo"  style="pointer-events: none">{{ refDico.stepCart2 }}</a>
            </div>
            <div class="stepItem">
              <a id="btnStepC" class="btnStepOff" title="stepCartPaymentInfo"  style="pointer-events: none">{{ refDico.stepCart3 }}</a>
            </div>
            <div class="stepItem">
              <a id="btnStepD" class="btnStepOff" title="stepCartValidationInfo"  style="pointer-events: none">{{ refDico.stepCart4 }}</a>
            </div>
            <div class="stepItem">
              <a id="btnStepE" class="btnStepOff" title="stepCartSummaryInfo"  style="pointer-events: none">{{ refDico.stepCart5 }}</a>
            </div>
          </div>
        </div>
        <br>
    </center>
    <!-- Steps (end) -->

    <div>
      <!-- Content (begin) -->
      <center>
        <div class="myCartItm1" style="display:inline-block; vertical-align:top; marging: 0px 0px 0px 0px;">


          <!-- Product (begin) -->
          <table class="myCart1table" border="0" >
            <!-- ----------- Ordered items - STD ---------- -->
            <tr style="background-color: #C0C0C0;" v-if=" refCartItemStdRecs.length > 0">
              <td class="myCart1tabletd" colspan="3" style="text-align: center; ">
                <b>{{ refDico.tleCart1 }} - {{ refDico.lblRoomTemperature }} </b><br>&nbsp;
              </td>
            </tr>
            <tr v-for="tmpRec in refCartItemStdRecs" :key="tmpRec.id" >
                <td class="myCart1tabletd" >
                  <a v-on:click="openItem(tmpRec.id, tmpRec.sItmCode1)" ><img class="itmIntroCellImg2" v-bind:src="'/appimg/'+tmpRec.sItmCode1+'.jpg'"></a>
                </td>
                <td class="myCart1tabletd" width="99%" >
                  <span v-if="tmpRec.iIsPromo == 1">{{ tmpRec.sLabel }} {{ refDico.flgPromo }}</span>
                  <span v-else>{{ tmpRec.sLabel }}</span>
                  <br>
                        <span v-if="tmpRec.sGrpCode.includes('P')" style="font-size:10px;">
                          {{ tmpRec.sSizeCode }} &nbsp;&nbsp; - &nbsp;&nbsp;{{ refDico.lblGrpPenghu}}
                        </span>
                        <span v-else>
                          {{ tmpRec.sSizeCode }}
                        </span>
                  <br>
                        <a v-on:click="qtyMinus(tmpRec.id)" style="cursor: pointer;">&nbsp;-&nbsp;</a>
                        <input type="text" value="1" style="text-align:center; width:30px;" v-model="tmpRec.iQtyOrd" v-on:change="qtySet(0, tmpRec.id)" />
                        <a v-on:click="qtyPlus(tmpRec.id)" style="cursor: pointer;">&nbsp;+&nbsp;</a>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {{ refItmTypeAttr[tmpRec.sTypeCode] }} x 
                        <span v-if="tmpRec.iPrice2">
                          <!-- Promo price -->
                          {{ tmpRec.iPrice2 }} {{ refDico.lblDevise }}
                        </span>
                        <span v-else>
                          <!-- Regular price -->
                          {{ tmpRec.iPrice1 }} {{ refDico.lblDevise }}
                        </span>
                </td>
                <td class="myCart1tabletd" style="text-align:center;" nowrap>
                   <a class="btnStd01" v-on:click="delCartItm(tmpRec.id)" ><img src="/img/delete.png"></a>
                   <br>
                   <b>{{ tmpRec.iTotalOrd }} {{ refDico.lblDevise }}</b>
                 </td>
            </tr>

            <!-- ----------- Ordered items - FZN ---------- -->
            <tr style="background-color: #E0E0E0;" v-if=" refCartItemFznRecs.length > 0">
              <td class="myCart1tabletd" colspan="3" style="text-align: center; ">
                <b>{{ refDico.tleCart1 }} - {{ refDico.lblLowTemperature }} </b><br>&nbsp;
              </td>
            </tr>
            <tr v-for="tmpRec in refCartItemFznRecs" :key="tmpRec.id" >
                <td class="myCart1tabletd" >
                  <a v-on:click="openItem(tmpRec.id, tmpRec.sItmCode1)" ><img class="itmIntroCellImg2" v-bind:src="'/appimg/'+tmpRec.sItmCode1+'.jpg'"></a>
                </td>
                <td class="myCart1tabletd" width="99%" >
                  {{ tmpRec.sLabel }}
                  <br>
                        <span v-if="tmpRec.sGrpCode.includes('P')" style="font-size:10px;">
                          {{ tmpRec.sSizeCode }} &nbsp;&nbsp; - &nbsp;&nbsp;{{ refDico.lblGrpPenghu}}
                        </span>
                        <span v-else>
                          {{ tmpRec.sSizeCode }}
                        </span>
                  <br>
                        <a v-on:click="qtyMinus(tmpRec.id)" style="cursor: pointer;">&nbsp;-&nbsp;</a>
                        <input type="text" value="1" style="text-align:center; width:30px;" v-model="tmpRec.iQtyOrd" v-on:change="qtySet(1, tmpRec.id)" />
                        <a v-on:click="qtyPlus(tmpRec.id)" style="cursor: pointer;">&nbsp;+&nbsp;</a>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {{ refItmTypeAttr[tmpRec.sTypeCode] }} x 
                        <span v-if="tmpRec.iPrice2">
                          <!-- Promo price -->
                          {{ tmpRec.iPrice2 }} {{ refDico.lblDevise }}
                        </span>
                        <span v-else>
                          <!-- Regular price -->
                          {{ tmpRec.iPrice1 }} {{ refDico.lblDevise }}
                        </span>
                </td>
                <td class="myCart1tabletd" style="text-align:center;" nowrap>
                   <a class="btnStd01" v-on:click="delCartItm(tmpRec.id)" ><img src="/img/delete.png"></a>
                   <br>
                   <b>{{ tmpRec.iTotalOrd }} {{ refDico.lblDevise }}</b>
                 </td>
            </tr>

            <!-- ----------- Free items - STD ---------- -->
            <tr style="background-color: #C0C0C0;" v-if=" refCartItemFreeStdRecs.length > 0">
              <td class="myCart1tabletd" colspan="3" style="text-align: center; ">
                <b>{{ refDico.tleCart1Free }} - {{ refDico.lblRoomTemperature }} </b><br>&nbsp;
              </td>
            </tr>
            <tr v-for="tmpRec in refCartItemFreeStdRecs" :key="tmpRec.id"  style="background-color: #F0F0F0;">
                <td class="myCart1tabletd" >
                  <a v-on:click="openItem(tmpRec.id, tmpRec.sItmCode1)" ><img class="itmIntroCellImg2" v-bind:src="'/appimg/'+tmpRec.sItmCode1+'.jpg'"></a>
                </td>
                <td class="myCart1tabletd" width="99%" >
                  {{ tmpRec.sLabel }}
                  <br>
                        <span v-if="tmpRec.sGrpCode.includes('P')" style="font-size:10px;">
                          {{ tmpRec.sSizeCode }} &nbsp;&nbsp; - &nbsp;&nbsp;{{ refDico.lblGrpPenghu}}
                        </span>
                        <span v-else>
                          {{ tmpRec.sSizeCode }}
                        </span>
                  <br>
                        {{ tmpRec.iQtyOrd }} {{ refItmTypeAttr[tmpRec.sTypeCode] }}
                </td>
                <td class="myCart1tabletd" style="text-align:center;" nowrap>
                   &nbsp;
                 </td>
            </tr>

            <!-- ----------- Free items - FZN ---------- -->
            <tr style="background-color: #E0E0E0;" v-if=" refCartItemFreeFznRecs.length > 0">
              <td class="myCart1tabletd" colspan="3" style="text-align: center; ">
                <b>{{ refDico.tleCart1Free }} - {{ refDico.lblLowTemperature }} </b><br>&nbsp;
              </td>
            </tr>
            <tr v-for="tmpRec in refCartItemFreeFznRecs" :key="tmpRec.id"  style="background-color: #F0F0F0;">
                <td class="myCart1tabletd" >
                  <a v-on:click="openItem(tmpRec.id, tmpRec.sItmCode1)" ><img class="itmIntroCellImg2" v-bind:src="'/appimg/'+tmpRec.sItmCode1+'.jpg'"></a>
                </td>
                <td class="myCart1tabletd" width="99%" >
                  {{ tmpRec.sLabel }}
                  <br>
                        <span v-if="tmpRec.sGrpCode.includes('P')" style="font-size:10px;">
                          {{ tmpRec.sSizeCode }} &nbsp;&nbsp; - &nbsp;&nbsp;{{ refDico.lblGrpPenghu}}
                        </span>
                        <span v-else>
                          {{ tmpRec.sSizeCode }}
                        </span>
                  <br>
                        {{ tmpRec.iQtyOrd }} {{ refItmTypeAttr[tmpRec.sTypeCode] }}
                </td>
                <td class="myCart1tabletd" style="text-align:center;" nowrap>
                   &nbsp;
                 </td>
            </tr>


          </table>


        </div>
            
        <div style="text-align: center; display:inline-block; vertical-align:top; width:200px; padding: 2px 10px 2px 10px; 			/* top right bottom left */">
          <div  style="border: 2px solid #D0D0D0; ">

          <table class="myCart1table" border="0" width="100%">
            <!-- ----------- Ordered items - STD ---------- -->
            <tr style="background-color: #C0C0C0;" v-if=" refCartItemStdRecs.length > 0">
              <td style="text-align: center; ">
                <b>{{ refDico.lblSubTotal }}</b><br>&nbsp;
              </td>
            </tr>
            <tr>
              <td style="text-align: center; " nowrap>
                {{ refCartRec.iSubTotal }} {{ refDico.lblDevise }}<br>&nbsp;
              </td>
            </tr>
            </table>

            <br>
            <a class="btnStd01" v-on:click="verifyInventory();" >{{ refDico.lblValidate }}</a>
          </div>
        </div>
        <br>
        <br>
        <br>&nbsp;

      </center>
      <!-- Content (end) -->
    </div>
  </span>
  <span v-else><br><br> {{ refDico.lblEmptyCart }} <br><br></span>
</template>

<script>
  import { ref, computed, onBeforeMount, onMounted } from 'vue';
  import router from '@/router/index.js';
  import { useRoute } from 'vue-router';
  import useTools from '@/utils/vjsTools.js';
  import useReferential from '@/modules/mdlReferential.js'; 
  import useClientApi from '@/services/svcClientApi.js';
  import useCartApi from '@/services/svcCartApi.js';
  import useItemApi from '@/services/svcItemApi.js';

      let { ascClientFindRec, ascClientSaveRec, ascClientDeleteRec, refClientResp, refClientLoading, refClientRec } = useClientApi();
      let { ascCartGetRecs, ascCartGetItemRecs, ascCartFindRec, ascCartSaveRec, ascCartDeleteRec, refCartResp, refCartLoading, refCartRec, refCartItemRecs, refCartItemFreeRecs, refCartItemStdRecs, refCartItemFznRecs, refCartItemFreeStdRecs, refCartItemFreeFznRecs, fctCartUpdate, fctCartDelItem, fctCartCreateOrder, fctCartUpdItem, ascFocasPayment } = useCartApi();
      let { ascItemGetIryRecs, ascItemFindRec, ascItemSaveRec, ascItemDeleteRec, fctRfshItem, refItemResp, refItemLoading, refItemRec, refItemRecs, refMenuRecs } = useItemApi();

  export default {
    setup() {
      const route = useRoute();
      let { tlsNavTo, tlsNavBack } = useTools();
      const { refFlgCheckIry, refDicoLang, refDicoAttr, refPageAttr, refItmTypeAttr, ascSetLang, refSlctMenu, fctExtractMenu, fctExtractSubmenu } = useReferential();

      let refDico = ref({});
      let refResp = ref({});
      let refRec = ref({});
      // let refCartItemStdRecs = ref([]);
      // let refCartItemFznRecs = ref([]);
      // let refCartItemFreeStdRecs = ref([]);
      // let refCartItemFreeFznRecs = ref([]);
      
      onBeforeMount(() => {
      
        console.log('vwsCart1.onBeforeMount: DEBUG: check refClientRecId='+refClientRec.value.id);  // prov_debug
        /*
        if (refClientRec.value.id) {
          refPageAttr.value.pgAction = 'init';
          refPageAttr.value.pgName = 'pgCart1';
        } else {
          // Client need to login before adding preferred item
          openPage('pgClientLogin');
        }
        */
      });
      
      onMounted(async () => {
        window.scrollTo(0, 0);
        refDico.value = refDicoAttr.value;
        refPageAttr.value.title = refDico.value.tleCart;
        
        let reqParams = { 
              criteria: "idParent = "+refClientRec.value.id, 
              orderby: "id ASC", // "sItmCode1 ASC",
              };
        // KO ascCartGetItemRecs(reqParams);  // KO // Without callback, the record can not be altered at loading
        ascCartGetItemRecs(reqParams, reqCallback);  // Callback is required if altering the record is required at loading

        
      }); // onMounted
      
      
      
      
      
      /**** Without callback (begin) ****
      // Without callback, the record can not be altered at loading
      refResp = computed(() => {
        console.log('vwsCart1.computed: DEBUG: refResp=refItemRespStr='+JSON.stringify(refItemResp.value));  // prov_debug
        return refItemResp.value;
      });
      **** Without callback (end) ****/
      
      /**** With callback (begin) ****/
      // Callback is required if altering the record is required at loading
      function reqCallback(myResp) {
        console.log('vwsCart1.reqCallback: DEBUG: respStr='+JSON.stringify(myResp.resp));  // prov_debug
        // ==> respStr={"resp":{"errCode":1,"errMsg":"OK"},"recs":[{"id":154,"sLabel":"統一布丁（大）","iStatus":null,"dtmCreate":"2021-04-15 12:00:38","dtmModif":"2021-04-15  ...
        
        /**** prov_debug ****
        // Copy an array of objects
        // refCartItemFreeRecs.value = JSON.parse(JSON.stringify(refCartItemRecs.value));
        // refCartItemFreeRecs.value = refCartItemFreeRecs.value.slice(0);
        refCartItemFreeRecs.value.length = 0;	// Empty the array
        refCartItemRecs.value.map(function(objElement, objIdx, objArray) {
          // objIdx = [0 ... newObj.length[
          refCartItemFreeRecs.value.push(objElement);
        });
        **** prov_debug ****/

        /*
        refCartItemStdRecs.value.length = 0;
        refCartItemFznRecs.value.length = 0;
        refCartItemFreeStdRecs.value.length = 0;
        refCartItemFreeFznRecs.value.length = 0;
        
        // Copy an array of objects

        // List of Order Std Item: refCartItemStdRecs -----------------------------
        refCartItemRecs.value.map(function(objElement, objIdx, objArray) {
          // objIdx = [0 ... newObj.length[
          console.log('vwsCart1.reqCallback: DEBUG: STD iFlgFzn='+objElement.iFlgFzn+', sItmCode1='+objElement.sItmCode1);
          if (objElement.iFlgFzn == 0) {
            refCartItemStdRecs.value.push(objElement);
          }
        });
        // List of Order Fzn Item: refCartItemFznRecs ----------------------------
        refCartItemRecs.value.map(function(objElement, objIdx, objArray) {
          // objIdx = [0 ... newObj.length[
          console.log('vwsCart1.reqCallback: DEBUG: FZN iFlgFzn='+objElement.iFlgFzn+', sItmCode1='+objElement.sItmCode1);
          if (objElement.iFlgFzn == 1) {
            refCartItemFznRecs.value.push(objElement);
          }
        });
        console.log('vwsCart1.reqCallback: DEBUG: refCartItemRecsLen='+refCartItemRecs.value.length+', refCartItemStdRecsLen='+refCartItemStdRecs.value.length+', refCartItemFznRecsLen='+refCartItemFznRecs.value.length );  // prov_debug

        // List of Free Std Item: refCartItemFreeStdRecs -------------------------------
        refCartItemFreeRecs.value.map(function(objElement, objIdx, objArray) {
          // objIdx = [0 ... newObj.length[
          if (objElement.iFlgFzn == 0) {
            refCartItemFreeStdRecs.value.push(objElement);
          }
        });
        // List of Free Fzn Item: refCartItemFreeFznRecs ------------------------------
        refCartItemFreeRecs.value.map(function(objElement, objIdx, objArray) {
          // objIdx = [0 ... newObj.length[
          if (objElement.iFlgFzn == 1) {
            refCartItemFreeFznRecs.value.push(objElement);
          }
        });
        */
        fctCartUpdate();

        console.log('vwsCart1.reqCallback: DEBUG: refCartItemStdRecsLen='+refCartItemStdRecs.value.length+', refCartItemFznRecsLen='+refCartItemFznRecs.value.length);  // prov_debug
        console.log('vwsCart1.reqCallback: DEBUG: refCartItemFreeStdRecsLen='+refCartItemFreeStdRecs.value.length+', refCartItemFreeFznRecsLen='+refCartItemFreeFznRecs.value.length);  // prov_debug

        // console.log('vwsCart1.qtyPlus: DEBUG: refCartItemStdRecsStr='+JSON.stringify(refCartItemStdRecs.value));  // debug
        // console.log('vwsCart1.qtyPlus: DEBUG: refCartItemFreeStdRecsStr='+JSON.stringify(refCartItemFreeStdRecs.value));  // debug

      }
      /**** With callback (end) ****/
      
      function openPage(pgName) {
        console.log('cpnHeader.openPage: pgName='+pgName);
        // this.$router.push({name:pgName,params:{pgAction:'init',prmId:''}});
        router.push({name:pgName,params:{pgAction:'init',prmId:''}});
      }
      
      function qtyMinus(itmId) {
        console.log('vwsCart1.qtyMinus: DEBUG: itmId='+itmId);  // prov_debug
        
        let myParams = {
          fct: 'updQty',
          idClient: refClientRec.value.id,    // Client's ID
          idItem: itmId,                      // Item's ID
          qtyItem: -1,                      // Nb items
        };
        fctCartUpdItem(myParams);
        fctCartUpdate();
      } // qtyMinus
      
      function qtyPlus(itmId) {
        console.log('vwsCart1.qtyPlus: DEBUG: itmId='+itmId);  // prov_debug
        // console.log('vwsCart1.qtyPlus: DEBUG: refRecsStr='+JSON.stringify(refCartItemRecs.value));  // debug
        
        let myParams = {
          fct: 'updQty',
          idClient: refClientRec.value.id,    // Client's ID
          idItem: itmId,                      // Item's ID
          qtyItem: 1,                      // Nb items
        };
        fctCartUpdItem(myParams);
        fctCartUpdate();
      } // qtyPlus
      
      /*
      function qtySet(itmId) {
        console.log('vwsCart1.qtySet: DEBUG: itmId='+itmId);  // prov_debug
        // console.log('vwsCart1.qtySet: DEBUG: refRecsStr='+JSON.stringify(refCartItemRecs.value));  // debug

        let index = refCartItemRecs.value.findIndex(function(item){ return item.id == itmId});
        let sLabel = refMenuRecs.value[index].sLabel;
        let iQty = 1* refMenuRecs.value[index].iQty;
        
        console.log('vwsCart1.qtySet: DEBUG: index='+index+', sLabel='+sLabel+', iQty='+iQty);  // prov_debug
        
      } // qtySet
      */

      function qtySet(flgFzn, itmId) {
        console.log('vwsCart1.qtySet: DEBUG: itmId='+itmId);  // prov_debug
        // console.log('vwsCart1.qtySet: DEBUG: refRecsStr='+JSON.stringify(refCartItemRecs.value));  // debug
        
        let index;
        let iQty;
        if (flgFzn == 1) {
          // FZN items
          index = refCartItemFznRecs.value.findIndex(function(item){ return item.id == itmId});
          iQty = 1* refCartItemFznRecs.value[index].iQtyOrd;
          console.log('vwsCart1.qtySet: DEBUG: STD index='+index+', sItmCode1='+refCartItemFznRecs.value[index].sItmCode1+', iQty='+iQty);  // prov_debug
        } else {
          // STD items (begin)
          index = refCartItemStdRecs.value.findIndex(function(item){ return item.id == itmId});
          iQty = 1* refCartItemStdRecs.value[index].iQtyOrd;
          console.log('vwsCart1.qtySet: DEBUG: STD index='+index+', sItmCode1='+refCartItemStdRecs.value[index].sItmCode1+', iQty='+iQty);  // prov_debug
        }
        if (index >= 0) {
          console.log('vwsCart1.qtySet: DEBUG: itmId='+itmId+', iQty='+iQty);  // prov_debug
          
          let myParams = {
            fct: 'setQty',
            idClient: refClientRec.value.id,    // Client's ID
            idItem: itmId,                      // Item's ID
            qtyItem: iQty,                      // Nb items
          };
          fctCartUpdItem(myParams);	// Update qty of the item in current shopping cart
          fctCartUpdate();	// Update list of Ordered & Free items STD&FZN
          // STD items (begin)
        }
      } // qtySet

      function delCartItm(itmId) {
        console.log('vwsCart1.delCartItm: DEBUG: itmId='+itmId);  // prov_debug
        let myParams = {
            fct: 'delete',
            idClient: refClientRec.value.id,    // Client's ID
            idItem: itmId,                      // Item's ID
          };
        fctCartDelItem(myParams);
        fctCartUpdate();
      }
      
      function tmpCreateOrderDone(myResp) {
        console.log('vwsCart1.tmpCreateOrderDone: DEBUG: myRespStr='+JSON.stringify(myResp));  // prov_debug
      }
      
      function openItem(recId, itmCode1) {
        console.log('vwsCart1.openItem: recId='+recId+', itmCode1='+itmCode1);
        // this.$router.push({name:'pgItemFrm',params:{pgAction:'init',prmId:recId}});
        // router.push({name:'pgItemFrm',params:{pgAction:'init',prmId:recId}});
        
        let query = {name:'pgItemFrm',params:{pgAction:'init',prmId:recId,prmCode:itmCode1}};
        tlsNavTo(query);
      }

      function verifyInventoryDone(myResp) {
        console.log('vwsCart1.verifyInventoryDone: DEBUG: respStr='+JSON.stringify(myResp.resp));  // prov_debug
        // ==> respStr={"errCode":1,"errMsg":"OK"}

        let errItmLabel = ''; // Label of sItmCode1
        let errItmIryQty = 0; // Qty in stock
        let errItmOrdQty = 0; // Qty in order

        if (myResp.resp.errCode == 1) {
          // OK: Extract and verify available stock (begin)
            console.log('vwsCart1.verifyInventoryDone: DEBUG: recsStr='+JSON.stringify(myResp.recs));  // prov_debug
            // ==> recsStr=[{"id":286,"sLabel":"XXX","iStatus":null,"dtmCreate":"2023-03-01 11:09:43","dtmModif":"2023-03-01 11:09:43","dtmDiscBegin":null,"dtmDiscEnd":null,"sDiscCode":"","sItmCode1":"078895100020","sItmCode2":"20890","sSupCode":"19","sCatCode":"dep001","sSizeCode":"510g","sTypeCode":"uni003","sType1Code":"mnu100","sType2Code":"snu1010","sDescr":"XXX","sImg":"","iPrice":null,"iPrice1":185,"iPrice2":null,"iQtyMax":null,"iQtyMin":10,"iQty":null,"sGrpCode":"_PRA","sFlgReturn":"","sFlgActif":"","sFlgMenu":"_","iInvQty":null,"iFreeItmId":null,"sFreeItmCode1":"","sFreeItmLabel":"","iFreeQty":null,"sFreeSizeCode":"","sFreeItmSize":"","sFreeTypeCode":"","sFreeItmType":"","iIsPromo":null,"iPromoQty":null,"iQtyCnt":null,"iPrice4":null,"iFlgFzn":0,"iFlgFreeFzn":null,"sFreeItmTitle":""},{"id":304,"s…

            // Update current qty in inventory
            myResp.recs.map(function(objElement, objIdx, objArray) {
              fctRfshItem(null, objElement);
              if (errItmOrdQty == 0) {
                // Check qty ord vs qty in stock (begin)
                let cartItemRecsIdx = refCartItemRecs.value.findIndex(function(item){ 
                  return item.sItmCode1 == objElement.sItmCode1; // sItmCode1
                });
                if (cartItemRecsIdx >= 0) {
                  if (objElement.iInvQty && (1 * refCartItemRecs.value[cartItemRecsIdx].iQtyOrd) > (1 * objElement.iInvQty)) {
                     // Qty in order > Qty in stock
                     errItmLabel =objElement.sLabel; // sItmCode1
                     errItmIryQty = 1 * objElement.iInvQty; // Qty in stock
                     errItmOrdQty = 1* refCartItemRecs.value[cartItemRecsIdx].iQtyOrd; // Qty in order
                  }
                }
                // Check qty ord vs qty in stock (end)
              }
            });

            if (errItmOrdQty > 0) {
              // KO: Not enough qty in stock (begin)
              let msgData = refDicoAttr.value.errCartItmQtyNoStock;
              // let errMsg  = msgData.replaceAll('XX', ': ' + errItmLabel + ':' + errItmOrdQty + ' > ' + errItmIryQty);
              let errMsg  = msgData.replaceAll('XX', ': ' + errItmLabel + ': ' + errItmIryQty);
              alert(errMsg);
              // KO: Not enough qty in stock (begin)
            } else {
              // OK: Qty in stock verified
              openPage('pgCart2');
            }

          // OK: Extract and verify available stock (end)
        } else {
          // ERROR: Coild not get data of the items
        }
      }

      function verifyInventory() {
        // refFlgCheckIry,			// Enable/Disable inventory verification // =true:check iInvQty, =false:do NOT check iInvQty
        if (refFlgCheckIry.value == true) {
          // check iInvQty (begin)
          let lstItmCode = '';
          refCartItemRecs.value.map(function(objElement, objIdx, objArray) {
            if (lstItmCode.length > 0) {
              lstItmCode = lstItmCode + ", '" + objElement.sItmCode1 + "' ";
            } else {
              lstItmCode = " '" + objElement.sItmCode1 + "' ";
            }
          });
          console.log('vwsCart1.verifyInventory: DEBUG: refCartItemRecsLen='+refCartItemRecs.value.length);  // prov_debug
          console.log("vwsCart1.verifyInventory: DEBUG: lstItmCode="+lstItmCode);  // prov_debug

          let reqParams = { 
            criteria:"sItmCode1 IN (" + lstItmCode + ")", 
            orderby:"sItmCode1 ASC"};
          ascItemGetIryRecs(reqParams, verifyInventoryDone); // Callback is required if altering the record is required at loading
          // check iInvQty (end)
        } else {
          // do NOT check iInvQty
          openPage('pgCart2');
        }
      }

      return {
        refDico,
        refPageAttr,
        refItmTypeAttr,
        refCartResp, 
        refCartLoading, 
        refCartRec,
        refCartItemRecs,
        refCartItemFreeRecs,
        refCartItemStdRecs,
        refCartItemFznRecs,
        refCartItemFreeStdRecs,
        refCartItemFreeFznRecs,
        openPage,
        qtyMinus,
        qtyPlus,
        qtySet,
        delCartItm,
        openItem,
        verifyInventory,
      }
    },

  };

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>