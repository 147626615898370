<template>
  <span v-if="refItmRec.sItmCode1">
  <div id="cpnItm">

      <center>
          <span v-if="refItmRec.sFreeItmCode1">
            <div style="background-color: #FFFFFF; text-align:left;"><img style="width:40%;" v-bind:src="'/appimg/offre_tw.jpg'"></div>
          </span>
          <span v-if="refItmRec.iPrice2">
            <div style="background-color: #FFFFFF; text-align:left;"><img style="width:40%;" v-bind:src="'/appimg/sale_tw.jpg'"></div>
          </span>
          <a v-on:click="openItem(refItmRec.id, refItmRec.sItmCode1)" ><img class="itmIntroCellImg" v-bind:src="'/appimg/'+refItmRec.sItmCode1+'.jpg'"></a>
          <table border="0" style="width:100%;">
            <tr>
              <td class="textItm" colspan="2">
                <b>{{ refItmRec.sLabel }}</b> <!-- TODO: {{ refItmRec.idItem }} /  {{ refItmRec.id }} / {{ refItmRec.isPreferred }} -->
              </td>
            </tr>  <!-- name -->
            
            <tr>
              <td class="textItm" colspan="2">
                {{ refItmRec.sSizeCode }}
              </td>
            </tr> <!-- quantity -->
            
            <tr>
              <td class="textItm" colspan="2">
                <span v-if="refItmRec.iPrice2">
                  <!-- Promo price -->
                  <span class="regularPriceOff">{{ refItmRec.iPrice1 }} {{ refDicoAttr.lblDevise }}/{{ refItmTypeAttr[refItmRec.sTypeCode] }}</span>
                  &nbsp;&nbsp;&nbsp;
                  <span class="promoPriceOn">{{ refItmRec.iPrice2 }} {{ refDicoAttr.lblDevise }}/{{ refItmTypeAttr[refItmRec.sTypeCode] }}</span>
                </span>
                <span v-else>
                  <!-- Regular price -->
                  <span class="regularPriceOn">{{ refItmRec.iPrice1 }} {{ refDicoAttr.lblDevise }}/{{ refItmTypeAttr[refItmRec.sTypeCode] }}</span>
                </span>
             </td>
           </tr>
            
            <tr>
              <td style="font-size: 10px;" colspan="2">
                <span v-if="refItmRec.sGrpCode.includes('P')">
                  {{ refDicoAttr.lblGrpPenghu}}
                </span>
                <span v-else>
                  &nbsp;
                </span>
              </td>
            </tr>
            
            <tr>
              <td style="font-size: 10px;" colspan="2">
                <span v-if="refItmRec.sGrpCode.includes('R')">
                  &nbsp;
                </span>
                <span v-else>
                  {{ refDicoAttr.lblItmNotReturn}}
                </span>
              </td>
            </tr>
            
            <!-- <tr><td colspan="2">{{ refItmRec.sItmCode1 }}</td></tr> -->
            <!-- <tr><td colspan="2">sType1Code= {{ refItmRec.sType1Code }}</td></tr> -->
            <!-- <tr><td colspan="2">sType2Code={{ refItmRec.sType2Code }}</td></tr> -->
            <!-- <tr><td colspan="2">sFlgMenu={{ refItmRec.sFlgMenu }}</td></tr> -->
            
            
            
            <tr style="background-color: #D0D0D0;">
             <td nowrap="1" align="right" >
                <span v-if="refItmRec.isPreferred == false">
                  <a v-on:click="addPreferred(refItmRec.id)" ><i class="fa fa-heart fa-1x"></i></a> <!-- coeur -->
                </span>
                <span v-else>
                  <a v-on:click="delPreferred(refItmRec.id)" ><img src="/img/delete.png"></a>
                </span>
              </td>
              <td nowrap="1" align="left" width="99%" v-if="(refFlgCheckIry == false) || (refItmRec.iInvQty && refItmRec.iInvQty > 0)">
                <a v-on:click="qtyMinus(refItmRec.id)" style="cursor: pointer;" >&nbsp;-&nbsp;</a>
                <input type="text" style="text-align:center;width:30px;" v-model="refItmRec.iQty" v-on:change="qtySet(refItmRec.id)" />
                <a v-on:click="qtyPlus(refItmRec.id)" style="cursor: pointer;" >&nbsp;+&nbsp;</a>
                <a v-on:click="addCart(refItmRec.id)" ><i class="fa fa-shopping-cart fa-1x"></i></a> <!-- chariot -->
              </td>
              <td class="textItm" align="center" width="99%" v-else style="color:#FF0000;">
                {{ refDicoAttr.errCartItmSoldOut }}
             </td>
            </tr>
          </table>
        </center>
    
  </div>
  </span>
</template>

<script>
  import { ref, toRefs, computed, onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  import useTools from '@/utils/vjsTools.js';
  import useReferential from '@/modules/mdlReferential.js';
  import usePreferredApi from '@/services/svcPreferredApi.js';
  import useItemApi from '@/services/svcItemApi.js'; 
  import useClientApi from '@/services/svcClientApi.js';
  import useCartApi from '@/services/svcCartApi.js';

      let { ascItemGetRecs, ascItemFindRec, fctRfshItem, refItemLoading, refItemResp, refItemRec, refItemRecs, refMenuLoading, refMenuRecs, refFlgPreferred, updateMenuRecs } = useItemApi();
      let { ascClientFindRec, ascClientSaveRec, ascClientDeleteRec, refClientResp, refClientLoading, refClientRec } = useClientApi();
      let { fctCartAddItem, ascCartGetItemRecs, ascCartFindOrdRec, ascCartSaveRec, ascCartDeleteRec, refCartResp, refCartLoading, refCartRec, refCartItemRecs } = useCartApi();
      let { ascPreferredGetRecs, ascPreferredGetItemRecs, ascPreferredFindRec, ascPreferredSaveRec, ascPreferredDeleteRec, refPreferredResp, refPreferredLoading, refPreferredRecs } = usePreferredApi();

  export default {
    setup(props, context) {
      const route = useRoute();
      let { tlsNavTo, tlsNavBack } = useTools();
      const { refFlgCheckIry, refDicoLoading, refDicoLang, refDicoAttr, refPageAttr, refItmTypeAttr, ascSetLang, refSlctMenu, fctExtractMenu, fctExtractSubmenu, refPgHomeReady } = useReferential();
    
      let refItmRec = ref({});
      let refItmId = ref(0);
      
      let refResp = ref({});
      
      // console.log('cpnItmRec.setup: props='+JSON.stringify(props));
      
      // Attributes (Non-reactive object)
      // console.log('cpnItmRec.setup: attrs='+JSON.stringify(context.attrs));

      // Slots (Non-reactive object)
      // console.log('cpnItmRec.setup: slots='+JSON.stringify(context.slots));
    
      let refProps = toRefs(props);
      
      onMounted(async () => {
        // console.log('cpnItmRec.onMounted: refProps='+JSON.stringify(refProps.value)); // debug
        // Attributes (Non-reactive object)
        // console.log('cpnItmRec.onMounted: attrs='+JSON.stringify(context.attrs)); // debug
        
        refItmRec.value = context.attrs.refItmRec;
        // console.log('cpnItmRec.onMounted: sItmCode1='+refItmRec.value.sItmCode1);  // prov_debug
        /*
        if (refPreferredRecs.value && refPreferredRecs.value.length > 0) {
          let preferredIdx = refPreferredRecs.value.findIndex(function(item){ 
          if (item.idItem == 144 || item.id == 144) {
            console.log('cpnItmRec.onMounted.findIndex: DEBUG: refFlgPreferred='+refFlgPreferred.value+', item='+item.idItem+'/'+item.id+', refItm='+refItmRec.value.idItem+'/'+refItmRec.value.id);  // prov_debug
          }
            return item.idItem == refItmRec.value.idItem
          });
          refFlgPreferred.value = (preferredIdx >= 0)? true : false;
        }
        */
      }); // onMounted

      function qtyMinus(itmId) {
        // console.log('cpnItmRec.qtyMinus: DEBUG: itmId='+itmId);  // prov_debug
        
        let index = refMenuRecs.value.findIndex(function(item){ return item.id == itmId});
        let sLabel = refMenuRecs.value[index].sLabel;
        // console.log('cpnItmRec.qtyPlus: DEBUG: index='+index+', sLabel='+sLabel);  // prov_debug
        
        if (1*refMenuRecs.value[index].iQty > 0){
          refMenuRecs.value[index].iQty = 1*refMenuRecs.value[index].iQty - 1;
        }
      } // qtyMinus
      
      function qtyPlus(itmId) {
        // console.log('cpnItmRec.qtyPlus: DEBUG: itmId='+itmId);  // prov_debug
        // console.log('cpnItmRec.qtyPlus: DEBUG: refRecsStr='+JSON.stringify(refMenuRecs.value));  // debug

        let index = refMenuRecs.value.findIndex(function(item){ return item.id == itmId});
        let sLabel = refMenuRecs.value[index].sLabel;
        console.log('cpnItmRec.qtyPlus: DEBUG: index='+index+', sLabel='+sLabel);  // prov_debug
        refMenuRecs.value[index].iQty = 1*refMenuRecs.value[index].iQty + 1;
      } // qtyPlus
      
      function qtySet(itmId) {
        // console.log('cpnItmRec.qtySet: DEBUG: itmId='+itmId);  // prov_debug
        // console.log('cpnItmRec.qtySet: DEBUG: refRecsStr='+JSON.stringify(refMenuRecs.value));  // debug

        let index = refMenuRecs.value.findIndex(function(item){ return item.id == itmId});
        let sLabel = refMenuRecs.value[index].sLabel;
        let iQty = 1* refMenuRecs.value[index].iQty;
        
        // console.log('cpnItmRec.qtySet: DEBUG: index='+index+', sLabel='+sLabel+', iQty='+iQty);  // prov_debug
        
      } // qtySet
      

      function addCartDone(myResp) {
        let myParams;
        
        // console.log('cpnItmRec.addCartDone: myRespStr='+JSON.stringify(myResp)); // prov_debug
        // ==> myRespStr={"resp":{"errCode":501,"errMsg":"Invalide function"}}
        // ==> myRespStr={"resp":{"errCode":502,"errMsg":"502:Could not save record"}}
        // ==> myRespStr={"resp":{"errCode":101,"errMsg":"Record not found"},"rec":{}}
        // ==> myRespStr={"resp":{"errCode":1,"errMsg":"OK"}}
        
        switch(myResp.resp.errCode) {
          case 1: // OK: done successfully
            refResp.value.errCode = myResp.resp.errCode;
            refResp.value.errMsg = myResp.resp.errMsg;
            alert(refDicoAttr.value.msgCartAdded);
            break;
          case 101: // myRespStr={"errCode":101,"errMsg":"Record not found"}
            // The shopping cart of the client not yet created. Create it
            console.log('cpnItmRec.addCartDone: create cart refClientRecId='+refClientRec.value.id);
            myParams = {
              fct: 'insert',
              rec: {idClient: refClientRec.value.id},
            };
            // ascCartSaveRec(myParams); // Without callback, the record can not be altered at loading
            //ascCartSaveRec(myParams, addCartDone); // Callback is required if altering the record is required at loading
            break;
          case 501: // errMsg501 = 'Invalide function';       // Unexpected function
            alert(refDicoAttr.value.errCartAdd);
            break;
        }
      } // addCartDone
      
      function addCartStep2() {
        let itmId = refItmId.value;
        console.log('cpnItmRec.addCartStep2: DEBUG: itmId='+itmId+', clientId='+refClientRec.value.id);  // prov_debug
        
        let itmCode;
        let index = refMenuRecs.value.findIndex(function(item){ return item.id == itmId});

        if (index >= 0) {
          let tmpQty = 0;
          let iQty = 1 * refMenuRecs.value[index].iQty;				// Qty to add to order
          let iInvQty = 1* refMenuRecs.value[index].iInvQty;		// Qty in stock
          itmCode = refMenuRecs.value[index].sItmCode1;

          if (refCartItemRecs.value && (refCartItemRecs.value.length > 0)) {
            let cartItemRecsIdx = refCartItemRecs.value.findIndex(function(item){ 
              // console.log('cpnItmRec.addCartStep2: DEBUG: sItmCode1='+item.sItmCode1+', itmCode='+itmCode);  // prov_debug
              return item.sItmCode1 == itmCode; // sItmCode1
            });
            if (cartItemRecsIdx >= 0) {
              tmpQty = 1 * refCartItemRecs.value[cartItemRecsIdx].iQtyOrd;	// Qty already in order
            }
          }
          // refFlgCheckIry,			// Enable/Disable inventory verification // =true:check iInvQty, =false:do NOT check iInvQty
          console.log('cpnItmRec.addCartStep2: DEBUG: itmId='+itmId+', itmCode='+itmCode+', iQty='+iQty+' + '+tmpQty+' / '+iInvQty + ', refFlgCheckIry='+refFlgCheckIry.value);  // prov_debug
          if ((refFlgCheckIry.value == false) || ((iQty + tmpQty) <= iInvQty)) {
            // OK: There is enough qty in stock (begin)
            refMenuRecs.value[index].iQty = 1;
            let myParams = {
              fct: 'insert',
              idClient: refClientRec.value.id,    // Client's ID
              idItem: itmId,                      // Item's ID
              qtyItem: iQty,                      // Nb items
            };
            fctCartAddItem(myParams);
            ascCartSaveRec(myParams, addCartDone); // Callback is required if altering the record is required at loading
            // OK: There is enough qty in stock (end)
          } else {
            // KO: Not enough qty in stock (begin)
            let msgData = refDicoAttr.value.errCartItmQtyNoStock;
            let errMsg  = msgData.replaceAll('XX', ''+iInvQty);
            alert(errMsg);
            // KO: Not enough qty in stock (begin)
          }
        
        } else {
            alert(refDicoAttr.value.errCartAdd);
        }
        

      } // addCartStep2
      
      function addCartStep1Callback(prmResp) {
        if (prmResp.resp.errCode == 1) {
          // Record loaded from the server. Update data of the record (begin)
          // console.log('cpnItmRec.addCartStep1Callback: DEBUG: refRecStr='+JSON.stringify(prmResp.rec));  // prov_debug
          // ==> 

          fctRfshItem(null, prmResp.rec);
          addCartStep2();
          // Record loaded from the server. Update data of the record (end)
        }
      } // addCartStep1
      
      function addCart(itmId) {
        console.log('cpnItmRec.addCart: DEBUG: itmId='+itmId+', clientId='+refClientRec.value.id);  // prov_debug
        refItmId.value = itmId;
        let myParams = {
          id: refItmId.value,
        };
        ascItemFindRec(myParams, addCartStep1Callback); // Callback is required if altering the record is required at loading
      } // addCart
      
      function insPreferredDone(myResp) {
        console.log('cpnItmRec.insPreferredDone: myRespStr='+JSON.stringify(myResp)); // prov_debug
        // ==> myRespStr={"resp":{"errCode":501,"errMsg":"Invalide function"}}
        // ==> myRespStr={"resp":{"errCode":502,"errMsg":"502:Could not save record"}}
        // ==> myRespStr={"resp":{"errCode":1,"errMsg":"OK"}}
        
        switch(myResp.resp.errCode) {
          case 1: // OK: done successfully
            refResp.value.errCode = myResp.resp.errCode;
            refResp.value.errMsg = myResp.resp.errMsg;
            alert(refDicoAttr.value.msgPreferredAdded);
            break;
          default: // errMsg501 = 'Invalide function';       // Unexpected function
            alert(refDicoAttr.value.errPreferredAdd);
            break;
        }
      } // insPreferredDone
      
      function insPreferred() {
        console.log('cpnItmRec.insPreferred: id='+refItmRec.value.id+', sItmCode1='+refItmRec.value.sItmCode1);  // prov_debug

          // Submit request to remote REST API services to get record matching the provided id
          let tmpRec = {};
          tmpRec.errCode = 0; // Reset errCode
          tmpRec.idParent = refClientRec.value.id;
          tmpRec.idItem = refItmRec.value.id;
          let myParams = {
              fct: 'insert',
              rec: tmpRec,
          };
          ascPreferredSaveRec(myParams, insPreferredDone); // Callback is required if altering the record is required at loading
      } // insPreferred

      function addPreferredDone(myResp) {
        console.log('cpnItmRec.addPreferredDone: myRespStr='+JSON.stringify(myResp)); // prov_debug
        // Already recorded: ==> myRespStr={"resp":{"errCode":1,"errMsg":"OK"},"rec":{"id":1,"idParent":1,"idItem":222,"sPassword":"","sPassword2":""}}
        // Not yet recorded: ==> myRespStr={"resp":{"errCode":101,"errMsg":"Record not found"},"rec":{}}

        switch(myResp.resp.errCode) {
          case 1: // Item already registered 
            refResp.value.errCode = 1;
            refResp.value.errMsg = "";
            alert(refDicoAttr.value.msgPreferredExist);
            break;
          case 101: // Item not yet registered. Add it
            refResp.value.errCode = 1;
            refResp.value.errMsg = "";
            insPreferred();
            break;
          default: // errMsg501 = 'Invalide function';       // Unexpected function
            alert(refDicoAttr.value.errPreferredAdd);
            break;
        }
      }
      
      function addPreferred(itmId) {
        console.log('cpnItmRec.addPreferred: DEBUG: itmId='+itmId+', clientId='+refClientRec.value.id);  // prov_debug
        
        if (refClientRec.value.id) {
          let tmpRec = {};
          tmpRec.errCode = 0; // Reset errCode
          tmpRec.idParent = refClientRec.value.id;
          tmpRec.idItem = itmId;
          
          // Submit request to remote REST API services to get record matching the provided id
          let sCriteria = "idParent = "+refClientRec.value.id+" AND idItem = "+itmId;
          let myParams = {
              criteria: sCriteria,
          };
          ascPreferredFindRec(myParams, addPreferredDone); // Callback is required if altering the record is required at loading
        } else {
          // Client need to login before adding preferred item
          // openPage('pgClientLogin');
          let query = {name:'pgClientLogin',params:{pgAction:'init',prmId:''}};
          tlsNavTo(query);
        }
      } // addPreferred

     /**** With callback (begin) ****/
      // Callback is required if altering the record is required at loading
      function reqPreferredCallback(myResp) {
        console.log('cpnItmRec.reqPreferredCallback: DEBUG: myRespStr='+JSON.stringify(myResp));  // prov_debug
        // ==> 
        
        refFlgPreferred.value = true;
        refMenuRecs.value.length = 0;
        refPreferredRecs.value.map(function(objElement, objIdx, objArray) {
          objElement.iQty = 1;
          refMenuRecs.value.push(objElement);
        });

      }
     /**** With callback (end) ****/

      function delPreferredDone(myResp) {
        console.log('vwsPreferred.delPreferredDone: myRespStr='+JSON.stringify(myResp)); // prov_debug
        // ==> myRespStr={"resp":{"errCode":501,"errMsg":"Invalide function"}}
        // ==> myRespStr={"resp":{"errCode":502,"errMsg":"502:Could not save record"}}
        // ==> myRespStr={"resp":{"errCode":1,"errMsg":"OK"}}
        
        let reqParams = { 
              criteria: "idParent = "+refClientRec.value.id, 
              orderby: "sItmCode1 ASC",
              };
        switch(myResp.resp.errCode) {
          case 1: // OK: done successfully
            refResp.value.errCode = myResp.resp.errCode;
            refResp.value.errMsg = myResp.resp.errMsg;
            
            // KO ascPreferredGetItemRecs(reqParams);  // KO // Without callback, the record can not be altered at loading
            ascPreferredGetItemRecs(reqParams, reqPreferredCallback);  // Callback is required if altering the record is required at loading
        
            alert(refDicoAttr.value.msgPreferredDeleted);
            break;
          case 501: // errMsg501 = 'Invalide function';       // Unexpected function
            alert(refDicoAttr.value.errPreferredDel);
            break;
        }
      } // delPreferredDone

      function delPreferred(itmId) {
        console.log('cpnItmRec.delPreferred: DEBUG: itmId='+itmId+', clientId='+refClientRec.value.id);  // prov_debug
        
        if (refClientRec.value.id) {
          let tmpRec = {};
          tmpRec.errCode = 0; // Reset errCode
          tmpRec.idParent = refClientRec.value.id;
          tmpRec.idItem = itmId;
          
        // Submit request to remote REST API services to get record matching the provided id
        let sCriteria = "idParent = "+refClientRec.value.id+" AND idItem = "+itmId;
        let myParams = {
            fct: 'delete',
            criteria: sCriteria,
        };
        ascPreferredDeleteRec(myParams, delPreferredDone); // Callback is required if altering the record is required at loading
        } else {
          // Client need to login before adding preferred item
          // openPage('pgClientLogin');
          let query = {name:'pgClientLogin',params:{pgAction:'init',prmId:''}};
          tlsNavTo(query);
        }
      } // delPreferred


      function openItem(recId, itmCode1) {
        console.log('cpnItmLst.openItem: recId='+recId+', itmCode1='+itmCode1);
        // this.$router.push({name:'pgItemFrm',params:{pgAction:'init',prmId:recId}});
        // router.push({name:'pgItemFrm',params:{pgAction:'init',prmId:recId}});
        
        let query = {name:'pgItemFrm',params:{pgAction:'init',prmId:recId,prmCode:itmCode1}};
        tlsNavTo(query);
      }
      
      return {
        refFlgCheckIry, // Enable/Disable inventory verification // =true:check iInvQty, =false:do NOT check iInvQty
        refDicoAttr,
        refItmTypeAttr,
        refItmRec,
        refFlgPreferred,
        qtyMinus,
        qtyPlus,
        qtySet,
        addCart,
        addPreferred,
        delPreferred,
        openItem,
      }
    },
    
  }
    
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  
  
</style>
