<template>
  <span v-if="refDicoLoading == false">
 
    
  <div id="cpnFooter">

    <br>
    <table width="100%" border="0">
      <tr>
        <td width="40%">
        <table width="100%" border="0">
          <tr>
            <td width="30%" nowrap="1">
              &nbsp;
            </td>
            <td width="30%" nowrap="1">
              <a v-on:click="openPage('pgP3')">{{ refDico.lblp3 }}</a>
            </td>
            <td width="30%" nowrap="1">
              <a v-on:click="openPage('pgP4')">{{ refDico.lblp4 }}</a>
            </td>
          </tr>      
          <tr>  
            <td width="30%" nowrap="1">
              &nbsp;
            </td>
            <td width="30%" nowrap="1">
              <a v-on:click="openPage('pgP1')">{{ refDico.lblp1 }}</a>
            </td>
            <td width="30%" nowrap="1">
              <a v-on:click="openPage('pgP2')">{{ refDico.lblp2 }}</a>
            </td>
          </tr>
          <tr>
            <td width="30%" nowrap="1">
              &nbsp;
            </td>
            <td width="30%" nowrap="1">
              <a v-on:click="openPage('pgP5')">{{ refDico.lblp5 }}</a>
            </td>
            <td width="30%" nowrap="1">
              <a href="https://lin.ee/ZNTqGA6" target="linkLine"><img src="/img/logoLine.png" alt="Add friend" border="0"></a>
            </td>
          </tr>
        </table>
        </td>
        <td width="60%">
          {{ refDico.lblShippingInfo1 }}
          <br>{{ refDico.lblShippingInfo2 }}
          <br>{{ refDico.lblShippingInfo3 }}
        </td>
      </tr> 
    </table>
    
    <br>&nbsp;

    <hr>
    
    <table width="100%">
      <tr>
        <td width="50%" >© 2020-2021 - DaoZhu. All rights reserved.</td>
        <td align="right"><a href="https://enovnow.com">Designed and hosted by enovnow.com&nbsp;</a></td>
      </tr>
    </table>
    
  </div>
  
  </span>
  <span v-else><br><br>Loading ...<br><br></span>
</template>

<script>
  import {
    ref, computed, onActivated, onBeforeMount, onBeforeUnmount, onBeforeUpdate,
    onDeactivated, onErrorCaptured, onMounted, onUnmounted, onUpdated, onRenderTracked, onRenderTriggered
  } from 'vue';
  import useReferential from '@/modules/mdlReferential.js';
  import router from '@/router/index.js';
  
  export default {
    setup() {
      const { refDicoLoading, refDicoLang, refDicoAttr, refPageAttr, refDepartmentAttr, ascSetLang, fctExtractState, fctExtractCity } = useReferential();
      let refDico = ref({});
      
      onMounted(async () => {
        console.log('cpnFooter.onMounted: ');
        // await ascSetLang('tw', reqCallback);
        
        refDico.value = refDicoAttr.value;
        refPageAttr.value.title = refDico.value.tleCart;
      }); // onMounted
      
      /**** With callback (begin) ****/
      function reqCallback(resp) {
        // console.log('cpnFooter.reqCallback: DEBUG: respStr='+JSON.stringify(resp));  // debug
        // refDico.value = resp.recDico;
        // console.log('cpnFooter.reqCallback: refDicoLoading='+refDicoLoading+', tleShopHome='+refDico.value.tleShopHome);
      }
      /**** With callback (end) ****/
      
      function openPage(pgName) {
        console.log('cpnHeader.openPage: pgName='+pgName);
        router.push({name:pgName,params:{pgAction:'init',prmId:''}});
      }
        
      return {
        refDicoLoading,
        // refDico: computed(() => refDicoAttr.value),
        openPage,
        refDico,
      }
    },
    
  }
    
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  
  
</style>
