<template>

  <div >
    
    <center>
    <br>
    
    <span v-if="refOrdersLoading"><br><br>Loading ...<br><br></span>
    <span v-else>

        <center>

      <form novalidate @submit.prevent="onSubmit" >
        <table border="0">
          
          <tr>
            <td>
              <!-- order (begin)-->
              <table border="0" width="100%">
                <tr>
                  <td nowrap>{{ refDico.lblDtOrder }} : </td>
                  <td nowrap>{{ formatDate(refRec.dtmCreate) }}</td>
                  <td width="5%">&nbsp;</td>
                  <td nowrap>{{ refDico.lblpaymentmethod }} : </td>
                  <td nowrap>{{ refPaymMethods[ 1*refRec.paymentMode ] }}</td>
                  <td width="5%">&nbsp;</td>
                  <td nowrap>{{ refDico.lbkDtReturn1 }} : </td>
                  <td nowrap>{{ formatDate(refRec.dtmReturn1) }}</td>
                </tr>
               
                <tr>
                  <td nowrap>{{ refDico.lblOrdId }} : </td>
                  <td nowrap>{{ refRec.sOrdCode }}</td>
                  <td>&nbsp;</td>
                   
                   <td nowrap>
                    <!-- 0:idel, 1:Pending, 2:Packaging, 3:Ready, 4:Cancel, 5:Delivering, 6:Delivered, 7:retRequest, 8:returning, 9:retDone -->
                    <span  v-if="refRec.iStatus == 9"> {{ refDico.lblInvRetId }} :</span>
                    <span  v-else> {{ refDico.lblInvId }} :</span>
                  </td>
                  <td nowrap>
                    <span  v-if="refRec.iStatus == 9">{{ refRec.sEtkInvCode2 }}</span>
                    <span  v-else>{{ refRec.sEtkInvCode }}</span>
                  </td>
                  <td>&nbsp;</td>
                  
                  <td nowrap>{{ refDico.lbkDtReturn2 }} : </td>
                  <td nowrap>{{ formatDate(refRec.dtmReturn2) }}</td>
                  
                </tr>
                
                <!-- 0:idel, 1:Pending, 2:Packaging, 3:Ready, 4:Cancel, 5:Delivering, 6:Delivered, 7:retRequest, 8:returning, 9:retDone -->
                
                
                <tr>
                  <td nowrap>{{ refDico.lblDtDelivery }} : </td>
                  <td nowrap>
                    <span v-if="refRec.dtmDel1"> {{ formatDate(refRec.dtmDel1) }} </span>
                    <span v-else>
                      <span v-if="refRec.dtmDel2"> {{ formatDate(refRec.dtmDel2) }} </span>
                      <span v-else>
                        {{ formatDate(refRec.dtmDelivery) }}
                      </span>
                    </span>
                  </td>
                  <td>&nbsp;</td>
                  <td nowrap>{{ refDico.lblDtInformation }} : </td>
                  
                  <td> 
                    <span v-if="refRec.invoiceMode == 1"> {{ refDico.lblinvoice1 }} </span>
                    <span v-if="refRec.invoiceMode == 2"> {{ refDico.lblinvoice2 }} </span>
                    <span v-if="refRec.invoiceMode == 3"> {{ refDico.lblinvoice3 }} </span>
                    <span v-if="refRec.invoiceMode == 4"> {{ refDico.lblinvoice4 }} </span>
                    <span v-else>&nbsp;</span>
                  </td>
                  <td>&nbsp;</td>
                  <td nowrap>{{ refDico.lblDtReceiver }} : {{ refRec.sDelFirstName }}</td>
                </tr>
                
                <tr>
                  <td nowrap><a target="pgShipping" href="https://postserv.post.gov.tw/pstmail/main_mail.html">{{ refDico.lblDelCode1 }} : </a></td>
                  <td nowrap>{{ refRec.sDelCode1 }} &nbsp;&nbsp;  {{ refDico.lblDelQty }} : {{ refRec.iPkgQty1 }} </td>
                  <td>&nbsp;</td>
                  <td nowrap><a target="pgShipping" href="https://www.t-cat.com.tw/inquire/trace.aspx">{{ refDico.lblDelCode2 }} :</a></td>
                  <td nowrap>{{ refRec.sDelCode2 }} &nbsp;&nbsp; {{ refDico.lblDelQty }} : {{ refRec.iPkgQty2 }} </td>
                  <td>&nbsp;</td>
                  <td colspan="2"> {{ refRec.sDelZipCode }} {{ getStateLbl(refRec.sDelProvince) }} {{ getCityLbl(refRec.sDelProvince, refRec.sDelCity) }} {{ refRec.sDelAddr1 }}</td>
                </tr>

              </table>
              <!-- order (end)-->
            </td>
          </tr>
          
          <tr>
            <td>
              <!-- items (begin) -->
              <table  class="cpnLst01" width="100%">
                <thead>
                <tr>
                  <th colspan="7">{{ refDico.tleShopOrder }}</th>
                  <th colspan="2"  v-if="(refPgAction == 'return' || refRec.iStatus >= 7)">{{ refDico.tleShopReturn }}</th>
                </tr>
                <!-- 0:idel, 1:Pending, 2:Packaging, 3:Ready, 4:Cancel, 5:Delivering, 6:Delivered, 7:retRequest, 8:returning, 9:retDone -->
                <tr>
                  <th>{{ refDico.lblItmCode1 }}</th>
                  <th>{{ refDico.lblItmLabel }}</th>
                  <th>{{ refDico.lblItmOrdQty }}</th>
                  <th>{{ refDico.lblItmFreeQty }}</th>
                  <th>{{ refDico.lblItmDelQty }}</th>
                  <th>{{ refDico.lblItmPrice }}</th>
                  <th>{{ refDico.lblItmTotal }}</th>
                  <th  v-if="(refPgAction == 'return' || refRec.iStatus >= 7)">{{ refDico.lblItmQty }}</th>
                  <th  v-if="(refPgAction == 'return' || refRec.iStatus >= 7)">{{ refDico.lblItmTotal }}</th>
                </tr>
                </thead>

                <tbody>

                  <!-- Regular items -->
                  <tr v-for="(tmpRec, tmpRecIdx) in refItmRecs" :key="tmpRec.id" >
                    <!-- td align="center">{{ tmpRecIdx+1 }}</td -->
                    <td>{{ tmpRec.sItemCode1 }}</td>
                    <td nowrap>
                      <span v-if="tmpRec.iIsPromo == 1">{{ tmpRec.sLabel }} {{ refDico.flgPromo }}</span>
                      <span v-else>{{ tmpRec.sLabel }}</span>
                      <br>{{ tmpRec.sSizeCode }}
                    </td>
                    <td align="right" nowrap>&nbsp;{{ tmpRec.iQtyOrd }}&nbsp;{{ refItmTypeAttr[tmpRec.sTypeCode] }}</td>
                    <td align="right">&nbsp;</td>
                    <td align="right">
                         {{ tmpRec.iQtyDel }}
                    </td>
                    <td align="right">&nbsp;{{ tmpRec.iPrice3 }}</td>
                    <td align="right">&nbsp;{{ tmpRec.iTotalOrd }}</td>
                    <td align="right" v-if="(refPgAction == 'return' || refRec.iStatus >= 7)">
                        <span v-if="tmpRec.sGrpCode.includes('R')">
                          <span v-if="refRec.iStatus == 9">
                            {{ tmpRec.iQtyRet }}
                          </span>
                          <span v-else>
                            <input type="text" style="width:40px;" v-model=" tmpRec.iQtyRet" v-on:change="updateQtyRet(0, tmpRecIdx, tmpRec.id, tmpRec.iQtyOrd, tmpRec.iQtyRet, tmpRec.iPrice3);"  />
                          </span>
                        </span>
                        <span v-else>&nbsp;</span>
                    </td>
                    <td align="right"  v-if="(refPgAction == 'return' || refRec.iStatus >= 7)">&nbsp;{{ tmpRec.iTotalRet }}</td>
                  </tr>
                  <!-- Regular free items -->
                  <tr v-for="(tmpRec) in refFreeItmRecs" :key="tmpRec.id" style="background-color: #F0F0F0;" >
                    <td>{{ tmpRec.sItemCode1 }}</td>
                    <td nowrap>{{ tmpRec.sLabel }}&nbsp;<br>{{ tmpRec.sSizeCode }}</td>
                    <td align="right" nowrap>&nbsp;</td>
                    <td align="right">&nbsp;{{ tmpRec.iQtyFree }}&nbsp;{{ refItmTypeAttr[tmpRec.sTypeCode] }}</td>
                    <td align="right">
                         {{ tmpRec.iQtyDel }}
                    </td>
                    <td align="right">&nbsp;{{ tmpRec.iPrice3 }}</td>
                    <td align="right">&nbsp;{{ tmpRec.iTotalOrd }}</td>
                    <td align="right" v-if="(refPgAction == 'return' || refRec.iStatus >= 7)">&nbsp;{{ tmpRec.iQtyRet }}</td>
                    <td align="right"  v-if="(refPgAction == 'return' || refRec.iStatus >= 7)">&nbsp;</td>
                  </tr>

                  <tr>
                    <td colspan="10"><hr></td>
                  </tr>
                  <!-- Frozen items -->
                  <tr v-for="(tmpRec, tmpRecIdx) in refItmFznRecs" :key="tmpRec.id" >
                    <!-- td align="center">{{ tmpRecIdx+1 }}</td -->
                    <td>{{ tmpRec.sItemCode1 }}</td>
                    <td nowrap>
                      <span v-if="tmpRec.iIsPromo == 1">{{ tmpRec.sLabel }} {{ refDico.flgPromo }}</span>
                      <span v-else>{{ tmpRec.sLabel }}</span>
                      <br>{{ tmpRec.sSizeCode }}
                    </td>
                    <td align="right" nowrap>&nbsp;{{ tmpRec.iQtyOrd }}&nbsp;{{ refItmTypeAttr[tmpRec.sTypeCode] }}</td>
                    <td align="right">&nbsp;</td>
                    <td align="right">
                         {{ tmpRec.iQtyDel }}
                    </td>
                    <td align="right">&nbsp;{{ tmpRec.iPrice3 }}</td>
                    <td align="right">&nbsp;{{ tmpRec.iTotalOrd }}</td>
                    <td align="right" v-if="(refPgAction == 'return' || refRec.iStatus >= 7)">
                        <span v-if="tmpRec.sGrpCode.includes('R')">
                          <input type="text" style="width:40px;" v-model=" tmpRec.iQtyRet" v-on:change="updateQtyRet(1, tmpRecIdx, tmpRec.id, tmpRec.iQtyOrd, tmpRec.iQtyRet, tmpRec.iPrice3);"  />
                        </span>
                        <span v-else>&nbsp;</span>
                    </td>
                    <td align="right"  v-if="(refPgAction == 'return' || refRec.iStatus >= 7)">&nbsp;{{ tmpRec.iTotalRet }}</td>
                  </tr>
                  <!-- Frozen free items -->
                  <tr v-for="(tmpRec) in refFreeItmFznRecs" :key="tmpRec.id" style="background-color: #F0F0F0;" >
                    <td>{{ tmpRec.sItemCode1 }}</td>
                    <td nowrap>{{ tmpRec.sLabel }}&nbsp;<br>{{ tmpRec.sSizeCode }}</td>
                    <td align="right" nowrap>&nbsp;</td>
                    <td align="right">&nbsp;{{ tmpRec.iQtyFree }}&nbsp;{{ refItmTypeAttr[tmpRec.sTypeCode] }}</td>
                    <td align="right">
                         {{ tmpRec.iQtyDel }}
                    </td>
                    <td align="right">&nbsp;{{ tmpRec.iPrice3 }}</td>
                    <td align="right">&nbsp;{{ tmpRec.iTotalOrd }}</td>
                    <td align="right" v-if="(refPgAction == 'return' || refRec.iStatus >= 7)">&nbsp;{{ tmpRec.iQtyRet }}</td>
                    <td align="right"  v-if="(refPgAction == 'return' || refRec.iStatus >= 7)">&nbsp;</td>
                  </tr>

<!-- Total -->
                  <tr style="background-color: #E0E0E0;">
                    <td colspan="6" align="right">{{ refDico.lblOrdAmount }}</td>
                    <td align="right">&nbsp;{{ refRec.iSubTotal }}</td> <!-- iOrdSubTotal -->
                    <td align="right" nowrap  v-if="(refPgAction == 'return' || refRec.iStatus >= 7)">{{ refDico.lblOrdAmount }}</td>
                    <td align="right"  v-if="(refPgAction == 'return' || refRec.iStatus >= 7)">&nbsp;{{ refRec.iRetSubTot }}</td>
                  </tr>
                  <tr v-if="refRec.iPromoTotal > 0" style="background-color: #E0E0E0;">
                    <td colspan="6" align="right">
                      {{ refDico.lblDiscountCode }}: {{ refRec.sPromoCode }} ({{ refRec.iPromoRate }}%)&nbsp;
                    </td>
                    <td align="right">&nbsp;- {{ refRec.iPromoTotal }}</td>
                    <td align="right" nowrap v-if="(refPgAction == 'return' || refRec.iStatus >= 7)">&nbsp;</td>
                    <td align="right" nowrap v-if="(refPgAction == 'return' || refRec.iStatus >= 7)">&nbsp;- {{ refRec.iPromoTotRet }}</td>
                  </tr>

                  <tr style="background-color: #E0E0E0;">
                    <td colspan="6" align="right">{{ refDico.lblOrdDelivery }}</td>
                    <td align="right">&nbsp;{{ refRec.iDelivery }}</td> <!-- iOrdCost -->
					
                    <td align="right" nowrap  v-if="(refPgAction == 'return' || refRec.iStatus >= 7)">{{ refDico.lblOrdDelivery }}</td>
                    <td align="right"  v-if="(refPgAction == 'return' || refRec.iStatus >= 7)">&nbsp;{{ refRec.iRetCost }}</td>
                  </tr>
                  <tr style="background-color: #E0E0E0;">
                    <td colspan="2" align="left">{{ refDico.lblOrdTotal0 }} : {{ refRec.iOrdTotal0 }} {{ refDico.lblDevise }}</td>
                    <td colspan="3" align="right">&nbsp;</td>
                    <td align="right">{{ refDico.lblOrdTotal }}</td>
                    <td align="right">&nbsp;{{ refRec.iTotalTax }}</td>

                    <td align="right" nowrap v-if="(refPgAction == 'return' || refRec.iStatus >= 7)">{{ refDico.lblRetTotal }}</td>
                    <td align="right" v-if="(refPgAction == 'return' || refRec.iStatus >= 7)">&nbsp;{{ refRec.iRetTotal }}</td>
                  </tr>

        <tr v-if="refRec.iOrdTaxRate == 0 || refRec.sDelProvince == 'dep010'" style="background-color: #E0E0E0;">
          <td colspan="7" >{{ refDico.lblDutyFree }}</td>
          <td nowrap colspan="2"  v-if="(refPgAction == 'return' || refRec.iStatus >= 7)">
            {{ refDico.lblOrdTotal3 }}: {{ refRec.iRetSubTotal }} {{ refDico.lblDevise }}
          </td>
        </tr>
        <tr v-else style="background-color: #E0E0E0;">
          <td nowrap colspan="5" >&nbsp;</td>
          <td nowrap colspan="2" >
            {{ refDico.lblTaxable }}
            <br>{{ refDico.lblTax }} {{ refRec.iOrdTaxRate }}% : {{ refRec.iTaxPrice }} {{ refDico.lblDevise }}
            <br>{{ refDico.lblHT }}: {{ refRec.iTotalHT }} {{ refDico.lblDevise }}
          </td>
          <td nowrap colspan="2"  v-if="(refPgAction == 'return' || refRec.iStatus >= 7)">
            {{ refDico.lblOrdTotal3 }}: {{ refRec.iRetSubTotal }} {{ refDico.lblDevise }}
            <br>{{ refDico.lblTax }} {{ refRec.iOrdTaxRate }}% : {{ refRec.iRetTax }} {{ refDico.lblDevise }}
            <br>{{ refDico.lblHT }}: {{ refRec.iRetSubTotal - refRec.iRetTax }} {{ refDico.lblDevise }}
          </td>
        </tr>

                  <tr   v-if="(refPgAction == 'return' || refRec.iStatus >= 7)">
                    <td>{{ refDico.lblOrdRetType }} :</td>
                    <td colspan="8">
                      <!--
                      <select v-model="refRec.returnType" :key="refOrdRetTypes" style="width:100%;" >
                        <option v-for="(name, value) in refOrdRetTypes" :key="value" v-bind:value="value">{{ name }}</option>
                      </select>
                      -->
                      <span v-if="refRec.iOrdTaxRate == 0">
                        {{ refDico.lblOrdRetTypePenghu }}<br>{{ refDico.lblOrdRetTypePgTw }}
                      </span>
                      <span v-if="refRec.iOrdTaxRate > 0">
                        {{ refDico.lblOrdRetTypeTaiwan }}<br>{{ refDico.lblOrdRetTypePgTw }}
                      </span>
                    </td>
                  </tr>
                  <tr   v-if="refPgAction == 'return'">
                    <td colspan="9" align="center"  style="width:100%;">
                      <br>&nbsp;
                      <span v-if="(refRec.iRetTotal > 0 && refRec.dtmReturn == null) || (refRec.iRetTotal == null) || (refRec.iRetTotal == 0)" >
                      <a class="btnStd01" v-on:click="submitOrdRet();" >{{ refDico.lblSubmitRet }}</a>
                      </span>
                    </td>
                  </tr>

                </tbody>
              </table>

              <!-- items (end) -->
            </td>
          </tr>
          <!-- order (end) -->
          
        </table>

      </form>

      <br>&nbsp;
        </center>
    
      <br>&nbsp;

    </span>
    
    </center>

  </div>
  
</template>

<script>
  import { ref, computed, onBeforeMount, onMounted } from 'vue';
  import router from '@/router/index.js';
  import { useRoute } from 'vue-router';
  import useTools from '@/utils/vjsTools.js';
  import useReferential from '@/modules/mdlReferential.js'; 
  import useClientApi from '@/services/svcClientApi.js';
  import useOrdersApi from '@/services/svcOrdersApi.js';

  export default {
    methods: {
      formatDate: function(sDate) {
        if (!sDate) { return ''; }
        // return moment(String(sDate)).format('YYYY/MM/DD');
        let dtDate = new Date(sDate);
        // console.log('vwsPromoLst.formatDate: date='+sDate+' / '+dtDate);
        // console.log('vwsPromoLst.formatDate: year='+dtDate.getFullYear());
        return dtDate.getFullYear() + '/' + 
                   ((dtDate.getMonth() < 9) ? '0' : '') + (dtDate.getMonth() + 1) + '/' +
                   ((dtDate.getDate() < 10) ? '0' : '') + dtDate.getDate();
      }
    },
    setup() {
      const route = useRoute();
      const { refDicoLang, refDicoAttr, refPageAttr, refItmTypeAttr, ascSetLang, refSlctMenu, fctExtractMenu, fctExtractSubmenu, fctExtractState, fctExtractCity } = useReferential();
      let { tlsGetDateStr } = useTools();
      let { ascClientFindRec, ascClientSaveRec, ascClientDeleteRec, refClientResp, refClientLoading, refClientRec } = useClientApi();
      let { fctGetDateStr, ascOrdersGetRecs, ascOrdersGetItemRecs, ascOrdersFindRec, ascOrdersSaveRec, ascOrdersDeleteRec, ascDeliverOrdItms, refOrdersResp, refOrdersLoading, refOrdersRecs, refOrdersRec } = useOrdersApi();
      
      let refDico = ref({});
      let refResp = ref({});
      let refRec = ref({});
      let refItmRecs = ref([]);
      let refItmFreeRecs = ref([]);
      
      let refItmFznRecs = ref([]);
      let refFreeItmRecs = ref([]);
      let refFreeItmFznRecs = ref([]);
      let refOrdStatus = ref([]);
      let refPaymMethods = ref([]);	// paymentMode=[1..4] :Payment methods
      // let refDelMethods = ref([]);		// deliveryMode=[] :Type of delivery
      let refOrdRetTypes = ref([]);		// returnMode :Raison of return
      let refPgAction = ref('');
      let refState = ref({});
      let refCity = ref({});
      
      onBeforeMount(() => {
        // this.$router.push({name:'pgItemFrm',params:{pgAction:'init',itmLstFilter:'myItmLstFilter'}});
        
        console.log('vwsOrderFrm.onBeforeMount: DEBUG: refClientRecId='+refClientRec.value.id);  // prov_debug
        if (refClientRec.value.id) {
          refPageAttr.value.pgAction = 'init';
          refPageAttr.value.pgName = 'pgOrders';
        } else {
          // Client need to login before displaying orders
          openPage('pgClientLogin');
        }
      });
      
      onMounted(async () => {
        refDico.value = refDicoAttr.value;
        refPageAttr.value.title = refDico.value.tleCliOrders;
        
        refOrdStatus.value.length = 0;
        refOrdStatus.value.push(refDico.value.lblOrdStatus0);
        refOrdStatus.value.push(refDico.value.lblOrdStatus1);
        refOrdStatus.value.push(refDico.value.lblOrdStatus2);
        refOrdStatus.value.push(refDico.value.lblOrdStatus3);
        refOrdStatus.value.push(refDico.value.lblOrdStatus4);
        refOrdStatus.value.push(refDico.value.lblOrdStatus5);
        refOrdStatus.value.push(refDico.value.lblOrdStatus6);
        refOrdStatus.value.push(refDico.value.lblOrdStatus7);
        refOrdStatus.value.push(refDico.value.lblOrdStatus8);
        refOrdStatus.value.push(refDico.value.lblOrdStatus9);
        
        refPaymMethods.value.length = 0;
        refPaymMethods.value.push(" ");	// idx 0
        refPaymMethods.value.push(refDico.value.lblpayment1);	// idx 1: Pay at the store
        refPaymMethods.value.push(refDico.value.lblpayment2);	// idx 2: Cash on delivery
        refPaymMethods.value.push(refDico.value.lblpayment3);	// idx 3: Credit card 
        refPaymMethods.value.push(refDico.value.lblpayment4);	// idx 4: Taiwan Pay
        refPaymMethods.value.push(refDico.value.lblpayment5);	// idx 5: Credit card KO
        refPaymMethods.value.push(refDico.value.lblpayment6);	// idx 6: Taiwan Pay KO
        
        refOrdRetTypes.value = {
          '0': ' ',
          '1': refDico.value.lblOrdRetType1,
          '2': refDico.value.lblOrdRetType2,
          '3': refDico.value.lblOrdRetType3,
          // '4': refDico.value.lblOrdRetType4,
        };
        
        console.log('vwsOrderFrm.onMounted: DEBUG: ordId='+route.params.prmId+', pgAction='+route.params.pgAction);  // prov_debug
        // ==> ordId=2, pgAction=return
        refPgAction.value = route.params.pgAction;	// 'init' / 'return'

        let reqParams = { 
              id: route.params.prmId,
        };
        ascOrdersFindRec(reqParams, reqCallback);  // Callback is required if altering the record is required at loading
      });
      
      /**** Without callback (begin) ****
      // Without callback, the record can not be altered at loading
      refResp = computed(() => {
        console.log('vwsOrderFrm.computed: DEBUG: refResp=refItemRespStr='+JSON.stringify(refItemResp.value));  // prov_debug
        return refItemResp.value;
      });
      **** Without callback (end) ****/
      
      /**** With callback (begin) ****/
      // Callback is required if altering the record is required at loading
      function reqCallback(myResp) {
        
        refResp.value = myResp.resp;
        refRec.value = refOrdersRec.value;
        // console.log('vwsOrderFrm.reqCallback: DEBUG: refRecStr='+JSON.stringify(refRec.value));  // prov_debug
        // ==> efRecStr={"id":111,"idClient":1,"sLabel":null,"iStatus":null,"dtmCreate":"2022-07-12 14:32:38","dtmModif":null,"sOrdCode":"220712143238101","iOrdSubTotal":18,"iOrdCost":0,"iOrdTaxRate":0,"iOrdTax":0,"iOrdTotal":18,"iItmNb":1,"sDelProvince":"\"dep010\"","sDelCity":"\"cty0110\"","sDelZipCode":"\"99000\"","sDelAddr1":"\"1 bd Elysée\"","sDelAddr2":null,"sDelMobile":"\"cli123456789\"","sDelGenderCode":"\"M\"","sDelFirstName":"\"Cli JackBis\"","sDelLastName":null,"deliveryMode":"\"b\"","sAssociation":"\"1\"","paymentMode":"\"4\"","invoiceMode":"\"1\"","dtmDelivery":null,"sDelCode":null,"dtmEtkInv":null,"sEtkInvCode":null,"dtmReturn":null,"dtmEtkCancel":null,"dtmEtkInv2":null,"sEtkInvCode2":null,"dtmCancel":null}

        console.log('vwsOrderFrm.reqCallback: DEBUG: respStr='+JSON.stringify(myResp.resp));  // prov_debug
        // ==> respStr={"errCode":1,"errMsg":"OK"}
        console.log('vwsOrderFrm.reqCallback: DEBUG: errCode='+refResp.value.errCode+', ordId='+refRec.value.id);  // prov_debug
        // ==> errCode=1, ordId=110

          if (refResp.value.errCode == 1) {
            // Record loaded correctely. Reset error message
            refResp.value.errCode = 0;
            refResp.value.errMsg = '';
          
            let myParams = {
              id: refRec.value.id,
            };
            ascOrdersGetItemRecs(myParams, reqCallbackItems);
        }
      }
      /**** With callback (end) ****/
      
      function reqCallbackItems(myResp) {
        // Set data of the record
        refResp.value = myResp.resp;
        
        // console.log('vwsOrderFrm.reqCallbackItems: DEBUG: myRespStr='+JSON.stringify(myResp));  // prov_debug
        // ==> myRespStr={"resp":{"errCode":1,"errMsg":"OK"},"recs":[{"id":115,"idParent":110,"idItem":149,"sLabel":null,"iStatus":null,"dtmCreate":"2022-07-12 14:28:58","dtmModif":null,"sItmCode1":"4710088435767","iQtyOrd":1,"iQtyDel":null,"iQtyRet":null,"iPrice":18,"iTotalOrd":18,"iTotalRet":null}]}
        
        switch(refResp.value.errCode) {
          case 1: // OK
            refItmRecs.value.length = 0;
            refItmFznRecs.value.length = 0;
            refFreeItmRecs.value.length = 0;
            refFreeItmFznRecs.value.length = 0;
/*
            // console.log('vwsOrderFrm.reqCallbackItems: DEBUG: myRespRecsStr='+JSON.stringify(myResp.recs));  // prov_debug
            // refItmRecs.value = myResp.recs;
            // Items ordered
            refItmRecs.value = [];
            refItmRecs.value.length = 0;
            myResp.recs.map(function(objElement, objIdx, objArray) {
              // objIdx = [0 ... newObj.length[
              if (objElement.iQtyOrd > 0) {
                let tmpItemRec = {
                  id:         objElement.id,
                  sItmCode1:  objElement.sItmCode1,
                  sLabel:  objElement.sLabel,
                  iQtyOrd:    objElement.iQtyOrd,
                  iQtyFree:    objElement.iQtyFree,
                  iQtyDel:    objElement.iQtyDel,
                  iPrice3:    objElement.iPrice3,
                  iTotalOrd:  objElement.iTotalOrd,
                  iQtyRet:    objElement.iQtyRet,
                  iTotalRet:  objElement.iTotalRet,
                  sFreeItmCode1: objElement.sFreeItmCode1,
                  iFreeQty: objElement.iFreeQty,
                };
                refItmRecs.value.push(tmpItemRec);
                }
              });
            // Items free
            refItmFreeRecs.value = [];
            refItmFreeRecs.value.length = 0;
            myResp.recs.map(function(objElement, objIdx, objArray) {
              // objIdx = [0 ... newObj.length[
              if (objElement.iQtyOrd == 0) {
                let tmpItemRec = {
                  id:         objElement.id,
                  sItmCode1:  objElement.sItmCode1,
                  sLabel:  objElement.sLabel,
                  iQtyOrd:    objElement.iQtyOrd,
                  iQtyFree:    objElement.iQtyFree,
                  iQtyDel:    objElement.iQtyDel,
                  iPrice3:    objElement.iPrice3,
                  iTotalOrd:  objElement.iTotalOrd,
                  iQtyRet:    objElement.iQtyRet,
                  iTotalRet:  objElement.iTotalRet,
                };
                refItmFreeRecs.value.push(tmpItemRec);
                }
              });
*/

            myResp.recs.map(function(objElement, objIdx, objArray) {
              // console.log('vwsReturnFrm.reqCallbackItems: DEBUG: objElementStr='+JSON.stringify(objElement));  // prov_debug

              if (objElement.iQtyOrd == 0) {
                // Free items (begin)
                if (objElement.iFlgFzn == 1) {
                  refFreeItmFznRecs.value.push(objElement);
                } else {
                  refFreeItmRecs.value.push(objElement);
                }
                // Free items (end)
              } else {
                // Ordered items (begin)
                if (objElement.iFlgFzn == 1) {
                  refItmFznRecs.value.push(objElement);
                } else {
                  refItmRecs.value.push(objElement);
                }
                // Ordered items (end)
              }

            });

            break;
          default:  // Error occurred
            refItmRecs.value.length = 0;
            refItmFznRecs.value.length = 0;
            refFreeItmRecs.value.length = 0;
            refFreeItmFznRecs.value.length = 0;
            break;
        }
      }	// reqCallbackItems
      
      function updateQtyRet(iFlgFzn, itmIdx, itmId, itmQtyOrd, itmQtyRet, itmPrice) {
        let iNbFreeQty = 0;
        let iPromoQty;
        let iFreeQty;
        let iDiscount;

         itmQtyOrd = 1 * itmQtyOrd;
         itmQtyRet = 1 * itmQtyRet;
         itmPrice = 1 * itmPrice;
         console.log('vwsOrderFrm.updateQtyRet: DEBUG: itmIdx='+itmIdx+', itmId='+itmId+', itmQtyOrd='+itmQtyOrd+', itmQtyRet='+itmQtyRet);  // prov_debug
         if (itmQtyRet > itmQtyOrd) {
           itmQtyRet = itmQtyOrd;
           if (iFlgFzn == 1)
             refItmFznRecs.value[itmIdx].iQtyRet = itmQtyRet;
           else
             refItmRecs.value[itmIdx].iQtyRet = itmQtyRet;
         }
         let iTotalRet = 1 * itmQtyRet * itmPrice;
         if (iFlgFzn == 1) {
           // FZN item (begin)
           if (iTotalRet > 0) {
             refItmFznRecs.value[itmIdx].iTotalRet = 1 * itmQtyRet * itmPrice;
             if ((!refItmFznRecs.value[itmIdx].iIsPromo || refItmFznRecs.value[itmIdx].iIsPromo == 0) && (refRec.value.iPromoTotal > 0)) {
               // Discount is applied only to item not in promo (begin)
              iDiscount = parseInt((refItmFznRecs.value[itmIdx].iTotalRet * refRec.value.iPromoRate) / 100);
              // refItmFznRecs.value[itmIdx].iTotalRet  -= iDiscount;
              refRec.value.iPromoTotRet  += iDiscount;	// amount of promo not to return
              // Discount is applied only to item not in promo (end)
             }
           } else {
             refItmFznRecs.value[itmIdx].iTotalRet = null;
           }
           iPromoQty = refItmFznRecs.value[itmIdx].iPromoQty;
           iFreeQty = refItmFznRecs.value[itmIdx].iFreeQty;
           console.log('vwsOrderFrm.updateQtyRet: DEBUG: FZN1 iPromoRate='+refRec.value.iPromoRate+',iIsPromo='+refItmFznRecs.value[itmIdx].iIsPromo+', iTotalRet='+refItmFznRecs.value[itmIdx].iTotalRet+', iDiscount='+iDiscount);  // prov_debug
           // FZN item (end)
         } else {
           // STD item (begin)
           if (iTotalRet > 0) {
             refItmRecs.value[itmIdx].iTotalRet = 1 * itmQtyRet * itmPrice;
             if ((!refItmRecs.value[itmIdx].iIsPromo || refItmRecs.value[itmIdx].iIsPromo == 0) && (refRec.value.iPromoTotal > 0)) {
               // Discount is applied only to item not in promo (begin)
              iDiscount = parseInt((refItmRecs.value[itmIdx].iTotalRet * refRec.value.iPromoRate) / 100);
              // refItmRecs.value[itmIdx].iTotalRet  -= iDiscount;
              refRec.value.iPromoTotRet  += iDiscount;	// amount of promo not to return
              // Discount is applied only to item not in promo (end)
             }
           } else {
             refItmRecs.value[itmIdx].iTotalRet = null;
           }
           iPromoQty = refItmRecs.value[itmIdx].iPromoQty;
           iFreeQty = refItmRecs.value[itmIdx].iFreeQty;
           console.log('vwsOrderFrm.updateQtyRet: DEBUG: STD iPromoRate='+refRec.value.iPromoRate+',iIsPromo='+refItmRecs.value[itmIdx].iIsPromo+', iTotalRet='+refItmRecs.value[itmIdx].iTotalRet+', iDiscount='+iDiscount);  // prov_debug
           // STD item (end)
         }

         // Calc free item to return
         let freeItmCode = '';
         let freeIdx = -1;
         if (iFlgFzn == 1) {
           // console.log('vwsOrderFrm.updateQtyRet: DEBUG: refItmFznRecs='+JSON.stringify(refItmFznRecs.value));  // 
           if (refItmFznRecs.value[itmIdx].sFreeItmCode1) {
             // Ordered item has free item (begin)
             freeItmCode = refItmFznRecs.value[itmIdx].sFreeItmCode1;
             // Ordered item has free item (end)
           }
         } else {
           // console.log('vwsOrderFrm.updateQtyRet: DEBUG: refItmRecs='+JSON.stringify(refItmRecs.value));  // 
           if (refItmRecs.value[itmIdx].sFreeItmCode1) {
             // Ordered item has free item (begin)
             freeItmCode = refItmRecs.value[itmIdx].sFreeItmCode1;
             freeIdx = refItmFreeRecs.value.findIndex(function(item){ return item.sItmCode1 == freeItmCode});
             // Ordered item has free item (end)
           }
         }

          if (freeItmCode != '') {
             if (iPromoQty && iPromoQty > 0) {
               iNbFreeQty = parseInt((itmQtyRet + iPromoQty - 1) / iPromoQty) * iFreeQty;
               console.log('vwsOrderFrm.updateQtyRet: DEBUG: Free calc itmQtyRet='+itmQtyRet+', iPromoQty='+iPromoQty+', iFreeQty='+iFreeQty+', iNbFreeQty='+iNbFreeQty);  // prov_debug
             } else {
               iNbFreeQty = itmQtyRet * iFreeQty;
             }
            freeIdx = refFreeItmFznRecs.value.findIndex(function(item){ return item.sItmCode1 == freeItmCode});
            if (freeIdx >= 0) {
              // Free frozen item: refFreeItmFznRecs
              console.log('vwsOrderFrm.updateQtyRet: DEBUG: Free fzn itmQtyRet='+itmQtyRet+', iPromoQty='+iPromoQty+', iFreeQty='+iFreeQty+', iNbFreeQty='+iNbFreeQty);  // prov_debug
              refFreeItmFznRecs.value.[freeIdx].iQtyRet = iNbFreeQty;
            } else {
              freeIdx = refFreeItmRecs.value.findIndex(function(item){ return item.sItmCode1 == freeItmCode});
              if (freeIdx >= 0) {
                // Free regular item: refFreeItmRecs
              console.log('vwsOrderFrm.updateQtyRet: DEBUG: Free std itmQtyRet='+itmQtyRet+', iPromoQty='+iPromoQty+', iFreeQty='+iFreeQty+', iNbFreeQty='+iNbFreeQty);  // prov_debug
                refFreeItmRecs.value.[freeIdx].iQtyRet = iNbFreeQty;
              } else {
                // ERR: Could not find the record of free item
                console.log('vwsOrderFrm.updateQtyRet: DEBUG: Free freeItmCode='+freeItmCode); // prov_debug 
                console.log('vwsOrderFrm.updateQtyRet: DEBUG: refFreeItmRecsStr='+JSON.stringify(refFreeItmRecs.value));  // 
              }
            }
         }

         let iSubTot = 0;
         let iQtyRetTot = 0;
         refItmRecs.value.map(function(objElement, objIdx, objArray) {
           // objIdx = [0 ... newObj.length[
           iSubTot += objElement.iTotalRet;
           iQtyRetTot += objElement.iQtyRet;
         });
         refItmFznRecs.value.map(function(objElement, objIdx, objArray) {
           // objIdx = [0 ... newObj.length[
           iSubTot += objElement.iTotalRet;
           iQtyRetTot += objElement.iQtyRet;
         });
         refFreeItmRecs.value.map(function(objElement, objIdx, objArray) {
           // objIdx = [0 ... newObj.length[
           iQtyRetTot += objElement.iQtyRet;
         });
         refFreeItmFznRecs.value.map(function(objElement, objIdx, objArray) {
           // objIdx = [0 ... newObj.length[
           iQtyRetTot += objElement.iQtyRet;
         });

         refRec.value.iItmNb2 = iQtyRetTot;

         refRec.value.iRetCost = 0;
         // New Total price of return items: iRetSubTot = SUM(shoppingitm.iTotalRet)
         refRec.value.iRetSubTot = iSubTot;
         refRec.value.iRetTotal = refRec.value.iRetSubTot - refRec.value.iPromoTotRet - refRec.value.iRetCost;
         // New total including tax of the invoice adter return: iRetSubTotal = iTotalTax - iRetTotal
         refRec.value.iRetSubTotal = refRec.value.iTotalTax - refRec.value.iRetTotal;
         // New total exluding tax: tmpHT = Math.round((refRec.value.iRetSubTotal * 100) / (100 + refRec.value.iOrdTaxRate));	// iTotalTax / 1.05
         let tmpHT = Math.round((refRec.value.iRetSubTotal * 100) / (100 + refRec.value.iOrdTaxRate));	// iTotalTax / 1.05
         // New tax amount of the invoice after return:  refRec.value.iRetTax = (refRec.value.iRetSubTotal - refRec.value.iRetTotal) - tmpHT;
         refRec.value.iRetTax = refRec.value.iRetSubTotal - tmpHT;

         // console.log('vwsOrderFrm.updateQtyRet: DEBUG: itmIdx='+itmIdx+', itmId='+itmId+', itmId='+itmQtyRet);  // prov_debug
      }  // updateQtyRet
      
      function frmActionDone(myResp) {
        console.log('vwsOrderFrm.frmActionDone: myRespStr='+JSON.stringify(myResp)); // prov_debug
        // ==> myRespStr={"resp":{"errCode":500,"errMsg":"ERROR"}}
        // ==> myRespStr={"resp":{"errCode":501,"errMsg":"Invalide function"}}
        // ==> myRespStr={"resp":{"errCode":502,"errMsg":"502:Could not save record"}}
        // ==> myRespStr={"resp":{"errCode":1,"errMsg":"OK"}}
        
        
        switch(myResp.resp.errCode) {
          case 1: // OK: done successfully
            refResp.value.errCode = myResp.resp.errCode;
            refResp.value.errMsg = myResp.resp.errMsg;
            break;
          case 500: // errMsg500 = 'Generic error';          // Default server-side error
          case 501: // errMsg501 = 'Invalide function';       // Unexpected function
            alert('ERROR: '+myResp.resp.errMsg); // prov_debug
            break;
        }
        
        
      } // frmActionDone

      function validateOrdDone(myResp) {
        console.log('vwsOrderFrm.validateOrdDone: DEBUG: respStr='+JSON.stringify(myResp.resp));  // 
        openPage('pgOrderLst');
      }

      function submitOrdRet() {

       refRec.value.dtmReturn1 = fctGetDateStr(null, null).substring(2); // (objDate, mask);	// dtmReturn1: Date of request to return items
        refRec.value.iStatus = 7; // 0:idel, 1:Pending, 2:Packaging, 3:Ready, 4:Cancel, 5:Delivering, 6:Delivered, 7:retRequest, 8:returning, 9:retDone

            let itmRecs = [];
            itmRecs.length = 0;
            let itmNb2 = 0;			

            // STD-items: refItmRecs
            refItmRecs.value.map(function(objElement, objIdx, objArray) {
              // objIdx = [0 ... newObj.length[
                let tmpItemRec = {
                  id:         objElement.id,
                  sItmCode1:  objElement.sItmCode1,
                  sLabel:  objElement.sLabel,
                  iQtyOrd:    objElement.iQtyOrd,
                  iQtyFree:    objElement.iQtyFree,
                  iQtyDel:    objElement.iQtyDel,
                  iPrice3:    objElement.iPrice3,
                  iTotalOrd:  objElement.iTotalOrd,
                  iQtyRet:    objElement.iQtyRet,
                  iTotalRet:  objElement.iTotalRet,
                  sFreeItmCode1: objElement.sFreeItmCode1,
                  iFreeQty: objElement.iFreeQty,
                };
                if (tmpItemRec.iQtyRet == null) tmpItemRec.iQtyRet = 0;
                tmpItemRec.iQtyRet = 1 * tmpItemRec.iQtyRet;
                itmNb2 += tmpItemRec.iQtyRet ;
                itmRecs.push(tmpItemRec);
              });
            // STD-Free items: refFreeItmRecs
            refFreeItmRecs.value.map(function(objElement, objIdx, objArray) {
              // objIdx = [0 ... newObj.length[
                let tmpItemRec = {
                  id:         objElement.id,
                  sItmCode1:  objElement.sItmCode1,
                  sLabel:  objElement.sLabel,
                  iQtyOrd:    objElement.iQtyOrd,
                  iQtyFree:    objElement.iQtyFree,
                  iQtyDel:    objElement.iQtyDel,
                  iPrice3:    objElement.iPrice3,
                  iTotalOrd:  objElement.iTotalOrd,
                  iQtyRet:    objElement.iQtyRet,
                  iTotalRet:  objElement.iTotalRet,
                  sFreeItmCode1: objElement.sFreeItmCode1,
                  iFreeQty: objElement.iFreeQty,
                };
                if (tmpItemRec.iQtyRet == null) tmpItemRec.iQtyRet = 0;
                tmpItemRec.iQtyRet = 1 * tmpItemRec.iQtyRet;
                itmNb2 += tmpItemRec.iQtyRet ;
                itmRecs.push(tmpItemRec);
              });

            // FZN-items: refItmFznRecs
            refItmFznRecs.value.map(function(objElement, objIdx, objArray) {
              // objIdx = [0 ... newObj.length[
                let tmpItemRec = {
                  id:         objElement.id,
                  sItmCode1:  objElement.sItmCode1,
                  sLabel:  objElement.sLabel,
                  iQtyOrd:    objElement.iQtyOrd,
                  iQtyFree:    objElement.iQtyFree,
                  iQtyDel:    objElement.iQtyDel,
                  iPrice3:    objElement.iPrice3,
                  iTotalOrd:  objElement.iTotalOrd,
                  iQtyRet:    objElement.iQtyRet,
                  iTotalRet:  objElement.iTotalRet,
                  sFreeItmCode1: objElement.sFreeItmCode1,
                  iFreeQty: objElement.iFreeQty,
                };
                if (tmpItemRec.iQtyRet == null) tmpItemRec.iQtyRet = 0;
                tmpItemRec.iQtyRet = 1 * tmpItemRec.iQtyRet;
                itmNb2 += tmpItemRec.iQtyRet ;
                itmRecs.push(tmpItemRec);
              });
            // FZN-Free items: refFreeItmFznRecs
            refFreeItmFznRecs.value.map(function(objElement, objIdx, objArray) {
              // objIdx = [0 ... newObj.length[
                let tmpItemRec = {
                  id:         objElement.id,
                  sItmCode1:  objElement.sItmCode1,
                  sLabel:  objElement.sLabel,
                  iQtyOrd:    objElement.iQtyOrd,
                  iQtyFree:    objElement.iQtyFree,
                  iQtyDel:    objElement.iQtyDel,
                  iPrice3:    objElement.iPrice3,
                  iTotalOrd:  objElement.iTotalOrd,
                  iQtyRet:    objElement.iQtyRet,
                  iTotalRet:  objElement.iTotalRet,
                  sFreeItmCode1: objElement.sFreeItmCode1,
                  iFreeQty: objElement.iFreeQty,
                };
                if (tmpItemRec.iQtyRet == null) tmpItemRec.iQtyRet = 0;
                tmpItemRec.iQtyRet = 1 * tmpItemRec.iQtyRet;
                itmNb2 += tmpItemRec.iQtyRet ;
                itmRecs.push(tmpItemRec);
              });

        refRec.value.iItmNb2 = itmNb2;
        console.log('vwsOrderFrm.submitOrdRet: DEBUG: refRec.value.iItmNb2='+refRec.value.iItmNb2+', itmRecsStr='+JSON.stringify(itmRecs));  // 

        let myPrm1 = {
          procFct: 'setReturn',			// procFct = setDelivery / setReturn
          procPrm: refRec.value
        }

        console.log('vwsOrderFrm.submitOrdRet: DEBUG: ordId='+myPrm1.procPrm.id);  // debug
        ascDeliverOrdItms(myPrm1, itmRecs, validateOrdDone);

      }  // submitOrdRet
      
      function openOrder(orderId) {
        console.log('vwsOrderFrm.openOrder: DEBUG: orderId='+orderId+', clientId='+refClientRec.value.id);  // prov_debug
      } // openOrder
      
      function cancelOrder(orderId) {
        console.log('vwsOrderFrm.cancelOrder: DEBUG: orderId='+orderId+', clientId='+refClientRec.value.id);  // prov_debug
      } // cancelOrder

      function openPage(pgName) {
        console.log('vwsOrderFrm.openPage: pgName='+pgName);
        // this.$router.push({name:pgName,params:{pgAction:'init',prmId:''}});
        router.push({name:pgName,params:{pgAction:'init',prmId:''}});
      }
      
      function getStateLbl (sDelProvince) {
            let tmpStates = fctExtractState();
            let retValue = tmpStates[sDelProvince];
            return retValue;
      }

      function getCityLbl (sDelProvince, sDelCity) {
            let tmpCities = fctExtractCity(sDelProvince);
            let retValue = tmpCities[sDelCity];
            return retValue;
      }
      
      return {
        refDico,
        refOrdStatus,
        refPaymMethods,
        refOrdRetTypes,
        refPageAttr,
        refItmTypeAttr,
        refOrdersResp,
        refOrdersLoading,
        refOrdersRec,
        refResp,
        refRec,
        refItmRecs,
            refItmFreeRecs,
        refItmFznRecs,
        refFreeItmRecs,
        refFreeItmFznRecs,
        refPgAction,
        updateQtyRet,
        submitOrdRet,
        openOrder,
        cancelOrder,
        getStateLbl,
        getCityLbl,
      }
    },

  };

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>