<template>
  <span v-if="refCartItemRecs.length == 0"><br><br>Empty cart<br><br></span>
  <span v-else>
      
  <div>
    <!-- Steps (begin) -->
    <center>
      <br>
      
        <div class="stepContainer">
          <div class="stepLink">
            <div class="stepItem">
              <a id="btnStepA" class="btnStepOn" title="stepCartInfo" style="cursor:pointer" v-on:click="openPage('pgCart1');">{{ refDico.stepCart1 }}</a>
            </div>
            <div class="stepItem">
              <a id="btnStepB" class="btnStepOn" title="stepCartDeliveryInfo"  style="cursor:pointer" v-on:click="openPage('pgCart2');">{{ refDico.stepCart2 }}</a>
            </div>
            <div class="stepItem">
              <a id="btnStepC" class="btnStepOn" title="stepCartPaymentInfo"  style="cursor:pointer" v-on:click="openPage('pgCart3');">{{ refDico.stepCart3 }}</a>
            </div>
            <div class="stepItem">
              <a id="btnStepD" class="btnStepOn" title="stepCartValidationInfo"  style="pointer-events: none">{{ refDico.stepCart4 }}</a>
            </div>
            <div class="stepItem">
              <a id="btnStepE" class="btnStepOff" title="stepCartSummaryInfo"  style="pointer-events: none">{{ refDico.stepCart5 }}</a>
            </div>
          </div>
        </div>
        <br>
    </center>
    <!-- Steps (end) -->
  </div>
  
  
  <div style="width:100%;">
      <center>
      <b style="text-align:center;">{{ refDico.tleCart4 }}</b><br>&nbsp;
      <br>
      <div style="display:inline-block; vertical-align:top; margin: 0px 20px 40px 20px; text-align:left; border: 2px solid #D0D0D0;">
        <!-- Content (begin) -->
        
        <table class="contacttable" border="0">
          <thead>
            <tr><th>{{ refDico.tleCart2 }}</th></tr>
          </thead>
          
          <tbody v-if="refCartRec.deliveryMode == 'b'" style="font-size: 12px; color:#808080;">
            <tr>
              <td style="color:#000000;">
                <b>{{ refDico.lblboutique }}</b>
              </td>
            </tr>
            <tr>
              <td style="width:70%;"><b>{{ refDico.lblcommercialbank }}</b></td>
              <td>&nbsp;</td>
              <td>{{ refDico.lblbusinesshour }}</td>
            </tr>
            <tr>
              <td style="white-space: normal;">澎湖縣馬公市中山路 23 號</td>
              <td>&nbsp;</td>
              <td style="white-space: normal;">{{ refDico.lblmondayfriday }}</td>
              <td>7:00-17:00</td>
            </tr>
            <tr>
             <td>{{ refDico.lblUsrTel}}:(06)9272155</td>
             <td>&nbsp;</td>
             <td>{{ refDico.lblsaturday }}</td>
             <td>7:00-12:00</td>
            </tr>
            <tr>
             <td>{{ refDico.lblUsrFax}}:(06)9275462</td>
             <td>&nbsp;</td>
             <td>{{ refDico.lblsunday }}</td>
            </tr>
            <tr>
              <td style="white-space: normal;">{{ refDico.lbldescriptionboutique }}</td>
            </tr>
          </tbody>
          
          <tbody v-else-if="refCartRec.deliveryMode == 'd'" style="font-size: 12px; color:#808080;">
            <tr>
              <td style="color:#000000;">
                <b>{{ refDico.lbldomicile }}</b>
              </td>
            </tr>
            <tr>
              <td width="30%">{{ refDico.lblUsrName }} (*)</td>
              <td width="80%">{{ refCartRec.sDelFirstName }}</td>
            </tr>
            <tr>
              <td>{{ refDico.lblMobile }}</td>
              <td>{{ refCartRec.sDelMobile }}</td>
            </tr>
            <tr>
              <td>{{ refDico.lblProvince }} / {{ refDico.lblCity }}</td>
              <td>{{ refStateLbl }} / {{ refCityLbl }}</td>
              
            </tr>
            

            
            
            
             
            <tr>
              <td>{{ refDico.lblZipCode }}</td>
              <td>{{ refCartRec.sDelZipCode }}</td>
            </tr>
            <tr>
              <td>{{ refDico.lblAddr1 }}</td>
              <td>{{ refCartRec.sDelAddr1 }}</td>
            </tr>
          </tbody>
          
          <tbody v-else>
            <tr><td>error</td></tr>
          </tbody>
        </table>
        <br>
        
        <table class="contacttable" border="0">
          <thead>
            <tr><th>{{ refDico.lblpaymentmethod }}</th></tr>
          </thead>
          
          <tbody style="font-size: 12px;">
            <tr v-if="refCartRec.paymentMode == '1'">
              {{ refDico.lblpayment1 }}
            </tr>
            <tr v-else-if="refCartRec.paymentMode == '2'">
              {{ refDico.lblpayment2 }}
            </tr>
            <tr v-else-if="refCartRec.paymentMode == '3'">
              <img src="/img/visa.jpg">
            </tr>
            <tr v-else-if="refCartRec.paymentMode == '4'">
              <img src="/img/taiwanpay.jpg">
            </tr>
            <tr v-else>
              error
            </tr>
          </tbody>
        </table>
        <br>
        
        <table class="contacttable" border="0">
          <thead>
            <tr><th>{{ refDico.lblInvoiceInformation }}</th></tr>
          </thead>
          
          <tbody style="font-size: 12px;">
            <tr v-if="refCartRec.invoiceMode == '1'">
              {{ refDico.lblinvoice1 }}
            </tr>
            <tr v-else-if="refCartRec.invoiceMode == '2'">
              <td>{{ refDico.lblinvoice2 }}</td>
              <td>{{ refDico.lbltitleinvoice2 }}: &nbsp; {{ refCartRec.sPInvoice2 }}</td>
            </tr>
            <tr v-else-if="refCartRec.invoiceMode == '3'">
              <td>{{ refDico.lblinvoice3 }}</td>
              <td>{{ refDico.lbltitleinvoice3 }}: &nbsp; {{ refCartRec.sComCode }}</td>
            </tr>
            <tr v-else-if="refCartRec.invoiceMode == '4'">
              <td>{{ refDico.lblinvoice4 }}</td>
              <td>{{ refDico.lbltitleinvoice4 }}: &nbsp; {{ valueAssociate }}</td>
            </tr>
            <tr v-else>
              error
            </tr>
          </tbody>
        </table>
        
        
        
        
        <!-- Content (end) -->
      </div>
    
      <div style="display:inline-block; vertical-align:top;">
        <cpnCart />
      </div>
      <br>
      <br>
      <a class="btnStd01" v-on:click="createOrder();" >{{ refDico.lblValidate }}</a>
      <br>
      <br>
      <br>&nbsp;
    </center>
  </div>

  </span>
</template>

<script>
  import { ref, computed, onBeforeMount, onMounted } from 'vue';
  import router from '@/router/index.js';
  import { useRoute } from 'vue-router';
  import useReferential from '@/modules/mdlReferential.js'; 
  import useClientApi from '@/services/svcClientApi.js';
  import useCartApi from '@/services/svcCartApi.js';
  import cpnCart from '@/components/cpnCart';
  import useTools from '@/utils/vjsTools.js'; 
  const axios = require("axios");

  export default {
    components: {
      cpnCart,
    },
    setup() {
      const route = useRoute();
      const { refDicoLang, refDicoAttr, refPageAttr, refItmTypeAttr, ascSetLang, refSlctMenu, fctExtractMenu, fctExtractSubmenu, refDepartmentAttr, fctExtractState, fctExtractCity } = useReferential();
      
      let { ascClientFindRec, ascClientSaveRec, ascClientDeleteRec, refClientResp, refClientLoading, refClientRec } = useClientApi();
      let { ascCartGetRecs, ascCartGetItemRecs, ascCartFindRec, ascCartSaveRec, ascCartCreateOrd, ascCartDeleteRec, refCartResp, refCartLoading, refCartRec, refCartItemRecs, fctCartDelItem } = useCartApi();
      
      let { tlsGetDateStr } = useTools();
      
      let refDico = ref({});
      let refStateLbl = ref('');
      let refCityLbl = ref('');
      
      
      let refResp = ref({});
      let refRec = ref({});
      let tabAssociate = [refDicoAttr.value.lblAssociate1, refDicoAttr.value.lblAssociate2, refDicoAttr.value.lblAssociate3, refDicoAttr.value.lblAssociate4, refDicoAttr.value.lblAssociate5];
      let valueAssociate = tabAssociate[1*refCartRec.value.sAssociation - 1];
      
      console.log('vwsCart4.setup: DEBUG: tabAssociate='+JSON.stringify(tabAssociate));  // prov_debug
      console.log('vwsCart4.setup: DEBUG: sAssociation='+refCartRec.value.sAssociation);
      console.log('vwsCart4.setup: DEBUG: valueAssociate='+valueAssociate);
      
      onBeforeMount(() => {
      
        console.log('vwsCart4.onBeforeMount: DEBUG: refClientRecId='+refClientRec.value.id);  // prov_debug
        if (refClientRec.value.id) {
          refPageAttr.value.pgAction = 'init';
          refPageAttr.value.pgName = 'pgCart1';
        } else {
          // Client need to login before adding preferred item
          openPage('pgClientLogin');
        }
      });
      
      onMounted(async () => {
        window.scrollTo(0, 0);
        refDico.value = refDicoAttr.value;
        refPageAttr.value.title = refDico.value.tleCart;
        
        let reqParams = { 
              criteria: "idParent = "+refClientRec.value.id, 
              orderby: "id ASC", // "sItmCode1 ASC",
              };
        // KO ascCartGetItemRecs(reqParams);  // KO // Without callback, the record can not be altered at loading
        ascCartGetItemRecs(reqParams, reqCallback);  // Callback is required if altering the record is required at loading
      });
      
      /**** Without callback (begin) ****
      // Without callback, the record can not be altered at loading
      refResp = computed(() => {
        console.log('vwsCart4.computed: DEBUG: refResp=refItemRespStr='+JSON.stringify(refItemResp.value));  // prov_debug
        return refItemResp.value;
      });
      **** Without callback (end) ****/
      
      /**** With callback (begin) ****/
      // Callback is required if altering the record is required at loading
      function reqCallback(myResp) {
        console.log('vwsCart4.reqCallback: DEBUG: respStr='+JSON.stringify(myResp.resp));  // prov_debug
        // ==> respStr={"resp":{"errCode":1,"errMsg":"OK"},"recs":[{"id":154,"sLabel":"統一布丁（大）","iStatus":null,"dtmCreate":"2021-04-15 12:00:38","dtmModif":"2021-04-15  ...
        
        // updateCatalogRecs(refPageAttr.value.pgName, false);  // =true:catalog with topMemu/subMenu, =false:other catalog filter
        
        // Get list of Provinces: refCartRec.sDelProvince
        let tmpStates = fctExtractState();
        refStateLbl.value = tmpStates[refCartRec.value.sDelProvince];
        // console.log('vwsCart4.reqCallback: DEBUG: refStateLbl['+refCartRec.value.sDelProvince+']='+refStateLbl.value+', tmpStatesStr='+JSON.stringify(tmpStates));

        // Get refCity related to the sProvince: refCartRec.sDelCity
        let tmpCities = fctExtractCity(refCartRec.value.sDelProvince);
        refCityLbl.value = tmpCities[refCartRec.value.sDelCity];
        console.log('vwsCart4.reqCallback: DEBUG: refCityLbl['+refCartRec.value.sDelCity+']='+refCityLbl.value+', tmpCitiesStr='+JSON.stringify(tmpCities));

      }
      /**** With callback (end) ****/
      
      function openPage(pgName) {
        console.log('cpnHeader.openPage: pgName='+pgName);
        // this.$router.push({name:pgName,params:{pgAction:'init',prmId:''}});
        router.push({name:pgName,params:{pgAction:'init',prmId:''}});
      }
      

      function paymBankFocas() {
        let nowStr = tlsGetDateStr(null, 'YYYMMDDhhmmss'); // (objDate, mask)
        let ordCode = "TMP"+nowStr;
        
        const postUrl = "https://www.focas-test.fisc.com.tw/FOCAS_WEBPOS/online";
        let postParams = "?MerchantID=007816565129001&TerminalID=90010001&merID=81656512&purchAmt=136&lidm="+ordCode+"&AuthResURL=https://dev01.daozhu.tw/apiPaym/"+ordCode;
        // let postParams = "?MerchantID=007816565129001&TerminalID=90010001&merID=81656512&purchAmt=135&lidm=20211110A&AuthResURL=https%3A%2F%2Fdev01.daozhu.tw%2F%23%2FpaymentAck";
        
        console.log('vwsCart4.paymBankFocas: DEBUG: windowLocation: '+postUrl + postParams); // prov_debug
        
        window.location = postUrl + postParams;
      }
      
      function paymTaiwanPay() {
        const postUrl = "https://www.focas-test.fisc.com.tw/FOCAS_WEBPOS/online";
        let postParams = "";
        
        console.log('vwsCart4.paymTaiwanPay: DEBUG: windowLocation: '+postUrl + postParams); // prov_debug
        window.location = postUrl + postParams;
      }
      
      function createOrderDone(myResp) {
        const keyOrdCode = "sOrdCode=";
        let iPos;
        
        console.log('vwsCart4.createOrderDone: DEBUG: paymentMode='+refCartRec.value.paymentMode+', respStr='+JSON.stringify(myResp.resp));  // prov_debug
        // ==> paymentMode=1, respStr={"errCode":501,"errMsg":"Request failed with status code 500"}
        // ==> paymentMode=1, respStr={"errCode":1,"errMsg":"idOrder=9, sOrdCode=211129111519101"}

        if (myResp.resp.errCode == 1) {
          switch(refCartRec.value.paymentMode) {
            case '3': // Bank online payment (FOCAS)
              paymBankFocas();
              break;
              
            case '4': // TaiwanPay: QR code (FOCAS)
              paymTaiwanPay();
              break;
              
            // case '1':
            // case '2':
            default:
              iPos = myResp.resp.errMsg.indexOf(keyOrdCode);
              if (iPos > 0) {
                // OK: Extract orderCode
                refCartRec.value.sOrdCode = myResp.resp.errMsg.substring(iPos+keyOrdCode.length);
                openPage('pgCart5');
              } else {
                // ERROR: Could not extract orderCode
                console.log('vwsCart4.createOrderDone: ERROR: Could not get the order code: respStr='+JSON.stringify(myResp.resp));
                alert('ERROR: Could not get the order code');
              }
              
              break;
          }
        } else {
          alert('ERROR: Could not save the order');
        }
        
   
        /* 
        if (myResp.resp.errCode == 1) {
          // openPage('pgCart5');
        } else {
          alert('ERROR: Could not save the order');
        }
        */
        
      }
      function createOrder() {
        // refCartRec, refCartItemRecs
        console.log('vwsCart4.createOrder: DEBUG: refCartRecStr='+JSON.stringify(refCartRec.value));  // prov_debug
        // ==> refCartRecStr={"idClient":1,"iSubTotal":36,"iDelivery":0,"iTotalHT":36,"iTotalTax":0,"iTotalTTC":36,"sDelProvince":"dep010","sDelCity":"cty0110","sDelZipCode":"99000","sDelAddr1":"1 bd Elysée","sDelMobile":"cli123456789","sDelGenderCode":"M","sDelFirstName":"Cli JackBis","sAssociation":"1","deliveryMode":"b","paymentMode":"1","invoiceMode":"1"}
        
        console.log('vwsCart4.createOrder: DEBUG: refCartItemRecsStr='+JSON.stringify(refCartItemRecs.value));  // prov_debug
        // ==> efCartItemRecsStr=[{"idItem":154,"iQtyOrd":1,"id":154,"sItmCode1":"4710088430755","sSizeCode":"180g","sTypeCode":"uni019","sType1Code":"mnu060","sType2Code":"snu0710","sLabel":"統一布丁（大）","sDescr":"統一布丁獨特焦糖風味與香Q滑嫩口感成就經典口味的國民甜點，不管是舀著吃、攪著吃、冰凍吃或邊玩邊吃，吃統一布丁的時候，就是最開心的時候！\n生鮮食品不享有7天鑑賞期，因冷藏食品或生鮮商品屬消耗性商品，商品屬性特殊或有保存期限等問題，除商品本身瑕疵或運送過程而造成的損毀，才可接受您的退貨申請。非商品瑕疵恕無法為您辦理換貨！一經拆封、食用、失溫及保存不良等情形而導致商品變質，則無法處理您的換貨申請，敬請見諒！","sImg":"","iPrice1":17,"iPrice2":18,"sFlgMenu":"234","iTotalOrd":18},{"idItem":149,"iQtyOrd":1,"id":149,"sItmCode1":"4710088435767","sSizeCode":"450ml","sTypeCode":"uni003","sType1Code":"mnu060","sType2Code":"snu0680","sLabel":"統一陽光無加糖高纖豆漿","sDescr":"【高纖】膳食纖維可幫助腸道蠕動，促進代謝。\n【健康食品認證】榮獲國家健康食品認證。\n1.有助於降低血中總膽固醇。\n2.有助於增加血中高密度脂蛋白膽固醇。\n3.有助於減少發生心血管疾病的危險因子 。\n生鮮食品不享有7天鑑賞期，因冷藏食品或生鮮商品屬消耗性商品，商品屬性特殊或有保存期限等問題，除商品本身瑕疵或運送過程而造成的損毀，才可接受您的退貨申請。非商品瑕疵恕無法為您辦理換貨！一經拆封、食用、失溫及保存不良等情形而導致商品變質，則無法處理您的換貨申請，敬請見諒！","sImg":"","iPrice1":18,"iPrice2":null,"sFlgMenu":"2","iTotalOrd":18}]
        
        // Save the order data to the DB
        refCartRec.value.sOrdCode = ''; // Reset orderCode
        ascCartCreateOrd(refCartRec.value, refCartItemRecs.value, createOrderDone);
        
      }
      
      return {
        refDico,
        refPageAttr,
        refItmTypeAttr,
        refCartResp, 
        refCartLoading, 
        refCartRec,
        refCartItemRecs,
        openPage,
        createOrder,
        valueAssociate,
        refStateLbl,
        refCityLbl,
      }
    },

  };

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>