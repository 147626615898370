<template>
  
  <div>
    
    
    <center>
    <br><br><br>
    
          {{ refDico.msgSigninDone }}
          <br>{{ refDico.msgGoHome }} <a href="https://daozhu.tw">https://daozhu.tw</a>
          <br>
          <br><a class="btnStd01" v-on:click="openPage('pgHome')"><i class="fa fa-home fa-1x"></i>&nbsp;{{ refDico.menuHome }}</a>
    
    <br><br><br><br><br><br><br><br>&nbsp;
    </center>
  </div>  
  
</template>

<script>
  import {
    ref, refs, computed, onActivated, onBeforeMount, onBeforeUnmount, onBeforeUpdate,
    onDeactivated, onErrorCaptured, onMounted, onUnmounted, onUpdated, onRenderTracked, onRenderTriggered
  } from 'vue';
  import router from '@/router/index.js'
  import { useRoute } from 'vue-router';
  import myApp from '@/myApp.vue';
  import useReferential from '@/modules/mdlReferential.js';
  import useClientApi from '@/services/svcClientApi.js';
  
  export default {
    setup() {
      const route = useRoute();
      const { refDicoLang, refDicoAttr, refPageAttr, ascSetLang } = useReferential();
      let { ascClientFindRec, ascClientSaveRec, ascClientDeleteRec, refClientResp, refClientLoading, refClientRec } = useClientApi();
      
      
      let refDico = ref({});
      
      let refResp = ref({});
      let refRec = ref({loginId:'', loginPsw:''});
      let refIsLoading = ref(true);
      
      onMounted(async () => {
        console.log('vwsClientRegisterDone.onMounted: pgAction='+route.params.pgAction+',id='+route.params.prmId);
        refDico.value = refDicoAttr.value;
        refPageAttr.value.title = refDico.value.menu111Register;
        
      }); // onMounted

      /**** Without callback (begin) ****
      // Without callback, the record can not be altered at loading
      refResp = computed(() => {
        // console.log('vwsClientRegisterDone.computed: DEBUG: refResp=refClientRespStr='+JSON.stringify(refClientResp.value));  // debug
        return refClientResp.value;
      });
      refRec = computed(() => {
        // console.log('vwsClientRegisterDone.computed: DEBUG: refRec=refClientRecStr='+JSON.stringify(refClientRec.value));  // debug
        return refClientRec.value;
      });
      **** Without callback (end) ****/
      
      /**** With callback (begin) ****
      // Callback is required if altering the record is required at loading
      function reqCallback(resp) {
        // Set data of the record
        refResp.value = resp.resp;
        // refRec.value = resp.rec;
        // console.log('vwsClientRegisterDone.reqCallback: DEBUG: respStr='+JSON.stringify(resp));  // prov_debug
        // ==> respStr={"resp":{"errCode":101,"errMsg":"Record not found"},"rec":{}}
        // 
        
        if (refResp.value.errCode == 1) {
          // Record loaded correctely. Reset error message
          refResp.value.errCode = 0;
          refResp.value.errMsg = '';
        }
        
        // You may alter the record here
        // ...
        
        // console.log('vwsClientRegisterDone.reqCallback: DEBUG: refRecStr='+JSON.stringify(refRec.value));  // prov_debug
        // ==> 
      }
      **** With callback (end) ****/
      
      
      function openPage(pgName) {
        console.log('vwsClientRegisterDone.openPage: pgName='+pgName);
        router.push({name:pgName,params:{pgAction:'init',prmId:''}});
      }
      
      
      return {
        refDico,
        refResp,
        refRec,
        openPage,
      }
    }, // onMounted
  } // setup
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .link:hover {
    cursor: pointer;
    color: #90150D;
  }
  .divHor01Shadow {
    display: flex;
    box-shadow: 0px 0px 10px #C0C0C0;		/* h-offset v-offset blur spread color */
    margin: 10px 10px 10px 10px;		/* top right bottom left */
  }

</style>