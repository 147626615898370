<template>
      
  <div>
    <!-- Steps (begin) -->
    <center>
      <br>
      
        <div class="stepContainer">
          <div class="stepLink">
            <div class="stepItem">
              <a id="btnStepA" class="btnStepOn" title="stepCartInfo" style="cursor:pointer" v-on:click="openPage('pgCart1');">{{ refDico.stepCart1 }}</a>
            </div>
            <div class="stepItem">
              <a id="btnStepB" class="btnStepOn" title="stepCartDeliveryInfo"  style="cursor:pointer" v-on:click="openPage('pgCart2');">{{ refDico.stepCart2 }}</a>
            </div>
            <div class="stepItem">
              <a id="btnStepC" class="btnStepOn" title="stepCartPaymentInfo"  style="cursor:pointer" v-on:click="openPage('pgCart3');">{{ refDico.stepCart3 }}</a>
            </div>
            <div class="stepItem">
              <a id="btnStepD" class="btnStepOn" title="stepCartValidationInfo"  style="cursor:pointer" v-on:click="openPage('pgCart4');">{{ refDico.stepCart4 }}</a>
            </div>
            <div class="stepItem">
              <a id="btnStepE" class="btnStepOn" title="stepCartSummaryInfo"  style="pointer-events: none">{{ refDico.stepCart5 }}</a>
            </div>
          </div>
        </div>
        <br>
    </center>
    <!-- Steps (end) -->
  </div>
  
  <div style="width:100%; text-align:center;">

    <span v-if="refOrdersRec.paymentMode == '5' || refOrdersRec.paymentMode == '6' ">
      <!-- Payment KO -->
      <b>{{ refPaymMethods[ 1*refOrdersRec.paymentMode ] }}</b>
    </span>
    <span v-else>
      <!-- Payment OK -->
      {{ refDico.tleCart5 }}: <b>{{ refOrdId }}</b>
    </span>

    <br><br><br>{{ refDico.lblTxtCart5 }}
    <br>
    <br>
    <a id="pgHome" class="btnStd01" v-on:click="openPage('pgHome');" ><i class="fa fa-home"></i> {{ refDico.menuHome }}</a>
    <a id="pglstorder" class="btnStd01" v-on:click="openPage('pgOrderLst');" > {{ refDico.lblOrderlist }}</a>
    <br>
    <br>
    <br>&nbsp;
  </div>
  
</template>

<script>
  import { ref, computed, onBeforeMount, onMounted } from 'vue';
  import router from '@/router/index.js';
  import { useRoute } from 'vue-router';
  import useReferential from '@/modules/mdlReferential.js'; 
  import useClientApi from '@/services/svcClientApi.js';
  import useCartApi from '@/services/svcCartApi.js';
  import useOrdersApi from '@/services/svcOrdersApi.js';

  export default {
    setup() {
      const route = useRoute();
      const { refDicoLang, refDicoAttr, refPageAttr, refItmTypeAttr, ascSetLang, refSlctMenu, fctExtractMenu, fctExtractSubmenu } = useReferential();
      let { ascClientFindRec, ascClientSaveRec, ascClientDeleteRec, refClientResp, refClientLoading, refClientRec } = useClientApi();
      let { ascCartGetRecs, ascCartGetItemRecs, ascCartFindRec, fctCartGetCliId, fctCartSetCliId, ascCartSaveRec, ascCartDeleteRec, refCartResp, refCartLoading, refCartRec, refCartItemRecs, fctCartDelItem, refOrdType, refOrdId, refCartItemFreeRecs } = useCartApi();
      let { fctGetDateStr, ascOrdersGetRecs, ascOrdersGetItemRecs, ascOrdersFindRec, ascOrdersSaveRec, ascOrdersDeleteRec, ascDeliverOrdItms, refOrdersResp, refOrdersLoading, refOrdersRecs, refOrdersRec, ascOrdersConfirm } = useOrdersApi();
      
      
      let refDico = ref({});
      let refResp = ref({});
      let refRec = ref({});
      let refCliId = ref();
      let refPaymMethods = ref([]);	// paymentMode=[1..4] :Payment methods
      
      onBeforeMount(() => {
      
        refCliId.value = fctCartGetCliId();
        console.log('vwsCart5.onBeforeMount: DEBUG: refCliId='+refCliId.value);  // prov_debug
        // ==> refCliId=46
        
        console.log('vwsCart5.onBeforeMount: DEBUG routeParamsStr='+JSON.stringify(route.params));  // prov_debug
        // console.log('vwsCart5.onBeforeMount: DEBUG routeRequestStr='+JSON.stringify(route.request));  // prov_debug: undefined
        // Request: https://dev01.daozhu.tw?pg=paymAck&toto=123
        // ==> routeParamsStr={"prmFromPath":"/","prmToQuery":"{\"pg\":\"paymAck\",\"toto\":\"123\"}"}
        // ==> routeParamsStr={"pgAction":"init","prmId":""}
        
        if (route.params.prmToQuery && route.params.prmToQuery) {
          // Indirect ack from payment API (begin)
          console.log('vwsCart5.onBeforeMount: DEBUG prmFromPath='+route.params.prmFromPath);  // prov_debug
          // ==> prmFromPathStr=/
          
          let prmToQuery = JSON.parse(route.params.prmToQuery);
          console.log('vwsCart5.onBeforeMount: DEBUG prmToQueryStr='+JSON.stringify(prmToQuery));  // prov_debug
          // ==> prmToQueryStr={"refId":"CC202207120856176409"}
          // ==> prmToQueryStr={"refId":"CC2307171238063783"} 

          console.log('vwsCart5.onBeforeMount: DEBUG len='+prmToQuery.refId.length+', refId='+prmToQuery.refId);  // prov_debug
          if (prmToQuery.refId.length > 2) {
            refOrdType.value = prmToQuery.refId.substring(0, 2);
            refOrdId.value = prmToQuery.refId.substring(2);
          }
          console.log('vwsCart5.onBeforeMount: DEBUG refOrdType='+refOrdType.value + ', refOrdId=' + refOrdId.value);  // prov_debug
          // ==> refOrdType=CC, refOrdId=202207120926309054
          
          // Indirect ack from payment API (end)
        } else {
          // Direct ack from internal order
        }
        
        
        
      
        /*
        console.log('vwsCart5.onBeforeMount: DEBUG: refClientRecId='+refClientRec.value.id);  // prov_debug
        if (refClientRec.value.id) {
          refPageAttr.value.pgAction = 'init';
          refPageAttr.value.pgName = 'pgCart5';
        } else {
          // Client need to login before adding preferred item
          openPage('pgClientLogin');
        }
        */
      });
      
      onMounted(async () => {
        window.scrollTo(0, 0);
        refDico.value = refDicoAttr.value;
        refPageAttr.value.title = refDico.value.tleCart;

        refPaymMethods.value.length = 0;
        refPaymMethods.value.push(" ");	// idx 0
        refPaymMethods.value.push(refDico.value.lblpayment1);	// idx 1: Pay at the store
        refPaymMethods.value.push(refDico.value.lblpayment2);	// idx 2: Cash on delivery
        refPaymMethods.value.push(refDico.value.lblpayment3);	// idx 3: Credit card 
        refPaymMethods.value.push(refDico.value.lblpayment4);	// idx 4: Taiwan Pay
        refPaymMethods.value.push(refDico.value.lblpayment5);	// idx 5: Credit card KO
        refPaymMethods.value.push(refDico.value.lblpayment6);	// idx 6: Taiwan Pay KO

        if (!refCartRec.value.idClient) {
          // Indirect ack from payment API (begin)
          console.log('vwsCart5.onMounted: DEBUG refOrdType='+refOrdType.value + ', refOrdId=' + refOrdId.value);  // prov_debug
          // ==> refOrdType=CC, refOrdId=202207120926309054
          let reqParams = { 
              // id: route.params.prmId,
              sOrdCode: refOrdId.value,
          };
          ascOrdersFindRec(reqParams, reqCallback);  // Callback is required if altering the record is required at loading
          // ==> Result are stored in: refOrdersRec.value
          // Indirect ack from payment API (end)
        } else {
          // Direct ack from internal order (begin)
            console.log('vwsCart5.onMounted: DEBUG: refCartRecStr='+JSON.stringify(refCartRec.value));  // prov_debug
            // ==> refCartRecStr={"idClient":1,"iSubTotal":36,"iDelivery":20,"iTotalHT":56,"iTaxRate":0,"iTotalTax":0,"iTotalTTC":56,"sDelProvince":"dep010","sDelCity":"cty0110","sDelZipCode":"99000","sDelAddr1":"1 bd Elysée","sDelMobile":"0908823043","sDelGenderCode":"M","sDelFirstName":"Cli JackBis","sComCode":"Cli001b","sAssociation":"1","deliveryMode":"d","paymentMode":"2","invoiceMode":"1","sOrdCode":"220714095702101","iItmNb":1}
        
        
            // Verify data
            if (refCartRec.value && (1*refCliId.value == refCartRec.value.idClient)) {
              // OK: clientId from cookies and idClient in the shopping cart are identical
              console.log('vwsCart5.onMounted: DEBUG: OK refCliId='+refCliId.value+'/'+refCartRec.value.idClient);
              // ==> refCliId=1
            } else {
              // ERROR: clientId from cookies does not match idClient in the shopping cart 
              console.log('vwsCart5.onMounted: ERROR: Invalid refCliId='+refCliId.value+'/'+refCartRec.value.idClient);
            }
            confirmOrder();
              // Direct ack from internal order (end)
          }

      });
      
      /**** Without callback (begin) ****
      // Without callback, the record can not be altered at loading
      refResp = computed(() => {
        console.log('vwsCart5.computed: DEBUG: refResp=refItemRespStr='+JSON.stringify(refItemResp.value));  // prov_debug
        return refItemResp.value;
      });
      **** Without callback (end) ****/
      
      /**** With callback (begin) ****/
      // Callback is required if altering the record is required at loading
      function reqCallback(myResp) {
        console.log('vwsCart5.reqCallback: DEBUG: respStr='+JSON.stringify(myResp.resp));  // prov_debug
        // ==> respStr={"errCode":1,"errMsg":"OK"}

        // console.log('vwsCart5.reqCallback: DEBUG: refOrdersRecStr='+JSON.stringify(refOrdersRec.value));  // prov_debug
        // ==> refOrdersRecStr={"id":168,"idClient":46,"sLabel":null,"iStatus":1,"dtmCreate":"2023-07-20 16:02:37","dtmModif":null,"sOrdCode":"230720160237101","iOrdSubTot":null,"iOrdCost":null,"iOrdTaxRate":0,"iOrdTax":null,"iOrdTotal0":205,"iOrdTotal":null,"iItmNb":1,"sDelProvince":"dep010","sDelCity":"cty0110","sDelZipCode":"","sDelAddr1":"","sDelAddr2":null,"sDelMobile":"0908823043","sDelGenderCode":"M","sDelFirstName":"Jean","sDelLastName":null,"deliveryMode":"d","sAssociation":"1","paymentMode":"3","invoiceMode":"1","dtmDelivery":null,"sDelCode":null,"dtmEtkInv":null,"sEtkInvCode":null,"dtmReturn":null,"dtmEtkCancel":null,"dtmEtkInv2":null,"sEtkInvCode2":null,"dtmCancel":null,"returnType":null,"iRetSubTot":null,"iRetCost":null,"iRetSubTotal":null,"iRetTax":null,"iRetTotal":null,"dtmReturn1":null,"dtmReturn2":null,"idStaffPre":null,"idStaffDel":null,"idStaffRet":null,"iTotalQty":null,"sPkgCode":"336","iPkgItmQty":null,"sPInvoice2":null,"sComCode":"","sDelCode1":null,"sDelCode2":null,"dtmDel1":null,"dtmDel2":null,"iPromoRate":0,"sPromoCode":null,"iPromoMini":0,"iOrdSubTot11":null,"iOrdSubTot21":null,"iOrdSubTot12":null,"iOrdSubTot22":null,"iPkgQty1":null,"iPkgQty2":null,"iItmQty1":1,"iItmQty2":0,"iLocCode":0,"iSubTotalNormalProduct":185,"iSubTotalFznProduct":0,"iSubTotal":185,"iPromoTotal":0,"iDelivery":20,"iDeliveryNormalProduct":150,"iDeliveryFznProduct":0,"iTotalTax":205,"iTaxPrice":0,"iTotalHT":205,"iFlgDelDone":0,"iSubTotalB":185,"iFlgRetDone":0,"iItmNb2":null,"iPromoTotRet":0}

        refOrdersRec.value.paymentMode = 1*refOrdersRec.value.paymentMode;
        console.log('vwsCart5.reqCallback: DEBUG: refCliId='+refCliId.value+', paymentMode='+refOrdersRec.value.paymentMode);  // prov_debug
        if (myResp.resp.errCode == 1 && refCliId.value) {
          // Submit request to remote REST API services to get record matching the provided id
          let myParams = {
              id: refCliId.value,
          };
          ascClientFindRec(myParams, loadCliCallback); // Callback is required if altering the record is required at loading
        }
      }
      /**** With callback (end) ****/

      function loadCliCallback(myResp) {
        console.log('vwsCart5.loadCliCallback: DEBUG: respStr='+JSON.stringify(myResp.resp));  // prov_debug
        // ==> respStr={"errCode":1,"errMsg":"OK"} vwsCart5.vue:181:16

        confirmOrder();
      }
      
      function openPage(pgName) {
        console.log('cpnHeader.openPage: pgName='+pgName);
        // this.$router.push({name:pgName,params:{pgAction:'init',prmId:''}});
        router.push({name:pgName,params:{pgAction:'init',prmId:''}});
      }

      function deleteOrderDone(myResp) {
        console.log('vwsCart5.deleteOrderDone: myRespStr='+JSON.stringify(myResp)); // prov_debug
      }

      function confirmOrder() {
        refCartRec.value.sPromoCode = '';
        refCartRec.value.iPromoRate = 0;
        refCartRec.value = {};
        refCartItemRecs.value = [];
        refCartItemRecs.value.length = 0;
        refCartItemFreeRecs.value = [];
        refCartItemFreeRecs.value.length = 0;

        console.log('vwsCart5.confirmOrder: DEBUG: refCliId='+refCliId.value);  // prov_debug
        console.log('vwsCart5.confirmOrder: DEBUG refOrdType='+refOrdType.value + ', refOrdId=' + refOrdId.value);  // prov_debug
        // console.log('vwsCart5.confirmOrder: DEBUG: refClientRecStr='+JSON.stringify(refClientRec.value));  // prov_debug
        // ==> refClientRecStr={"id":46,"iStatus":null,"dtmCreate":"2023-07-17 16:44:59","dtmModif":"2023-07-17 16:44:59","dtmValidMob":null,"dtmValidEmail":null,"sPassword":"jpn13579","sMobile":"0908823043","sEmail":"jpn_email@yahoo.com","sGenderCode":"M","sFirstName":"Jean","sLastName":"","sAddr1":"","sAddr2":"","sZipCode":"","sCity":"cty0110","sProvince":"dep010","sMsgMode":"E","sCheckCode":"","sLevel":"","sNote":"","sComCode":"","sComName":"","sComTel":"","sComFax":"","sDepartment":"dep001","sPassword2":"jpn13579","dtmExpPsw2":null,"sBizAddr1":"","sBizAddr2":"","sBizZipCode":"","sBizCity":"","sBizProvince":""}

        if (refClientRec.value.sMobile) {
          refRec.value.loginId = refClientRec.value.sMobile
        } else {
          refRec.value.loginId = refClientRec.value.sEmail
        }
        console.log('vwsCart5.confirmOrder: DEBUG: loginId='+refRec.value.loginId+', paymentMode='+refOrdersRec.value.paymentMode);  // prov_debug
        if (refRec.value.loginId && 1*refOrdersRec.value.paymentMode != 5 && 1*refOrdersRec.value.paymentMode != 6) {
          // OK: order paid. Send notification (begin)
          let sCriteria = " sMobile = '"+refRec.value.loginId+"' OR sEmail = '"+refRec.value.loginId+"' ";
          let myParams = {
            tblCriteria: sCriteria,
            clientRec: refClientRec.value,
            ordId: refOrdId.value,
          };
          ascOrdersConfirm(myParams); 
          // OK: order paid. Send notification (end)
        } else {
          // KO: Payment failed. Remove the order (begin)
          let myParams = {
                  fct: 'delete',
                  rec: refOrdersRec.value,
              };
          ascOrdersDeleteRec(myParams, deleteOrderDone);
          // KO: Payment failed. Remove the order (end)
        }
      } 
      
      return {
        refDico,
        refPageAttr,
        refItmTypeAttr,
        refCartResp, 
        refCartLoading, 
        refCartRec,
        refCartItemRecs,
        openPage,
        refOrdType,
        refOrdId,
        refPaymMethods,
        refOrdersRec,
      }
    },

  };

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>