
import { createWebHistory, createRouter } from "vue-router";
// import Router from "vue-router";
import useReferential from '@/modules/mdlReferential.js';
import vwsHome from '@/views/vwsHome.vue';
import vwsMenu00Items from '@/views/vwsMenu00Items.vue';
import vwsMenu02Discount from '@/views/vwsMenu02Discount.vue';
import vwsMenu03NewProducts from '@/views/vwsMenu03NewProducts.vue';
import vwsMenu04Exclusive from '@/views/vwsMenu04Exclusive.vue';
import vwsMenu05BestSellers from '@/views/vwsMenu05BestSellers.vue';
import vwsMenu06Wholesale from '@/views/vwsMenu06Wholesale.vue';
import vwsMenu07Distribution from '@/views/vwsMenu07Distribution.vue';
import vwsItemFrm from '@/views/vwsItemFrm.vue';
import vwsCart1 from '@/views/vwsCart1.vue';
import vwsCart2 from '@/views/vwsCart2.vue';
import vwsCart3 from '@/views/vwsCart3.vue';
import vwsCart4 from '@/views/vwsCart4.vue';
import vwsCart4Paym from '@/views/vwsCart4Paym.vue';
import vwsCart5 from '@/views/vwsCart5.vue';
import vwsPreferred from '@/views/vwsPreferred.vue';
import vwsOrderLst from '@/views/vwsOrderLst.vue';
import vwsOrderFrm from '@/views/vwsOrderFrm.vue';
import vwsReturnLst from '@/views/vwsReturnLst.vue';
import vwsReturnFrm from '@/views/vwsReturnFrm.vue';
import vwsClientLogin from '@/views/vwsClientLogin.vue';
import vwsClientRegister from '@/views/vwsClientRegister.vue';
import vwsClientRegisterDone from '@/views/vwsClientRegisterDone.vue';
import vwsClientSetPsw from '@/views/vwsClientSetPsw.vue';
import vwsClientResetPsw from '@/views/vwsClientResetPsw.vue';
import vwsClientResetPswDone from '@/views/vwsClientResetPswDone.vue';
import vwsClientLogout from '@/views/vwsClientLogout.vue';
import vwsClientFrm from '@/views/vwsClientFrm.vue';
import vwsP1 from '@/views/vwsP1.vue';
import vwsP2 from '@/views/vwsP2.vue';
import vwsP3 from '@/views/vwsP3.vue';
import vwsP4 from '@/views/vwsP4.vue';
import vwsP5 from '@/views/vwsP5.vue';
import vwsPg404 from '@/views/vwsPg404.vue';
import useItemApi from '@/services/svcItemApi.js';


const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", name: "pgHome", component: vwsHome },
    { path: "/", name: "pgMenu00Items", component: vwsMenu00Items },
    { path: "/", name: "pgMenu02Discount", component: vwsMenu02Discount },
    { path: "/", name: "pgMenu03NewProducts", component: vwsMenu03NewProducts },
    { path: "/", name: "pgMenu04Exclusive", component: vwsMenu04Exclusive },
    { path: "/", name: "pgMenu05BestSellers", component: vwsMenu05BestSellers },
    { path: "/", name: "pgMenu06Wholesale", component: vwsMenu06Wholesale },
    { path: "/", name: "pgMenu07Distribution", component: vwsMenu07Distribution },
    { path: "/", name: "pgItemFrm", component: vwsItemFrm },
    { path: "/", name: "pgCart1", component: vwsCart1 },
    { path: "/", name: "pgCart2", component: vwsCart2 },
    { path: "/", name: "pgCart3", component: vwsCart3 },
    { path: "/", name: "pgCart4", component: vwsCart4 },
    { path: "/", name: "pgCart4Paym", component: vwsCart4Paym },
    { path: "/", name: "pgCart5", component: vwsCart5 },
    { path: "/", name: "pgPreferred", component: vwsPreferred },
    { path: "/", name: "pgOrderLst", component: vwsOrderLst },
    { path: "/", name: "pgOrderFrm", component: vwsOrderFrm },
    { path: "/", name: "pgReturnLst", component: vwsReturnLst },
    { path: "/", name: "pgReturnFrm", component: vwsReturnFrm },
    { path: "/", name: "pgClientLogin", component: vwsClientLogin },
    { path: "/", name: "pgClientRegister", component: vwsClientRegister },
    { path: "/", name: "pgClientRegisterDone", component: vwsClientRegisterDone },
	{ path: "/", name: "pgClientSetPsw", component: vwsClientSetPsw },
	{ path: "/", name: "pgClientResetPsw", component: vwsClientResetPsw },
	{ path: "/", name: "pgClientResetPswDone", component: vwsClientResetPswDone},
    { path: "/", name: "pgClientLogout", component: vwsClientLogout },
    { path: "/", name: "pgClientFrm", component: vwsClientFrm },
    { path: "/", name: "pgP1", component: vwsP1 },
    { path: "/", name: "pgP2", component: vwsP2 },
    { path: "/", name: "pgP3", component: vwsP3 },
    { path: "/", name: "pgP4", component: vwsP4 },
    { path: "/", name: "pgP5", component: vwsP5 },
    { path: "/404", name: "pg404", component: vwsPg404 }
    // KO: { path: "/:pathMatch(.*)*", name: "pg404", component: vwsPg404 }
  ],
});




// Step 1: beforeEach
router.beforeEach((to, from, next) => {
  console.log('Step1:index.beforeEach: DEBUG: to.fullPathStr='+JSON.stringify(to.fullPath));  // debug
  // ==> to.fullPathStr="/apiPaym/TMP20211111112253"
  /*
  from={"path":"/","params":{},"query":{},"hash":"","fullPath":"/","matched":[],"meta":{}}, to={"fullPath":"/","path":"/","query":{},"hash":"","name":"pgHome","params":{},"matched":[{"path":"/","name":"pgHome","meta":{},"props":{"default":false},"children":[],"instances":{},"leaveGuards":{},"updateGuards":{},"enterCallbacks":{},"components":{"default":{"methods":{},"__scopeId":"data-v-6c5e1b46"}}}],"meta":{},"href":"/"}
  */
  /*
  http://mail.enw.io:13080/?fct=open&id=123
  ==> to={"fullPath":"/?fct=open&id=123","path":"/",
  "query":{"fct":"open","id":"123"},
  "hash":"","name":"pgHome","params":{},"matched":[{"path":"/","name":"pgHome","meta":{},"props":{"default":false},"children":[],"instances":{},"leaveGuards":{},"updateGuards":{},"enterCallbacks":{},"components":{"default":{"methods":{},"__scopeId":"data-v-6c5e1b46"}}}],"meta":{},
  "href":"/?fct=open&id=123"}
  */
  
  
  // ==> from={"path":"/","params":{},"query":{},"hash":"","fullPath":"/","matched":[],"meta":{}}, 
  //  to={
    //  "fullPath":"/#/paymentAck?pg=paymAck&toto=123",
    //  "path":"/#/paymentAck",
    //  "query":{"pg":"paymAck","toto":"123"},
    //  "hash":"",
    //  "params":{},
    //  "matched":[],
    //  "meta":{},
    //  "href":"/#/paymentAck?pg=paymAck&toto=123"}
  
  // console.log('index.beforeEach: DEBUG: from='+from.name+', to='+to.name);  // debug
  if (to.query.pg == "items") {
    // https://dev01.daozhu.tw?pg=items&topmenu=mnu020&submenu=snu0220
    if (to.query.topmenu) {
      if (to.query.submenu) {
        next({ name: 'pgMenu00Items', params:{prmTopMenu:'mnu020',prmSubMenu:'snu0220'}});
      } else {
        next({ name: 'pgMenu00Items', params:{prmTopMenu:'mnu020'}});
      }
    }
  } else if (to.query.pg == "discount") {
    // https://dev01.daozhu.tw?pg=discount
    next({ name: 'pgMenu02Discount'});
  } else if (to.query.pg == "newItems") {
    // https://dev01.daozhu.tw?pg=newItems
    next({ name: 'pgMenu03NewProducts'});
  } else if (to.query.pg == "exclusiveItems") {
    // https://dev01.daozhu.tw?pg=exclusiveItems
    next({ name: 'pgMenu04Exclusive'});
  } else if (to.query.pg == "bestItems") {
    // https://dev01.daozhu.tw?pg=bestItems
    next({ name: 'pgMenu05BestSellers'});
  } else if (to.query.pg == "wholesaleItems") {
    // https://dev01.daozhu.tw?pg=wholesaleItems
    next({ name: 'pgMenu06Wholesale'});
  } else if (to.query.pg == "localItems") {
    // https://dev01.daozhu.tw?pg=localItems
    next({ name: 'pgMenu07Distribution'});
  } else if (to.fullPath.startsWith("/webPaym/")) {
    // https://dev01.daozhu.tw/apiPaym/TMP20211111112253
    // ==> to.fullPathStr="/apiPaym/TMP20211111112253"
    
    // https://dev01.daozhu.tw?pg=paymAck&toto=123
    // https://dev01.daozhu.tw/#/paymentAck?pg=paymAck&toto=123
    console.log('index.beforeEach.paymentAck: DEBUG: from='+JSON.stringify(from)+', to='+JSON.stringify(to));  // debug
    console.log('index.beforeEach.paymentAck: DEBUG: query='+JSON.stringify(to.query)+', params='+JSON.stringify(to.params));  // debug
    // ==> from={"path":"/","params":{},"query":{},"hash":"","fullPath":"/","matched":[],"meta":{}},
    //      to={
    //  "fullPath":"/?pg=paymAck&toto=123",
    //  "path":"/",
    // "query":{"pg":"paymAck","toto":"123"},
    // "hash":"",
    // "name":"pgHome",
    // "params":{},
    // "matched":[{"path":"/","name":"pgHome","meta":{},"props":{"default":false},"children":[],"instances":{},"leaveGuards":{},"updateGuards":{},"enterCallbacks":{},"components":{"default":{"components":{},"__scopeId":"data-v-35320a84"}}}],
    // "meta":{},
    // "href":"/?pg=paymAck&toto=123"}
    
    next({ name: 'pgCart5', params:{prmFromPath:from.path,prmToQuery:JSON.stringify(to.query)}});
  } else {
    // console.log('index.beforeEach: DEBUG: NEXT from='+JSON.stringify(from)+', to='+JSON.stringify(to));  // debug
    next();
  }
})


// Step 2: global guards.
router.beforeResolve((to, from, next) => {
  console.log('Step2:index.beforeResolve');  // : DEBUG: from='+JSON.stringify(from)+', to='+JSON.stringify(to));  // debug
  next();
});
// Step 3: global hooks.
// router.afterEach((to, from, next) => {
router.afterEach((to, from, next) => {
  console.log('Step3:index.afterEach');  // : DEBUG: from='+JSON.stringify(from)+', to='+JSON.stringify(to));  // debug
  // next();
});


export default router;
