<template>

  <div >
    
    <center>
    <br>
    
    <span v-if="refPreferredLoading"><br><br>Loading ...<br><br></span>
    <span v-else>
    

      <div class="myCatItm" v-for="tmpRec in refMenuRecs" :key="tmpRec.id">
        <cpnItmRec v-bind:refItmRec="tmpRec"></cpnItmRec>
      </div>

      <br>&nbsp;

    </span>
    
    </center>

  </div>
  
</template>

<script>
  import { ref, computed, onBeforeMount, onMounted } from 'vue';
  import router from '@/router/index.js';
  import { useRoute } from 'vue-router';
  import useReferential from '@/modules/mdlReferential.js'; 
  import useClientApi from '@/services/svcClientApi.js';
  import useItemApi from '@/services/svcItemApi.js'; 
  import usePreferredApi from '@/services/svcPreferredApi.js';
  import cpnItmRec from '@/components/cpnItmRec';

  export default {
    components: {
      cpnItmRec,
    },
    setup() {
      const route = useRoute();
      const { refDicoLang, refDicoAttr, refPageAttr, refItmTypeAttr, ascSetLang, refSlctMenu, fctExtractMenu, fctExtractSubmenu } = useReferential();
      let { ascClientFindRec, ascClientSaveRec, ascClientDeleteRec, refClientResp, refClientLoading, refClientRec } = useClientApi();
      const { ascItemGetRecs, refItemLoading, refItemResp, refItemRecs, refMenuLoading, refMenuRecs, refFlgPreferred, updateMenuRecs } = useItemApi();
      let { ascPreferredGetRecs, ascPreferredGetItemRecs, ascPreferredFindRec, ascPreferredSaveRec, ascPreferredDeleteRec, refPreferredResp, refPreferredLoading, refPreferredRecs } = usePreferredApi();
      
      let refDico = ref({});
      let refResp = ref({});
      let refRec = ref({});
      
      onBeforeMount(() => {
        // this.$router.push({name:'pgItemFrm',params:{pgAction:'init',itmLstFilter:'myItmLstFilter'}});
        
        console.log('vwsPreferred.onBeforeMount: DEBUG: refClientRecId='+refClientRec.value.id);  // prov_debug
        if (refClientRec.value.id) {
          refPageAttr.value.pgAction = 'init';
          refPageAttr.value.pgName = 'pgPreferred';
        } else {
          // Client need to login before adding preferred item
          openPage('pgClientLogin');
        }
      });
      
      onMounted(async () => {
        refDico.value = refDicoAttr.value;
        refPageAttr.value.title = refDico.value.tlePreferred;
        
        let reqParams = { 
              criteria: "idParent = "+refClientRec.value.id, 
              orderby: "sItmCode1 ASC",
              };
        // KO ascPreferredGetItemRecs(reqParams);  // KO // Without callback, the record can not be altered at loading
        ascPreferredGetItemRecs(reqParams, reqCallback);  // Callback is required if altering the record is required at loading
      });
      
      /**** Without callback (begin) ****
      // Without callback, the record can not be altered at loading
      refResp = computed(() => {
        console.log('vwsPreferred.computed: DEBUG: refResp=refItemRespStr='+JSON.stringify(refItemResp.value));  // prov_debug
        return refItemResp.value;
      });
      **** Without callback (end) ****/
      
      /**** With callback (begin) ****/
      // Callback is required if altering the record is required at loading
      function reqCallback(myResp) {
        console.log('vwsPreferred.reqCallback: DEBUG: myRespStr='+JSON.stringify(myResp));  // prov_debug
        // ==> vwsPreferred.reqCallback: DEBUG: respStr={"resp":{"errCode":1,"errMsg":"OK"}}

        refFlgPreferred.value = true;
        refMenuRecs.value.length = 0;
        console.log('vwsPreferred.reqCallback: DEBUG: refPreferredRecsLen='+refPreferredRecs.value.length);  // prov_debug
        // console.log('vwsPreferred.reqCallback: DEBUG: refPreferredRecsStr='+JSON.stringify(refPreferredRecs.value));  // prov_debug
        // ==> refPreferredRecsStr=[
        // {"id":222,"idParent":1,"idItem":222,"sLabel":"xxx","iStatus":null,"dtmCreate":"2022-11-14 10:12:06","dtmModif":"2023-01-18 08:45:09","dtmDiscBegin":null,"dtmDiscEnd":null,"sDiscCode":"","sItmCode1":"2022100108521","sItmCode2":"30031","sSupCode":"8","sCatCode":"dep001","sSizeCode":"40g","sTypeCode":"uni007","sType1Code":"mnu110","sType2Code":"snu1120","sDescr":"xxx","sImg":"","iPrice":null,"iPrice1":60,"iPrice2":null,"iQtyMax":null,"iQtyMin":10,"iQty":null,"sGrpCode":"_RA","sFlgReturn":"","sFlgActif":"","sFlgMenu":"_7","iInvQty":null,"iFreeItmId":null,"sFreeItmCode1":"","sFreeItmLabel":"","iFreeQty":null,"sFreeSizeCode":"","sFreeItmSize":"","sFreeTypeCode":"","sFreeItmType":"","iItmIsPromo":null,"iPromoQty":null,"iQtyCnt":null,"iPrice4":null,"iFlgFzn":0,"iFlgFreeFzn":0,"sFreeItmTitle":null},
        // {"id":254,"idParent":1,"idItem":254,"sLabel":"xxx","iStatus":null,"dtmCreate":null,"dtmModif":"2023-02-17 14:50:03","dtmDiscBegin":null,"dtmDiscEnd":null,"sDiscCode":"","sItmCode1":"2022111816057","sItmCode2":"30040","sSupCode":"20","sCatCode":"dep001","sSizeCode":"180g","sTypeCode":"uni007","sType1Code":"mnu110","sType2Code":"snu1120","sDescr":"xxx","sImg":"","iPrice":null,"iPrice1":70,"iPrice2":null,"iQtyMax":null,"iQtyMin":10,"iQty":null,"sGrpCode":"_RA","sFlgReturn":"","sFlgActif":"","sFlgMenu":"_7","iInvQty":null,"iFreeItmId":null,"sFreeItmCode1":"","sFreeItmLabel":"","iFreeQty":null,"sFreeSizeCode":"","sFreeItmSize":"","sFreeTypeCode":"","sFreeItmType":"","iItmIsPromo":null,"iPromoQty":null,"iQtyCnt":null,"iPrice4":null,"iFlgFzn":0,"iFlgFreeFzn":0,"sFreeItmTitle":null},
        // {"id":144,"idParent":1,"idItem":144,"sLabel":"xxx","iStatus":null,"dtmCreate":"2021-04-15 11:51:53","dtmModif":"2023-02-27 15:18:28","dtmDiscBegin":null,"dtmDiscEnd":null,"sDiscCode":"","sItmCode1":"4710088432513","sItmCode2":"30060","sSupCode":"3","sCatCode":"dep001","sSizeCode":"1858ml","sTypeCode":"uni003","sType1Code":"mnu060","sType2Code":"snu0690","sDescr":"xxx","sImg":"","iPrice":58,"iPrice1":58,"iPrice2":null,"iQtyMax":null,"iQtyMin":10,"iQty":null,"sGrpCode":"_PA","sFlgReturn":"","sFlgActif":"","sFlgMenu":"_","iInvQty":7,"iFreeItmId":null,"sFreeItmCode1":null,"sFreeItmLabel":null,"iFreeQty":null,"sFreeSizeCode":null,"sFreeItmSize":null,"sFreeTypeCode":null,"sFreeItmType":null,"iItmIsPromo":0,"iPromoQty":null,"iQtyCnt":null,"iPrice4":null,"iFlgFzn":1,"iFlgFreeFzn":0,"sFreeItmTitle":null}]




        refPreferredRecs.value.map(function(objElement, objIdx, objArray) {
          objElement.iQty = 1;
          refMenuRecs.value.push(objElement);
        });

      }
      /**** With callback (end) ****/
      
      
      function delPreferredDone(myResp) {
        console.log('vwsPreferred.delPreferredDone: myRespStr='+JSON.stringify(myResp)); // prov_debug
        // ==> myRespStr={"resp":{"errCode":501,"errMsg":"Invalide function"}}
        // ==> myRespStr={"resp":{"errCode":502,"errMsg":"502:Could not save record"}}
        // ==> myRespStr={"resp":{"errCode":1,"errMsg":"OK"}}
        
        let reqParams = { 
              criteria: "idParent = "+refClientRec.value.id, 
              orderby: "sItmCode1 ASC",
              };
        switch(myResp.resp.errCode) {
          case 1: // OK: done successfully
            refResp.value.errCode = myResp.resp.errCode;
            refResp.value.errMsg = myResp.resp.errMsg;
            
            // KO ascPreferredGetItemRecs(reqParams);  // KO // Without callback, the record can not be altered at loading
            ascPreferredGetItemRecs(reqParams, reqCallback);  // Callback is required if altering the record is required at loading
        
            alert(refDico.value.msgPreferredDeleted);
            break;
          case 501: // errMsg501 = 'Invalide function';       // Unexpected function
            alert(refDico.value.errPreferredDel);
            break;
        }
      } // delPreferredDone
      function delPreferred(preferredId) {
        console.log('vwsPreferred.delPreferred: DEBUG: preferredId='+preferredId+', clientId='+refClientRec.value.id);  // prov_debug
        
        refRec.value.errCode = 0; // Reset errCode
        refRec.value.id = preferredId;
        // Submit request to remote REST API services to get record matching the provided id
        let myParams = {
            fct: 'delete',
            rec: refRec.value,
        };
        
        ascPreferredDeleteRec(myParams, delPreferredDone); // Callback is required if altering the record is required at loading
      } // delPreferred
      
      function openPage(pgName) {
        console.log('cpnHeader.openPage: pgName='+pgName);
        // this.$router.push({name:pgName,params:{pgAction:'init',prmId:''}});
        router.push({name:pgName,params:{pgAction:'init',prmId:''}});
      }
      
      return {
        refDico,
        refPageAttr,
        refItmTypeAttr,
        refPreferredResp,
        refPreferredLoading,
        refPreferredRecs,
        refMenuRecs,
        delPreferred,
      }
    },

  };

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>