<template>
  <span v-if="refDicoLoading"><br><br>Loading ...<br><br></span>
  <span v-else>
  
  <!-- div id="myApp" -->
    <div id="myAppHeader">
      <cpnHeader />
    </div>
    <div id="myAppContent">
      <!-- debug --
      my content
      1<br>2<br>3<br>4<br>5<br>6<br>7<br>8<br>9<br>
      a1<br>2<br>3<br>4<br>5<br>6<br>7<br>8<br>9<br>
      b1<br>2<br>3<br>4<br>5<br>6<br>7<br>8<br>9<br>
      c1<br>2<br>3<br>4<br>5<br>6<br>7<br>8<br>9<br>
      d1<br>2<br>3<br>4<br>5<br>6<br>7<br>8<br>9<br>
      e1<br>2<br>3<br>4<br>5<br>6<br>7<br>8<br>9<br>
      f1<br>2<br>3<br>4<br>5<br>6<br>7<br>8<br>9<br>
      g1<br>2<br>3<br>4<br>5<br>6<br>7<br>8<br>9<br>
      h1<br>2<br>3<br>4<br>5<br>6<br>7<br>8<br>9<br>
      -- debug -->
      <router-view />
    </div>
    <div id="myAppFooter">
      <cpnFooter />
    </div>
  <!-- /div -->
  
  </span>
</template>

<script>
  import { ref, computed, onMounted } from 'vue';
  import useReferential from '@/modules/mdlReferential.js';
  import useClientApi from '@/services/svcClientApi.js';
  import cpnHeader from '@/components/cpnHeader'
  import cpnFooter from '@/components/cpnFooter'
  
  export default {
    name: 'myapp',
    components: {
      cpnHeader,
      cpnFooter,
    },
    setup() {
      // setup (begin)
      const { refDicoLoading, refDicoLang, refDicoAttr, refPageAttr, ascSetLang, refSlctMenu, fctExtractMenu, fctExtractSubmenu } = useReferential();
      let { ascClientFindRec, ascClientSaveRec, ascClientDeleteRec, refClientResp, refClientLoading, refClientRec } = useClientApi();

      // let refDico = ref({});
      
     onMounted(async () => {
        ascSetLang('tw', reqCallback);
        
        // const title = getTitle(this)
        // if (title) {
        //  document.title = title
        // }
        // ENCODE/DECODE specials characters: https://www.w3docs.com/tools/html-encoder/
        document.title = '\u5cf6\u4e3b\u7684\u5973\u5152';
        // console.log('myApp.onMounted: DEBUG: refDicoLoading='+refDicoLoading.value);
        
        // Add <script src='/inc/myTools.js' />
        let recaptchaScript = document.createElement('script');
        recaptchaScript.setAttribute('src', '/inc/myTools.js');
        document.head.appendChild(recaptchaScript);
      });
      
      /**** With callback (begin) ****/
      function reqCallback(resp) {
        
        // refDico.value = resp.recDico;
        
        console.log('myApp.reqCallback: DEBUG: refDicoLoading='+refDicoLoading.value);  // debug
      }
      /**** With callback (end) ****/
      
      return {
        refDicoLoading,
        // refDico: computed(() => refDicoAttr.value),
      }
      // setup (end)
    },
    metaInfo() {
      return {
        title: "toto",
        meta: [
          { name:"viewport", content:"width=device-width, initial-scale=2.0, user-scalable=0, minimum-scale=1.0, maximum-scale=1.0" },
          { name:"apple-mobile-web-app-capable", content:"yes"},
        ],
      }
    },
    
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  
</style>
