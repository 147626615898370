<template>

  <p>/ {{ refSlctMenu.topMenuName }} / {{ refSlctMenu.subMenuName }} </p>
  
  <div style="width:100%;">
    <center>
    
<!--
    <span v-if="refIsLoading"><br><br>Loading ...<br><br></span>
    <span v-else>
-->
      <div class="myMenuDescr">
        <!-- TODO_DEBUG_cpnItmLst: refSlctMenu.img: {{ refSlctMenu.img }}<br> -->
        <img v-bind:src="'/appimg/'+refSlctMenu.img" style="width:100%;max-width:600px;" >
      </div>
    
      <div class="myCatItm" v-for="tmpRec in refMenuRecs" :key="tmpRec.id">
        <cpnItmRec v-bind:refItmRec="tmpRec"></cpnItmRec>
      </div>
    
      <br>&nbsp;
<!--
    </span>
-->
    </center>

  </div>
  
</template>

<script>
  import {
    ref, computed, onActivated, onBeforeMount, onBeforeUnmount, onBeforeUpdate,
    onDeactivated, onErrorCaptured, onMounted, onUnmounted, onUpdated, onRenderTracked, onRenderTriggered
  } from 'vue';
  import router from '@/router/index.js';
  import { useRoute } from 'vue-router';
  import useTools from '@/utils/vjsTools.js';
  import useReferential from '@/modules/mdlReferential.js';
  import useClientApi from '@/services/svcClientApi.js';
  import useItemApi from '@/services/svcItemApi.js';  
  import usePreferredApi from '@/services/svcPreferredApi.js';
  import useCartApi from '@/services/svcCartApi.js';
  import cpnItmRec from '@/components/cpnItmRec';

      let { ascClientFindRec, ascClientSaveRec, ascClientDeleteRec, refClientResp, refClientLoading, refClientRec } = useClientApi();
      const { ascItemGetRecs, refItemLoading, refItemResp, refItemRecs, refMenuLoading, refMenuRecs, updateMenuRecs } = useItemApi();
      let { ascPreferredFindRec, ascPreferredSaveRec, ascPreferredDeleteRec, refPreferredResp, refPreferredLoading, refPreferredRec } = usePreferredApi();
      let { fctCartAddItem, ascCartGetItemRecs, ascCartFindOrdRec, ascCartSaveRec, ascCartDeleteRec, refCartResp, refCartLoading, refCartRec, refCartItemRecs } = useCartApi();

  export default {
    components: {
      cpnItmRec,
    },
    setup() {
      const route = useRoute();
      let { tlsNavTo, tlsNavBack } = useTools();
      const { refDicoLang, refDicoAttr, refPageAttr, refItmTypeAttr, ascSetLang, refSlctMenu, fctExtractMenu, fctExtractSubmenu } = useReferential();
      
      let refDico = ref({});
      let refMenu = ref({});
      let refSubmenu = ref({});
      
      let refResp = ref({});
      let refRec = ref({});
      let refRecs = ref([]);
      let refIsLoading = ref(true);
     
      onBeforeMount(() => console.log('before mount'));       // Event triggered on open page
      // onMounted(() => console.log('mounted'));             // Event triggered on open page
      onBeforeUpdate(() => console.log('before update'));     // Event triggered on open page
      onUpdated(() => console.log('updated'));                // Event triggered on open page
      onBeforeUnmount(() => console.log('before unmount'));   // Event triggered on leaving page
      onUnmounted(() => console.log('unmounted'));            // Event triggered on leaving page
      onActivated(() => console.log('activated'));
      onDeactivated(() => console.log('deactivated'));
      onErrorCaptured(() => console.error('error captured'));
      onRenderTracked(() => console.log('render tracked'));
      onRenderTriggered(() => console.log('render triggered'));
      
      onMounted(async () => {
        refDico.value = refDicoAttr.value;
        
        console.log('cpnItmLst.onMounted: DEBUG: pgAction='+refPageAttr.value.pgAction+', pgName='+refPageAttr.value.pgName); // prov_debug
        console.log('cpnItmLst.onMounted: DEBUG: topMenuCode='+refSlctMenu.value.topMenuCode+', subMenuCode='+refSlctMenu.value.subMenuCode); // prov_debug
        // ==> refSlctMenu={"topMenuCode":"","topMenuName":"","subMenuCode":"","subMenuName":"","fctCallback":"","img":"mnu000.jpg","descr":"...","fctCallbackMainMenu":"","fctCallbackTopMenu":""}
        // ==> submenuCode=snu0110, topMenuCode=mnu010, subMenuCode=snu0110

        // ==> pgAction=init, pgName=myMenu01Products
        // console.log('cpnItmLst.onMounted: DEBUG: refItmTypeAttrStr='+JSON.stringify(refItmTypeAttr.value));  // prov_debug
        
        refMenu.value = fctExtractMenu();
        // console.log('cpnItmLst.onMounted: DEBUG: refMenuStr='+JSON.stringify(refMenu.value));  // prov_debug
        
        
        // sFlgMenu: 1234567: flg to show item in corresponding menu
        let reqParams = { criteria:"", orderby:"sItmCode1 ASC", pgName:refPageAttr.value.pgName, flgTopMenu:false};
        switch(refPageAttr.value.pgName) {
          case 'myMenu02Discount':      reqParams.criteria = "sFlgMenu LIKE '%2%'"; break;
          case 'myMenu03NewProducts':   reqParams.criteria = "sFlgMenu LIKE '%3%'"; break;
          case 'myMenu04Exclusive':     reqParams.criteria = "sFlgMenu LIKE '%4%'"; break;
          case 'myMenu05BestSellers':   reqParams.criteria = "sFlgMenu LIKE '%5%'"; break;
          case 'myMenu06Wholesale':     reqParams.criteria = "sFlgMenu LIKE '%6%'"; break;
          case 'myMenu07Distribution':  reqParams.criteria = "sFlgMenu LIKE '%7%'"; break;
          case 'myMenu01Products':      
            // console.log('cpnItmLst.onMounted: DEBUG: topMenuCode='+refSlctMenu.value.topMenuCode+', subMenuCode='+refSlctMenu.value.subMenuCode);  // prov_debug
            if (refSlctMenu.value.topMenuCode != "") {
              reqParams.flgTopMenu=true;
              reqParams.flgAllItems=false;
            } else {
              reqParams.flgTopMenu=false;
              reqParams.flgAllItems=true;
            }
            break;
          default: break;
        }
        console.log('cpnItmLst.onMounted: DEBUG: criteria='+reqParams.criteria+', pgName='+refPageAttr.value.pgName); // prov_debug
        // ascItemGetRecs(reqParams);  // Without callback, the record can not be altered at loading
        ascItemGetRecs(reqParams, reqCallback);  // Callback is required if altering the record is required at loading
      });
      
      /**** Without callback (begin) ****
      // Without callback, the record can not be altered at loading
      refResp = computed(() => {
        // console.log('cpnItmLst.computed: DEBUG: refResp=refItemRespStr='+JSON.stringify(refItemResp.value));  // debug
        return refItemResp.value;
      });
      
      refRecs = computed(() => {
        return refMenuRecs.value;
      });
      
      **** Without callback (end) ****/
      
      /**** With callback (begin) ****/
      // Callback is required if altering the record is required at loading
      function reqCallback(myResp) {
        console.log('cpnItmLst.reqCallback: DEBUG: myRespStr='+JSON.stringify(myResp.resp));  // prov_debug
        // ==> myRespStr={"errCode":101,"errMsg":"Record not found"}
        
        refResp.value = myResp.resp;
        // You may alter the record here
        // ...
        
        // List of item type
        // console.log('cpnItmLst.reqCallback: DEBUG: refItmTypeAttrStr='+JSON.stringify(refItmTypeAttr.value));  // prov_debug
        // ==> 
        // refItmType.value = refItmTypeAttr.value;
        // Set default sTypeCode if not found
        // refRec.value.sTypeCode = tlsCheckElementKey(refItmType.value, refRec.value.sTypeCode, true);  // true:return first item type if not found

        /*
        switch(refPageAttr.pgName) {
          case 'myMenu001Products':
            updateMenuRecs();
            break;
          // case 'myMenu02Discount':
          default:
            break;
        }
        */
        // updateMenuRecs();
        
        window.scrollTo(0,0); // Scroll to top of the page
        
      }
      /**** With callback (end) ****/
      
      return {
        refDico,
        refMenu,
        refSubmenu,
        refSlctMenu,
        refPageAttr,
        refItmTypeAttr,
        refIsLoading: computed(() => refItemLoading.value),
        refResp,
        refMenuRecs,
      }
    },

  };

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>