<template>
  
  <div>
    
    <center>
    <br>
    
    <span v-if="refIsLoading"><br><br>Loading ...<br><br></span>
    <span v-else>
      <form novalidate @submit.prevent="onSubmit" v-on:change="frmChanged()" >

          <br>
          {{ refDico.msgSingin }}
          <br>{{ refDico.msgSingin2 }}
          <br>&nbsp;
          <!-- // ==> errCode = [1 ... 99]:OK message, [100 ... 899]:Error message, [900 ... 999]:Error message, [[1000 ... 999]:Process aborted -->
          <span v-if="(refResp.errCode > 0) && (refResp.errCode <= 99)"><div class="divOk">{{ refResp.errMsg }}<br>&nbsp;</div></span>
          <span v-if="(refResp.errCode > 100) && (refResp.errCode <= 499)"><div class="divOk">{{ refResp.errMsg }}<br>&nbsp;</div></span>
          <span v-if="refResp.errCode == 901"><div class="divError">{{ refDico.msgErrInvalidPsw }}<br>&nbsp;</div></span>
          <span v-if="refResp.errCode == 903"><div class="divError">{{ refDico.msgErrMissingDigits }}<br>&nbsp;</div></span>
          <span v-if="refResp.errCode == 904"><div class="divError">{{ refDico.msgErrEmailUsed }}<br>&nbsp;</div></span>
          <span v-if="refResp.errCode == 905"><div class="divError">{{ refDico.msgErrMobileUsed }}<br>&nbsp;</div></span>
          <span v-if="refResp.errCode >= 500"><div class="divError">{{ refResp.errMsg }}<br>&nbsp;</div></span>
          <br>

          <div class="divclient">
            <table class="clientinfo" border="0">
              <tr>
                <td style="width:20%">{{ refDico.lblMobile }} (*)</td>
                <td style="width:80%"><input type="text" id="sMobile" ref="refMobile" v-model="refRec.sMobile" /></td>
              </tr>
              <tr>
                <td>{{ refDico.lblEmail }}</td>
                <td><input type="text" id="sEmail" v-model="refRec.sEmail" /></td>
              </tr>
              <tr>
                <td>{{ refDico.lblUsrPassword }} (*)</td>
                <td><input id="sPassword" v-model="refRec.sPassword" v-on:change="frmCheckPasswordDigits()" v-bind:placeholder="refDico.infoPassword" type="password" /></td>
              </tr>
              <tr>
                <td>{{ refDico.lblUsrPassword2 }} (*)</td>
                <td><input id="sPassword2" v-model="refRec.sPassword2" v-on:change="frmCheckPassword()" v-bind:placeholder="refDico.lblUsrPassword2" type="password" /></td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>{{ refDico.lblUsrPswSize }}</td>
              </tr>
              
              <tr>
                <td>{{ refDico.lblUsrName }} (*)</td>
                <td>
                  <select id="sGenderCode" v-model="refRec.sGenderCode">
                    <option value="M">{{ refDico.lblGenderM }}</option>
                    <option value="F">{{ refDico.lblGenderF }}</option>
                  </select> 
                  <input style="width: auto;" type="text" id="sFirstName" v-model="refRec.sFirstName" v-bind:placeholder="refDico.infoFirstName" />
                </td> 
                <!-- <td>{{ refDico.lblSvcLabel }} </td>
                <td>
                  <select v-model="refRec.sDepartment" :key="refDepartment" >
                    <option v-for="(name, value) in refDepartment" :key="value" v-bind:value="value">{{ name }}</option>
                  </select>
                </td> -->
              </tr>
              
              <tr>
                <td>{{ refDico.lblUsrMsgMode }}</td> 
                <td colspan="1" align="left">
                  <input style="width: auto;" id="sMsgModeM" name="sMsgMode" v-model="refRec.sMsgMode" type="radio" value="M"> {{ refDico.lblUsrMsgModeM }}
                  <input style="width: auto;" id="sMsgModeE" name="sMsgMode" v-model="refRec.sMsgMode" type="radio" value="E"> {{ refDico.lblUsrMsgModeE }}
                </td>
              </tr>
              <tr>
                <br><br>
              </tr>
            </table>
          </div>
          

          
          <div class="divclient">
          <table class="clientinfo" border="0">
            <tr>
              <td style="width:20%">{{ refDico.lblBizCode }}</td>
              <td style="width:80%"><input type="text" id="sComCode" v-model="refRec.sComCode" /></td>
            </tr>
            <tr>
              <td>{{ refDico.lblCompanyName }}</td>
              <td><input type="text" id="sComName" v-model="refRec.sComName" /></td>
            </tr>
            <tr>
              <td>{{ refDico.lblUsrTel }}</td>
              <td><input type="text" id="sComTel" v-model="refRec.sComTel" /></td>
            </tr>
            <tr>
              <td>{{ refDico.lblUsrFax }}</td>
              <td><input type="text" id="sComFax" v-model="refRec.sComFax" /></td>
            </tr>
            
            <tr>
              <td>{{ refDico.lblProvince }} / {{ refDico.lblCity }}</td>
              <td colspan="1" nowrap="1">
                <select v-model="refRec.sProvince" :key="refState" style="width:50%;" >
                  <option v-for="(name, value) in refState" :key="value" v-bind:value="value">{{ name }}</option>
                </select> 
                <select v-model="refRec.sCity" :key="refCity" style="width:50%;" >
                  <option v-for="(name, value) in refCity" :key="value" v-bind:value="value">{{ name }}</option>
                </select></td>
            </tr>
            <tr>
              <td>
                {{ refDico.lblZipCode }}
              </td>
              <td>
                <input type="text" id="sZipCode" v-model="refRec.sZipCode" style="width:60px;" />
              </td>
            </tr>
            <tr>
              <td>{{ refDico.lblAddr1 }}</td>
              <td colspan="1"><input type="text" id="sAddr1" v-model="refRec.sAddr1" style="width:99%;"/></td>
            </tr>
            
          </table>
          </div>
          <br>
          <br>
          <a class="btnStd01" v-on:click="frmAction('submit')"><i class="fa fa-check fa-1x"></i>&nbsp;{{ refDico.lblSignin }}</a>

 
      </form>
    </span>
    
    <br>&nbsp;
    </center>
  </div>  
  
</template>

<script>
  import {
    ref, computed, onActivated, onBeforeMount, onBeforeUnmount, onBeforeUpdate,
    onDeactivated, onErrorCaptured, onMounted, onUnmounted, onUpdated, onRenderTracked, onRenderTriggered
  } from 'vue';
  import router from '@/router/index.js'
  import { useRoute } from 'vue-router';
  import myApp from '@/myApp.vue';
  import useReferential from '@/modules/mdlReferential.js';
  import useTools from '@/utils/vjsTools.js'; 
  import useClientApi from '@/services/svcClientApi.js';
  
  export default {

    setup() {
      const route = useRoute();
      const { refDicoLang, refDicoAttr, refPageAttr, refDepartmentAttr, ascSetLang, fctExtractState, fctExtractCity } = useReferential();
      const { tlsCheckElementKey } = useTools();
      let { ascClientFindRec, ascClientCheckRec, ascClientSaveRec, ascClientDeleteRec, refClientResp, refClientLoading, refClientRec } = useClientApi();
      
      let refDico = ref({});
      
      let refDepartment = ref({});
      let refState = ref({});
      let refCity = ref({});
      
      let refResp = ref({});
      let refRec = ref({});
      let refIsLoading = ref(true);
      
      let refMobile = ref(null);
      
      
      
      // onBeforeMount(() => console.log('before mount'));
      // onMounted(() => console.log('mounted'));
      // onBeforeUpdate(() => console.log('before update'));
      // onUpdated(() => console.log('updated'));
      // onBeforeUnmount(() => console.log('before unmount'));
      // onUnmounted(() => console.log('unmounted'));
      // ??? onActivated(() => console.log('activated'));                // ???
      // ??? onDeactivated(() => console.log('deactivated'));            // ???
      // ??? onErrorCaptured(() => console.error('error captured'));     // ???
      // ??? onRenderTracked(() => console.log('render tracked'));       // ???
      // ??? onRenderTriggered(() => console.log('render triggered'));   // ???
      
      onBeforeMount(async () => {
        console.log('vwsClientRegister.onBeforeMount: pgAction='+route.params.pgAction+',id='+route.params.prmId);
      }); // onBeforeMount
      
      onMounted(async () => {
        console.log('vwsClientRegister.onMounted: pgAction='+route.params.pgAction+',id='+route.params.prmId);
        refDico.value = refDicoAttr.value;
        refPageAttr.value.title = refDico.value.tleSignin;
        
        refIsLoading.value = true;
        refState.value = fctExtractState();
        
        frmAction('new');
        console.log('vwsClientRegister.onMounted: DEBUG: refRecStr='+JSON.stringify(refRec.value));  // debug
        refIsLoading.value = false;
        
      }); // onMounted

      /**** Without callback (begin) ****
      // Without callback, the record can not be altered at loading
      refResp = computed(() => {
        // console.log('vwsClientRegister.computed: DEBUG: refResp=refClientRespStr='+JSON.stringify(refClientResp.value));  // debug
        return refClientResp.value;
      });
      refRec = computed(() => {
        // console.log('vwsClientRegister.computed: DEBUG: refRec=refClientRecStr='+JSON.stringify(refClientRec.value));  // debug
        return refClientRec.value;
      });
      **** Without callback (end) ****/
      
      /**** With callback (begin) ****
      // Callback is required if altering the record is required at loading
      function reqCallback(resp) {
        // Set data of the record
        refResp.value = resp.resp;
        refRec.value = resp.rec;
        console.log('vwsClientRegister.reqCallback: DEBUG: respStr='+JSON.stringify(resp));  // prov_debug
        // ==> respStr={"resp":{"errCode":101,"errMsg":"Record not found"},"rec":{}}
        // ==> respStr={"resp":{"errCode":1,"errMsg":"OK"},"rec":{"id":1,"iStatus":1,"dtmCreate":"2020-01-01T23:00:00.000Z","dtmModif":null,"dtmValidMob":null,"dtmValidEmail":null,"sPassword":"","sMobile":"123456789","sEmail":"jpn_email@yahoo.com","sGenderCode":"M","sFirstName":"Jack","sLastName":"SMITH","sAddr1":"1 bd Elysée","sAddr2":"1 FL","sZipCode":"99000","sCity":"NewCity","sProvince":"County","sMsgMode":"E","sCheckCode":null,"sLevel":"A","sNote":null,"sPassword2":""}}
        
        if (refResp.value.errCode == 1) {
          // Record loaded correctely. Rset error message
          refResp.value.errCode = 0;
          refResp.value.errMsg = '';
        }
        
        // You may alter the record here
        // ...
        
        updateSelect();
        
        // console.log('vwsClientRegister.reqCallback: DEBUG: refRecStr='+JSON.stringify(refRec.value));  // prov_debug
        // ==> 
      }
      **** With callback (end) ****/
      
      function updateSelect() {
        // List of department
        // console.log('vwsItemFrm.updateSelect: DEBUG: refDepartmentAttrStr='+JSON.stringify(refDepartmentAttr.value));  // debug
        // ==> refDepartmentAttrStr={"dep001":"Sales services","dep002":"Dairy services","dep003":"Sauce services","dep004":"Marketing services"}
        refDepartment.value = refDepartmentAttr.value;
        // Set default sDepartment if not found
        refRec.value.sDepartment = tlsCheckElementKey(refDepartment.value, refRec.value.sDepartment, true);  // true:return first department if not found
        
        // Set default sProvince if not found
        refRec.value.sProvince = tlsCheckElementKey(refState.value, refRec.value.sProvince, true);  // true:return first stateCode if not found
        
        // Get refCity related to the sProvince
        refCity.value = fctExtractCity(refRec.value.sProvince);
        // Set default sCity if not found
        refRec.value.sCity = tlsCheckElementKey(refCity.value, refRec.value.sCity, true);  // true:return first stateCode if not found
      }
      
      function frmChanged() {
        console.log('vwsClientRegister.frmChanged: DEBUG: sProvince='+refRec.value.sProvince); // prov_debug
        // ==> sProvince=dep020
        
        // Get refCity related to the sProvince
        refCity.value = fctExtractCity(refRec.value.sProvince);
        console.log('vwsClientRegister.frmChanged: DEBUG: refCityStr='+JSON.stringify(refCity.value));  // debug
        // Set default sCity if not found
        refRec.value.sCity = tlsCheckElementKey(refCity.value, refRec.value.sCity, true);  // true:return first stateCode if not found
        
      } // frmChanged
      
      function frmCheckPassword() {
        console.log('vwsClientRegister.frmCheckPassword: DEBUG: refPsw='+refRec.value.sPassword+', psw2='+refRec.value.sPassword2); // prov_debug
        
        if ((refRec.value.sPassword == '') || (refRec.value.sPassword != refRec.value.sPassword2) || (refRec.value.sPassword2.length < 8)) {
          refResp.value.errCode = 901; // 901: Invalid password
        } else {
          refResp.value.errCode = 0; // Idle
        }
      } // frmCheckPassword
      
      function frmCheckPasswordDigits() {
        console.log('vwsClientRegister.frmCheckPasswordDigits: DEBUG: Psw='+refRec.value.sPassword); // prov_debug
        if (refRec.value.sPassword.length < 8) {
            refResp.value.errCode = 903; // 903: Missing Digits
        }  else {
          refResp.value.errCode = 0; // Idle
        }
        
      } // frmCheckPasswordDigits


      function frmChkMobileDone(myResp) {
        console.log('vwsClientRegister.frmChkMobileDone: myRespStr='+JSON.stringify(myResp)); // prov_debug
        // ==> myRespStr={"resp":{"errCode":101,"errMsg":"Record not found"},"rec":{}}

        // console.log('vwsClientRegister.frmChkMobileDone: errCode='+myResp.resp.errCode); // prov_debug
        if (myResp.resp.errCode == 1) {
          // Mobile already used by another client
          refResp.value.errCode = 905;	// msgErrMobileUsed
        } else {
          // Check done. Save the record
          frmActionInsert();
        }
      }
      function frmChkMobile() {
        let sCriteria;
        let myParams;

        if (refRec.value.sMobile && refRec.value.sMobile != '') {
          // Mobile has been modified. Check if not already used by another client (begin)
          console.log('vwsClientRegister.frmChkMobile: Chk mobile='+refRec.value.sMobile); // prov_debug
              // Submit request to remote REST API services to get record matching the provided id
              sCriteria = " sMobile = '"+refRec.value.sMobile+"'";
              myParams = {
                  rec: refRec.value,
                  tblCriteria: sCriteria,
              };
              ascClientCheckRec(myParams, frmChkMobileDone); // Callback is required if altering the record is required at loading
          // Mobile has been modified. Check if not already used by another client (begin)
          } else {
            // Discard mobile check
            console.log('vwsClientRegister.frmChkMobile: Empty mobile='+refRec.value.sMobile); // prov_debug
            frmActionInsert();
          }
      }

      function frmChkEmailDone(myResp) {
        console.log('vwsClientRegister.frmChkEmailDone: myRespStr='+JSON.stringify(myResp)); // prov_debug
        // ==> myRespStr={"resp":{"errCode":1,"errMsg":"OK"},"rec":{"id":9,"iStatus":null,"dtmCreate":"2021-07-30 16:32:37","dtmModif":"2022-11-14 13:27:37","dtmValidMob":null,"dtmValidEmail":null,"sPassword":"12345678","sMobile":"0963511876","sEmail":"sylvie6421@yahoo.com","sGenderCode":"F","sFirstName":"盧文淑","sLastName":"","sAddr1":"中山路23號","sAddr2":"","sZipCode":"880","sCity":"cty0110","sProvince":"dep010","sMsgMode":"E","sCheckCode":"","sLevel":"","sNote":"","sComCode":"","sComName":"中山","sComTel":"","sComFax":"","sDepartment":"dep001","sPassword2":"12345678","dtmExpPsw2":"2022-11-14 13:30:08","sBizAddr1":null,"sBizAddr2":null,"sBizZipCode":null,"sBizCity":"cty0110","sBizProvince":"dep010"}}
        
        // console.log('vwsClientRegister.frmChkEmailDone: errCode='+myResp.resp.errCode); // prov_debug
        if (myResp.resp.errCode == 1) {
          // Email already used by another client
          refResp.value.errCode = 904;	// msgErrEmailUsed
        } else {
          frmChkMobile();
        }
      }
      function frmChkEmail() {
        let sCriteria;
        let myParams;

        if (refRec.value.sEmail && refRec.value.sEmail != '') {
          // Email addr has been modified. Check if not already used by another client (begin)
          console.log('vwsClientRegister.frmChkEmail: Chk email='+refRec.value.sEmail+', sMsgMode='+refRec.value.sMsgMode); // prov_debug
              // Submit request to remote REST API services to get record matching the provided id
              sCriteria = " sEmail = '"+refRec.value.sEmail+"'";
              myParams = {
                  rec: refRec.value,
                  tblCriteria: sCriteria,
              };
              ascClientCheckRec(myParams, frmChkEmailDone); // Callback is required if altering the record is required at loading
          // Email addr has been modified. Check if not already used by another client (begin)
          } else {
            // Discard Email check
            console.log('vwsClientRegister.frmChkEmail: Empty email='+refRec.value.sEmail+', sMsgMode='+refRec.value.sMsgMode); // prov_debug
            frmChkMobile();
          }
      }

      function frmActionDone(myResp) {
        console.log('vwsClientRegister.frmActionDone: myRespStr='+JSON.stringify(myResp)); // prov_debug
        // ==> myRespStr={"resp":{"errCode":501,"errMsg":"Invalide function"}}
        // ==> myRespStr={"resp":{"errCode":502,"errMsg":"502:Could not save record"}}
        // ==> myRespStr={"resp":{"errCode":1,"errMsg":"OK"}}
        
        switch(myResp.resp.errCode) {
          case 1: // OK: done successfully
            refResp.value.errCode = myResp.resp.errCode;
            refResp.value.errMsg = myResp.resp.errMsg;
            openPage('pgClientRegisterDone');
            break;
          case 501: // errMsg501 = 'Invalide function';       // Unexpected function
            alert('ERROR: '+myResp.errMsg); // prov_debug
            break;
        }
      } // frmActionDone

      function frmActionInsert() {
        console.log('vwsClientRegister.frmActionDone: refRecStr='+JSON.stringify(refRec.value)); // prov_debug

        refRec.value.errCode = 0; // Reset errCode
        refRec.value.sPassword2 = ''; // Reset sPassword2 in order to avoid asking to change password at login
              // Submit request to remote REST API services to get record matching the provided id
              let myParams = {
                  fct: 'insert',
                  rec: refRec.value,
              };
              ascClientSaveRec(myParams, frmActionDone); // Callback is required if altering the record is required at loading
      } // frmActionInsert

      function frmAction(myFct) {
        // myFct = new, insert, update, delete
        let isValid = true;
        let myParams;
        
        console.log('vwsClientRegister.frmAction: DEBUG: myFct='+myFct); // prov_debug
        // refIsLoading.value = true;
        switch(myFct) {
          case 'new':
            refClientRec.value = {};
            refRec.value = {};

            refResp.value.errCode = 0;
            refResp.value.errMsg = '';
            updateSelect();
            // refMobile.value.focus();
            break;
          case 'submit':
            if ((refRec.value.sPassword == '') || (refRec.value.sPassword != refRec.value.sPassword2) || (refRec.value.sPassword.length < 8) ) {
              isValid = false;
              refResp.value.errCode = 901;
            }

            if (refRec.value.sEmail) refRec.value.sEmail = refRec.value.sEmail.trim();
            if (refRec.value.sMobile) refRec.value.sMobile = refRec.value.sMobile.trim();
            if ((refRec.value.sMsgMode == 'E') && (!refRec.value.sEmail || (refRec.value.sEmail == ''))) {
              // Email not defined
              isValid = false;
              refResp.value.errCode = 902;	// msgErrMissingRequired
            }
            if ((refRec.value.sMsgMode == 'M') && (!refRec.value.sMobile || (refRec.value.sMobile == ''))) {
              // Mobile not defined
              isValid = false;
              refResp.value.errCode = 902;	// msgErrMissingRequired
            }
            if (refRec.value.sFirstName.length == 0) {
              isValid = false;
              refResp.value.errCode = 903;
            }

            if (isValid == true) {
              frmChkEmail();
            } else {
              console.log('vwsClientRegister.frmAction: Ko: myFct='+myFct+',id='+refRec.value.id+',psw='+refRec.value.sPassword+',psw2='+refRec.value.sPassword2);
              refRec.value.errCode = 901; // 901: Invalid password
            }
            break;
        }
        // refIsLoading.value = false;
                
      } // frmAction
      
      function openPage(pgName) {
        console.log('vwsClientLogin.openPage: pgName='+pgName);
        router.push({name:pgName,params:{pgAction:'init',prmId:''}});
      }
      
      return {
        refDico,
        refDepartment,
        refState,
        refCity,
        refIsLoading,	// refIsLoading: computed(() => refClientLoading.value),
        refResp,
        refRec,
        frmChanged,
        frmCheckPassword,
        frmCheckPasswordDigits,
        frmAction,
        openPage,
      }
    }, // onMounted
  } // setup
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .divOk {
    color: #00FF00;
  }
  .divError {
    color: #FF0000;
  }
  input:disabled {
    background-color: #F0F0F0;
  }

 
  

</style>