/*
* File: vsjTools.js
* Note: Useful functions
* Usage:
*   import vjsTools from '@/utils/vjsTools.js';
*/

import router from '@/router/index.js';
import useReferential from '@/modules/mdlReferential.js';

const crypto = require('crypto');

let navHistories = [{"navQuery":{"name":"pgHome","params":{"pgAction":"init","prmId":""}}}];

export default function useTools() {
  
  let { refSlctMenu, refCpnHeader } = useReferential();
  
  /* ==================================================================================
   * Get random number between specified values
   *
   */
  function tlsGetRndNumber(min, max) {  
	return Math.floor(
		Math.random() * (max - min) + min
	)
  }	// tlsGetRndNumber
  
  function tlsGetDateStr(objDate, mask) {
    if (objDate == null) objDate = new Date();  // Get current date and time

    // adjust 0 before single digit date
    let date = ("0" + objDate.getDate()).slice(-2); // current date
    let month = ("0" + (objDate.getMonth() + 1)).slice(-2); // current month
    let year = objDate.getFullYear(); // current year
    let hours = ("0" + (objDate.getHours() + 1)).slice(-2); // current hours
    let minutes = ("0" + (objDate.getMinutes() + 1)).slice(-2); // current minutes
    let seconds = ("0" + (objDate.getSeconds() + 1)).slice(-2); // current seconds

    let dateStr;
    switch(mask) {
        case 'YYYMMDDhhmmssxxxx':
          dateStr = year + month + date + hours + minutes + seconds;
          dateStr += tlsGetRndNumber(1000, 9999);
          break;
        case 'YYYMMDDhhmmss':
          dateStr = year + month + date + hours + minutes + seconds;
          break;
        case 'YYYMMDD':
          dateStr = year + month + date;
          break;
        case 'hhmmss':
          dateStr = hours + minutes + seconds;
          break;
        case '_YYYMMDD':
          dateStr = year + "-" + month + "-" + date;
          break;
        case '_hhmmss':
          dateStr = hours + ":" + minutes + ":" + seconds;
          break;
        case '_hhmm':
          dateStr = hours + ":" + minutes;
          break;
        default:
          dateStr = year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds;
          break;
    }
    return dateStr;
  }
  
  function tlsIsMobile() {
    let flgMobile = true;
    
    let winWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    console.log('vjsTools.winWidth: DEBUG: winWidth='+winWidth);  // prov_debug
    if (winWidth >= 980) {
      flgMobile = false;
    }
  
    return flgMobile;
  }
  
  function tslUpdateMenuInfo(query) {
    // query: {name:pgName,params:{pgAction:'init',prmId:''}};
    //        {name:'pgMenu00Items',params:{pgAction:'init',prmMainMenu:'1'}};
    //        {name:'pgMenu00Items',params:{pgAction:'init',prmTopMenu:menuCode}};
    let descrMenu;
        
    console.log('vjsTools.tslUpdateMenuInfo: query='+JSON.stringify(query));  // prov_debug
    // ==> {"name":"pgMenu02Discount","params":{"pgAction":"init","prmId":""}}
    // ==> {"name":"pgMenu00Items","params":{"pgAction":"init","prmMainMenu":"1"}}
    // ==> {"name":"pgMenu00Items","params":{"pgAction":"init","prmTopMenu":"mnu010"}}
    
    /*
    if (query.pgName == "pgMenu02Discount") {
      if (query.params.prmTopMenu != "") {
        refSlctMenu.value.topMenuCode = query.params.prmTopMenu;
      } else {
        refSlctMenu.value.topMenuCode = '';
      }
    }
    */
    
    // console.log('vjsTools.tslUpdateMenuInfo: refSlctMenu='+JSON.stringify(refSlctMenu.value));  // prov_debug
    // console.log('vjsTools.tslUpdateMenuInfo: refCpnHeader='+JSON.stringify(refCpnHeader.value));  // prov_debug
    
    /*
    if (refSlctMenu.value.callBackUpdateMenuSelected) {
      console.log('vjsTools.tslUpdateMenuInfo: go callBackUpdateMenuSelected');
      refSlctMenu.value.callBackUpdateMenuSelected(query.name);
    }
    */
    if (refCpnHeader.value.callBackUpdateMenuSelected) {
      refCpnHeader.value.callBackUpdateMenuSelected(query.name);
    }
    
    /*
        refMenu.value = fctExtractMenu();
        if (route.params.prmMainMenu == 1) {
          // All items
          console.log('vwsMenu00Items.onMounted: prmMainMenu='+route.params.prmMainMenu);  // prov_debug
          refSubmenu.value = {};
          refSlctMenu.value.topMenuCode = '';
          refSlctMenu.value.topMenuName = '';
          refSlctMenu.value.subMenuCode = '';
          refSlctMenu.value.subMenuName = '';
          refSlctMenu.value.fctCallback = openMainMenu;
          
          console.log('vwsMenu00Items.onMounted: prmMainMenu='+route.params.prmMainMenu);
        } else {
          // Open topmenu & submenu requested: http://mail.enw.io:13080/?pg=items&topmenu=mnu020&submenu=snu0220
          console.log('vwsMenu00Items.onMounted: prmMainMenu='+route.params.prmMainMenu+',prmTopMenu='+route.params.prmTopMenu+',prmSubMenu='+route.params.prmSubMenu);  // prov_debug
          // ==> prmTopMenu=mnu020,prmSubMenu=snu0220
          
          refSubmenu.value = {};
          refSlctMenu.value.topMenuCode = route.params.prmTopMenu;
          refSlctMenu.value.topMenuCode = tlsCheckElementKey(refMenu.value, refSlctMenu.value.topMenuCode, true);  // true:return first menu if not found
          refSlctMenu.value.topMenuName = refMenu.value[refSlctMenu.value.topMenuCode];
          
          if (route.params.prmSubMenu) {
            // Open topMenu & subMenu
            // http://mail.enw.io:13080/?pg=items&topmenu=mnu020&submenu=snu0220
            refSlctMenu.value.subMenuCode = tlsCheckElementKey(refSubmenu.value, route.params.prmSubMenu, true);  // true:return first menu if not found
            refSlctMenu.value.subMenuName = refSubmenu.value[refSlctMenu.value.subMenuCode];
            refSlctMenu.value.fctCallback = openSubMenu;
            console.log('vwsMenu00Items.onMounted: openSubMenu: prmTopMenu='+route.params.prmTopMenu+',prmSubMenu='+route.params.prmSubMenu);  // prov_debug
          } else {
            // Open topMenu
            // http://mail.enw.io:13080/?pg=items&topmenu=mnu020
            refSlctMenu.value.subMenuCode = '*';
            refSlctMenu.value.subMenuName = '';
            refSlctMenu.value.fctCallback = openTopMenu;
            console.log('vwsMenu00Items.onMounted: openTopMenu: prmMenu='+route.params.prmMenu+',prmSubMenu='+route.params.prmSubMenu);  // prov_debug
          }
        }
        */
  }
  
  function tlsNavTo(query) {
    if (navHistories.length > 10) {
      navHistories.splice(0,1);
    }
    navHistories.push({navQuery: query});
    console.log('vjsTools.tlsNavTo: navHistoriesLen='+navHistories.length);
    console.log('vjsTools.tlsNavTo: query='+JSON.stringify(query));
    // ==> navHistories=[{"navQuery":{"name":"pgMenu00Items","params":{"pgAction":"init","prmTopMenu":"mnu010"}}}]
    
    /*
    if (refCpnHeader.value.callBackUpdateMenuSelected) {
      refCpnHeader.value.callBackUpdateMenuSelected(query.name);
    }
    */
    /*
    if (refSlctMenu.value.callBackUpdateMenuSelected) {
      console.log('vjsTools.tlsNavTo: go callBackUpdateMenuSelected');
      refSlctMenu.value.callBackUpdateMenuSelected(query.name);
    }
    */
    
    tslUpdateMenuInfo(query);
    router.push(query);
    
  }
  
  function tlsNavBack() {
    console.log('vjsTools.tlsNavBack: navHistoriesLen='+navHistories.length);
    console.log('vjsTools.tlsNavBack: navHistories='+JSON.stringify(navHistories));
    
    if (navHistories.length >= 1) {
      let navPrev;
      if (navHistories.length > 1) {
        let navCurrent = navHistories.pop(); // Get the last element and remove it from the array
        navPrev = navHistories[navHistories.length-1]; // Get the element
        console.log('vjsTools.tlsNavBack: navHistoriesLen='+navHistories.length+', navPrev='+JSON.stringify(navPrev));
      } else {
        navPrev = navHistories[0]; // Get the element
        console.log('vjsTools.tlsNavBack: navHistoriesLen='+navHistories.length+', last navPrev='+JSON.stringify(navPrev));
      }
      
      tslUpdateMenuInfo(navPrev.navQuery);
      router.push(navPrev.navQuery);
    }
  }
  
  /* =============================================================================================
   * tlsDecodeUri: Decode value of each element of the provided JSON object.
   * It also iterates to element of type array
   */
  function tlsDecodeUri(objJson) {
    const fctName = 'vjsTools.tlsDecodeUri';
    
    // console.log(fctName+': DEBUG: objJsonStr(1)='+JSON.stringify(objJson));  // debug
    // ==> objJsonStr(1)=[{"dep010":"Penghu","submenu":[{"cty0110":"Makung"},{"cty0120":"Xiyu"}]},{"dep020":"Taiwan","submenu":[{"cty0210":"Taipei"},{"cty0220":"Taichung"},{"cty0230":"Tainan"}]}]
    
    let objKey;
    let objValue;
    let tmpObj;
    let newObj;
    
    if (Array.isArray(objJson)) {
      // Process each element of the array (begin)

      // The map() method creates a new array with the results of calling 
      // a provided function on every element in the calling array.
      // console.log(fctName+': IN: objJsonStr='+JSON.stringify(objJson));  // debug
      // ==>
      /**** Intercept the function (begin) ****
      newObj = objJson.map(function(objElement, objIdx, objArray) {
        // objIdx = [0 ... newObj.length[
        console.log(fctName+': DEBUG: In: objElementStr['+objIdx+'/'+objArray.length+']='+JSON.stringify(objElement));  // debug
        // ==> 
        tmpObj = tlsDecodeUri(objElement);
        console.log(fctName+': DEBUG: Out: tmpObjStr['+objIdx+'/'+objArray.length+']='+JSON.stringify(tmpObj));  // debug
        // ==> 
        return tmpObj;
      });
      **** Intercept the function (end) ****/
      
      /**** Calling directly the function ****/
      newObj = objJson.map(tlsDecodeUri);
      /**** Calling directly the function ****/
      
      // console.log(fctName+': OUT: newObjStr='+JSON.stringify(newObj));  // debug
      // ==>
      
      return newObj;
      // Process each element of the array (end)
    }
    
    for (const prop in objJson) {
      // Decode value of each element of the JSON object (begin)
      // from element:  "btnMoreItems":"%E6%9B%B4%E5%A4%9A%E9%A0%85%E7%9B%AE"
      // to element:    "btnMoreItems":"更多項目"
      objKey = prop;                          // objKey="menuHome" 
      objValue = objJson[objKey];             // objValue=Home
      if (Array.isArray(objValue)) {
        // Process array of objects
        tmpObj = tlsDecodeUri(objValue);
        // console.log(fctName+': DEBUG: tmpObjStr['+objKey+']='+JSON.stringify(tmpObj));  // debug
        // ==> 
        objJson[objKey] = tmpObj;
      } else {
        // console.log(fctName+': DEBUG: objValueStr['+objKey+']='+JSON.stringify(objValue));  // debug
        // ==> 
        if (typeof objValue === "object") {
          // Process an object
          // console.log(fctName+': DEBUG: objValueStr['+objKey+']='+JSON.stringify(objValue));  // debug
          let eleValue;
          for (const eleName in objValue) {
            eleValue = objValue[eleName];
            // console.log(fctName+': DEBUG: objValueStr['+objKey+']: '+eleName+'='+eleValue);  // debug
            objValue[eleName] = decodeURI(eleValue);
          }
        } else {
          // Process a string
          // console.log(fctName+': DEBUG:string: objValue['+objKey+']='+objValue+', typeOf='+(typeof objValue));  // debug
          objJson[objKey] = decodeURI(objValue);
        }
      }
      
      // Decode value of each element of the JSON object (end)
    }
    
    // console.log(fctName+': DEBUG: objJsonStr(2)='+JSON.stringify(objJson));  // debug
    // ==>
    
    return objJson;
  } // tlsDecodeUri
    
  /* =============================================================================================
   * tlsCheckElementKey: Return the provided code if its exists in the JSON object.
   * If not found, return the first code in case where flgFirstRec = true 
   * Ex: let sCity = tlsGetDefaultElement(refCity.value, sCity, true);  // true:return first cityCode if not found
   */
  function tlsCheckElementKey(myObj, eleKey, flgFirstRec) {
    const fctName = 'vjsTools.tlsCheckElementKey';
    
    // console.log(fctName+': DEBUG: refStateStr='+JSON.stringify(myObj));  // debug
    // console.log(fctName+': DEBUG: eleKey='+eleKey+', flg='+flgFirstRec);  // debug
    
    if (myObj[eleKey]) {
      // Found element. Keep it unchanged.
    } else {
      // Element not found. Get default one (begin)
      if (flgFirstRec) {
        // Get default state: the first one of the select list
        let eleValue;
        for ( [eleKey, eleValue] of Object.entries(myObj)) {
          // console.log(fctName+': DEBUG:default sProvince:'+eleKey+':'+eleValue);  // debug
          break;
        }
      } else {
        eleKey = '';
      }
      // Element not found. Get default one (end)
    }
    // console.log(fctName+': DEBUG:default eleKey:'+eleKey);  // debug
    return eleKey;
  } // tlsCheckElementKey
  
  // tlsMd5Encrypt: --------------------------------------------------------------------------------------
  function tlsMd5Encrypt(sValue, sDigest) {
    if (!sDigest) sDigest = 'hex';  // 'hex' / 'base64'
    let sMd5 = crypto.createHash('md5').update(sValue).digest(sDigest);
    return sMd5;
  } // tlsMd5Encrypt
  // tlsSha256Encrypt: --------------------------------------------------------------------------------------
  function tlsSha256Encrypt(sValue, sDigest) {
    const fctName = 'myTools-tlsSha256Encrypt';
    
    if (!sDigest) sDigest = 'hex';  // 'hex' / 'base64'
    let sEncoded = crypto.createHash('sha256').update(sValue).digest(sDigest);
    sEncoded = sEncoded.toUpperCase();
    
    // myLogger.info(fctName+': DEBUG: sValue='+sValue+', sDigest='+sDigest+', sEncoded='+sEncoded); // prov_debug
    
    return sEncoded;
  } // tlsSha256Encrypt
  
  return  {
	tlsGetRndNumber,
    tlsGetDateStr,
    tlsIsMobile,
    tlsNavTo,
    tlsNavBack,
    tlsDecodeUri,         // tlsDecodeUri: Decode value of each element of the provided JSON object. 
    tlsCheckElementKey,   // tlsGetDefaultState: Return the provided code if its exists in the JSON object. 
    tlsMd5Encrypt,
    tlsSha256Encrypt
  }
}
