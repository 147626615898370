<template>
  <!-- span v-if="refDicoLoading"><br><br>Loading ...<br><br></span -->
  <span v-if="refDicoAttr.menuHome">
  
  <div id="cpnHeader">
    <nav class="mainMenu">
      <label id="btnMob" for="drop" class="toggle"><i class="fa fa-navicon fa-2x"></i></label>
      <input type="checkbox" id="drop" />
      <ul class="mainMenu2" >
        <li><a id="pgHome" class='itemActive' v-on:click="openPage('pgHome')"><i class="fa fa-home"></i> {{ refDicoAttr.menuHome }}</a></li>
        
        <li>
        <label for="drop-1" class='toggleMob' ><i class="fa fa-tags"></i> {{ refDicoAttr.menu01Products }}</label>
        <a id="pgMenu00Items" v-on:click="openMainMenu()" class='togglePc' ><i class="fa fa-tags"></i> {{ refDicoAttr.menu01Products }}</a>
        <input type="checkbox" id="drop-1"/>
        <ul>
          <li id="menuCheckBox"><a v-on:click="openMainMenu()"><div id="menuCheckBox2">{{ refDicoAttr.menu01mnu00 }}</div></a></li>
          <li v-for="(name, value) in refMenu" :key="value" v-bind:value="value" id="menuCheckBox"><a v-on:click="openPgMenu(value)"><div id="menuCheckBox2">{{ name }}</div></a></li>
        </ul> 
        </li>
    
        <li><a id="pgMenu02Discount" class='item' v-on:click="openPage('pgMenu02Discount')">{{ refDicoAttr.menu02Discount }}</a></li>
        <li><a id="pgMenu03NewProducts" class='item' v-on:click="openPage('pgMenu03NewProducts')">{{ refDicoAttr.menu03NewProducts }}</a></li>
        <li><a id="pgMenu04Exclusive" class='item' v-on:click="openPage('pgMenu04Exclusive')">{{ refDicoAttr.menu04Exclusive }}</a></li>
        <li><a id="pgMenu05BestSellers" class='item' v-on:click="openPage('pgMenu05BestSellers')">{{ refDicoAttr.menu05BestSellers }}</a></li>
        <li><a id="pgMenu06Wholesale" class='item' v-on:click="openPage('pgMenu06Wholesale')">{{ refDicoAttr.menu06Wholesale }}</a></li>
        <li><a id="pgMenu07Distribution" class='item' v-on:click="openPage('pgMenu07Distribution')">{{ refDicoAttr.menu07Distribution }}</a></li>
        
        
        <span v-if="refClientRec.sMobile" >
        <li>
          <label for="drop-2" class='toggleMob' style="color:white;" ><i class="fa fa-user fa-1x"></i> {{ refClientRec.sFirstName }}</label>
          <a id="pgMenu00Items" class='togglePc' style="color:white;">&nbsp;&nbsp;<i class="fa fa-user fa-1x"></i>&nbsp;&nbsp;{{ refClientRec.sFirstName }}</a>
          <input type="checkbox" id="drop-2"/>
          <ul>
            <li id="menuCheckBox"><a id="pgMenu07Distribution" class='item' v-on:click="openPage('pgClientFrm')"><div id="menuCheckBox2">{{ refDicoAttr.menu114Profile }}</div></a></li>
            <li id="menuCheckBox"><a id="pgMenu07Distribution" class='item' v-on:click="openPage('pgClientSetPsw')"><div id="menuCheckBox2">{{ refDicoAttr.menu115NewPassword }}</div></a></li>
            <li id="menuCheckBox"><a id="pgMenu07Distribution" class='item' v-on:click="openPage('pgOrderLst')"><div id="menuCheckBox2">{{ refDicoAttr.tleCliOrders }}</div></a></li>
            <!-- <li><a id="pgMenu07Distribution" class='item' v-on:click="openPage('pgReturnLst')">{{ refDicoAttr.tleCliReturns }}</a></li> -->
          </ul> 
          
        </li>
        </span>
      </ul>
    </nav>
  
    
      <table border="0" width="100%">
        <tr>
          <td>
            <div class="hdrDesk" ><img alt="https://daozhu.tw" src="@/assets/logo.png"></div>
            <div class="hdrMobile"><img alt="https://daozhu.tw" src="@/assets/logo2.png"></div>
          </td>
          <td valign="top" align="left" width="99%" nowrap="1">
            &nbsp;
          </td>
          
          <td id="headerUser" nowrap="1">
            <tr>
              <!-- Search -->
              <td>
                <input type="text" style="text-align:left; "  id="search" ref="refSearchId" v-model="refSearch" />
                &nbsp;<a class="icoButton" v-on:click="searchItems()" v-bind:title="refDicoAttr.tleSearchItems" ><i class="fa fa-search fa-1x"></i></a>
              </td>
              
              <td>
                <div style="width: 10px;">&nbsp;</div>
              </td>
              
              <!-- Cart / Preferred / Login-Profile / Logout -->
              <td style="text-align:right; ">
                <a class="icoButton" v-on:click="openPage('pgCart1')" v-bind:title="refDicoAttr.tleCart" >
                  <i class="fa" style="font-size:24px;">&#xf07a;</i>
                  <span v-if="refCartItemRecs && refCartItemRecs.length > 0" id='lblCartCount'   style="font-size:18px; color: #F0F0F0;"> {{ refCartRec.iTotalQty }} </span>
                </a>
                
                <span v-if="refClientRec.sMobile" style="padding-top:5px;">
                  &nbsp;<a class="icoButton" v-on:click="openPage('pgPreferred')" v-bind:title="refDicoAttr.tlePreferred" ><i class="fa fa-heart fa-1x"></i></a>
                              
                  &nbsp;<a class="icoButton" v-on:click="openPage('pgClientLogout')" v-bind:title="refDicoAttr.menu111Logout" ><i class="fa fa-sign-out fa-1x"></i></a>&nbsp;
                </span>
                
                <span v-else>
                  &nbsp;<a class="icoButton" v-on:click="openPage('pgClientLogin')" v-bind:title="refDicoAttr.menu111Login" ><i class="fa fa-user fa-1x"></i></a>&nbsp;
                </span>
                
                <a class="icoButton" v-on:click="switchLang()"><i class="fa fa-globe fa-1x"></i></a>
              </td>
            </tr>

          </td>
        </tr>
      </table>
    
  </div>
  
  </span>
  <span v-else><br><br>Loading ...<br><br></span>
</template>

<script>
  import { ref, computed, onBeforeMount, onMounted } from 'vue';
  import router from '@/router/index.js';
  import useTools from '@/utils/vjsTools.js';
  import useReferential from '@/modules/mdlReferential.js';
  import useClientApi from '@/services/svcClientApi.js';
  import useItemApi from '@/services/svcItemApi.js';
  import useCartApi from '@/services/svcCartApi.js';
  import vwsMenu00Items, {selectTopMenu} from '@/views/vwsMenu00Items.vue';

  export default {
    /* props: [ selectTopMenu ], */
    setup() {
      // const route = useRoute();
      let { refDicoLoading, refDicoLang, refDicoAttr, refPageAttr, ascSetLang, refSlctMenu, fctExtractMenu, fctExtractSubmenu, refCpnHeader } = useReferential();
      let { ascClientFindRec, ascClientSaveRec, ascClientDeleteRec, refClientResp, refClientLoading, refClientRec } = useClientApi();
      let { tlsNavTo, tlsNavBack } = useTools();
      let { ascCartGetRecs, ascCartGetItemRecs, ascCartFindRec, ascCartSaveRec, ascCartDeleteRec, refCartResp, refCartLoading, refCartRec, refCartItemRecs, refCartItemFreeRecs, fctCartDelItem, fctCartCreateOrder, fctCartUpdItem, ascFocasPayment, calcCartTotalQty } = useCartApi();
      const { refItemLoading, refItemResp, ascItemGetRecs, setSearchCriteria, updateCatalogRecs } = useItemApi();
      

      let refPage = ref({});
      let refMenu = ref({});
      let refSubmenu = ref({});
      
      let refSearchId = ref(null);
      let refSearch = ref(null);
      
      let prevPgId = 'pgHome';
      // let navHistory = [];
      
      // onBeforeMount(() => console.log('before mount'));
      // onMounted(() => console.log('mounted'));
      // onBeforeUpdate(() => console.log('before update'));
      // onUpdated(() => console.log('updated'));
      // onBeforeUnmount(() => console.log('before unmount'));
      // onUnmounted(() => console.log('unmounted'));
      // ??? onActivated(() => console.log('activated'));                // ???
      // ??? onDeactivated(() => console.log('deactivated'));            // ???
      // ??? onErrorCaptured(() => console.error('error captured'));     // ???
      // ??? onRenderTracked(() => console.log('render tracked'));       // ???
      // ??? onRenderTriggered(() => console.log('render triggered'));   // ???
      
      onBeforeMount(() => {
        console.log('cpnHeader.onBeforeMount');
        // ascSetLang('tw', reqCallback);
      }); // onBeforeMount
      
      onMounted(async () => {
        console.log('cpnHeader.onMounted: ');
        console.log('cpnHeader.onMounted: DEBUG pgName='+refPageAttr.value.pgName);  // prov_debug
        // ==> pgName=undefined
        // ==> pgName=myMenu02Discount
        
        refCpnHeader.value = {};
        refCpnHeader.value.callBackUpdateMenuSelected = updateMenuSelected;
        refSlctMenu.value.callBackUpdateMenuSelected = updateMenuSelected;
        
        // if back button is pressed
        window.onpopstate = function(event) {
          console.log('cpnHeader.BackButton: ' + document.location + ', state: ' + JSON.stringify(event.state));
          // ==> cpnHeader.BackButton: http://mail.enw.io:13080/, state: {"back":"/","current":"/","forward":"/","replaced":false,"position":191,"scroll":{"left":0,"top":0}}
          // history.go(0);
          tlsNavBack();
        };
   
        // ascSetLang('en'); // ==> KO
        // ascSetLang('tw', reqCallback);
        reqCallback({});
      }); // onMounted
      
      /*
      computed(() => {
        // console.log('cpnHeader.computed: DEBUG: refDicoAttrStr='+JSON.stringify(refDicoAttr.value));  // debug
        // ==>
        console.log('cpnHeader.computed: DEBUG: menuHome='+refDicoAttr.value.menuHome);  // debug
        return refDicoAttr.value;
      });
      */
      
      const switchLang = async () => {
        if (refDicoLang.value == 'en') {
          console.log('cpnHeader.switchLang: refDicoLang='+refDicoLang.value+' to tw');
          // await ascSetLang('tw'); // ==> KO
          await ascSetLang('tw', reqCallback);
        } else {
          console.log('cpnHeader.switchLang: refDicoLang='+refDicoLang.value+' to en');
          // await ascSetLang('en'); // ==> KO
          await ascSetLang('en', reqCallback);
        }
      };
      
      /**** With callback (begin) ****/
      function reqCallback(resp) {
        // console.log('cpnHeader.reqCallback: DEBUG: respStr='+JSON.stringify(resp));  // debug
        // ==> {"status":"OK","rec":{"menuHome":"Home","menuShopping":"Shopping","menuShoLst":"Shopping orders","menuShoFrmNew":"New order","menuCliLst":"List of clients","menuCliFrmNew":"New client","menuPurchase":"Purchase","menuPurLst":"Purchase orders","menuPurFrmNew":"New purchase","menuSupLst":"List of suppliers","menuSupFrmNew":"New supplier","menuSetup":"Setup"}}
        
        // refDico.value = resp.recDico;
        // console.log('cpnHeader.reqCallback: DEBUG: menuHome='+refDicoAttr.value.menuHome);  // debug
        
        refMenu.value = fctExtractMenu();
        // console.log('cpnHeader.reqCallback: DEBUG: refMenuStr='+JSON.stringify(refMenu.value));  // debug
        // ==> refMenuStr={"mnu010":"Water","mnu020":"Almighty drink","mnu040":"Instant noodles","mnu060":"Refrigeration drink","mnu080":"Fried meat","mnu090":"Freezer","mnu100":"Condiment"}
        
        if (refPageAttr.value.pgName) {
          // ==> pgName=myMenu02Discount
          // Transform 'myMenu...' into 'pgMen...'
          let tmpPgName = 'pg'+refPageAttr.value.pgName.substring(2);
          console.log('cpnHeader.reqCallback: DEBUG pgName='+refPageAttr.value.pgName+' / '+tmpPgName);  // prov_debug
          updateMenuSelected(tmpPgName);
        } else {
          // ==> pgName=undefined
          console.log('cpnHeader.reqCallback: DEBUG pgName= DEFAULT Home page');  // prov_debug
        }
      }
      /**** With callback (end) ****/
      
      
      
      function updateMenuSelected(newId) {
        console.log('cpnHeader.updateMenuSelected: prevPgId='+prevPgId+', newId='+newId);
        
        let prevSelectedObj;
        let newSelectedObj;
        
        if (prevPgId != '') {
          prevSelectedObj = document.getElementById(prevPgId);
          // if (prevSelectedObj) {
            if (prevPgId == "pgMenu00Items") {
              prevSelectedObj.className = "togglePc";
            } else {
              prevSelectedObj.className = "item";
            }
          // }
          prevPgId = '';
        }
            
        switch(newId) {
          case 'pgHome':
          case 'pgMenu00Items':
          case 'pgMenu02Discount':
          case 'pgMenu03NewProducts':
          case 'pgMenu04Exclusive':
          case 'pgMenu05BestSellers':
          case 'pgMenu06Wholesale':
          case 'pgMenu07Distribution':
            newSelectedObj = document.getElementById(newId);
            if (newId == "pgMenu00Items") {
              newSelectedObj.className = "togglePcActive";
            } else {
              newSelectedObj.className = "itemActive";
            }
            prevPgId = newId;
            break;
          default:
            break;
        }
        
      }
      
      function openPage(pgName) {
        document.getElementById("drop").checked = false;
        document.getElementById("drop-1").checked = false;
        
        console.log('cpnHeader.openPage: pgName='+pgName);
        // updateMenuSelected(pgName);
        
        let query = {name:pgName,params:{pgAction:'init',prmId:''}};
        tlsNavTo(query);

      }
      
      function selectPgMenu(menuCode) {
        // refSlctMenu = ref({topMenuCode:'', topMenuName:'', subMenuCode:'', subMenuName:'' });
        console.log('cpnHeader.selectPgMenu: menuCode='+menuCode);
        // router.push({name:'pgMenu00Items',params:{pgAction:'init',prmTopMenu:menuCode}});
        
        refCpnHeader.value = {};
        refCpnHeader.value.callBackUpdateMenuSelected = updateMenuSelected;
        refSlctMenu.value.callBackUpdateMenuSelected = updateMenuSelected;
        console.log('cpnHeader.selectPgMenu: refSlctMenu='+JSON.stringify(refSlctMenu.value));  // prov_debug
        
        /* */
        if (menuCode == 'mnu00') {
          if (refSlctMenu.value.fctCallbackMainMenu) {
            console.log('cpnHeader.selectPgMenu: DEBUG: to fctCallbackMainMenu refSlctMenuStr='+JSON.stringify(refSlctMenu.value));  // prov_debug
            refSlctMenu.value.fctCallbackMainMenu(menuCode);
          } else {
            console.log('cpnHeader.selectPgMenu: DEBUG: no fctCallbackMainMenu refSlctMenuStr='+JSON.stringify(refSlctMenu.value));  // prov_debug
          }
        } else {
          if (refSlctMenu.value.fctCallbackTopMenu) {
            console.log('cpnHeader.selectPgMenu: DEBUG: to fctCallbackTopMenu refSlctMenuStr='+JSON.stringify(refSlctMenu.value));  // prov_debug
            refSlctMenu.value.fctCallbackTopMenu(menuCode);
          } else {
            console.log('cpnHeader.selectPgMenu: DEBUG: no fctCallbackTopMenu refSlctMenuStr='+JSON.stringify(refSlctMenu.value));  // prov_debug
          }
        }
        /* */
      }
      
      function openMainMenu() {
        document.getElementById("drop").checked = false;
        document.getElementById("drop-1").checked = false;

        console.log('cpnHeader.openMainMenu: ');
        // updateMenuSelected('pgMenu00Items');
        selectPgMenu('mnu00');
        
        let query = {name:'pgMenu00Items',params:{pgAction:'init',prmMainMenu:'1'}};
        tlsNavTo(query);
      } 
      
      function openPgMenu(menuCode) {
        document.getElementById("drop").checked = false;
        document.getElementById("drop-1").checked = false;

        console.log('cpnHeader.openPgMenu: menuCode='+menuCode);
        // updateMenuSelected('pgMenu00Items');
        selectPgMenu(menuCode);
        
        let query = {name:'pgMenu00Items',params:{pgAction:'init',prmTopMenu:menuCode}};
        tlsNavTo(query);
        
      } 
      
      function searchItems() {
        console.log('cpnHeader.searchItems: refSearch='+refSearch.value);
        setSearchCriteria(refSearch.value);
        openMainMenu();
        refSearchId.value.focus();
      }
      
      return {
        refDicoLoading,
        refDicoAttr,
        refMenu,
        refSubmenu,
        refPageAttr,
        refClientRec,
        switchLang,
        selectPgMenu,
        openPage,
        openMainMenu,
        openPgMenu,
        refSearchId,
        refSearch,
        searchItems,
        refCartItemRecs,
        refCartRec,
      }
    },
    
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- style scoped -->
<style>
  @import '/inc/font-awesome.min.css';
  @import '/inc/dzhMenu.css';
</style>
<style scoped>
  h1 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 24px;
    color: #90150D;
  }
  .icoButton:hover {
    cursor: pointer;
    color: #90150D;
  }
</style>
