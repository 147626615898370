import { ref, computed } from "vue";
// import vueCookies from 'vue-cookie';   // npm install vue-cookie --save
import useReferential from '@/modules/mdlReferential.js';
import useItemApi from '@/services/svcItemApi.js';
import useTools from '@/utils/vjsTools.js';

const axios = require("axios");
const { tlsSha256Encrypt, tlsGetDateStr } = useTools();


// Global variable that can be shared state with Composition API
let users = ref([]);	// Array of uers

// Cart
let refCartLoading = ref(false);
// let refCartRecs = ref([]);	// Array of records
let refCartRec = ref({});  // Current record    // API:rtsApiCart.myTblCartOrd
// let refCartRecsNb = ref(0);
let refCartItemRecs = ref([]);	// Array of children records
let refCartItemFreeRecs = ref([]);	// Array of children records (free items)
let refCartItemStdRecs = ref([]);
let refCartItemFznRecs = ref([]);
let refCartItemFreeStdRecs = ref([]);
let refCartItemFreeFznRecs = ref([]);
let refCartResp = ref({errCode:0, errMsg:'159'});

      let refOrdType = ref();	// Type of Payment: creditCard / taiwanPay;
      let refOrdId = ref();

const cookieName_myCartRecs = 'myCartRecs';
const cookieName_myCliId = 'myCliId';
  let { ascItemGetRecs, refItemLoading, refItemResp, refItemRecs, refMenuLoading, refMenuRecs, updateMenuRecs } = useItemApi();
  
export default function useCartApi() {

  
  
      
  /* ==================================================================================
   * ascCartGetRecs: Request REST API services for an array of records
   * reqParams { criteria: "sFlgMenu LIKE '%2%'", } :Input parameters
   */
  /*
  async function ascCartGetRecs(reqParams, fctCallback) {
    const fctName = 'svcCartApi.ascCartGetRecs';
    const reqUrl = '/api/cart/getRecs';
    
    // let reqParams = {};
    refCartLoading.value = true;
    try {
      const { data } = await axios.post(
        reqUrl,
        reqParams
      );
      // console.log(fctName+': DEBUG: retDataStr='+JSON.stringify(data));  // debug
      // ==> retDataStr={"errCode":1,"errMsg":"OK","rec":{},"recsNb":1,"recs":[{"id":1,"iStatus":1,"dtmCreate":"2020-01-01T23:00:00.000Z","dtmModif":null,"dtmValidMob":null,"dtmValidEmail":null,"sPassword":"adm123","sMobile":"123456789","sEmail":"jpn_email@yahoo.com","sGenderCode":"M","sFirstName":"Jack","sLastName":"SMITH","sAddr1":"1 bd Elysée","sAddr2":"1 FL","sZipCode":"99000","sCity":"NewCity","sProvince":"County","sMsgMode":"E","sCheckCode":null,"sLevel":"A","sNote":null}]}      
      
      refCartResp.value.errCode = data.errCode; 
      refCartResp.value.errMsg = data.errMsg;
      switch(data.errCode) {
        case 1: // OK: rec found
          // Get data
          refCartRecs.value = data.recs;
          refCartRecsNb.value = refCartRecs.value.length;
          break;
        default:
          refCartRec.value = [{}];
          refCartRecsNb.value = 0;
          break;
      }
      
      if (fctCallback) {
        // fctCallback has been defined
        // console.log(fctName+': DEBUG: to fctCallback retDataStr='+JSON.stringify(data));  // debug
        let resp = {resp:refCartResp.value, recs: refCartRecs.value};
        fctCallback(resp);
      } else {
        // console.log(fctName+': DEBUG: no callback retDataStr='+JSON.stringify(data));  // debug
      }
      
    } catch (ex) {
      if (ex.response) {
        // Request made and server responded
        console.log(fctName+': EXC: exStatus='+ex.response.status+', exData='+ex.response.data);
        // console.log('exrespdata:'+ex.response.data);  // 9002:DB Exception:2021-04-08 16:59:36 - mdlDbMysql.dbFind
        // console.log('exrespstatus:'+ex.response.status);  // 500
        // console.log('exrespheader:'+ex.response.headers);
      } else {
        console.log(fctName+': EXC: ex='+ex.stack);
      }
    } finally {
      refCartLoading.value = false;
    }
  } // ascCartGetRecs
  */
  
  /* ==================================================================================
   * ascCartGetItemRecs: Request REST API services for an array of records with item data
   * reqParams { criteria: "sFlgMenu LIKE '%2%'", } :Input parameters
   */
  async function ascCartGetItemRecs(reqParams, fctCallback) {
    const fctName = 'svcCartApi.ascCartGetItemRecs';
    const reqUrl = '/api/cart/getItemRecs';
    
    /**** prov_debug ****/
    if (fctCallback) {
      // fctCallback has been defined
      // console.log(fctName+': DEBUG: to fctCallback retDataStr='+JSON.stringify(data));  // debug
      let resp = {resp:refCartResp.value, recs: refCartItemRecs.value};
      fctCallback(resp);
    }
    /**** prov_debug ****/
      
  } // ascCartGetItemRecs
  
  
  /* ==================================================================================
   * ascCartFindOrdRec: Request REST API services for the record having the specified id or custom criteria
   * reqParams { fct: myFct, rec: refRec.value, } :Input parameters
   * fctCallback :Callback function, if defined
   */
  async function ascCartFindOrdRec(reqParams, fctCallback) {
    const fctName = 'svcCartApi.ascCartFindOrdRec';
    const reqUrl = '/api/cart/findOrdRec';
    
    refCartLoading.value = true;
    try {
      // # const { data } is the name set by axios.post. Can not be renamed.
      const { data } = await axios.post(
        reqUrl,
        reqParams   // { fct: myFct, rec: refRec.value, }
      );
      
      console.log(fctName+': DEBUG: dataStr='+JSON.stringify(data));  // prov_debug
      // ==> dataStr={"errCode":1,"errMsg":"OK","rec":{"id":1,"iStatus":1,"dtmCreate":"2020-01-01T23:00:00.000Z","dtmModif":null,"dtmValidMob":null,"dtmValidEmail":null,"sPassword":"adm123","sMobile":"123456789","sEmail":"jpn_email@yahoo.com","sGenderCode":"M","sFirstName":"Jack","sLastName":"SMITH","sAddr1":"1 bd Elysée","sAddr2":"1 FL","sZipCode":"99000","sCity":"NewCity","sProvince":"County","sMsgMode":"E","sCheckCode":null,"sLevel":"A","sNote":null},"recsNb":0,"recs":[{}]}
      
      // errCode = [1 ... 99] :OK message, [100 ... 999] :Error message, value = [1000 ... 999] :Process aborted
      refCartResp.value.errCode = data.errCode; 
      refCartResp.value.errMsg = data.errMsg;
      switch(data.errCode) {
        case 1: // OK: rec found
          // Get data
          refCartRec.value = data.rec;  // API:rtsApiCart.myTblCartOrd
          // You may alter data here
          break;
        case 2: // Record not found
          refCartRec.value = {};
          break;
        default: // Error occured at server sidebar
          refCartRec.value = {};
          break;
      }
      if (fctCallback) {
        // fctCallback has been defined
        // console.log(fctName+': DEBUG: to fctCallback');  // debug
        let resp = {resp:refCartResp.value, rec: refCartRec.value};
        fctCallback(resp);
      } else {
        console.log(fctName+': DEBUG: no callback');  // prov_debug
      }

    } catch (ex) {
      if (ex.response) {
        // Request made and server responded
        console.log(fctName+': EXC: exStatus='+ex.response.status+', exData='+ex.response.data);
        // console.log('exrespdata:'+ex.response.data);  // 9002:DB Exception:2021-04-08 16:59:36 - mdlDbMysql.dbFind
        // console.log('exrespstatus:'+ex.response.status);  // 500
        // console.log('exrespheader:'+ex.response.headers);
      } else {
        console.log(fctName+': EXC: ex='+ex.stack);
      }
    } finally {
      refCartLoading.value = false;
    }

  } // ascCartFindOrdRec
  
  /* ==================================================================================
   * fctCartGetCliId: Extract clientId from the cookies
   */
  function fctCartGetCliId() {
    const fctName = 'svrCartApi.fctCartGetCliId';
    
    let myCliId;
    let sCookies = document.cookie; // [];
        // console.log(fctName+': DEBUG: sCookies='+sCookies);  // prov_debug
        // ==> sCookies=myCartRecs=[{"itmId":154,"itmQty":1}]; myTest=TOTO
        
        if (sCookies && (sCookies != "")) {
          let myCookies = sCookies.split(";");
          let sMyCliId = myCookies.find(
            function(str) {
              str = str.trim();
              // console.log(fctName+'.find: DEBUG: str=['+str+']'+ ' / [' +cookieName_myCliId+']');  // prov_debug
              return str.startsWith(cookieName_myCliId+'=');
            }
          );
          // console.log(fctName+': DEBUG: sMyCartRecs='+sMyCartRecs);  // prov_debug
          // ==> sMyCartRecs=myCartRecs=[{"itmId":154,"itmQty":1}]
          
          if (sMyCliId) {
            // sMyCliId cookie exists (begin)
            myCliId = sMyCliId.trim().substring(cookieName_myCliId.length+1);
            
            console.log(fctName+': DEBUG: sMyCliId='+sMyCliId+', myCliId='+myCliId);  // prov_debug
            // ==> sMyCliId= myCliId=1
            
            // sMyCliId cookie exists (end)
          } else {
            // sMyCliId cookie does NOT exist (beegin)
            console.log(fctName+': DEBUG: doc.cookie: NEW');  // prov_debug
            // sMyCliId cookie does NOT exist (end)
          }
        } else {
          // NO cookies
          console.log(fctName+': DEBUG: NO doc.cookie');  // prov_debug
        }
    
    return myCliId;
  } // fctCartGetCliId
   
   /* ==================================================================================
   * fctCartSetCliId: Set clientId into the cookies
   */
  function fctCartSetCliId(idClient) {
    const fctName = 'svrCartApi.fctCartSetCliId';
    
    // Save the clientId into the cookies
    let dtExpires = new Date();
    dtExpires.setTime(dtExpires.getTime() + 1 * 24 * 60 * 60 * 1000);
    let sExpires = "expires=" + dtExpires.toUTCString();
    document.cookie = cookieName_myCliId+"=" + idClient + ";" + sExpires + ";path=/";
    
    return;
  } // fctCartSetCliId

  /* ==================================================================================
   * ascCartSaveRec: Send a record to save to cookies and eventually REST API services
   * reqParams = {
   *   fct: 'insert',
   *   idClient: refClientRec.value.id,    // Client's ID = undefined if User's login has not yet been done
   *   idItem: itmId,                      // Item's ID
   *   qtyItem: iQty,                      // Nb items
   * };
   */
   async function ascCartSaveRec(reqParams, fctCallback) {
    const fctName = 'svrCartApi.ascCartSaveRec';
    const reqUrl = '/api/cart/saveRec';
    
    console.log(fctName+': DEBUG: reqUrlStr='+JSON.stringify(reqUrl));  // prov_debug
    // ==> reqUrlStr="/api/cart/saveRec"
    
    console.log(fctName+': DEBUG: reqParamsStr='+JSON.stringify(reqParams));  // prov_debug
    // ==> reqParamsStr={"fct":"insert","idClient":1,"idItem":149,"qtyItem":1}

    refCartLoading.value = true;
    try {
      /*
      if (reqParams.idClient) {
        // User's login has been done. Save data to the DB (begin)
        // # const { data } is the name set by axios.post. Can not be renamed.
        const { data } = await axios.post(
          reqUrl,
          reqParams
        );
        
        // console.log(fctName+': DEBUG: dataStr='+JSON.stringify(data));  // debug
        // ==> dataStr={"errCode":201,"errMsg":"Invalide function"}
        refCartResp.value.errCode = data.errCode; 
        refCartResp.value.errMsg = data.errMsg;
        
        // User's login has been done. Save data to the DB (end)
      } else { */
        // User's login has NOT been done. Save data to the cookie (begin)
        let myCartRecs = [];
        let sCookies = document.cookie; // [];
        // console.log(fctName+': DEBUG: sCookies='+sCookies);  // prov_debug
        // ==> sCookies=myCartRecs=[{"itmId":154,"itmQty":1}]; myTest=TOTO
        
        if (sCookies && (sCookies != "")) {
          let myCookies = sCookies.split(";");
          let sMyCartRecs = myCookies.find(
            function(str) {
              str = str.trim();
              // console.log(fctName+'.find: DEBUG: str=['+str+']'+ ' / [' +cookieName_myCartRecs+']');  // prov_debug
              return str.startsWith(cookieName_myCartRecs+'=');
            }
          );
          // console.log(fctName+': DEBUG: sMyCartRecs='+sMyCartRecs);  // prov_debug
          // ==> sMyCartRecs=myCartRecs=[{"itmId":154,"itmQty":1}]
          
          if (sMyCartRecs) {
            // myCartRecs cookie exists (beegin)
            sMyCartRecs = sMyCartRecs.trim();
            sMyCartRecs = sMyCartRecs.substring(cookieName_myCartRecs.length+1);
            // console.log(fctName+': DEBUG: doc.cookie: sMyCartRecs=['+sMyCartRecs+']');  // prov_debug
            myCartRecs = JSON.parse(sMyCartRecs);
            // console.log(fctName+': DEBUG: doc.cookie: myCartRecsStr='+JSON.stringify(myCartRecs));  // prov_debug
            
            // let tmpRec = myCartRecs.find(myEle => myEle.itmId == reqParams.idItem);
            let tmpRecIdx = myCartRecs.findIndex(function(ele){
              // console.log(fctName+'.find: DEBUG: eleStr='+JSON.stringify(ele));  // prov_debug
              // console.log(fctName+'.find: DEBUG: ele.itmId='+ele.itmId+'/'+reqParams.idItem);  // debug
              return ele.itmId == reqParams.idItem; 
            });
            
            // console.log(fctName+': DEBUG: tmpRecIdx='+tmpRecIdx+', idItem='+reqParams.idItem);  // prov_debug
            if (tmpRecIdx >= 0) {
              // Record exists in the shopping card. Remove it (begin)
              // console.log(fctName+': DEBUG: Remove tmpRecIdx='+tmpRecIdx);  // prov_debug
              myCartRecs.splice(tmpRecIdx, 1);
              // Record exists in the shopping card. Remove it (begin)
            }
            // console.log(fctName+': DEBUG: myCartRecsStr='+JSON.stringify(myCartRecs));  // prov_debug
            
            // myCartRecs cookie exists (end)
          } else {
            // myCartRecs cookie does NOT exist (beegin)
            console.log(fctName+': DEBUG: doc.cookie: NEW');  // prov_debug
            // myCartRecs cookie does NOT exist (end)
          }
        }
        
        // Append the record to the shopping card
        let myCartRec = {
          itmId: reqParams.idItem,
          itmQty: reqParams.qtyItem
        };
        myCartRecs.push(myCartRec);
        console.log(fctName+': DEBUG: cookie: myCartRecsStr='+JSON.stringify(myCartRecs));  // prov_debug
        
        // Save the data into the cookies
        let dtExpires = new Date();
        dtExpires.setTime(dtExpires.getTime() + 1 * 24 * 60 * 60 * 1000);
        let sExpires = "expires=" + dtExpires.toUTCString();
        document.cookie = cookieName_myCartRecs+"=" + JSON.stringify(myCartRecs) + ";" + sExpires + ";path=/";
        document.cookie = cookieName_myCliId+"=" + reqParams.idClient + ";" + sExpires + ";path=/";
        
        // document.cookie = "myTest=TOTO" + ";" + sExpires + ";path=/";
        
        refCartResp.value.errCode = 1; 
        refCartResp.value.errMsg = 'OK';
    
        // User's login has NOT been done. Save data to the cookie (end)
      /* } */
    
      if (fctCallback) {
        // fctCallback has been defined
        let resp = {resp:refCartResp.value};
        console.log(fctName+': DEBUG: callback: respStr='+JSON.stringify(resp));  // prov_debug
        fctCallback(resp);
      } else {
        console.log(fctName+': DEBUG: no callback');  // prov_debug
      }
      
    } catch (ex) {
      if (ex.response) {
        // Request made and server responded
        console.log(fctName+': EXC: exStatus='+ex.response.status+', exData='+ex.response.data);
        // console.log('exrespdata:'+ex.response.data);  // 9002:DB Exception:2021-04-08 16:59:36 - mdlDbMysql.dbFind
        // console.log('exrespstatus:'+ex.response.status);  // 500
        // console.log('exrespheader:'+ex.response.headers);
      } else {
        console.log(fctName+': EXC: ex='+ex.stack);
      }
    } finally {
      refCartLoading.value = false;
    }
  } // ascCartSaveRec
  
  /* ==================================================================================
   * ascCartCreateOrd: Send the shopping cart and its items to save to REST API services
   */
   async function ascCartCreateOrd(cartRec, cartItemRecs, cartItemFreeRecs, fctCallback) {
    const fctName = 'svrCartApi.ascCartCreateOrd';
    const reqUrl = '/api/shopping/createOrd';
    
    // console.log(fctName+': DEBUG: cartRecStr='+JSON.stringify(cartRec));  // debug
    // 

    // refCartLoading.value = true;
    try {
      
      let tmpItemRecs = [];
      cartItemRecs.map(function(objElement, objIdx, objArray) {
        // objIdx = [0 ... newObj.length[
        let tmpItemRec = {
          iStatus: 0,										/* 0:item of the order, 1:free item */
          id:         objElement.id,
          sItmCode1:  objElement.sItmCode1,
          iQtyOrd:    objElement.iQtyOrd,
          iQtyFree:    0,
          iPrice3:    objElement.iPrice,
          iTotalOrd:  objElement.iTotalOrd,
          sFreeItmCode1:  objElement.sFreeItmCode1,
          iFreeQty:    objElement.iFreeQty,
          iFlgFzn:    objElement.iFlgFzn,
          sGrpCode:    objElement.sGrpCode,
          iIsPromo:    objElement.iIsPromo,
        };
        tmpItemRecs.push(tmpItemRec);
      });
      // cartRec.iItmNb = cartItemRecs.length;
      // cartRec.sOrdCode = tlsGetDateStr(null, 'YYYMMDDhhmmssxxxx').substring(2); // (objDate, mask);
      // console.log(fctName+': DEBUG: sOrdCode='+cartRec.sOrdCode);  // debug
      cartRec.sOrdCode = ''; // Reset orderCode
      
      // Append free Items
      cartItemFreeRecs.map(function(objElement, objIdx, objArray) {
        // objIdx = [0 ... newObj.length[
        let tmpItemRec = {
          iStatus: 1,										/* 0:item of the order, 1:free item */
          id:         objElement.id,
          sItmCode1:  objElement.sItmCode1,
          iQtyOrd:    0,
          iQtyFree:    objElement.iQtyOrd,
          iPrice3:    0,
          iTotalOrd:  0,
          iFlgFzn:    objElement.iFlgFzn,
          sGrpCode:    objElement.sGrpCode,
          iIsPromo:    objElement.iIsPromo,
        };
        tmpItemRecs.push(tmpItemRec);
      });
      cartRec.iItmNb = tmpItemRecs.length;	// Nb of ordered and free items
      calcCartTotal();
    
      // console.log(fctName+': DEBUG: cartRecStr='+JSON.stringify(cartRec));  // debug
      // ==> cartRecStr={"idClient":1,"iSubTotal":1824,"iDelivery":0,"iTotalHT":1824,"iTaxRate":0,"iTotalTax":0,"iTotalTTC":1824,"sDelProvince":"dep010","sDelCity":"cty0110","sDelZipCode":"99000","sDelAddr1":"1 bd Elysée","sDelMobile":"0908823043","sDelGenderCode":"M","sDelFirstName":"Cli JackBis","sComCode":"Cli001b","sAssociation":"1","deliveryMode":"d","paymentMode":"2","invoiceMode":"1","sOrdCode":"2208072207058815","iItmNb":2}
      
      // console.log(fctName+': DEBUG: tmpItemRecsStr='+JSON.stringify(tmpItemRecs));  // debug
      // ==> tmpItemRecsStr=[{"id":154,"sItmCode1":"4710088430755","iQtyOrd":4,"iPrice1":17,"iPrice2":18,"iTotalOrd":72}]
      
      // let nowStr = tlsGetDateStr(null, 'YYYMMDDhhmmss'); // (objDate, mask)
      // let strDate = tlsGetDateStr(null, 'YYYMMDD'); // (objDate, mask)
      // let strTime = tlsGetDateStr(null, 'hhmmss'); // (objDate, mask)
      
      
      // # const { data } is the name set by axios.post. Can not be renamed.
      let reqParams = {
        rec: cartRec,
        itemRecs: tmpItemRecs,
      };
      const { data } = await axios.post(
        reqUrl,
        reqParams
      );
      
      console.log(fctName+': DEBUG: dataStr='+JSON.stringify(data));  // debug
      // ==> dataStr={"errCode":201,"errMsg":"Invalide function"}
      refCartResp.value.errCode = data.errCode; 
      refCartResp.value.errMsg = data.errMsg;
      
      if (fctCallback) {
        // fctCallback has been defined
        console.log(fctName+': DEBUG: callback: dataStr='+JSON.stringify(data));  // prov_debug
        // ==> dataStr={"errCode":1,"errMsg":"idOrder=2, sOrdCode=220714083139101","rec":{},"recsNb":8,"recs":[[{"msgDebug":"DEBUG: NO pending order: "}],[{"msgDebug":"DEBUG: sOrdCode=220714083139101,sDtmCreate=2022-07-14 08:31:39"}],[{"msgDebug":null}],[{"msgInsShopOrd":"idOrder=2, sOrdCode=220714083139101"}],[{"msgInsShopItm":"Promo: iId=154, sItmCode1=4710088430755, iPrice=17/17/18"}],[{"msgExit":"OK: Inserted order(2) having 1 items"}],{"fieldCount":0,"affectedRows":0,"insertId":0,"serverStatus":42,"warningCount":0,"message":"","protocol41":true,"changedRows":0},[{"retcode":0}]]}
        
        refCartResp.value.errMsg = data.errMsg;
        let resp = {resp:refCartResp.value};
        fctCallback(resp);
      } else {
        console.log(fctName+': DEBUG: no callback retDataStr='+JSON.stringify(data));  // prov_debug
      }
      
      
    } catch (ex) {
      if (ex.response) {
        // Request made and server responded
        console.log(fctName+': EXC: exMessage='+ex.message+', exStatus='+ex.response.status+', exData='+ex.response.data);
        // ==> exStatus=500, exData=Error: errCode=9002
        if (fctCallback) {
          let resp = {errCode:501, errMsg:ex.message};
          fctCallback({resp:resp});
        }
      } else {
        console.log(fctName+': EXC: ex='+ex.stack);
      }
    } finally {
      // refCartLoading.value = false;
    }
  } // ascCartCreateOrd
  
  /* ==================================================================================
   * ascCartDeleteRec: Send request to delete a record to REST API services
   */
   async function ascCartDeleteRec(reqParams, fctCallback) {
    const fctName = 'svrCartApi.ascCartDeleteRec';
    const reqUrl = '/api/cart/deleteRec';
    
    // console.log(fctName+': DEBUG: reqParamsStr='+JSON.stringify(reqParams));  // debug
    // ==> reqParamsStr={"id":"STA031"}

    refCartLoading.value = true;
    try {
      // # const { data } is the name set by axios.post. Can not be renamed.
      const { data } = await axios.post(
        reqUrl,
        reqParams
      );
      
      // console.log(fctName+': DEBUG: dataStr='+JSON.stringify(data));  // debug
      // ==> dataStr={"errCode":201,"errMsg":"Invalide function"}
      refCartResp.value.errCode = data.errCode; 
      refCartResp.value.errMsg = data.errMsg;
      
      if (fctCallback) {
        // fctCallback has been defined
        console.log(fctName+': DEBUG: callback: dataStr='+JSON.stringify(data));  // prov_debug
        let resp = {resp:refCartResp.value};
        fctCallback(resp);
      } else {
        console.log(fctName+': DEBUG: no callback retDataStr='+JSON.stringify(data));  // prov_debug
      }
      
      
    } catch (ex) {
      if (ex.response) {
        // Request made and server responded
        console.log(fctName+': EXC: exStatus='+ex.response.status+', exData='+ex.response.data);
        // console.log('exrespdata:'+ex.response.data);  // 9002:DB Exception:2021-04-08 16:59:36 - mdlDbMysql.dbFind
        // console.log('exrespstatus:'+ex.response.status);  // 500
        // console.log('exrespheader:'+ex.response.headers);
      } else {
        console.log(fctName+': EXC: ex='+ex.stack);
      }
    } finally {
      refCartLoading.value = false;
    }
  } // ascCartDeleteRec
  
  /* ==================================================================================
   * fctCartUpdItem: Update an item in current shopping cart
   * Note: data are not saved at the server-side
   * Attributes of reqParams:
   *  idClient: refClientRec.value.id,    // Client's ID
   *  idItem: itmId,                      // Item's ID
   *  qtyItem: iQty,                      // Nb items
   */
   async function fctCartUpdItem(reqParams) {
    const fctName = 'svrCartApi.fctCartUpdItem';
    // const reqUrl = '/api/cart/saveRec';
    
    // console.log(fctName+': DEBUG: reqParamsStr='+JSON.stringify(reqParams));  // debug
    // ==> reqParamsStr={"fct":"insert","idClient":1,"idItem":154,"qtyItem":null}
    
    if (refCartRec.value.idClient) {
      // The shopping cart has been initialized
    } else {
      // Initialize new shopping cart
      refCartRec.value.idClient = reqParams.idClient;
      // refCartItemRecs.value = [];	// Array of children records
    }
    
    let itemRec;
    let iQty = 1*reqParams.qtyItem;
    if (!iQty) iQty = 1;
    
    let cartItemRecsIdx = refCartItemRecs.value.findIndex(function(item){
      // console.log(fctName+'.findIndex: DEBUG: item.id='+item.id+'/'+reqParams.idItem);  // debug
      return item.id == reqParams.idItem; 
    });
    // console.log(fctName+': DEBUG: cartItemRecsIdx='+cartItemRecsIdx+', idItem='+reqParams.idItem);  // debug
    // ==> cartItemRecsIdx=-1, idItem=149
    
    if (cartItemRecsIdx >= 0) {
      // Item already recorded in Shopping Cart. Update its qty. (begin)
      // let freeCode;
      let tmpQtyOrd;
      // let iNbFreeQty;
      // let freeIdx = -1;
      // console.log(fctName+': DEBUG: sFreeItmCode1='+refCartItemRecs.value[cartItemRecsIdx].sFreeItmCode1);  // debug
      // ==> sFreeItmCode1=4710088522061

      /*
      if (refCartItemRecs.value[cartItemRecsIdx].sFreeItmCode1 && refCartItemRecs.value[cartItemRecsIdx].sFreeItmCode1 != '') {
        // Has free item (begin)
        freeCode = refCartItemRecs.value[cartItemRecsIdx].sFreeItmCode1;
        freeIdx = refCartItemFreeRecs.value.findIndex(function(item){ return item.sItmCode1 == freeCode});
        console.log(fctName+': DEBUG: freeCode='+freeCode+', freeIdx='+freeIdx);  // debug
        // ==> freeCode=4710088522061, freeIdx=0
        // Has free item (end)
      }
      */

      refCartResp.value = {errCode:1, errMsg:'OK'};
      switch (reqParams.fct) {
        case 'updQty':
          // Current Qty
          tmpQtyOrd = refCartItemRecs.value[cartItemRecsIdx].iQtyOrd * 1;
          /*
          if (freeIdx >= 0) {
            // Has free item. Remove free qty (begin)
            if (refCartItemRecs.value[cartItemRecsIdx].iPromoQty && refCartItemRecs.value[cartItemRecsIdx].iPromoQty > 0) {
              iNbFreeQty = parseInt(tmpQtyOrd / refCartItemRecs.value[cartItemRecsIdx].iPromoQty) * refCartItemRecs.value[cartItemRecsIdx].iFreeQty;
            } else {
              iNbFreeQty = tmpQtyOrd * refCartItemRecs.value[cartItemRecsIdx].iFreeQty;
            }
            console.log(fctName+': DEBUG: updQty: iQtyOrd='+refCartItemFreeRecs.value[freeIdx].iQtyOrd+' - iNbFreeQty='+iNbFreeQty);  // debug
            refCartItemFreeRecs.value[freeIdx].iQtyOrd -= 1 * iNbFreeQty; // Remove free qty
            // Has free item. Remove free qty (end)
          }
          */
          // New Qty
          tmpQtyOrd += 1 * iQty;
          refCartItemRecs.value[cartItemRecsIdx].iQtyOrd = 1 * tmpQtyOrd;
          /*
          if (freeIdx >= 0) {
            // Has free item. Add free qty (begin)
            if (refCartItemRecs.value[cartItemRecsIdx].iPromoQty && refCartItemRecs.value[cartItemRecsIdx].iPromoQty > 0) {
              iNbFreeQty = parseInt(tmpQtyOrd / refCartItemRecs.value[cartItemRecsIdx].iPromoQty) * refCartItemRecs.value[cartItemRecsIdx].iFreeQty;
            } else {
              iNbFreeQty = tmpQtyOrd * refCartItemRecs.value[cartItemRecsIdx].iFreeQty;
            }
            console.log(fctName+': DEBUG: updQty: iQtyOrd='+refCartItemFreeRecs.value[freeIdx].iQtyOrd+' + iNbFreeQty='+iNbFreeQty);  // debug
            refCartItemFreeRecs.value[freeIdx].iQtyOrd += 1 * iNbFreeQty;	// Remove previous free qty
            // Has free item. Add free qty (end)
          }
          */
          break;
        case 'setQty':
          /*
          // Current Qty
          tmpQtyOrd = refCartItemRecs.value[cartItemRecsIdx].iQtyOrd * 1;
          if (freeIdx >= 0) {
            // Has free item. Remove free qty (begin)
            if (refCartItemRecs.value[cartItemRecsIdx].iPromoQty && refCartItemRecs.value[cartItemRecsIdx].iPromoQty > 0) {
              iNbFreeQty = parseInt(tmpQtyOrd / refCartItemRecs.value[cartItemRecsIdx].iPromoQty) * refCartItemRecs.value[cartItemRecsIdx].iFreeQty;
            } else {
              iNbFreeQty = tmpQtyOrd * refCartItemRecs.value[cartItemRecsIdx].iFreeQty;
            }
            console.log(fctName+': DEBUG: setQty: iQtyOrd='+refCartItemFreeRecs.value[freeIdx].iQtyOrd+' - iNbFreeQty='+iNbFreeQty);  // debug
            refCartItemFreeRecs.value[freeIdx].iQtyOrd -= 1 * iNbFreeQty; // Remove free qty
            // Has free item. Remove free qty (begin)
          }
          */
          // New Qty
          tmpQtyOrd = 1 * iQty;
          refCartItemRecs.value[cartItemRecsIdx].iQtyOrd = 1 * tmpQtyOrd;
          /*
          if (freeIdx >= 0) {
            // Has free item. Add free qty (begin)
            if (refCartItemRecs.value[cartItemRecsIdx].iPromoQty && refCartItemRecs.value[cartItemRecsIdx].iPromoQty > 0) {
              iNbFreeQty = parseInt(tmpQtyOrd / refCartItemRecs.value[cartItemRecsIdx].iPromoQty) * refCartItemRecs.value[cartItemRecsIdx].iFreeQty;
            } else {
              iNbFreeQty = tmpQtyOrd * refCartItemRecs.value[cartItemRecsIdx].iFreeQty;
            }
            console.log(fctName+': DEBUG: setQty: iQtyOrd='+refCartItemFreeRecs.value[freeIdx].iQtyOrd+' + iNbFreeQty='+iNbFreeQty);  // debug
            refCartItemFreeRecs.value[freeIdx].iQtyOrd += 1 * iNbFreeQty;	// Remove previous free qty
            // Has free item. Add free qty (end)
          }
          */
          break;
        default: // Invalid fct
          console.log(fctName+': WARN: invalid fct: '+JSON.stringify(reqParams));  // debug)
          refCartResp.value = {errCode:100, errMsg:'Invalid fct:'+reqParams.fct};
          break;
      }

      calcCartFreeItems();
      calcCartTotal();

      // Item already recorded in Shopping Cart. Update its qty. (end)
    } else {
      // errCode = [1 ... 99] :OK message, [100 ... 999] :Error message, value = [1000 ... 999] :Process aborted
      refCartResp.value = {errCode:100, errMsg:'Record not found'};
    }
    
    return refCartResp.value;
  } // fctCartUpdItem
  
  /* ==================================================================================
   * fctCartAddItem: Add an item to current shopping cart
   * Note: data are not saved at the server-side
   * Attributes of reqParams:
   *  idClient: refClientRec.value.id,    // Client's ID
   *  idItem: itmId,                      // Item's ID
   *  qtyItem: iQty,                      // Nb items
   */
   function fctCartAddItem(reqParams) {
    const fctName = 'svrCartApi.fctCartAddItem';
    // const reqUrl = '/api/cart/saveRec';
    
    console.log(fctName+': DEBUG: reqParamsStr='+JSON.stringify(reqParams));  // debug
    // ==> reqParamsStr={"fct":"insert","idClient":1,"idItem":154,"qtyItem":null}
    
    if (refCartRec.value.idClient) {
      // The shopping cart has been initialized
    } else {
      // Initialize new shopping cart
      refCartRec.value.idClient = reqParams.idClient;
      // refCartItemRecs.value = [];	// Array of children records
    }
    
    let itemRec;
    let iNbFreeQty = -1;
    let iQty = reqParams.qtyItem;
    if (!iQty || iQty <= 0) iQty = 1;

    let itemRecsIdx = refItemRecs.value.findIndex(function(item){
      return item.id == reqParams.idItem;
    });
    // console.log(fctName+': DEBUG: itemRecsIdx='+itemRecsIdx+', idItem='+reqParams.idItem);  // debug
    // ==> itemRecsIdx=0, idItem=154
    // ==> itemRecsIdx=28, idItem=149
    
    let cartItemRecsIdx = refCartItemRecs.value.findIndex(function(item){ 
      // console.log(fctName+'.findIndex: DEBUG: item.id='+item.id+'/'+reqParams.idItem);  // debug
      return item.id == reqParams.idItem; 
    });
    // console.log(fctName+': DEBUG: cartItemRecsIdx='+cartItemRecsIdx+', idItem='+reqParams.idItem);  // debug
    // ==> 

    
    if (cartItemRecsIdx < 0) {
      // Item not yet recorded in Shopping Cart. Add it to the shopping cart.
      itemRec = { idItem: reqParams.idItem, iQtyOrd: iQty };

      if (!refItemRecs.value[itemRecsIdx].iFlgFzn) refItemRecs.value[itemRecsIdx].iFlgFzn = 0;

      itemRec.iQtyOrd0 = 1 * itemRec.iQtyOrd;
      itemRec.id = refItemRecs.value[itemRecsIdx].id;
      itemRec.sItmCode1 = refItemRecs.value[itemRecsIdx].sItmCode1;
      itemRec.sSizeCode = refItemRecs.value[itemRecsIdx].sSizeCode;
      itemRec.sTypeCode = refItemRecs.value[itemRecsIdx].sTypeCode;
      itemRec.sType1Code = refItemRecs.value[itemRecsIdx].sType1Code;
      itemRec.sType2Code = refItemRecs.value[itemRecsIdx].sType2Code;
      itemRec.sLabel = refItemRecs.value[itemRecsIdx].sLabel;
      itemRec.sDescr = refItemRecs.value[itemRecsIdx].sDescr;
      itemRec.sImg = refItemRecs.value[itemRecsIdx].sImg;
      itemRec.iPrice1 = refItemRecs.value[itemRecsIdx].iPrice1;
      itemRec.iPrice2 = refItemRecs.value[itemRecsIdx].iPrice2;
      itemRec.sFlgMenu = refItemRecs.value[itemRecsIdx].sFlgMenu;
      itemRec.sGrpCode = refItemRecs.value[itemRecsIdx].sGrpCode;		// Groupcode: E:Exclusive, P:Penghu only
      itemRec.iIsPromo = refItemRecs.value[itemRecsIdx].iIsPromo;		// 0:not in promo, 1:promo
      /*if (itemRec.iPrice2) { 
        itemRec.iTotalOrd = refItemRecs.value[itemRecsIdx].iPrice2 * itemRec.iQtyOrd;
      } else {
        itemRec.iTotalOrd = refItemRecs.value[itemRecsIdx].iPrice1 * itemRec.iQtyOrd;
      }*/
      itemRec.sFreeItmCode1 = refItemRecs.value[itemRecsIdx].sFreeItmCode1;
      itemRec.iFreeQty = 1 * refItemRecs.value[itemRecsIdx].iFreeQty;
      itemRec.iPromoQty = 1 * refItemRecs.value[itemRecsIdx].iPromoQty;
      itemRec.iFlgFzn = refItemRecs.value[itemRecsIdx].iFlgFzn;

      refCartItemRecs.value.push(itemRec);

      if (refItemRecs.value[itemRecsIdx].sFreeItmCode1 && refItemRecs.value[itemRecsIdx].sFreeItmCode1 != '') {
        // Has free item (begin)
        let freeCode = refItemRecs.value[itemRecsIdx].sFreeItmCode1;
        let freeIdx = refItemRecs.value.findIndex(function(item){ return item.sItmCode1 == freeCode});
        console.log(fctName+': DEBUG: freeCode='+freeCode+', freeIdx='+freeIdx);  // debug

        if (freeIdx >= 0) {
          // Free item exists (begin)
          if (!refItemRecs.value[freeIdx].iFlgFzn) refItemRecs.value[freeIdx].iFlgFzn = 0;

          let freeIdx0 = refCartItemFreeRecs.value.findIndex(function(item){ return item.sItmCode1 == freeCode});
          if (freeIdx0 < 0) {
            // Free item not yet registered. Do it (begin)
            let freeRec = {
              id: refItemRecs.value[freeIdx].id,
              sItmCode1: refItemRecs.value[freeIdx].sItmCode1,
              iFreeQty: 0,
              iPromoQty: 0,
              iQtyOrd: 0,
              sSizeCode: refItemRecs.value[freeIdx].sSizeCode,
              sTypeCode: refItemRecs.value[freeIdx].sTypeCode,
              sType1Code: refItemRecs.value[freeIdx].sType1Code,
              sType2Code: refItemRecs.value[freeIdx].sType2Code,
              sLabel: refItemRecs.value[freeIdx].sLabel,
              sDescr: refItemRecs.value[freeIdx].sDescr,
              sImg: refItemRecs.value[freeIdx].sImg,
              iPrice: 0,
              iPrice1: 0,
              iPrice2: 0,
              iFlgFzn: refItemRecs.value[freeIdx].iFlgFzn,
              sGrpCode: refItemRecs.value[freeIdx].sGrpCode,
              iIsPromo: refItemRecs.value[freeIdx].iIsPromo,
            };
            refCartItemFreeRecs.value.push(freeRec);
            console.log(fctName+': DEBUG: new sFreeItmCode1='+refItemRecs.value[itemRecsIdx].sFreeItmCode1+', iNbFreeQty='+iNbFreeQty);  // debug
            // Free item not yet registered. Do it (begin)
          } else {
            // Free item already registered
          }
          // Free item exists (begin)
        } else {
          console.log(fctName+': ERROR: Could not find sFreeItmCode1='+refItemRecs.value[itemRecsIdx].sFreeItmCode1);  // debug
        }
        // Has free item (end)
      }

    } else {
      // Item already recorded in Shopping Cart. Update its qty.(begin)
      /*
      if (refCartItemRecs.value[cartItemRecsIdx].sFreeItmCode1 && refCartItemRecs.value[cartItemRecsIdx].sFreeItmCode1 != '') {
        // Has free item (begin)
        let tmpQtyOrd = refCartItemRecs.value[cartItemRecsIdx].iQtyOrd * 1;
        let cartItemFreeRecsIdx = refCartItemFreeRecs.value.findIndex(function(item){ 
          // console.log(fctName+'.findIndex: DEBUG: item.id='+item.id+'/'+reqParams.idItem);  // debug
          return item.id == reqParams.idItem; 
        });
        if (cartItemFreeRecsIdx >= 0) {
          // Update qty of free item 
            console.log(fctName+': DEBUG: exist: tmpQtyOrd='+tmpQtyOrd+', iPromoQty='+refCartItemRecs.value[cartItemRecsIdx].iPromoQty+', iFreeQty='+refCartItemRecs.value[cartItemRecsIdx].iFreeQty+', iNbFreeQty='+iNbFreeQty);  // debug
          if (refCartItemRecs.value[cartItemRecsIdx].iPromoQty && refCartItemRecs.value[cartItemRecsIdx].iPromoQty > 0) {
            iNbFreeQty = parseInt(tmpQtyOrd / refCartItemRecs.value[cartItemRecsIdx].iPromoQty) * refCartItemRecs.value[cartItemRecsIdx].iFreeQty;
          } else {
            iNbFreeQty = tmpQtyOrd * refCartItemRecs.value[cartItemRecsIdx].iFreeQty;
          }
          refCartItemFreeRecs.value[cartItemFreeRecsIdx].iQtyOrd -= iNbFreeQty;	// Remove previous free qty
          // Update to new qty
          tmpQtyOrd += 1 * iQty;
          refCartItemRecs.value[cartItemRecsIdx].iQtyOrd = 1 * tmpQtyOrd;
          if (refCartItemRecs.value[cartItemRecsIdx].iPromoQty && refCartItemRecs.value[cartItemRecsIdx].iPromoQty > 0) {
            iNbFreeQty =parseInt(tmpQtyOrd / refCartItemRecs.value[cartItemRecsIdx].iPromoQty) * refCartItemRecs.value[cartItemRecsIdx].iFreeQty;
          } else {
            iNbFreeQty = tmpQtyOrd * refCartItemRecs.value[cartItemRecsIdx].iFreeQty;
          }
          refCartItemFreeRecs.value[cartItemFreeRecsIdx].iQtyOrd += 1 * iNbFreeQty;	// Remove previous free qty
        }
        console.log(fctName+': DEBUG: upd sFreeItmCode1='+refItemRecs.value[cartItemRecsIdx].sFreeItmCode1+', iNbFreeQty='+iNbFreeQty);  // debug
        // Has free item (end)
      } else {
        // Has no free item
        refCartItemRecs.value[cartItemRecsIdx].iQtyOrd += 1 * iQty;
      }
      */
      refCartItemRecs.value[cartItemRecsIdx].iQtyOrd += 1 * iQty;
      if (refCartItemRecs.value[cartItemRecsIdx].iQtyOrd <= 0) {
        // No more qty. Remove the item (begin)
        refCartItemRecs.value.splice(cartItemRecsIdx, 1);
        // No more qty. Remove the item (end)
      }
      // Item already recorded in Shopping Cart. Update its qty.(begin)
    }
    
    calcCartFreeItems();
    calcCartTotal();
    
    refCartResp.value = {errCode:1, errMsg:'OK'};
    
    // console.log(fctName+': DEBUG: refCartRecStr='+JSON.stringify(refCartRec.value));  // debug
    // ==> 
    // console.log(fctName+': DEBUG: refCartItemRecsStr='+JSON.stringify(refCartItemRecs.value));  // debug
    // ==> 
    
    return refCartResp.value;
  } // fctCartAddItem
  
  /* ==================================================================================
   * fctCartDelItem: Delete an item from current shopping cart
   * Note: data are not saved at the server-side
   * Attributes of reqParams:
   *  idClient: refClientRec.value.id,    // Client's ID
   *  idItem: itmId,                      // Item's ID
   */
   async function fctCartDelItem(reqParams) {
    const fctName = 'svrCartApi.fctCartDelItem';
    
    console.log(fctName+': DEBUG: reqParamsStr='+JSON.stringify(reqParams));  // debug
    // ==> 
    
    if (refCartRec.value.idClient) {
      // The shopping cart has been initialized
    } else {
      // Initialize new shopping cart
      refCartRec.value.idClient = reqParams.idClient;
      // refCartItemRecs.value = [];	// Array of children records
    }
    
    let tmpQtyOrd;
    let iNbFreeQty;
    let cartItemRecsIdx = refCartItemRecs.value.findIndex(function(item){ return item.id == reqParams.idItem});
    console.log(fctName+': DEBUG:cartItemRecsIdx='+cartItemRecsIdx);
    if (cartItemRecsIdx >= 0) {
      // Remove the item from the shopping cart (begin)

      let freeCode;
      let freeIdx = -1;
      console.log(fctName+': DEBUG: sFreeItmCode1='+refCartItemRecs.value[cartItemRecsIdx].sFreeItmCode1);  // debug
      tmpQtyOrd = 1 * refCartItemRecs.value[cartItemRecsIdx].iQtyOrd;
      refCartItemRecs.value[cartItemRecsIdx].iQtyOrd = 0;
      
      if (refCartItemRecs.value[cartItemRecsIdx].sFreeItmCode1 && refCartItemRecs.value[cartItemRecsIdx].sFreeItmCode1 != '') {
        // Has free item (begin)
        freeCode = refCartItemRecs.value[cartItemRecsIdx].sFreeItmCode1;
        freeIdx = refCartItemFreeRecs.value.findIndex(function(item){ return item.sItmCode1 == freeCode});
        console.log(fctName+': DEBUG: freeCode='+freeCode+', freeIdx='+freeIdx);  // debug
        
        if (freeIdx >= 0) {
          /*
          console.log(fctName+': DEBUG: iQtyOrd='+refCartItemFreeRecs.value[freeIdx].iQtyOrd+', iQtyOrd='+refCartItemRecs.value[cartItemRecsIdx].iQtyOrd+', iFreeQty='+refCartItemRecs.value[cartItemRecsIdx].iFreeQty);
          if (refCartItemFreeRecs.value[freeIdx].iQtyOrd == (refCartItemRecs.value[cartItemRecsIdx].iQtyOrd * refCartItemRecs.value[cartItemRecsIdx].iFreeQty)) {
 
            // Remove free item (begin)
           refCartItemFreeRecs.value.splice(freeIdx, 1);
            // Remove free item (end)
          }
          */
          // tmpQtyOrd = 1 * refCartItemRecs.value[cartItemRecsIdx].iQtyOrd;
          if (refCartItemRecs.value[cartItemRecsIdx].iPromoQty && refCartItemRecs.value[cartItemRecsIdx].iPromoQty > 0) {
             iNbFreeQty = parseInt(tmpQtyOrd / refCartItemRecs.value[cartItemRecsIdx].iPromoQty) * refCartItemRecs.value[cartItemRecsIdx].iFreeQty;
             console.log(fctName+': DEBUG: iNbFreeQty1='+iNbFreeQty+', tmpQtyOrd='+ tmpQtyOrd+', iPromoQty='+refCartItemRecs.value[cartItemRecsIdx].iPromoQty+', iFreeQty='+refCartItemRecs.value[cartItemRecsIdx].iFreeQty);
             // ==> iNbFreeQty1=0, tmpQtyOrd=0, iPromoQty=2, iFreeQty=1
          } else {
             iNbFreeQty = tmpQtyOrd * refCartItemRecs.value[cartItemRecsIdx].iFreeQty;
             console.log(fctName+': DEBUG: iNbFreeQty2='+iNbFreeQty+', tmpQtyOrd='+ tmpQtyOrd+', iFreeQty='+refCartItemRecs.value[cartItemRecsIdx].iFreeQty);
          }
          if ((1 * refCartItemFreeRecs.value[freeIdx].iQtyOrd) == (1 * iNbFreeQty)) {
            // Remove free item (begin)
            console.log(fctName+': DEBUG:remove Free freeIdx='+freeIdx+', qty='+ refCartItemFreeRecs.value[freeIdx].iQtyOrd+'/'+iNbFreeQty);
            refCartItemFreeRecs.value.splice(freeIdx, 1);
            // Remove free item (end)
          } else {
            console.log(fctName+': DEBUG:Free not removed freeIdx='+freeIdx+', qty='+ refCartItemFreeRecs.value[freeIdx].iQtyOrd+"/"+iNbFreeQty);
          }
        }
        // Has free item (end)
      }
      console.log(fctName+': DEBUG:remove cartItemRecsIdx='+cartItemRecsIdx);
      refCartItemRecs.value.splice(cartItemRecsIdx, 1);
      calcCartTotal();
      // Remove the item from the shopping cart (end)
    } else {
      console.log(fctName+': error DEBUG:cartItemRecsIdx='+cartItemRecsIdx);
    }
    
    refCartResp.value = {errCode:1, errMsg:'OK'};
    
    console.log(fctName+': DEBUG: refCartRecStr='+JSON.stringify(refCartRec.value));  // debug
    // ==> 
    console.log(fctName+': DEBUG: refCartItemRecsStr='+JSON.stringify(refCartItemRecs.value));  // debug
    // ==> 
    
    return refCartResp.value;
  } // fctCartDelItem


  /* ==================================================================================
   * fctCartUpdate: Update list of Ordered & Free items STD&FZN
   * Note: data are not saved at the server-side
   */
   async function fctCartUpdate() {
    const fctName = 'svrCartApi.fctCartUpdate';
    
    refCartItemStdRecs.value.length = 0;
    refCartItemFznRecs.value.length = 0;
    refCartItemFreeStdRecs.value.length = 0;
    refCartItemFreeFznRecs.value.length = 0;

        // Copy an array of objects

        // List of Order Std Item: refCartItemStdRecs -----------------------------
        refCartItemRecs.value.map(function(objElement, objIdx, objArray) {
          // objIdx = [0 ... newObj.length[
          console.log('vwsCart1.reqCallback: DEBUG: STD iFlgFzn='+objElement.iFlgFzn+', sItmCode1='+objElement.sItmCode1);
          if (objElement.iFlgFzn != 1) {
            objElement.iFlgFzn = 0;
            refCartItemStdRecs.value.push(objElement);
          }
        });
        // List of Order Fzn Item: refCartItemFznRecs ----------------------------
        refCartItemRecs.value.map(function(objElement, objIdx, objArray) {
          // objIdx = [0 ... newObj.length[
          console.log('vwsCart1.reqCallback: DEBUG: FZN iFlgFzn='+objElement.iFlgFzn+', sItmCode1='+objElement.sItmCode1);
          if (objElement.iFlgFzn == 1) {
            refCartItemFznRecs.value.push(objElement);
          }
        });
        console.log('vwsCart1.reqCallback: DEBUG: refCartItemRecsLen='+refCartItemRecs.value.length+', refCartItemStdRecsLen='+refCartItemStdRecs.value.length+', refCartItemFznRecsLen='+refCartItemFznRecs.value.length );  // prov_debug

        // List of Free Std Item: refCartItemFreeStdRecs -------------------------------
        refCartItemFreeRecs.value.map(function(objElement, objIdx, objArray) {
          // objIdx = [0 ... newObj.length[
          if (objElement.iFlgFzn != 1) {
            objElement.iFlgFzn = 0;
            refCartItemFreeStdRecs.value.push(objElement);
          }
        });
        // List of Free Fzn Item: refCartItemFreeFznRecs ------------------------------
        refCartItemFreeRecs.value.map(function(objElement, objIdx, objArray) {
          // objIdx = [0 ... newObj.length[
          if (objElement.iFlgFzn == 1) {
            refCartItemFreeFznRecs.value.push(objElement);
          }
        });


    console.log(fctName+': DEBUG: Ordered items='+refCartItemStdRecs.value.length+'/'+refCartItemFznRecs.value.length);  // debug
    console.log(fctName+': DEBUG: Free items='+refCartItemFreeStdRecs.value.length+'/'+refCartItemFreeFznRecs.value.length);  // debug
  } // fctCartUpdate


  /* ==================================================================================
   * fctCartCreateOrder: Create order from the shopping cart
   * Note: data are not saved at the server-side
   * Attributes of reqParams:
   *  idClient: refClientRec.value.id,    // Client's ID
   */
   function fctCartCreateOrder(reqParams) {
    const fctName = 'svrCartApi.fctCartCreateOrder';
    
    console.log(fctName+': DEBUG: reqParamsStr='+JSON.stringify(reqParams));  // debug
    // ==> 
    
    if (refCartRec.value.idClient) {
      // The client has processed the login. Create order (begin)
      
      refCartResp.value = {errCode:1, errMsg:'OK'};
      // The client has processed the login. Create order (end)
    } else {
      // The login has not been processed
      refCartRec.value.idClient = reqParams.idClient;
      refCartItemRecs.value = [];	// Array of children records
      refCartResp.value = {errCode:-1, errMsg:'ERROR'};
    }
    
    console.log(fctName+': DEBUG: refCartRecStr='+JSON.stringify(refCartRec.value));  // debug
    // ==> 
    
    return refCartResp.value;
  } // fctCartCreateOrder
  
  
  function calcCartFreeItems() {
    const fctName = 'svcCartApi.calcCartFreeItems';

    console.log(fctName+': DEBUG: BEGIN');  // debug
      // Reset qty of free items
      refCartItemFreeRecs.value.forEach( function(elem) {
        elem.iQtyOrd = 0;
      });
      // Calculate free items (begin)
      refCartItemRecs.value.forEach( function(elem) {
        if (elem.sFreeItmCode1 && elem.sFreeItmCode1 != '') {
          // Has free item (begin)
          let iNbFreeQty;
          let freeCode = elem.sFreeItmCode1;
          let freeIdx = refCartItemFreeRecs.value.findIndex(function(item){ return item.sItmCode1 == freeCode});
          if (freeIdx >= 0) {
            // OK: Rec of free item located
            console.log(fctName+': DEBUG: found itmCode='+elem.sItmCode1+', freeCode='+freeCode+', freeIdx='+freeIdx);  // debug
          } else {
            // Rec of free item could not be located. Add it (begin)
            // Free item not yet registered. Do it (begin)
            let freeItmIdx = refItemRecs.value.findIndex(function(item){ return item.sItmCode1 == freeCode});
            let freeRec = {
              id: refItemRecs.value[freeItmIdx].id,
              sItmCode1: refItemRecs.value[freeItmIdx].sItmCode1,
              iFreeQty: 0,
              iPromoQty: 0,
              iQtyOrd: 0,
              sSizeCode: refItemRecs.value[freeItmIdx].sSizeCode,
              sTypeCode: refItemRecs.value[freeItmIdx].sTypeCode,
              sType1Code: refItemRecs.value[freeItmIdx].sType1Code,
              sType2Code: refItemRecs.value[freeItmIdx].sType2Code,
              sLabel: refItemRecs.value[freeItmIdx].sLabel,
              sDescr: refItemRecs.value[freeItmIdx].sDescr,
              sImg: refItemRecs.value[freeItmIdx].sImg,
              iPrice: 0,
              iPrice1: 0,
              iPrice2: 0,
              iFlgFzn: refItemRecs.value[freeItmIdx].iFlgFzn,
              sGrpCode: refItemRecs.value[freeItmIdx].sGrpCode,
              iIsPromo: refItemRecs.value[freeItmIdx].iIsPromo,
            };
            refCartItemFreeRecs.value.push(freeRec);
            freeIdx = refCartItemFreeRecs.value.findIndex(function(item){ return item.sItmCode1 == freeCode});
            console.log(fctName+': DEBUG: Add itmCode='+elem.sItmCode1+', freeCode='+freeCode+', freeIdx='+freeIdx);  // debug
            // Rec of free item could not be located. Add it (begin)
          }

          if (freeIdx >= 0) {
            // Update qty of free item (begin)
            if (elem.iPromoQty && elem.iPromoQty > 0) {
              iNbFreeQty = parseInt(elem.iQtyOrd / elem.iPromoQty) * elem.iFreeQty;
            } else {
              iNbFreeQty = elem.iQtyOrd * elem.iFreeQty;
            }
          console.log(fctName+': DEBUG: Update freeCode['+freeIdx+']='+freeCode+', iQtyOrd='+refCartItemFreeRecs.value[freeIdx].iQtyOrd+', iNbFreeQty='+iNbFreeQty);  // debug
          refCartItemFreeRecs.value[freeIdx].iQtyOrd += iNbFreeQty;
            // Update qty of free item (end)
          }
        // Has free item (end)
        }
      });
      // Calculate free items (end)

      let freeIdx;
      do {
        // Remove free item having iQtyOrd = 0 (begin)
        freeIdx = refCartItemFreeRecs.value.findIndex(function(ele){
          return ele.iQtyOrd == 0; 
        });
        if (freeIdx >= 0) {
          console.log(fctName+': DEBUG: Remove freeCode['+freeIdx+']='+refCartItemFreeRecs.value[freeIdx].sItmCode1);  // debug
          refCartItemFreeRecs.value.splice(freeIdx, 1);
        }
        // Remove free item having iQtyOrd = 0 (end)
      } while (freeIdx >= 0);

    console.log(fctName+': DEBUG: END');  // debug
    return;
  } // calcCartFreeItems
  
  
  function calcCartTotal() {
    //SubTotal
    const fctName = 'svcCartApi.calcCartTotal';
    let total = 0;
    /*refCartItemRecs.value.forEach( function(elem) {
      if (elem.iPrice2) {
        // Promo price
        elem.iPrice = elem.iPrice2;
        elem.iTotalOrd = (1*elem.iPrice2) * (1*elem.iQtyOrd);
      } else {
        // Regular price
        elem.iPrice = elem.iPrice1;
        elem.iTotalOrd = (1*elem.iPrice1) * (1*elem.iQtyOrd);
      }
      total += elem.iTotalOrd;
      console.log(fctName+': DEBUG: iPrice2='+elem.iPrice2 +', iQtyOrd='+elem.iQtyOrd +', iTotalOrd=' +elem.iTotalOrd);  // debug
    });
    refCartRec.value.iSubTotal = total;
    console.log(fctName+': DEBUG: iSubTotal='+refCartRec.value.iSubTotal);  // debug*/

     refCartRec.value.iItmQty1 = refCartItemStdRecs.value.length + refCartItemFreeStdRecs.value.length;	// Nb regular items
     refCartRec.value.iItmQty2 = refCartItemFznRecs.value.length + refCartItemFreeFznRecs.value.length;	// Nb frozen items

    //Delivery
    if (refCartRec.value.deliveryMode == "b") {
      //console.log(fctName+': DEBUG: iDelivery EN BOUTIQUE');  // debug
      refCartRec.value.iDelivery = 0;
    } else {
      if (refCartRec.value.sDelProvince == "dep010") {
        //console.log(fctName+': DEBUG: iDelivery PENGHU');  // debug
        if (refCartRec.value.iSubTotal >= 500) {
          refCartRec.value.iDelivery = 0;
        } else {
          refCartRec.value.iDelivery = 20;
        }
      } else {
        //console.log(fctName+': DEBUG: iDelivery AUTRE');  // debug
        if (refCartRec.value.iSubTotal >= 1500) {
          refCartRec.value.iDelivery = 0;
        } else {
          refCartRec.value.iDelivery = 200;
        }
      }
    }
    console.log(fctName+': DEBUG: iDelivery='+refCartRec.value.iDelivery);  // debug
    
        // iOrdSubTot: myRec.iSubTotal,     // Total price of the order
        // iOrdSubTot=null, iOrdCost=20, iOrdSubTotal=74, iOrdTotal=74
    

    
    
    //TotalTax
    /*if (refCartRec.value.sDelProvince == "dep010") {
      refCartRec.value.iTaxRate = 0;
      refCartRec.value.iTotalTax = 0
    } else {
      refCartRec.value.iTaxRate = 5;
      refCartRec.value.iTotalTax = Math.round((refCartRec.value.iTaxRate * refCartRec.value.iTotalHT) / 100);
    }
    console.log(fctName+': DEBUG: iTotalTax='+refCartRec.value.iTotalTax);  // debug

    
    //TotalTTC
    refCartRec.value.iTotalTTC = refCartRec.value.iTotalTax + refCartRec.value.iTotalHT
    console.log(fctName+': DEBUG: iTotalTTC='+refCartRec.value.iTotalTTC);  // debug
    
    */
    
    //iTotalQty
    calcCartTotalQty()
    console.log(fctName+': DEBUG: iTotalQty='+refCartRec.value.iTotalQty);  // debug
    
    //SubTotalNormalProduct
    let subtotal1 = 0;
    let subtotal1B = 0;	// SubTotal without items in promo
    refCartItemRecs.value.forEach( function(elem) {
      console.log(fctName+': DEBUG: ele.sItmCode1='+elem.sItmCode1+', iFlgFzn='+elem.iFlgFzn+', iPrice1='+elem.iPrice1+', iPrice2='+elem.iPrice2);  // debug
      if(elem.iFlgFzn == 0){
        
        if (elem.iPrice2) {
          // Promo price
          elem.iPrice = elem.iPrice2;
          elem.iTotalOrd = (1*elem.iPrice2) * (1*elem.iQtyOrd);
        } else {
          // Regular price
          elem.iPrice = elem.iPrice1;
          elem.iTotalOrd = (1*elem.iPrice1) * (1*elem.iQtyOrd);
        }
        subtotal1 += elem.iTotalOrd;
        if (elem.iIsPromo != 1) subtotal1B += elem.iTotalOrd; // SubTotal without items in promo
        console.log(fctName+': DEBUG: iPrice2='+elem.iPrice2 +', iQtyOrd='+elem.iQtyOrd +', iTotalOrd=' +elem.iTotalOrd);  // debug
      }
    });
    
    refCartRec.value.iSubTotalNormalProduct = subtotal1;
    console.log(fctName+': DEBUG: iSubTotalNormalProduct='+refCartRec.value.iSubTotalNormalProduct);  // debug
    
    //SubTotalFznProduct
    let subtotal2 = 0;
    let subtotal2B = 0;	// SubTotal without items in promo
    refCartItemRecs.value.forEach( function(elem) {
      if(elem.iFlgFzn == 1){
        
        if (elem.iPrice2) {
          // Promo price
          elem.iPrice = elem.iPrice2;
          elem.iTotalOrd = (1*elem.iPrice2) * (1*elem.iQtyOrd);
        } else {
          // Regular price
          elem.iPrice = elem.iPrice1;
          elem.iTotalOrd = (1*elem.iPrice1) * (1*elem.iQtyOrd);
        }
        subtotal2 += elem.iTotalOrd;
        if (elem.iIsPromo != 1) subtotal2B += elem.iTotalOrd; // SubTotal without items in promo
        console.log(fctName+': DEBUG: iPrice2='+elem.iPrice2 +', iQtyOrd='+elem.iQtyOrd +', iTotalOrd=' +elem.iTotalOrd);  // debug
      }
    });
    refCartRec.value.iSubTotalFznProduct = subtotal2;
    console.log(fctName+': DEBUG: iSubTotalFznProduct='+refCartRec.value.iSubTotalFznProduct);  // debug
    
    //SubTotal
    refCartRec.value.iSubTotal = subtotal1 + subtotal2;
    refCartRec.value.iSubTotalB = subtotal1B + subtotal2B; // SubTotal without items in promo
    console.log(fctName+': DEBUG: iSubTotal='+refCartRec.value.iSubTotal);  // debug
    
    // Calc: PromoTotal
    refCartRec.value.iPromoTotal = 0;
    if (!refCartRec.value.iPromoRate) refCartRec.value.iPromoRate = 0;
    if (!refCartRec.value.iPromoMini) refCartRec.value.iPromoMini = 0;
    if (refCartRec.value.iSubTotalB >= (1*refCartRec.value.iPromoMini)) {
      refCartRec.value.iPromoTotal = parseInt((refCartRec.value.iSubTotalB * refCartRec.value.iPromoRate) / 100);
      console.log(fctName+': DEBUG: iPromoTotal='+refCartRec.value.iPromoTotal+', iPromoRate='+refCartRec.value.iPromoRate);  // debug
    }
    // Calc: Delivery cost
    if (refCartRec.value.iOrdTaxRate == 0) {	// if (refCartRec.value.sDelProvince == "dep010") {
        // Delivery cost in Penghu (begin)
        console.log(fctName+': DEBUG: iDelivery PENGHU');  // debug
        if (refCartRec.value.iSubTotal >= 500) {
          refCartRec.value.iDelivery = 0;
        } else {
          refCartRec.value.iDelivery = 20;
        }
        // Delivery cost in Penghu (end)
    } else {
        // Delivery cost to Taiwan (begin)
      console.log(fctName+': DEBUG: iDelivery AUTRE');  // debug
      
        if (refCartRec.value.iSubTotalNormalProduct >= 2000 || refCartRec.value.iSubTotalNormalProduct == 0) {
          refCartRec.value.iDeliveryNormalProduct = 0;
        } else {
          refCartRec.value.iDeliveryNormalProduct = 150;
        }
        console.log(fctName+': DEBUG: iDeliveryNormalProduct='+refCartRec.value.iDeliveryNormalProduct);  // debug
        
        if (refCartRec.value.iSubTotalFznProduct >= 4000 || refCartRec.value.iSubTotalFznProduct == 0) {
          refCartRec.value.iDeliveryFznProduct = 0;
        } else {
          refCartRec.value.iDeliveryFznProduct = 350;
        }
        console.log(fctName+': DEBUG: iSubTotalFznProduct='+refCartRec.value.iSubTotalFznProduct);  // debug
        
        refCartRec.value.iDelivery = refCartRec.value.iDeliveryNormalProduct + refCartRec.value.iDeliveryFznProduct;
        // Delivery cost to Taiwan (end)
    }
    console.log(fctName+': DEBUG: iDelivery='+refCartRec.value.iDelivery);  // debug
    
    // Calc: Total including tax
    refCartRec.value.iTotalTax = refCartRec.value.iSubTotal - refCartRec.value.iPromoTotal + refCartRec.value.iDelivery;
    console.log(fctName+': DEBUG: iTotalTax='+refCartRec.value.iTotalTax);  // debug
    
    // Calc: Amount excluding tax
    refCartRec.value.iTotalHT = Math.round((refCartRec.value.iTotalTax * 100) / (100 + refCartRec.value.iOrdTaxRate));	// iTotalTax / 1.05
    console.log(fctName+': DEBUG: iTotalHT='+refCartRec.value.iTotalHT);  // debug
    
    // Calc: Tax amount
    // refCartRec.value.iTaxPrice = Math.round(refCartRec.value.iTotalHT * refCartRec.value.iOrdTaxRate / 100);	// iOrdTaxRate
    refCartRec.value.iTaxPrice = refCartRec.value.iTotalTax - refCartRec.value.iTotalHT;
    console.log(fctName+': DEBUG: iTaxPrice='+refCartRec.value.iTaxPrice + ', iOrdTaxRate='+refCartRec.value.iOrdTaxRate);  // debug
    
    console.log(fctName+': DEBUG: refCartRecStr='+JSON.stringify(refCartRec.value));  // debug
    // ==> 
    
  } // calcCartTotal
  

  /* ==================================================================================
   * ascCartCheckPromo: Request REST API services to check promo code
   * reqParams { fct: myFct, promocode: ... } :Input parameters
   * fctCallback :Callback function, if defined
   */
  async function ascCartCheckPromo(reqParams, fctCallback) {
    const fctName = 'svcCartApi.ascCartCheckPromo';
    const reqUrl = '/api/shopping/checkPromo';
    
    refCartLoading.value = true;
    try {
      // # const { data } is the name set by axios.post. Can not be renamed.
      const { data } = await axios.post(
        reqUrl,
        reqParams   // { fct: myFct, promocode: ... }
      );
      
      console.log(fctName+': DEBUG: dataStr='+JSON.stringify(data));  // prov_debug
      // ==> dataStr={"errCode":1,"errMsg":"OK","rec":{"id":4,"sPromoCode":"FEB2023","dtmPromoBegin":"2023-02-01 00:00:00","dtmPromoEnd":"2023-02-28 00:00:00","iPromoMini":1000,"iPromoRate":20,"sPromoGroup":"EDF"},"recsNb":0,"recs":[{}]}
      
      // errCode = [1 ... 99] :OK message, [100 ... 999] :Error message, value = [1000 ... 999] :Process aborted
      refCartResp.value.errCode = data.errCode; 
      refCartResp.value.errMsg = data.errMsg;
      switch(data.errCode) {
        case 1: // OK: rec found
          // Get data
          // refCartRec.value = data.rec;  // API:rtsApiCart.myTblCartOrd
          // You may alter data here
          refCartRec.value.sPromoCode = data.rec.sPromoCode;
          refCartRec.value.dtmPromoBegin = data.rec.dtmPromoBegin;
          refCartRec.value.dtmPromoEnd = data.rec.dtmPromoEnd;
          refCartRec.value.iPromoMini = data.rec.iPromoMini;
          refCartRec.value.iPromoRate = data.rec.iPromoRate;
          refCartRec.value.sPromoGroup = data.rec.sPromoGroup;
          break;
        case 2: // Record not found
          // refCartRec.value = {};
          refCartRec.value.sPromoCode = null;
          refCartRec.value.dtmPromoBegin = null;
          refCartRec.value.dtmPromoEnd = null;
          refCartRec.value.iPromoMini = 0;
          refCartRec.value.iPromoRate = 0;
          refCartRec.value.sPromoGroup = null;
          break;
        default: // Error occured at server sidebar
          // refCartRec.value = {};
          refCartRec.value.sPromoCode = null;
          refCartRec.value.dtmPromoBegin = null;
          refCartRec.value.dtmPromoEnd = null;
          refCartRec.value.iPromoMini = 0;
          refCartRec.value.iPromoRate = 0;
          refCartRec.value.sPromoGroup = null;
          break;
      }
      if (fctCallback) {
        // fctCallback has been defined
        // console.log(fctName+': DEBUG: to fctCallback');  // debug
        let resp = {resp:refCartResp.value, rec: refCartRec.value};
        fctCallback(resp);
      } else {
        console.log(fctName+': DEBUG: no callback');  // prov_debug
      }

    } catch (ex) {
      if (ex.response) {
        // Request made and server responded
        console.log(fctName+': EXC: exStatus='+ex.response.status+', exData='+ex.response.data);
        // console.log('exrespdata:'+ex.response.data);  // 9002:DB Exception:2021-04-08 16:59:36 - mdlDbMysql.dbFind
        // console.log('exrespstatus:'+ex.response.status);  // 500
        // console.log('exrespheader:'+ex.response.headers);
      } else {
        console.log(fctName+': EXC: ex='+ex.stack);
      }
    } finally {
      refCartLoading.value = false;
    }

  } // ascCartCheckPromo



  /* ==================================================================================
   * calcReqToken: Format the reqToken and calculate its SHA-256
   * ordParams { fct:..., ordId: myOrdId, ordTotal: myOrdTotal, callbackUrl: ..., sDate:YYYYMMDD, sTime:hhmmss } :Input parameters
   * fctCallback :Callback function, if defined
   */
  function calcReqToken(ordParams) {
    const fctName = 'svcCartApi.calcReqToken';
    const reqUrl = '/api/cart/encodeSha256';
    
    let strDecoded;
    let strEncoded;
    // let nowStr = tlsGetDateStr(null, 'YYYMMDDhhmmss'); // (objDate, mask)
    const acqBank = '007';
    const bizKey = 'B202112110750C069272155D12345678';  // B202112110750C069272155D12345678
    const merchandId = '007816565129001';
    const terminalId = '90010001';
    
    switch(ordParams.fct) { // =creditCard, taiwanPay
      case "creditCard":
        // ReqToken = SHA-256(lidm&purchAmt&驗證參數&MerchantID&TerminalID&LocalDateLocalTime)
        strDecoded = encodeURI(ordParams.ordId +
                  '&' + ordParams.ordTotal +
                  '&' + bizKey +
                  '&' + merchandId +
                  '&' + terminalId +
                  '&' + ordParams.sDate+ordParams.sTime);
        strEncoded = tlsSha256Encrypt(strDecoded, null);
        console.log(fctName+': DEBUG:creditCard strDecoded='+strDecoded);  // prov_debug
        console.log(fctName+': DEBUG:creditCard strEncoded='+strEncoded);  // prov_debug
        break;
      case "taiwanPay":
        // reqToken = SHA-256(AcqBank&AuthResURL&lidm&MerchantID&purchAmt&TerminalID&驗證參數)
        strDecoded = encodeURI(acqBank +
                  '&' + ordParams.callbackUrl +
                  '&' + ordParams.ordId +
                  '&' + merchandId +
                  '&' + ordParams.ordTotal +
                  '&' + terminalId +
                  '&' + bizKey);
        strEncoded = tlsSha256Encrypt(strDecoded, null);
        console.log(fctName+': DEBUG:taiwanPay strDecoded='+strDecoded);  // prov_debug
        console.log(fctName+': DEBUG:taiwanPay strEncoded='+strEncoded);  // prov_debug
        break;
      default:
        console.log(fctName+': ERROR: Unexpected fct='+ordParams.fct);  // prov_debug
        break;
    }


    return strEncoded;
  } // calcReqToken
  
   /* ==================================================================================
   * calcReqToken: Format the reqToken and calculate its SHA-256
   * ordParams { fct:..., ordId: myOrdId, ordTotal: myOrdTotal, callbackUrl: ..., sDate:YYYYMMDD, sTime:hhmmss } :Input parameters
   * fctCallback :Callback function, if defined
   */
  async function ascOnlinePaym(paymType, fctCallback) {
    const fctName = 'svcCartApi.ascOnlinePaym';
    const reqUrl = '/api/cart/paymGetToken';
    
    let postUrl;
    let postParams;
	
	console.log(fctName + ': DEBUG: refCartRecStr='+JSON.stringify(refCartRec.value));  // prov_debug
	// ==> refCartRecStr={"idClient":1,"iSubTotal":18,"iDelivery":0,"iTotalHT":18,"iTaxRate":0,"iTotalTax":0,"iTotalTTC":18,"sDelProvince":"dep010","sDelCity":"cty0110","sDelZipCode":"99000","sDelAddr1":"1 bd Elysée","sDelMobile":"cli123456789","sDelGenderCode":"M","sDelFirstName":"Cli JackBis","sComCode":"Cli001b","sAssociation":"1","deliveryMode":"b","paymentMode":"3","invoiceMode":"1","sOrdCode":"202207111241216042","iItmNb":1}
	
	
	
	console.log(fctName + ': DEBUG: ordCode='+refCartRec.value.sOrdCode); // prov_debug
	// ==> ordCode=202207111241216042
	
	
    try {
      let reqParams = {
        paymType: paymType,
        ordCode: refCartRec.value.sOrdCode,
        ordTotal: refCartRec.value.iTotalTax,
      };
      // # const { data } is the name set by axios.post. Can not be renamed.
      const { data } = await axios.post(
        reqUrl,
        reqParams,   // { fct: myFct, rec: refRec.value, }
        {
          emulateJSON: true  // <-- This was missing
        }
      );
      console.log(fctName+': DEBUG: dataStr='+JSON.stringify(data));  // prov_debug
        // ==> dataStr={"paymUrl":"https://www.focas-test.fisc.com.tw/FOCAS_WEBPOS/online/",
        // "paymParams":"MerchantID=007816565129001&TerminalID=90010001&merID=81656512&purchAmt=18&lidm=202207120456029799&AuthResURL=https://dev01.daozhu.tw/api/shopping/paymAck&reqToken=1FA97EE9657D1AB94FB8C39072106FA08D17A6A7B1943E42C558B0A642AA1219&LocalDate=2022-07-12 09:55:01&LocalTime=2022-07-12 09:55:01&PayType=0&AutoCap=1"}
      
      postUrl = data.paymUrl;
      postParams = data.paymParams;
    } catch (ex) {
      if (ex.response) {
        // Request made and server responded
        console.log(fctName+': EXC: exStatus='+ex.response.status+', exData='+ex.response.data);
      } else {
        console.log(fctName+': EXC: ex='+ex.stack);
      }
    } finally {
      refCartLoading.value = false;
    }
    
      /*
        let bizKey = "B202112110750C069272155D12345678";  // B202112110750C069272155D12345678
        const acqBank = "007";
        const merchandId = "007816565129001";
        const terminalId = "90010001";
        const merID = "81656512";
        const respUrl = "https://dev01.daozhu.tw/api/shopping/paymAck"; // "https://dev01.daozhu.tw/apiPaym/";
        
        // let nowStr = tlsGetDateStr(null, 'YYYMMDDhhmmss'); // (objDate, mask)
        let strDate = tlsGetDateStr(null, 'YYYMMDD'); // (objDate, mask)
        let strTime = tlsGetDateStr(null, 'hhmmss'); // (objDate, mask)
        
        
        let ordParams = {
          fct: paymType,  // =creditCard, taiwanPay
          ordId: refCartRec.value.sOrdCode,	// ordCode, 
          ordTotal: ordTotal,
          callbackUrl: respUrl,
          sDate: strDate,
          sTime: strTime
        }
        let reqToken = calcReqToken(ordParams);
        
        let postUrl;
        let postParams;
        if (paymType == "creditCard") {
          postUrl = "https://www.focas-test.fisc.com.tw/FOCAS_WEBPOS/online/";
          postParams = "MerchantID=" + merchandId +
                          "&TerminalID=" + terminalId +
                          "&merID=" + merID +
                          "&purchAmt=" + refCartRec.value.iTotalTTC +
                          "&lidm=" + refCartRec.value.sOrdCode +
                          "&AuthResURL=" + respUrl +
                          "&reqToken=" + reqToken +
                          "&LocalDate=" + strDate +
                          "&LocalTime=" + strTime +
                          "&PayType=0&AutoCap=1";
        } else {
          postUrl = "https://www.focas-test.fisc.com.tw/FOCAS_WEBPOS/QR_PAGE";
          postParams = "AcqBank=" + acqBank +
                          "&AuthResURL=" + respUrl +
                          "&lidm=" + refCartRec.value.sOrdCode +
                          "&MerchantID=" + merchandId +
                          "&purchAmt=" + refCartRec.value.iTotalTTC +
                          "&reqToken=" + reqToken +
                          "&TerminalID=" + terminalId +
                          "&timeoutSecs=600" +
                          "&txnDateLocal=" + strDate +
                          "&txnTimeLocal=" + strTime;
        }
		
		console.log(fctName + ': DEBUG: windowLocation: '+postUrl + '?' + postParams); // prov_debug
		// ==> windowLocation: https://www.focas-test.fisc.com.tw/FOCAS_WEBPOS/online/?MerchantID=007816565129001&TerminalID=90010001&merID=81656512&purchAmt=251&lidm=O20220711105405&AuthResURL=https://dev01.daozhu.tw/api/shopping/paymAck&reqToken=7FE62DF0B380F1B47A18F857883F21E84B4A71D7E91C71822DCE590B0C228475&LocalDate=20220711&LocalTime=105405&PayType=0&AutoCap=1
		*/
      
      /*
      refClientResp.value.errCode = data.errCode; 
      refClientResp.value.errMsg = data.errMsg;
      switch(data.errCode) {
        case 1: // OK: rec found
          // Get data
          refClientRecs.value = data.recs;
          refClientRecsNb.value = refClientRecs.value.length;
          break;
        default:
          refClientRec.value = [{}];
          refClientRecsNb.value = 0;
          break;
      }
      
      if (fctCallback) {
        // fctCallback has been defined
        // console.log(fctName+': DEBUG: to fctCallback retDataStr='+JSON.stringify(data));  // debug
        let resp = {resp:refClientResp.value, recs: refClientRecs.value};
        fctCallback(resp);
      } else {
        // console.log(fctName+': DEBUG: no callbacks retDataStr='+JSON.stringify(data));  // debug
      }
      
    } catch (ex) {
      if (ex.response) {
        // Request made and server responded
        console.log(fctName+': EXC: exStatus='+ex.response.status+', exData='+ex.response.data);
        // console.log('exrespdata:'+ex.response.data);  // 9002:DB Exception:2021-04-08 16:59:36 - mdlDbMysql.dbFind
        // console.log('exrespstatus:'+ex.response.status);  // 500
        // console.log('exrespheader:'+ex.response.headers);
      } else {
        console.log(fctName+': EXC: ex='+ex.stack);
      }
    } finally {
    }
    */
		
		if (fctCallback) {
			// fctCallback has been defined
			
			refCartResp.value.errMsg = ''; // data.errMsg;
			let resp = {resp:refCartResp.value, postUrl: postUrl, postParams: postParams};
			fctCallback(resp);
		} else {
			console.log(fctName+': DEBUG: no callback ');  // prov_debug
		}

	return;
  } // ascOnlinePaym
	
  /* ==================================================================================
   * ascFocasPayment: Process online payment at FOCAS webservices
   * reqParams { ordId: myOrdId, ordTotal: myOrdTotal, } :Input parameters
   * fctCallback :Callback function, if defined
   */
  async function ascPaymBankFocas() {
    const fctName = 'svcCartApi.ascPaymBankFocas';
    const reqUrl = '/api/cart/paymOnline';
    
    /*
    let nowStr = tlsGetDateStr(null, 'YYYMMDDhhmmss'); // (objDate, mask)
    let ordCode = "TMP"+nowStr;
    
    const postUrl = "https://www.focas-test.fisc.com.tw/FOCAS_WEBPOS/online";
    let postParams = "?MerchantID=007816565129001&TerminalID=90010001&merID=81656512&purchAmt=136&lidm="+ordCode+"&AuthResURL=https://dev01.daozhu.tw/apiPaym/"+ordCode;
    // let postParams = "?MerchantID=007816565129001&TerminalID=90010001&merID=81656512&purchAmt=135&lidm=20211110A&AuthResURL=https%3A%2F%2Fdev01.daozhu.tw%2F%23%2FpaymentAck";
    
    console.log('vwsCart4.paymBankFocas: DEBUG: windowLocation: '+postUrl + postParams); // prov_debug
    
    window.location = postUrl + postParams;
    */
    
    /*
    
    let reqParams = {
      MerchantID : "007816565129001",
      TerminalID : "90010001",
      merID : "81656512",
      purchAmt : 151,
      lidm : "20211213A",
      AuthResURL :  "https%3A%2F%2Fdev01.daozhu.tw%2FapiPaym%2F20211213A",
      reqToken : "5E61ADD5CA86E6BE65C4C30B1DCAA28F461F1573BFBAA8638DEE8D8018DCE524",
      LocalDate : "20211213",
      LocalTime : "054200",
      PayType : 0,
      AutoCap : 1
    };
    */
    
    let reqParams = {
      fct: "creditCard",  // =creditCard, taiwanPay
      lidm : "20211213A",
      AuthResURL :  "https%3A%2F%2Fdev01.daozhu.tw%2FapiPaym%2F20211213A"
    };
    
    
    try {
      // # const { data } is the name set by axios.post. Can not be renamed.
      const { data } = await axios.post(
        reqUrl,
        reqParams,   // { fct: myFct, rec: refRec.value, }
        {
          emulateJSON: true  // <-- This was missing
        }
      );
      console.log(fctName+': DEBUG: dataStr='+JSON.stringify(data));  // prov_debug
    } catch (ex) {
      if (ex.response) {
        // Request made and server responded
        console.log(fctName+': EXC: exStatus='+ex.response.status+', exData='+ex.response.data);
        // console.log('exrespdata:'+ex.response.data);  // 9002:DB Exception:2021-04-08 16:59:36 - mdlDbMysql.dbFind
        // console.log('exrespstatus:'+ex.response.status);  // 500
        // console.log('exrespheader:'+ex.response.headers);
      } else {
        console.log(fctName+': EXC: ex='+ex.stack);
      }
    } finally {
      refCartLoading.value = false;
    }

  } // ascPaymBankFocas
  
  function calcCartTotalQty() {
    const fctName = 'svcCartApi.calcCartTotalQty';
    let total = 0;
    refCartItemRecs.value.forEach( function(elem) {
      total += 1 * elem.iQtyOrd;
    });
    refCartRec.value.iTotalQty = total;
    console.log(fctName+': DEBUG: iTotalQty='+refCartRec.value.iTotalQty);  // debug
    
  } // calcCartTotalQty
  
  return  {
      refCartResp: computed(() => refCartResp.value),
      refCartLoading: computed(() => refCartLoading.value),
      // refCartRecs: computed(() => refCartRecs.value),
      refCartRec: computed(() => refCartRec.value),
      refCartItemRecs: computed(() => refCartItemRecs.value),
      refCartItemFreeRecs: computed(() => refCartItemFreeRecs.value),
      refCartItemStdRecs,
      refCartItemFznRecs,
      refCartItemFreeStdRecs,
      refCartItemFreeFznRecs,
      // ascCartGetRecs,
      ascCartGetItemRecs,
      ascCartFindOrdRec,
      fctCartGetCliId,
      fctCartSetCliId,
      ascCartSaveRec,
      ascCartCreateOrd,
      ascCartDeleteRec,   // ascCartDeleteRec: Send request to delete a record to REST API services
      fctCartUpdItem,     // fctCartUpdItem: Update qty of the item in current shopping cart
      fctCartAddItem,     // fctCartAddItem: Add an item to current shopping cart
      fctCartDelItem,     // fctCartDelItem: Delete an item from current shopping cart
      fctCartUpdate,      // fctCartUpdate: Update list of Ordered & Free items STD&FZN
      fctCartCreateOrder, // fctCartCreateOrder: Create order from the shopping cart
      calcCartTotal,
      ascCartCheckPromo,	// ascCartCheckPromo: Request REST API services to check promo code
      calcReqToken,
      ascOnlinePaym,
      ascPaymBankFocas,
      calcCartTotalQty,
      refOrdType,
      refOrdId,
  }
}
