<template>
  
  <div>
    
    <!-- prov_debug --
    <br>vwsClientFrm: refIsLoading = {{ refIsLoading }}
    <br>vwsClientFrm: refResp.errCode = {{ refResp.errCode }}
    <br>vwsClientFrm: refResp.errMsg = {{ refResp.errMsg }}
    <br>vwsClientFrm: refRec.sProvince = {{ refRec.sProvince }}
    <hr>
    <div class="content" v-for="(name, value) in refState" :key="name">
      <div class="control">
        <div class="field">
          <label class="checkbox"> name = {{ name }} - value = {{ value }}
          <input type="text" v-model="refState[value]" />
          </label>
        </div>
      </div>
    </div>  
    <hr>
    <br>
    -- prov_debug -->
    
    <center>
    <br>
    
    <span v-if="refIsLoading"><br><br>Loading ...<br><br></span>
    <span v-else>
      <form novalidate @submit.prevent="onSubmit" >
        <center>
        <div class="divHor01Shadow">
          <table border="0">
            <tr>
              <td align="center">
                
                <!-- // ==> errCode = [1 ... 99]:OK message, [100 ... 899]:Error message, [900 ... 999]:Error message, [[1000 ... 999]:Process aborted -->
                <span v-if="(refResp.errCode > 0) && (refResp.errCode <= 99)"><div class="divOk">{{ refResp.errMsg }}<br>&nbsp;</div></span>
                <span v-if="(refResp.errCode > 100) && (refResp.errCode <= 499)"><div class="divOk">{{ refResp.errMsg }}<br>&nbsp;</div></span>
                <span v-if="refResp.errCode == 901"><div class="divError">Invalid password<br>&nbsp;</div></span>
                <span v-if="refResp.errCode >= 500"><div class="divError">{{ refResp.errMsg }}<br>&nbsp;</div></span>
              </td>
            </tr>
            <tr>
              <td align="center">
                {{ refDico.msgLogout }}
              </td>
            </tr>

            <tr>
              <td align="center">
                <br>
                <a class="btnStd01" v-on:click="openPage('pgHome')"><i class="fa fa-home fa-1x"></i>&nbsp;{{ refDico.menuHome }}</a>
                <br>&nbsp;
              </td>
            </tr>
          </table>
        </div>
        </center>
      </form>
    </span>
    
    <br>&nbsp;
    </center>
  </div>  
  
</template>

<script>
  import {
    ref, computed, onActivated, onBeforeMount, onBeforeUnmount, onBeforeUpdate,
    onDeactivated, onErrorCaptured, onMounted, onUnmounted, onUpdated, onRenderTracked, onRenderTriggered
  } from 'vue';
  import router from '@/router/index.js'
  import { useRoute } from 'vue-router';
  import myApp from '@/myApp.vue';
  import useReferential from '@/modules/mdlReferential.js';
  import useClientApi from '@/services/svcClientApi.js';
  import useCartApi from '@/services/svcCartApi.js';
  
  export default {
    setup() {
      const route = useRoute();
      const { refDicoLang, refDicoAttr, refPageAttr, ascSetLang } = useReferential();
      let { ascClientFindRec, ascClientSaveRec, ascClientDeleteRec, refClientResp, refClientLoading, refClientRec } = useClientApi();
      let { refCartRec, refCartItemRecs } = useCartApi();
      
      let refDico = ref({});
      
      let refResp = ref({});
      let refRec = ref({});
      let refIsLoading = ref(true);
      
      onMounted(async () => {
        console.log('vwsClientLogout.onMounted: pgAction='+route.params.pgAction+',id='+route.params.prmId);
        refDico.value = refDicoAttr.value;
        refPageAttr.value.title = refDico.value.menu116Logout;
        
        refClientRec.value = {'idle':0};  // Reset client record
        refCartRec.value.iTotalQty = 0;
        console.log('vwsClientLogout.onMounted: sMobile='+refClientRec.sMobile);
        refCartRec.value = {'idle':0}; // Reset shopping cart
        refCartItemRecs.value.length = 0;   // Reset shopping cart details
        console.log('vwsClientLogout.onMounted: iTotalQty='+refCartRec.value.iTotalQty);
      }); // onMounted
      
      function openPage(pgName) {
        console.log('vwsClientLogout.openPage: pgName='+pgName);
        // this.$router.push({name:pgName,params:{pgAction:'init',prmId:''}});
        router.push({name:pgName,params:{pgAction:'init',prmId:''}});
      }
      
      return {
        refDico,
        refIsLoading: computed(() => refClientLoading.value),
        refResp,
        refRec,
        openPage,
      }
    }, // onMounted
  } // setup
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .divOk {
    color: #00FF00;
  }
  .divError {
    color: #FF0000;
  }
  input:disabled {
    background-color: #F0F0F0;
  }
  .link:hover {
    cursor: pointer;
    color: #90150D;
  }

</style>