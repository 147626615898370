<template>
 <!-- https://www.freeformatter.com/html-escape.html -->
 <br>
 <div class="divpx">
  <h1 class="divph1">會員服務條款</h1>
  <br>如何購買
  <br>購買流程
 <br>選購商品&rarr;購物車&rarr;email或手機號碼註冊登入&rarr;選擇配送方式(填寫正確姓名地址)&rarr;選擇支付方式&rarr;確認訂單訊息(※發貨通知)&rarr;等待貨到。
  <br>
  <br>貼心提醒:
  <br>*請訂單結帳前務必確認內容。訂單結帳完成後恕無法異動修改訂單內的商品明細、付款方式、配送通路，以避免造成延誤出貨的情況！
  <br>
  <br>*如您欲取消訂單，請於訂單狀態顯示『<b>處理中</b>』時，線上點選『取消訂單』若訂單狀態為"出貨中"恕無法取消訂單，請務必留意。
*重新訂購時請以訂購當下官網顯示的商品庫存/價格及活動為準。（商品加入購物車但未結帳前，並無保留商品庫存功能，商品庫存分配將以結帳順序為依據）
  <br>
  <br>*產品詳細說明及有效期限請參閱外包裝
  <br>*產品網頁因拍攝關係，圖檔略有差異，實際以出貨為主
  <br>*產品文案若有異動敬請參照實際商品為準 

  <br><b>*確認購買即代表同意官網購物規則；提醒您，若您無故或惡意違反本網站制定訂購與退貨相關規則，本公司有權暫停您的帳號並拒絕您使用本服務，同時列為交易黑名單，請您留意。</b>
  <br>
  <br>
  <br>
 <br>付款方式
  <br>
  <br>*貨到付款: 現金
  <br>
  <br>*線上付款: 信用卡付款,台灣 pay
  <br>
  <br>物流配送
  <br>澎湖本島地區（馬公市、白沙鄉、湖西鄉、西嶼鄉）
  <br>*配送到府
  <br>無電梯樓層以一樓為簽收處,恕不搬運上樓
  <br>
  <br>澎湖離島地區（望安鄉、七美鄉、虎井島、桶盤島、大倉嶼、員貝嶼、鳥嶼、吉貝嶼）
  <br>*無提供海運配送,請提供澎湖本島運送地址
  <br>
  <br>台灣本島地區
  <br>*中華郵政
  <br>*黑貓宅急便 
  <br>配送到府
  <br>◇ 黑貓宅急便下列區域無法送達。
  <br>澎湖地區：望安鄉、七美鄉、虎井島、桶盤島、大倉嶼、員貝嶼、鳥嶼、吉貝嶼
  <br>金門地區：烏坵、烈嶼、大膽、二膽
  <br>其它地區：蘭嶼、釣漁台列嶼、東沙、南沙及郵政信箱
  <br>
  <br>包裹注意事項
  <br>1.請務必填寫正確收件人資訊，若因為收件訊息不正確無法配送且無人簽收，導致包裹退回我們將直接取消整筆訂單。
  <br>2.被退回之包裹(運費一律不歸還)；作業完成後也會以 e-mail 或手機簡訊方式通知您。
  <br>
  <br><b>※特別提醒：若當地的快遞公司多次投遞未成功且客服通知收件人也未取得聯繫，部份快遞公司將不再退回包裹，而我們也將不會再進行任何款項退款，請您務必留意。</b>
  <br>
  <br>*澎湖地區(馬公市,西嶼鄉,白沙鄉,湖西鄉)消費滿 500 元免運，未滿 500 元需另加運費 20 元
  <br>*台灣本島常溫免運門檻2000元 未滿需加宅配150元
  <br>
  <br>*台灣本島低溫免運門檻4000元 未滿需加宅配350元
  <br>
  <br>
    <br>售後服務，退貨說明
  <br>*退回商品需包裝完好無損和附件齊全，如有不符退貨條件，將無法受理您的退貨申請，請留意避免影響您退貨之權益。
  <br>線上退貨：請至「我的訂單」，每筆訂單後方皆有「退貨」按鈕，進入訂單明細輸入退貨商品之退貨數量。(此退貨按鈕會於商品送達後出現，7日後消失不再受理退貨)
  <br>*每筆訂單提供乙次退貨，恕不受理多次退貨程序
  <br>*會員享有簽收商品當天算起 7 日鑑賞期（包含例假日）；原訂單出貨時若達免運門檻無須支付運費，而若鑑賞期內商品全數退貨，無任何保留（視同沒有購買），商品總金額未達免運費門檻則需補扣原訂單出貨的運費，將從收到的退貨商品金額中扣除。
  <br>請確認取貨地址,如超過 2 次取貨不成將取消此退貨單,煩請留意手機來電以免錯過收件時效，若超時未被宅配人員收取退貨包裹，則將不受理退貨。<br>
  <br>*購買的網路商品如果屬於下面這 7 種類型，無法享有「7 天鑑賞期」或無條件退換貨
  <br>
  <br>一、易於腐敗、保存期限較短或解約時即將逾期。
  <br>生鮮食品不享有 7 天鑑賞期，因冷藏食品或生鮮商品屬消耗性商品，商品屬性特殊或有保存期限等問題，除商品本身瑕疵或運送過程而造成的損毀，才可接受您的退貨申請。非商品瑕疵恕無法為您辦理換貨，下單前請務必考慮、詢問清楚！一經拆封、食用、失溫及保存不良等情形而導致商品變質，則無法處理您的換貨申請，敬請見諒！ 
  <br>
  <br>二、依消費者要求所為之客製化給付。
  <br>
  <br>三、報紙、期刊或雜誌。
  <br>
  <br>四、經消費者拆封之影音商品或電腦軟體。
  <br>
  <br>五、非以有形媒介提供之數位內容或一經提供即為完成之線上服務，經消費者事先同意始提供。
  <br>
  <br>六、已拆封之個人衛生用品。
  <br>
  <br>七、國際航空客運服務。
  <br>
  <br>7 日鑑賞期計算: 從簽收日算起 7 日(含)內，鑑賞期不為試用期，還請留意請保持商品全新狀態。黑貓宅配為簽收日算第一天，不論是否為買家本人簽收(管理員/家人等) ，請務必留意。
  <br>
  <br>*組合優惠商品若辦理退貨後保留的件數未達活動規定件數，保留的商品將恢復原價計算，將從收到退貨商品刷退金額當中扣除恢復原訂單保留組合商品恢復原價之差額，還請會員務必留意，以免造成自身權益損失。
  <br>
  <br>*若有贈品之商品退貨時，請將贈送商品一併退回
  <br>
  <br>*請留意辦理退貨後訂單所保留之金額或條件若未達滿額活動規則，須將全新未拆封滿額贈品連同退貨商品一併退回，若滿額活動贈品未退回，待收到退貨商品刷退金額將會扣除滿額贈品的正價金額(視同原價購買)。
  <br>*線上付款退款流程約 7-10 天（不含例假日）完成
  <br>*澎湖本島地區訂購時若為現金支付將於退貨時直接退款。
  <br>
  <br>退貨不符注意事項：
  <br>(1)若退回包裹內附上有私人物品(非販售商品)，將以貨到付款方式退回，若經客服通知後未於 7 日內回覆，將直接報銷處理。
  <br>(2)若退貨包裹與退貨明細不符，將會直接依照實際收到退貨商品受理退貨，不再另行通知，請於辦理退貨時留意商品項目。
  <br>(3)退貨包裹一經確認若有人為瑕疵商品將無法受理退貨，其他無異常商品將會先受理退貨退款，人為瑕疵商品將會再聯繫消費者並且以貨到付款方式退回。
  <br>(4)異常訂單，若未於通知期限內回覆將取消訂單，請您再重新訂購(依官網現行的庫存數量、優惠活動及售價為主)。
  <br>(5)若原訂單須支付運費，將會於退貨金額內扣除
  <br>
  <br>*若確認商品無法受理退貨，將於三日內寄回至訂單或會員地址，恕無法退回購物金，還請留意
  <br>

  <br><b>*確認購買即代表同意官網購物規則；提醒您，若您無故或惡意違反制定訂購與退貨相關規則，本公司有權暫停您的帳號並拒絕您使用本服務，同時列為交易黑名單，請您留意。</b>
  <br>
  <br>關於發票
  <br>
  <br>*電子發票將於訂單出貨時附上。可於訂購時勾選【個人電子發票】或【公司戶電子發票】。單筆訂單僅能產生乙張發票，若需開立多張發票還煩請個別完成訂購動作。
  <br>包裹寄出後恕無法異動發票格式，還請於成立訂單時確認是否開立正確，若未於訂單內選擇發票種類，一律將優先以個人電子發票為主，請見諒。
  <br>
  <br>*辦理退貨時，請一併退回電子發票證明聯
  <br>
  <br>
  <br>
  <br>
 </div>
</template>
  <script>
  import {
    ref, computed, onActivated, onBeforeMount, onBeforeUnmount, onBeforeUpdate,
    onDeactivated, onErrorCaptured, onMounted, onUnmounted, onUpdated, onRenderTracked, onRenderTriggered
  } from 'vue';
  import useReferential from '@/modules/mdlReferential.js';
  
    export default {
      methods: {
      },
      setup() {
        onBeforeMount(() => console.log('before mount'));       // Event triggered on open page
        // onMounted(() => console.log('mounted'));             // Event triggered on open page
        onBeforeUpdate(() => console.log('before update'));     // Event triggered on open page
        onUpdated(() => console.log('updated'));                // Event triggered on open page
        onBeforeUnmount(() => console.log('before unmount'));   // Event triggered on leaving page
        onUnmounted(() => console.log('unmounted'));            // Event triggered on leaving page
        onActivated(() => console.log('activated'));
        onDeactivated(() => console.log('deactivated'));
        onErrorCaptured(() => console.error('error captured'));
        onRenderTracked(() => console.log('render tracked'));
        onRenderTriggered(() => console.log('render triggered'));
        const { refDicoLoading, refDicoLang, refDicoAttr, refPageAttr, refItmTypeAttr, ascSetLang, refSlctMenu, fctExtractMenu, fctExtractSubmenu } = useReferential();
        
        onMounted(async () => {
          console.log('vwsP2.onMounted: DEBUG: ');  // debug
          window.scrollTo(0, 0);
          refPageAttr.value.title = '';
        });
      
        return {
        }
      },
      
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
