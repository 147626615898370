<template>
  <!-- https://www.freeformatter.com/html-escape.html -->
  <br>
  <div class="divpx">
    <h1 class="divph1">關於我們</h1>
    <br>
    <br>島主的女兒誕生於2020年 on Line！
    <br>來自澎湖百年經銷通路的<a href="http://phtc.tw" target="pg_phtc">中國商行</a>，提供更好更方便的網路購物平台
    <br>
    <br>島主的女兒，本著「只要我喜歡，全部很可以」
    <br>好吃本色，嚐遍各地美食，能嘴出食材的優美，能做出一桌好料理
    <br>挑剔玩美，對美感偏執的堅持，對設計講究而不將就
    <br>懂得享樂，好好生活，樂活比幹活更快活
    <br>
    <br>島主的女兒，心愛澎湖，以一顆澎湃的真心，將你需求捧在手心
    <br>熱衷為島民嚴選好物享優惠，好康特別多
    <br>品牌齊全，貨色完備，購物免出門
    <br>每天大小事，吃喝玩樂活，在家掃貨就搞定
    <br>
    <br>澎湖人最大，澎湖島優先
    <br>
    <br>
    <br>
    <br>
  </div>
</template>

<script>
  import {
    ref, computed, onActivated, onBeforeMount, onBeforeUnmount, onBeforeUpdate,
    onDeactivated, onErrorCaptured, onMounted, onUnmounted, onUpdated, onRenderTracked, onRenderTriggered
  } from 'vue';
  import useReferential from '@/modules/mdlReferential.js';
  
    export default {
      methods: {
      },
      setup() {
        onBeforeMount(() => console.log('before mount'));       // Event triggered on open page
        // onMounted(() => console.log('mounted'));             // Event triggered on open page
        onBeforeUpdate(() => console.log('before update'));     // Event triggered on open page
        onUpdated(() => console.log('updated'));                // Event triggered on open page
        onBeforeUnmount(() => console.log('before unmount'));   // Event triggered on leaving page
        onUnmounted(() => console.log('unmounted'));            // Event triggered on leaving page
        onActivated(() => console.log('activated'));
        onDeactivated(() => console.log('deactivated'));
        onErrorCaptured(() => console.error('error captured'));
        onRenderTracked(() => console.log('render tracked'));
        onRenderTriggered(() => console.log('render triggered'));
        const { refDicoLoading, refDicoLang, refDicoAttr, refPageAttr, refItmTypeAttr, ascSetLang, refSlctMenu, fctExtractMenu, fctExtractSubmenu } = useReferential();
        
        onMounted(async () => {
          console.log('vwsP3.onMounted: DEBUG: ');  // debug
          window.scrollTo(0, 0);
          refPageAttr.value.title = '';
        });
      
        return {
        }
      },
      
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
