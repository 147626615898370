import { ref, computed } from "vue";

const axios = require("axios");

// Global variable that can be shared state with Composition API
let users = ref([]);	// Array of uers

// Client
let refClientLoading = ref(false);
let refClientRecs = ref([]);	// Array of records
let refClientRec = ref({});  // Current record  // API:rtsApiClient.myTblClient
let refClientRecsNb = ref(0);
let refClientResp = ref({errCode:0, errMsg:'159'});
let refClientPswReset = ref({msgMode:'', msgValue:''});

export default function useClientApi() {
    
  /* ==================================================================================
   * ascClientGetRecs: Request REST API services for an array of records
   */
  async function ascClientGetRecs(fctCallback) {
    const fctName = 'svcClientApi.ascClientGetRecs';
    const reqUrl = '/api/client/getRecs';
    
    let reqParams = {};
    refClientLoading.value = true;
    try {
      const { data } = await axios.post(
        reqUrl,
        reqParams
      );
      // console.log(fctName+': DEBUG: retDataStr='+JSON.stringify(data));  // debug
      // ==> retDataStr={"errCode":1,"errMsg":"OK","rec":{},"recsNb":1,"recs":[{"id":1,"iStatus":1,"dtmCreate":"2020-01-01T23:00:00.000Z","dtmModif":null,"dtmValidMob":null,"dtmValidEmail":null,"sPassword":"adm123","sMobile":"123456789","sEmail":"jpn_email@yahoo.com","sGenderCode":"M","sFirstName":"Jack","sLastName":"SMITH","sAddr1":"1 bd Elysée","sAddr2":"1 FL","sZipCode":"99000","sCity":"NewCity","sProvince":"County","sMsgMode":"E","sCheckCode":null,"sLevel":"A","sNote":null}]}      
      
      refClientResp.value.errCode = data.errCode; 
      refClientResp.value.errMsg = data.errMsg;
      switch(data.errCode) {
        case 1: // OK: rec found
          // Get data
          refClientRecs.value = data.recs;
          refClientRecsNb.value = refClientRecs.value.length;
          break;
        default:
          refClientRec.value = [{}];
          refClientRecsNb.value = 0;
          break;
      }
      
      if (fctCallback) {
        // fctCallback has been defined
        // console.log(fctName+': DEBUG: to fctCallback retDataStr='+JSON.stringify(data));  // debug
        let resp = {resp:refClientResp.value, recs: refClientRecs.value};
        fctCallback(resp);
      } else {
        // console.log(fctName+': DEBUG: no callbacks retDataStr='+JSON.stringify(data));  // debug
      }
      
    } catch (ex) {
      if (ex.response) {
        // Request made and server responded
        console.log(fctName+': EXC: exStatus='+ex.response.status+', exData='+ex.response.data);
        // console.log('exrespdata:'+ex.response.data);  // 9002:DB Exception:2021-04-08 16:59:36 - mdlDbMysql.dbFind
        // console.log('exrespstatus:'+ex.response.status);  // 500
        // console.log('exrespheader:'+ex.response.headers);
      } else {
        console.log(fctName+': EXC: ex='+ex.stack);
      }
    } finally {
      refClientLoading.value = false;
    }
  } // ascClientGetRecs
  
  
  /* ==================================================================================
   * ascClientLogin: Request REST API services to check user's credential
   * reqParams { sLoginId: '...', sLoginPsw: '...' } :Input parameters
   * fctCallback :Callback function, if defined
   */
  async function ascClientLogin(reqParams, fctCallback) {
    const fctName = 'svcClientApi.ascClientLogin';
    const reqUrl = '/api/client/chkLogin';
    
    console.log(fctName+': DEBUG: reqUrlStr='+JSON.stringify(reqUrl));  // prov_debug
    // ==> reqUrlStr="/api/client/chkLogin"
    
    console.log(fctName+': DEBUG: reqParamsStr='+JSON.stringify(reqParams));  // prov_debug
    // ==> reqParamsStr={"sLoginId":"cli123456789","sLoginPsw":"tmp123"}
    
    refClientLoading.value = true;
    try {
      // # const { data } is the name set by axios.post. Can not be renamed.
      const { data } = await axios.post(
        reqUrl,
        reqParams   // { sLoginId: '...', sLoginPsw: '...' }
      );
      
      // console.log(fctName+': DEBUG: dataStr='+JSON.stringify(data));  // debug
      // ==> dataStr={"errCode":1,"errMsg":"OK","rec":{"id":1,"iStatus":1,"dtmCreate":"2020-01-01T23:00:00.000Z","dtmModif":null,"dtmValidMob":null,"dtmValidEmail":null,"sPassword":"adm123","sMobile":"123456789","sEmail":"jpn_email@yahoo.com","sGenderCode":"M","sFirstName":"Jack","sLastName":"SMITH","sAddr1":"1 bd Elysée","sAddr2":"1 FL","sZipCode":"99000","sCity":"NewCity","sProvince":"County","sMsgMode":"E","sCheckCode":null,"sLevel":"A","sNote":null},"recsNb":0,"recs":[{}]}
      
      // errCode = [1 ... 99] :OK message, [100 ... 999] :Error message, value = [1000 ... 999] :Process aborted
      refClientResp.value.errCode = data.errCode; 
      refClientResp.value.errMsg = data.errMsg;
      switch(data.errCode) {
        case 1: // OK: rec found
          // Get data
          refClientRec.value = data.rec;
          // You may alter data here
          // refClientRec.value.sPassword = '';   // Reset password. It must be entered again by the user
          // refClientRec.value.sPassword2 = refClientRec.value.sPassword;
          console.log(fctName+': DEBUG: ssoKey='+refClientRec.value.ssoKey);  // debug
          break;
        case 2: // Record not found
          refClientRec.value = {};
          break;
        default: // Error occured at server sidebar
          refClientRec.value = {};
          break;
      }
      if (fctCallback) {
        // fctCallback has been defined
        // console.log(fctName+': DEBUG: to fctCallback');  // debug
        let resp = {resp:refClientResp.value, rec: refClientRec.value};
        fctCallback(resp);
      } else {
        console.log(fctName+': DEBUG: no callback');  // prov_debug
      }

    } catch (ex) {
      if (ex.response) {
        // Request made and server responded
        console.log(fctName+': EXC: exStatus='+ex.response.status+', exData='+ex.response.data);
        // console.log('exrespdata:'+ex.response.data);  // 9002:DB Exception:2021-04-08 16:59:36 - mdlDbMysql.dbFind
        // console.log('exrespstatus:'+ex.response.status);  // 500
        // console.log('exrespheader:'+ex.response.headers);
      } else {
        console.log(fctName+': EXC: ex='+ex.stack);
      }
    } finally {
      refClientLoading.value = false;
    }

  } // ascClientLogin
  
  /* ==================================================================================
   * ascClientFindRec: Request REST API services for the record having the specified id
   * reqParams { fct: myFct, rec: refRec.value, } :Input parameters
   * fctCallback :Callback function, if defined
   */
  async function ascClientFindRec(reqParams, fctCallback) {
    const fctName = 'svcClientApi.ascClientFindRec';
    const reqUrl = '/api/client/findRec';
    
    refClientLoading.value = true;
    try {
      // # const { data } is the name set by axios.post. Can not be renamed.
      const { data } = await axios.post(
        reqUrl,
        reqParams   // { fct: myFct, rec: refRec.value, }
      );
      
      // console.log(fctName+': DEBUG: dataStr='+JSON.stringify(data));  // debug
      // ==> dataStr={"errCode":1,"errMsg":"OK","rec":{"id":1,"iStatus":1,"dtmCreate":"2020-01-01T23:00:00.000Z","dtmModif":null,"dtmValidMob":null,"dtmValidEmail":null,"sPassword":"adm123","sMobile":"123456789","sEmail":"jpn_email@yahoo.com","sGenderCode":"M","sFirstName":"Jack","sLastName":"SMITH","sAddr1":"1 bd Elysée","sAddr2":"1 FL","sZipCode":"99000","sCity":"NewCity","sProvince":"County","sMsgMode":"E","sCheckCode":null,"sLevel":"A","sNote":null},"recsNb":0,"recs":[{}]}
      
      // errCode = [1 ... 99] :OK message, [100 ... 999] :Error message, value = [1000 ... 999] :Process aborted
      refClientResp.value.errCode = data.errCode; 
      refClientResp.value.errMsg = data.errMsg;
      switch(data.errCode) {
        case 1: // OK: rec found
          // Get data
          refClientRec.value = data.rec;
          // You may alter data here
          // refClientRec.value.sPassword = '';   // Reset password. It must be entered again by the user
          refClientRec.value.sPassword2 = refClientRec.value.sPassword;
          break;
        case 2: // Record not found
          refClientRec.value = {};
          break;
        default: // Error occured at server sidebar
          refClientRec.value = {};
          break;
      }
      if (fctCallback) {
        // fctCallback has been defined
        // console.log(fctName+': DEBUG: to fctCallback');  // debug
        let resp = {resp:refClientResp.value, rec: refClientRec.value};
        fctCallback(resp);
      } else {
        console.log(fctName+': DEBUG: no callback');  // prov_debug
      }

    } catch (ex) {
      if (ex.response) {
        // Request made and server responded
        console.log(fctName+': EXC: exStatus='+ex.response.status+', exData='+ex.response.data);
        // console.log('exrespdata:'+ex.response.data);  // 9002:DB Exception:2021-04-08 16:59:36 - mdlDbMysql.dbFind
        // console.log('exrespstatus:'+ex.response.status);  // 500
        // console.log('exrespheader:'+ex.response.headers);
      } else {
        console.log(fctName+': EXC: ex='+ex.stack);
      }
    } finally {
      refClientLoading.value = false;
    }

  } // ascClientFindRec

  /* ==================================================================================
   * ascClientCheckRec: Request REST API services for the record having the specified id
   * reqParams { fct: myFct, rec: refRec.value, } :Input parameters
   * fctCallback :Callback function, if defined
   */
  async function ascClientCheckRec(reqParams, fctCallback) {
    const fctName = 'svcClientApi.ascClientCheckRec';
    const reqUrl = '/api/client/checkRec';
    
    refClientLoading.value = true;
    try {
      // # const { data } is the name set by axios.post. Can not be renamed.
      const { data } = await axios.post(
        reqUrl,
        reqParams   // { fct: myFct, rec: refRec.value, }
      );
      
      // console.log(fctName+': DEBUG: dataStr='+JSON.stringify(data));  // debug
      // ==> dataStr={"errCode":1,"errMsg":"OK","rec":{"id":1,"iStatus":1,"dtmCreate":"2020-01-01T23:00:00.000Z","dtmModif":null,"dtmValidMob":null,"dtmValidEmail":null,"sPassword":"adm123","sMobile":"123456789","sEmail":"jpn_email@yahoo.com","sGenderCode":"M","sFirstName":"Jack","sLastName":"SMITH","sAddr1":"1 bd Elysée","sAddr2":"1 FL","sZipCode":"99000","sCity":"NewCity","sProvince":"County","sMsgMode":"E","sCheckCode":null,"sLevel":"A","sNote":null},"recsNb":0,"recs":[{}]}
      
      // errCode = [1 ... 99] :OK message, [100 ... 999] :Error message, value = [1000 ... 999] :Process aborted
      refClientResp.value.errCode = data.errCode; 
      refClientResp.value.errMsg = data.errMsg;
      switch(data.errCode) {
        case 1: // OK: rec found
          // Get data
          refClientRec.value = data.rec;
          // You may alter data here
          // refClientRec.value.sPassword = '';   // Reset password. It must be entered again by the user
          refClientRec.value.sPassword2 = refClientRec.value.sPassword;
          break;
        case 2: // Record not found
          refClientRec.value = {};
          break;
        default: // Error occured at server sidebar
          refClientRec.value = {};
          break;
      }
      if (fctCallback) {
        // fctCallback has been defined
        // console.log(fctName+': DEBUG: to fctCallback');  // debug
        let resp = {resp:refClientResp.value, rec: refClientRec.value};
        fctCallback(resp);
      } else {
        console.log(fctName+': DEBUG: no callback');  // prov_debug
      }

    } catch (ex) {
      if (ex.response) {
        // Request made and server responded
        console.log(fctName+': EXC: exStatus='+ex.response.status+', exData='+ex.response.data);
        // console.log('exrespdata:'+ex.response.data);  // 9002:DB Exception:2021-04-08 16:59:36 - mdlDbMysql.dbFind
        // console.log('exrespstatus:'+ex.response.status);  // 500
        // console.log('exrespheader:'+ex.response.headers);
      } else {
        console.log(fctName+': EXC: ex='+ex.stack);
      }
    } finally {
      refClientLoading.value = false;
    }

  } // ascClientCheckRec

  /* ==================================================================================
   * ascClientSetPsw: Request REST API services for the record having the specified id. Then send notification of new passowrd
   * reqParams { tblCriteria: ' sMobile =  ... OR sEmail =  ... ', psw: 'NewPassword', } :Input parameters
   * fctCallback :Callback function, if defined
   */
  async function ascClientSetPsw(reqParams, fctCallback) {
    const fctName = 'svcClientApi.ascClientSetPsw';
    const reqUrl = '/api/client/findRec';
	const reqUrlSendNotification = '/api/msg/sendNotification';
    
    refClientLoading.value = true;
    try {
      // # const { data } is the name set by axios.post. Can not be renamed.
      
      let msgParams;
      let data2;
      
      const { data } = await axios.post(
        reqUrl,
        reqParams   // { tblCriteria: ' sMobile =  ... OR sEmail =  ... ', psw: 'NewPassword', }
      );
      
      // console.log(fctName+': DEBUG: dataStr='+JSON.stringify(data));  // debug
      
      // errCode = [1 ... 99] :OK message, [100 ... 999] :Error message, value = [1000 ... 999] :Process aborted
      refClientResp.value.errCode = data.errCode; 
      refClientResp.value.errMsg = data.errMsg;
      switch(data.errCode) {
        case 1: // OK: rec found
          // Get data
          refClientRec.value = data.rec;
          // You may alter data here
          // refClientRec.value.sPassword = '';   // Reset password. It must be entered again by the user
          refClientRec.value.sPassword = reqParams.psw;
          refClientRec.value.sPassword2 = refClientRec.value.sPassword;
          
          // console.log(fctName+': DEBUG: rec found: refClientRecStr='+JSON.stringify(refClientRec.value));  // debug
          // ==> refClientRecStr={"id":1,"iStatus":1,"dtmCreate":"2020-01-02 00:00:00","dtmModif":"2021-05-16 16:50:12","dtmValidMob":null,"dtmValidEmail":null,
          //	"sPassword":"tmp123","sMobile":"cli123456789","sEmail":"jpn_email@yahoo.com"
          //	"sGenderCode":"M","sFirstName":"Cli JackBis","sLastName":"CLI SMITH","sAddr1":"1 bd Elysée","sAddr2":"1 FL","sZipCode":"99000","sCity":"cty0110","sProvince":"dep010",
          //	"sMsgMode":"E","sCheckCode":null,"sLevel":"A","sNote":null,"sComCode":"Cli001b","sComName":"Société 001b","sComTel":null,"sComFax":null,"sDepartment":"dep001","sPassword2":"tmp123"}
          
          msgParams = {
			msgId: 'pswSet',	//  Message ID: pswSet, pswReset, pswChanged, usrLogin, etc defined in refmsg_xx.json
			msgMode: refClientRec.value.sMsgMode,     // 'E':Email, 'M':SMS
			usrEmail: refClientRec.value.sEmail,   // User's Email
			usrMobile: refClientRec.value.sMobile, // User's Mobile
			usrPassword: reqParams.psw, // New User's sPassword
          };
          
          data2 = await axios.post(
			reqUrlSendNotification,
			msgParams   // { fct: myFct, rec: refRec.value, }
          );
          console.log(fctName+': DEBUG: data2Str='+JSON.stringify(data2));  // debug
          // ==> data2Str={"data":"OK","status":200,"statusText":"OK","headers":{"connection":"keep-alive","content-length":"2","content-type":"text/html; charset=utf-8","date":"Tue, 12 Jul 2022 09:11:37 GMT","etag":"W/\"2-nOO9QiTIwXgNtWtBJezz8kv3SLc\"","server":"nginx/1.14.0 (Ubuntu)","x-powered-by":"Express"},"config":{"url":"/api/msg/sendNotification","method":"post","data":"{\"msgId\":\"pswReset\",\"msgMode\":\"E\",\"usrEmail\":\"jpn_email@yahoo.com\",\"usrMobile\":\"cli123456789\"}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json;charset=utf-8"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1,"maxBodyLength":-1},"request":{}}
          
          break;
        case 2: // Record not found
          console.log(fctName+': DEBUG: rec not found: dataStr='+JSON.stringify(data));  // debug
          
          refClientRec.value = {};
          break;
        default: // Error occured at server sidebar
          refClientRec.value = {};
          break;
      }
      if (fctCallback) {
        // fctCallback has been defined
        // console.log(fctName+': DEBUG: to fctCallback');  // debug
        let resp = {resp:refClientResp.value, rec: refClientRec.value};
        fctCallback(resp);
      } else {
        console.log(fctName+': DEBUG: no callback');  // prov_debug
      }

    } catch (ex) {
      if (ex.response) {
        // Request made and server responded
        console.log(fctName+': EXC: exStatus='+ex.response.status+', exData='+ex.response.data);
        // console.log('exrespdata:'+ex.response.data);  // 9002:DB Exception:2021-04-08 16:59:36 - mdlDbMysql.dbFind
        // console.log('exrespstatus:'+ex.response.status);  // 500
        // console.log('exrespheader:'+ex.response.headers);
      } else {
        console.log(fctName+': EXC: ex='+ex.stack);
      }
    } finally {
      refClientLoading.value = false;
    }

  } // ascClientSetPsw


  /* ==================================================================================
   * ascClientResetPsw: Request REST API services for the record having the specified id. Then send notification to reset password
   * reqParams { fct: myFct, rec: refRec.value, } :Input parameters
   * fctCallback :Callback function, if defined
   */
  async function ascClientResetPsw(reqParams, fctCallback) {
    const fctName = 'svcClientApi.ascClientResetPsw';
    const reqUrl = '/api/client/findRec';
	const reqUrlSendNotification = '/api/msg/sendNotification';
    
    refClientLoading.value = true;
    try {
      // # const { data } is the name set by axios.post. Can not be renamed.
      
      let msgParams;
      let data2;
      
      const { data } = await axios.post(
        reqUrl,
        reqParams   // { fct: myFct, rec: refRec.value, }
      );
      
      // console.log(fctName+': DEBUG: dataStr='+JSON.stringify(data));  // debug
      // ==> dataStr={"errCode":1,"errMsg":"OK","rec":{"id":1,"iStatus":1,"dtmCreate":"2020-01-01T23:00:00.000Z","dtmModif":null,"dtmValidMob":null,"dtmValidEmail":null,"sPassword":"adm123","sMobile":"123456789","sEmail":"jpn_email@yahoo.com","sGenderCode":"M","sFirstName":"Jack","sLastName":"SMITH","sAddr1":"1 bd Elysée","sAddr2":"1 FL","sZipCode":"99000","sCity":"NewCity","sProvince":"County","sMsgMode":"E","sCheckCode":null,"sLevel":"A","sNote":null},"recsNb":0,"recs":[{}]}
      
      // errCode = [1 ... 99] :OK message, [100 ... 999] :Error message, value = [1000 ... 999] :Process aborted
      refClientResp.value.errCode = data.errCode; 
      refClientResp.value.errMsg = data.errMsg;
      switch(data.errCode) {
        case 1: // OK: rec found
          // Get data
          refClientRec.value = data.rec;
          // You may alter data here
          // refClientRec.value.sPassword = '';   // Reset password. It must be entered again by the user
          refClientRec.value.sPassword2 = refClientRec.value.sPassword;
          
          // console.log(fctName+': DEBUG: rec found: refClientRecStr='+JSON.stringify(refClientRec.value));  // debug
          // ==> refClientRecStr={"id":1,"iStatus":1,"dtmCreate":"2020-01-02 00:00:00","dtmModif":"2021-05-16 16:50:12","dtmValidMob":null,"dtmValidEmail":null,
          //	"sPassword":"tmp123","sMobile":"cli123456789","sEmail":"jpn_email@yahoo.com"
          //	"sGenderCode":"M","sFirstName":"Cli JackBis","sLastName":"CLI SMITH","sAddr1":"1 bd Elysée","sAddr2":"1 FL","sZipCode":"99000","sCity":"cty0110","sProvince":"dep010",
          //	"sMsgMode":"E","sCheckCode":null,"sLevel":"A","sNote":null,"sComCode":"Cli001b","sComName":"Société 001b","sComTel":null,"sComFax":null,"sDepartment":"dep001","sPassword2":"tmp123"}
          
          msgParams = {
			msgId: 'pswReset',	//  Message ID: pswReset, pswChanged, usrLogin, etc defined in refmsg_xx.json
			msgMode: refClientRec.value.sMsgMode,     // 'E':Email, 'M':SMS
			usrEmail: refClientRec.value.sEmail,   // User's Email
			usrMobile: refClientRec.value.sMobile, // User's Mobile
          };
          
          data2 = await axios.post(
			reqUrlSendNotification,
			msgParams   // { fct: myFct, rec: refRec.value, }
          );
          console.log(fctName+': DEBUG: data2Str='+JSON.stringify(data2));  // debug
          // ==> data2Str={"data":"OK","status":200,"statusText":"OK","headers":{"connection":"keep-alive","content-length":"2","content-type":"text/html; charset=utf-8","date":"Tue, 12 Jul 2022 09:11:37 GMT","etag":"W/\"2-nOO9QiTIwXgNtWtBJezz8kv3SLc\"","server":"nginx/1.14.0 (Ubuntu)","x-powered-by":"Express"},"config":{"url":"/api/msg/sendNotification","method":"post","data":"{\"msgId\":\"pswReset\",\"msgMode\":\"E\",\"usrEmail\":\"jpn_email@yahoo.com\",\"usrMobile\":\"cli123456789\"}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json;charset=utf-8"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1,"maxBodyLength":-1},"request":{}}
          
          break;
        case 2: // Record not found
          console.log(fctName+': DEBUG: rec not found: dataStr='+JSON.stringify(data));  // debug
          
          refClientRec.value = {};
          break;
        default: // Error occured at server sidebar
          refClientRec.value = {};
          break;
      }
      if (fctCallback) {
        // fctCallback has been defined
        // console.log(fctName+': DEBUG: to fctCallback');  // debug
        let resp = {resp:refClientResp.value, rec: refClientRec.value};
        fctCallback(resp);
      } else {
        console.log(fctName+': DEBUG: no callback');  // prov_debug
      }

    } catch (ex) {
      if (ex.response) {
        // Request made and server responded
        console.log(fctName+': EXC: exStatus='+ex.response.status+', exData='+ex.response.data);
        // console.log('exrespdata:'+ex.response.data);  // 9002:DB Exception:2021-04-08 16:59:36 - mdlDbMysql.dbFind
        // console.log('exrespstatus:'+ex.response.status);  // 500
        // console.log('exrespheader:'+ex.response.headers);
      } else {
        console.log(fctName+': EXC: ex='+ex.stack);
      }
    } finally {
      refClientLoading.value = false;
    }

  } // ascClientResetPsw


  /* ==================================================================================
   * ascClientSaveRec: Send a record to save to REST API services
   */
   async function ascClientSaveRec(reqParams, fctCallback) {
    const fctName = 'svrClientApi.ascClientSaveRec';
    const reqUrl = '/api/client/saveRec';
    
    // console.log(fctName+': DEBUG: reqParamsStr='+JSON.stringify(reqParams));  // debug
    // ==> reqParamsStr={"id":"STA031"}

    refClientLoading.value = true;
    try {
      // # const { data } is the name set by axios.post. Can not be renamed.
      const { data } = await axios.post(
        reqUrl,
        reqParams
      );
      
      // console.log(fctName+': DEBUG: dataStr='+JSON.stringify(data));  // debug
      // ==> dataStr={"errCode":201,"errMsg":"Invalide function"}
      refClientResp.value.errCode = data.errCode; 
      refClientResp.value.errMsg = data.errMsg;
      
      if (fctCallback) {
        // fctCallback has been defined
        console.log(fctName+': DEBUG: callback: dataStr='+JSON.stringify(data));  // prov_debug
        let resp = {resp:refClientResp.value};
        fctCallback(resp);
      } else {
        console.log(fctName+': DEBUG: no callback retDataStr='+JSON.stringify(data));  // prov_debug
      }
      
      
    } catch (ex) {
      if (ex.response) {
        // Request made and server responded
        console.log(fctName+': EXC: exStatus='+ex.response.status+', exData='+ex.response.data);
        // console.log('exrespdata:'+ex.response.data);  // 9002:DB Exception:2021-04-08 16:59:36 - mdlDbMysql.dbFind
        // console.log('exrespstatus:'+ex.response.status);  // 500
        // console.log('exrespheader:'+ex.response.headers);
      } else {
        console.log(fctName+': EXC: ex='+ex.stack);
      }
    } finally {
      refClientLoading.value = false;
    }
  } // ascClientSaveRec
  
  /* ==================================================================================
   * ascClientDeleteRec: Send request to delete a record to REST API services
   */
   async function ascClientDeleteRec(reqParams, fctCallback) {
    const fctName = 'svrClientApi.ascClientDeleteRec';
    const reqUrl = '/api/client/deleteRec';
    
    // console.log(fctName+': DEBUG: reqParamsStr='+JSON.stringify(reqParams));  // debug
    // ==> reqParamsStr={"id":"STA031"}

    refClientLoading.value = true;
    try {
      // # const { data } is the name set by axios.post. Can not be renamed.
      const { data } = await axios.post(
        reqUrl,
        reqParams
      );
      
      // console.log(fctName+': DEBUG: dataStr='+JSON.stringify(data));  // debug
      // ==> dataStr={"errCode":201,"errMsg":"Invalide function"}
      refClientResp.value.errCode = data.errCode; 
      refClientResp.value.errMsg = data.errMsg;
      
      if (fctCallback) {
        // fctCallback has been defined
        console.log(fctName+': DEBUG: callback: dataStr='+JSON.stringify(data));  // prov_debug
        let resp = {resp:refClientResp.value};
        fctCallback(resp);
      } else {
        console.log(fctName+': DEBUG: no callback retDataStr='+JSON.stringify(data));  // prov_debug
      }
      
      
    } catch (ex) {
      if (ex.response) {
        // Request made and server responded
        console.log(fctName+': EXC: exStatus='+ex.response.status+', exData='+ex.response.data);
        // console.log('exrespdata:'+ex.response.data);  // 9002:DB Exception:2021-04-08 16:59:36 - mdlDbMysql.dbFind
        // console.log('exrespstatus:'+ex.response.status);  // 500
        // console.log('exrespheader:'+ex.response.headers);
      } else {
        console.log(fctName+': EXC: ex='+ex.stack);
      }
    } finally {
      refClientLoading.value = false;
    }
  } // ascClientDeleteRec
  
  /* =============================================================================================
   * fctExtractClient: Return a JSON object holding all client  
   * in the form { "supId01":"supName01", "supIdxx":"supNamexx", ...]
   */
  function fctExtractClient() {
    const fctName = 'vjsTools.fctExtractClient';
  
    if (refClientRecs.value.length == 0) {
      console.log(fctName+': ERROR: load refClientRecs'); 
      // ascClientGetRecs(fctExtractClient);
      return {};
    }
    console.log(fctName+': DEBUG: refClientRecsStr='+JSON.stringify(refClientRecs.value));  // debug
    
    let rawdata = '{';
    for (let objIdx=0; objIdx < refClientRecs.value.length; objIdx++) {
      let myObj = refClientRecs.value[objIdx];
      // console.log(fctName+': DEBUG: myObjStr['+objIdx+']='+JSON.stringify(myObj));  // debug
      if (objIdx > 0) rawdata += ',';
      rawdata += '"'+myObj.id+'":"'+myObj.sComName+'"';
    }
    rawdata += '}';
    
    console.log(fctName+': DEBUG: rawdata='+rawdata);  // debug
    // ==> 
    
    return JSON.parse(rawdata);
  } // fctExtractClient

  return  {
      refClientResp: computed(() => refClientResp.value),
      refClientPswReset: computed(() => refClientPswReset.value),
      refClientLoading: computed(() => refClientLoading.value),
      refClientRecs: computed(() => refClientRecs.value),
      refClientRecsNb: computed(() => refClientRecsNb.value),
      refClientRec,
      ascClientGetRecs,
      ascClientLogin,
      ascClientFindRec,
      ascClientCheckRec,	//  ascClientCheckRec: Request REST API services for the record having the specified id
      ascClientSetPsw,
      ascClientResetPsw,
      ascClientSaveRec,
      ascClientDeleteRec,  // ascClientDeleteRec: Send request to delete a record to REST API services
      fctExtractClient,    // fctExtractClient: Return a JSON object holding all supplier  
  }
}
