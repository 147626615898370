import { ref, computed } from "vue";
import useDicoApi from '@/services/svcDicoApi.js';
import useStateCityApi from '@/services/svcStateCityApi.js';
import useMenuTypeApi from '@/services/svcMenuTypeApi.js';
import useDepartmentApi from '@/services/svcDepartmentApi.js';
import useItmTypeApi from '@/services/svcItmTypeApi.js';
// import useSupplierApi from '@/services/svcSupplierApi.js';

// Enable/Disable inventory verification
let refFlgCheckIry = ref(false);		// =true:check iInvQty, =false:do NOT check iInvQty

// Global variable that can be shared state with Composition API (begin)
let refDicoLoading = ref(true);
let refDicoLang = ref('xx');      // Current lang = 'tw', 'en'
let refDicoAttr = ref({});      // Dictionnary of name/calue: { 'xxAttrName':'xxAttrValue', ... }
let refStateCityAttr = ref({}); // Hierarchy of state/city: { 'xxStateCode':'xxStateName', 'submenu':{ 'xxCityCode':'xxCityName', ... }, ... }
let refMenuTypeAttr = ref({}); // Hierarchy of menu/submenu: { 'xxStateCode':'xxStateName', 'submenu':{ 'xxCityCode':'xxCityName', ... }, ... }
let refDepartmentAttr = ref({}); // JSON object holding the list of departments
let refItmTypeAttr = ref({});    // JSON object holding the list of item type
let refPageAttr = ref({});       // JSON object holding information related to current page
let refSlctMenu = ref({topMenuCode:'', topMenuName:'', subMenuCode:'', subMenuName:'', fctCallback:'', img:'', descr:'', fctCallbackMainMenu:'', fctCallbackTopMenu:'' });

let refDescrMenus = ref([
  {id:'mnu000', img:'mnu000.jpg', descr:'descr000'},
  {id:'mnu010', img:'mnu010.jpg', descr:'descr010'},
  {id:'mnu020', img:'mnu020.jpg', descr:'descr020'},
  {id:'mnu040', img:'mnu040.jpg', descr:'descr040'},
  {id:'mnu060', img:'mnu060.jpg', descr:'descr060'},
  {id:'mnu080', img:'mnu080.jpg', descr:'descr080'},
  {id:'mnu090', img:'mnu090.jpg', descr:'descr090'},
  {id:'mnu100', img:'mnu100.jpg', descr:'descr100'},
  {id:'mnu105', img:'mnu105.jpg', descr:'descr105'},
  {id:'mnu110', img:'mnu110.jpg', descr:'descr110'},
  {id:'mnu120', img:'mnu120.jpg', descr:'descr120'},
  {id:'mnu130', img:'mnu130.jpg', descr:'descr130'},
  {id:'mnu140', img:'mnu140.jpg', descr:'descr140'},
  {id:'titre02', img:'titre02.jpg', descr:'精選促銷商品，隨時享受超低折扣優惠，購物省錢更開心。'},
  {id:'titre03', img:'titre03.jpg', descr:'各式各樣的新商品，不定期更新，发现新品寶藏的小確幸。'},
  {id:'titre04', img:'titre04.jpg', descr:'推薦超優惠的獨家商品任你挑選,線上獨家趕快來選購吧。'},
  {id:'titre05', img:'titre05.jpg', descr:'真心推薦人氣熱銷排行商品，不要錯過！快來看看哪個你最喜歡。'},
  {id:'titre06', img:'titre06.jpg', descr:'限量發售商品，賣完即止，無法持續補貨，請您行動要快。'},
  {id:'titre07', img:'titre07.jpg', descr:'提供大量訂購品項，宅配批發各大公司行號， 一次大量訂購，優惠享更多。'},
]);

let refPgHomeReady = ref(false);
let refCpnHeader = ref({});
// Global variable that can be shared state with Composition API (end)

export default function useReferential() {
  
  function referentialGetVersion() {
    const fctName = 'mdlReferential.referentialGetVersion';
        console.log(fctName+': TODO: refSlctMenuStr='+JSON.stringify(refSlctMenu.value));
    return "mdlReferential v1.0.20230504";
  }

  /* =============================================================================================
   * ascSetLang: Load referentials from REST API services:
   * refDicoAttr:       Dictionnary of name/value
   * refStateCityAttr:  Hierarchy of state/city
   * refMenuTypeAttr:   Hierarchy of menu/submenu
   * // refDepartmentAttr: List of department
   * refItmTypeAttr:    List of item type
   */
  async function ascSetLang(newLang, fctCallback) {
    const fctName = 'mdlReferential.ascSetLang';
    
    const {refDicoRec, ascDicoLoad} = useDicoApi();
    const {refStateCityRec, ascStateCityLoad} = useStateCityApi();
    const {refMenuTypeRec, ascMenuTypeLoad} = useMenuTypeApi();
    const {refDepartmentRec, ascDepartmentLoad} = useDepartmentApi();
    const {refItmTypeRec, ascItmTypeLoad} = useItmTypeApi();
    // const {refSupplierRecs, ascSupplierGetRecs} = useSupplierApi();
    
    
    
    if (refDicoLang.value != newLang) {
      // Fetch dico of corresponding lang (begin)
      console.log('mdlReferential.ascSetLang: DEBUG: NEW Lang='+newLang+'/'+refDicoLang.value);  // debug
      
      refDicoLang.value = newLang;  // Set current lang
      
      // Load Dico ........................................................................................
      await ascDicoLoad(refDicoLang.value);
      // refDicoAttr.value = computed(() => {
      //  return refDicoRec.value;
      // });
      refDicoAttr.value = refDicoRec.value;
      
      // console.log(fctName+'.computed: DEBUG: refDicoRecStr='+JSON.stringify(refDicoRec.value));  // debug
      // console.log(fctName+'.computed: DEBUG: refDicoAttrStr='+JSON.stringify(refDicoAttr.value));  // debug
      // ==> refDicoAttrStr={"menuHome":"Home","menuShopping":"Shopping","menuShoLst":"Shopping orders","menuShoFrmNew":"New order","menuCliLst":"List of clients","menuCliFrmNew":"New client","menuPurchase":"Purchase","menuPurLst":"Purchase orders","menuPurFrmNew":"New purchase","menuSupLst":"List of suppliers","menuSupFrmNew":"New supplier","menuSetup":"Setup"}
      
      // console.log(fctName+'.computed: DEBUG: menuHome='+refDicoAttr.value.menuHome);  // debug
      
      // Load StateCity ........................................................................................
      await ascStateCityLoad(refDicoLang.value);
      // refStateCityAttr = computed(() => {
      //  return refStateCityRec.value;
      // });
      refStateCityAttr.value = refStateCityRec.value;
      
      // console.log(fctName+'.computed: DEBUG: refStateCityAttrStr='+JSON.stringify(refStateCityAttr.value));  // debug
      // ==> refStateCityAttrStr={"menuHome":"Ho首頁me","menuShopping":"Shopping","menuShoLst":"Shopping orders","menuShoFrmNew":"New order","menuCliLst":"List of clients","menuCliFrmNew":"New client","menuPurchase":"Purchase","menuPurLst":"Purchase orders","menuPurFrmNew":"New purchase","menuSupLst":"List of suppliers","menuSupFrmNew":"New supplier","menuSetup":"Setup"}
      
      // Load MenuType ........................................................................................
      await ascMenuTypeLoad(refDicoLang.value);
      // refMenuTypeAttr = computed(() => {
      //  return refMenuTypeRec.value;
      // });
      refMenuTypeAttr.value = refMenuTypeRec.value;
      
      // console.log(fctName+'.computed: DEBUG: refMenuTypeAttrStr='+JSON.stringify(refMenuTypeAttr.value));  // debug
      // ==> refMenuTypeAttrStr=[{"mnu010":"Water","submenu":{"snu0110":"Mineral water","snu0120":"Energy water"}},{"mnu020":"Almighty drink","submenu":{"snu0210":"Coffee","snu0220":"Tea","snu0230":"Bean milk","snu0240":"Juice"}},{"mnu040":"Instant noodles","submenu":{"snu0410":"Science noodles","snu0420":"President noodles","snu0430":"One more cup","snu0440":"Man noodles","snu0450":"AQ noodles","snu0460":"Hand-Pulled noodles","snu0470":"Rice vermicelli","snu0480":"Instant thin noodles","snu0490":"Noodles"}},{"mnu060":"Refrigeration drink","submenu":{"snu0610":"Juice","snu0620":"Cerebrose milk","snu0630":"Milk","snu0640":"Coffee","snu0650":"Tea","snu0660":"Flavor milk","snu0670":"Cereal milk","snu0680":"Soya bean milk","snu0690":"Brown rice milk","snu0700":"Yakult","snu0710":"Pudding","snu0720":"Yogurt"}},{"mnu080":"Fried meat","submenu":{"snu0810":"Sausage","snu0820":"Bacon","snu0830":"Ham"}},{"mnu090":"Freezer","submenu":{"snu0910":"Dumplings"}},{"mnu100":"Condiment","submenu":{"snu1010":"Sauce"}}]
      
      // Load Department ........................................................................................
      await ascDepartmentLoad(refDicoLang.value);
      // refDepartmentAttr = computed(() => {
      //   return refDepartmentRec.value;
      // });
      refDepartmentAttr.value = refDepartmentRec.value;

      // console.log(fctName+'.computed: DEBUG: refDepartmentAttrStr='+JSON.stringify(refDepartmentAttr.value));  // debug
      // ==> refDepartmentAttrStr={"dep001":"Sales services","dep002":"Dairy services","dep003":"Sauce services","dep004":"Marketing services"}
      
      // Load Item type ........................................................................................
      await ascItmTypeLoad(refDicoLang.value);
      // refItmTypeAttr = computed(() => {
      //  return refItmTypeRec.value;
      // });
      refItmTypeAttr.value = refItmTypeRec.value;
      
      // console.log(fctName+'.computed: DEBUG: refItmTypeAttrStr='+JSON.stringify(refItmTypeAttr.value));  // debug
      // ==> refItmTypeAttrStr={"uni001":"Box","uni002":"Case ","uni003":"Bottle","uni004":"Can","uni005":"Packet","uni006":"Set","uni007":"Sachet","uni008":"Pallet","uni009":"Strip","uni010":"Cup","uni011":"Branch","uni012":"Sheets","uni013":"Barrel","uni014":"Basket","uni015":"Bouquet","uni016":"Tablet","uni017":"Grain","uni018":"Single","uni019":"Piece","uni020":"Slice"}
      
      // Load Suppliers ........................................................................................
      // await ascSupplierGetRecs();
      
      
      // ...................................................................................................
      refDicoLoading.value = false; // =false: referential data has been loaded into refDico
      // Fetch dico of corresponding lang (end)
    } else {
      console.log('mdlReferential.ascSetLang: DEBUG: SAME Lang='+newLang+'/'+refDicoLang.value);  // debug
    }
    if (fctCallback) {
      // fctCallback has been defined
      console.log(fctName+': DEBUG: to fctCallback: refDicoLang='+refDicoLang.value+'/'+newLang);  // debug
      let resp = {status:'OK', recDico: refDicoAttr.value};
      fctCallback(resp);
    } else {
      console.log(fctName+': DEBUG: NO fctCallback: refDicoLang='+refDicoLang.value+'/'+newLang);  // debug
    }
  } // ascSetLang
  
  /* =============================================================================================
   * fctExtractState: Return a JSON object holding all states  
   * at the first element of each row of the provided JSON array
   */
  function fctExtractState() {
    const fctName = 'vjsTools.fctExtractState';
  
    // console.log(fctName+': DEBUG: jsonArrayStr='+JSON.stringify(refStateCityAttr.value));  // debug
    
    let eleValue;
    let rawdata = '{';
    for (let objIdx=0; objIdx < refStateCityAttr.value.length; objIdx++) {
      let objState = refStateCityAttr.value[objIdx];
      for (const eleName in objState) {
        eleValue = objState[eleName];
        if (rawdata != '{') rawdata += ',';
        rawdata += '"'+eleName+'":"'+eleValue+'"';
        break;  // State is the first element of each row of the array. Skip other elements.
      }
    }
    rawdata += '}';
    // console.log(fctName+': DEBUG: rawdata='+rawdata);  // debug
    // ==> 
    
    return JSON.parse(rawdata);
  } // fctExtractState
  
  /* =============================================================================================
   * fctExtractCity: Return a JSON object holding all cities related to the specified state  
   */
  function fctExtractCity(stateCode) {
    const fctName = 'vjsTools.fctExtractCity';
  
    // console.log(fctName+': DEBUG: stateCode='+stateCode+', jsonArrayStr='+JSON.stringify(refStateCityAttr.value));  // debug
    
    let objState;
    let objCity = {};
    
    for (let lstIdx=0; lstIdx < refStateCityAttr.value.length; lstIdx++) {
      // Look for matching state (begin)
      objState = refStateCityAttr.value[lstIdx];
      // console.log(fctName+': DEBUG: objStateStr['+lstIdx+']='+JSON.stringify(objState));  // debug
      if (refStateCityAttr.value[lstIdx][stateCode]) {
        // Found record of corresponding state (begin)
        // console.log(fctName+': DEBUG: found: objStateStr='+JSON.stringify(objState));  // debug
        // ==> objStateStr={"dep010":"Penghu","city":{"cty0110":"Makung","cty0120":"Xiyu"}}
        // List of cities is at the element: city
        objCity = objState['city'];
        break;
        // Found record of corresponding state (end)
      }
      // Look for matching state (end)
    }
    
    // console.log(fctName+': DEBUG: objCityStr='+JSON.stringify(objCity));  // debug
    // ==> 
    
    return objCity;
  } // fctExtractCity
  
  /* =============================================================================================
   * fctExtractMenu: Return a JSON object holding all top level menu type
   * at the first element of each row of the provided JSON array
   */
  function fctExtractMenu() {
    const fctName = 'vjsTools.fctExtractMenu';
  
    // console.log(fctName+': DEBUG: jsonArrayStr='+JSON.stringify(refMenuTypeAttr.value));  // debug
    
    let eleValue;
    let rawdata = '{';
    for (let objIdx=0; objIdx < refMenuTypeAttr.value.length; objIdx++) {
      let objState = refMenuTypeAttr.value[objIdx];
      for (const eleName in objState) {
        eleValue = objState[eleName];
        if (rawdata != '{') rawdata += ',';
        rawdata += '"'+eleName+'":"'+eleValue+'"';
        break;  // Top level menu type is the first element of each row of the array. Skip other elements.
      }
    }
    rawdata += '}';
    // console.log(fctName+': DEBUG: rawdata='+rawdata);  // debug
    // ==> 
    
    return JSON.parse(rawdata);
  } // fctExtractMenu
  
  /* =============================================================================================
   * fctExtractSubmenu: Return a JSON object holding all submenu related to the specified top level menu type  
   */
  function fctExtractSubmenu(menuCode) {
    const fctName = 'vjsTools.fctExtractSubmenu';
  
    // console.log(fctName+': DEBUG: menuCode='+menuCode+', jsonArrayStr='+JSON.stringify(refMenuTypeAttr.value));  // debug
    
    let objMenu;
    let objSubmenu = {};
    
    for (let lstIdx=0; lstIdx < refMenuTypeAttr.value.length; lstIdx++) {
      // Look for matching top level menu (begin)
      objMenu = refMenuTypeAttr.value[lstIdx];
      if (refMenuTypeAttr.value[lstIdx][menuCode]) {
        // Found record of corresponding state (begin)
        // console.log(fctName+': DEBUG: found: objMenuStr='+JSON.stringify(objMenu));  // debug
        // ==> objStateStr={"dep010":"Penghu","city":{"cty0110":"Makung","cty0120":"Xiyu"}}
        // List of cities is at the element: city
        objSubmenu = objMenu['submenu'];
        break;
        // Found record of corresponding state (end)
      }
      // Look for matching top level menu (end)
    }
    
    // console.log(fctName+': DEBUG: objSubmenuStr='+JSON.stringify(objSubmenu));  // debug
    // ==> 
    
    return objSubmenu; 
  } // fctExtractSubmenu

  return  {
      refFlgCheckIry,			// Enable/Disable inventory verification // =true:check iInvQty, =false:do NOT check iInvQty
      refDicoLoading,       // =true:referential not yet ready, =false:referential loaded
      refDicoLang,          // Current lang = 'tw', 'en'
      refDicoAttr,          // Dictionnary of name/calue: { 'xxAttrName':'xxAttrValue', ... }
      refStateCityAttr,     // Hierarchy of state/city: { 'xxStateCode':'xxStateName', 'submenu':{ 'xxCityCode':'xxCityName', ... }, ... }
      refMenuTypeAttr,     // Hierarchy of menu/submenu: { 'xxStateCode':'xxStateName', 'submenu':{ 'xxCityCode':'xxCityName', ... }, ... }
      refDepartmentAttr,    // JSON object holding the list of departments
      refItmTypeAttr,       // JSON object holding the list of item type
      refPageAttr,          // JSON object holding information related to current page
      referentialGetVersion,
      ascSetLang,           // ascSetLang: Load referentials from REST API services:
      fctExtractState,      // fctExtractState: Return a JSON object holding all states
      fctExtractCity,       // fctExtractCity: Return a JSON object holding all cities related to the specified state
      fctExtractMenu,       // fctExtractMenu: Return a JSON object holding all top level menu type
      fctExtractSubmenu,    // fctExtractSubmenu: Return a JSON object holding all submenu related to the specified top level menu type  
      refSlctMenu: computed(() => refSlctMenu.value),
      refDescrMenus: computed(() => refDescrMenus.value),
      refPgHomeReady,
      refCpnHeader,
  }
}
