<template>
  <!-- https://www.freeformatter.com/html-escape.html -->
  <br>
  <div class="divpx">
    <h1 class="divph1">聯絡我們</h1>
    <br>
    <table class="contacttable">
     <thead>
      <td style="width:60%;"><b>中悅商行國際有限公司</b></td>
      <td>&nbsp;</td>
      <td style="width:25%;">營業時間</td>
      <td>&nbsp;</td>
     </thead>
     <tbody>
      <tr>
        <td>統編: 83027687</td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td style="white-space: normal;">澎湖縣馬公市中山路 23 號</td>
        <td>&nbsp;</td>
        <td style="white-space: normal;">週一 至 週五<br>&nbsp;</td>
        <td>8:00-12:00<br>13:30-17:00</td>
      </tr>
      <tr>
       <td>電話:(06)9263293</td>
       <td>&nbsp;</td>
       <td>&nbsp;</td>
       <!--<td>13:30-17:00</td>-->
      </tr>
      <tr>
       <td>傳真:(06)9275462</td>
       <td>&nbsp;</td>
       <td>週六 至 週日</td>
       <td>休息</td>
      </tr>
      <tr>
       <td>&nbsp;</td>
      </tr>
      <tr>
       <td style="white-space: normal;" colspan=3>歡迎加入 line 任何好康資訊，各種有利消息都在這裡</td>
       <td style="text-align:right;">
         <a href="https://lin.ee/ZNTqGA6" target="linkLine"><img src="/img/logoLine.png" alt="Add friend" border="0"></a>
       </td>
      </tr>
      
     </tbody>
     
    </table>
    <br>
    <br>
    <br>&nbsp;

     <!--
    <table class="contacttable" border="0">
      <tr>
        <td>您的姓名</td>
        <td><input type="text" style="width:98%;"/></td>
      </tr>
      <tr>
        <td>email 信箱</td>
        <td><input type="text" style="width:98%;"/></td>
      </tr>
      <tr>
        <td>手機號碼</td>
        <td><input type="text" style="width:98%;"/></td>
      </tr>
      <tr>
        <td>詢問內容</td>
        <td><input type="text" style="width:98%; height:50px;"/></td>
      </tr>
    </table>
     -->
     
    <br>
    <br>
    <br>
    <br>&nbsp;
  </div>
</template>

<script>
  import {
    ref, computed, onActivated, onBeforeMount, onBeforeUnmount, onBeforeUpdate,
    onDeactivated, onErrorCaptured, onMounted, onUnmounted, onUpdated, onRenderTracked, onRenderTriggered
  } from 'vue';
  import useReferential from '@/modules/mdlReferential.js';
  
    export default {
      methods: {
      },
      setup() {
        onBeforeMount(() => console.log('before mount'));       // Event triggered on open page
        // onMounted(() => console.log('mounted'));             // Event triggered on open page
        onBeforeUpdate(() => console.log('before update'));     // Event triggered on open page
        onUpdated(() => console.log('updated'));                // Event triggered on open page
        onBeforeUnmount(() => console.log('before unmount'));   // Event triggered on leaving page
        onUnmounted(() => console.log('unmounted'));            // Event triggered on leaving page
        onActivated(() => console.log('activated'));
        onDeactivated(() => console.log('deactivated'));
        onErrorCaptured(() => console.error('error captured'));
        onRenderTracked(() => console.log('render tracked'));
        onRenderTriggered(() => console.log('render triggered'));
        const { refDicoLoading, refDicoLang, refDicoAttr, refPageAttr, refItmTypeAttr, ascSetLang, refSlctMenu, fctExtractMenu, fctExtractSubmenu } = useReferential();
        
        onMounted(async () => {
          console.log('vwsP4.onMounted: DEBUG: ');  // debug
          window.scrollTo(0, 0);
          refPageAttr.value.title = '';
        });
      
        return {
        }
      },
      
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
