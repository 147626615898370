<template>
 <!-- https://www.freeformatter.com/html-escape.html -->
 <br>
 <div class="divpx">
  <h1 class="divph1">問與答</h1>
  <br>
  <br>Q：我昨天才購買商品結果今天就降價了？
  <br>A：為配合不定期促銷活動和主題促銷，部份產品在各階段將以不同價格出售，屬價格正常調動，不提供補差價服務；活動頁面上我們對價格、折扣和促銷活動均有明確說明，且活動結束後將不享受活動價格，還請您諒解。
  <br>
  <br>Q：鑑賞期如何計算？
  <br>A：七日鑑賞期計算: 從簽收日算起七日內，不論是否為買家本人簽收(管理員/家人等) ，還請務必留意。鑑賞期不為試用期，還請留意請保持商品全新狀態。購買的網路商品如果屬於下面這 7 種類型，無法享有「7 天鑑賞期」或無條件退換貨 
  <br>一、易於腐敗、保存期限較短或解約時即將逾期。
  <br>二、依消費者要求所為之客製化給付。
  <br>三、報紙、期刊或雜誌。
  <br>四、經消費者拆封之影音商品或電腦軟體。
  <br>五、非以有形媒介提供之數位內容或一經提供即為完成之線上服務，經消費者事先同意始提供。
  <br>六、已拆封之個人衛生用品。
  <br>七、國際航空客運服務。
  <br>
  <br>Q：該如何加入會員呢？ 忘記密碼或帳號怎麼辦？
  <br>A：請先將喜歡的商品加入購物車，至購物車結帳時點選「結帳」，依照步驟完成訂購，系統即會自動將您加入會員，並請熟記當時輸入的帳號與密碼以便後續登入。
  <br>◇若您忘記密碼請點選 登入會員 &gt; 忘記密碼 &gt; 輸入會員帳號並送出 &gt; 系統將重新提供新密碼至您的聯絡信箱或以簡訊通知 。
  <br>
  <br>Q：如何變更密碼呢？ 
  <br>A：請點選 登入會員 &gt; 基本資料 &gt; 在密碼欄位輸入新的密碼並再次確認 &gt;按驗證紐
  <br>
  <br>Q：如何取消訂單呢?
  <br>A：線上取消訂單：請至「我的訂單」，確認辦理的訂單狀態顯示『處理中』時，按下「取消」
  <br>
  <br>Q：已經結帳還能加購商品或改成別種付款方式嗎？
  <br>A：因完成結帳至出貨作業程序較為快速，故訂單成立後，無法做任何異動。
  <br>若下單後發現訂單有誤或想加購商品，請登入&gt;&gt;我的訂單，進行取消並重新下單
  <br>
  <br>Q：如何退回部分商品?
  <br>A：線上退貨：請至「我的訂單」，每筆訂單前方皆有「打開訂單」按鈕，進入訂單明細選擇退貨商品、退貨數量、退貨原因。 
  <br>
  <br>Q：退貨時訂單所支付之運費會退回嗎？
  <br>A：商品總金額未達免運費門檻會酌收運費，此運費無法於退貨時退回。
  <br>
  <br>Q：該如何知道商品送達了？ 離島地區是否寄送？
  <br>A：您的商品出貨後，會以 E-MAIL 或手機通知。宅急便包裹配送到府時，宅急便人員亦會致電聯繫您已到達您指定的地點。
  <br>◇澎湖離島地區（望安鄉、七美鄉、虎井島、桶盤島、大倉嶼、員貝嶼、鳥嶼、吉貝嶼）
  <br>*無提供海運配送,請提供澎湖本島運送地址
  <br>◇黑貓宅急便下列區域無法送達。
  <br>澎湖地區：望安鄉、七美鄉、虎井島、桶盤島、大倉嶼、員貝嶼、鳥嶼、吉貝嶼
  <br>金門地區：烏坵、烈嶼、大膽、二膽
  <br>其它地區：蘭嶼、釣漁台列嶼、東沙、南沙及郵政信箱
  <br>
  <br>Q：有電子發票嗎？ 可開 二聯或三聯式發票嗎？  一筆訂單可以開立多張發票嗎？
  <br>A：電子發票將於訂單出貨時附上。可於訂單成立時勾選【個人電子發票】或【公司戶電子發票】。單筆訂單僅能產生乙張發票，若需開立多張發票還煩請個別完成訂購動作。
  <br>
  <br>
  <br>
  <br>&nbsp;
 </div>
</template>

<script>
  import {
    ref, computed, onActivated, onBeforeMount, onBeforeUnmount, onBeforeUpdate,
    onDeactivated, onErrorCaptured, onMounted, onUnmounted, onUpdated, onRenderTracked, onRenderTriggered
  } from 'vue';
  import useReferential from '@/modules/mdlReferential.js';
  
    export default {
      methods: {
      },
      setup() {
        onBeforeMount(() => console.log('before mount'));       // Event triggered on open page
        // onMounted(() => console.log('mounted'));             // Event triggered on open page
        onBeforeUpdate(() => console.log('before update'));     // Event triggered on open page
        onUpdated(() => console.log('updated'));                // Event triggered on open page
        onBeforeUnmount(() => console.log('before unmount'));   // Event triggered on leaving page
        onUnmounted(() => console.log('unmounted'));            // Event triggered on leaving page
        onActivated(() => console.log('activated'));
        onDeactivated(() => console.log('deactivated'));
        onErrorCaptured(() => console.error('error captured'));
        onRenderTracked(() => console.log('render tracked'));
        onRenderTriggered(() => console.log('render triggered'));
        const { refDicoLoading, refDicoLang, refDicoAttr, refPageAttr, refItmTypeAttr, ascSetLang, refSlctMenu, fctExtractMenu, fctExtractSubmenu } = useReferential();
        
        onMounted(async () => {
          console.log('vwsP5.onMounted: DEBUG: ');  // debug
          window.scrollTo(0, 0);
          refPageAttr.value.title = '';
        });
      
        return {
        }
      },
      
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>