<template>

  <div >
    
    <center>
    <br>
    
    <span v-if="refOrdersLoading"><br><br>Loading ...<br><br></span>
    <span v-else>

        <center>

      <h1>{{ refDico.tleCliReturns }}</h1>
      <br>
      <table  class="cpnLst01" width="90%">
          <thead>
          <tr>
              <th> &nbsp; </th>
              <!-- th>{{ refDico.lblOrdKey }}</th -->
              <!-- th>{{ refDico.lblCliName }}</th -->
              <th>{{ refDico.lblDtOrder }}</th>
              <th>{{ refDico.lblOrdId }}</th>
              <th>{{ refDico.lblTotal }}</th>
              <th>{{ refDico.lblInvCode }}</th>
              <th>{{ refDico.lblOrdStatus }}</th>
              <th>{{ refDico.lblOrdCancelDate }}</th>
              <th>{{ refDico.lbkDtReturn2 }}</th>
          </tr>
          </thead>
          <tbody>
            <!-- tr v-for="tmpRec in refOrdersRecs" :key="tmpRec.id" v-on:click="openForm(tmpRec.id)" -->
            <tr v-for="tmpRec in refOrdersRecs" :key="tmpRec.id" >
                <td align="center">
                    <a class="btnStd01" v-on:click="openForm(tmpRec.id);" v-bind:title="refDico.lblOrdOpen" >&nbsp;<i class="fa fa-file-o fa-1x"></i>&nbsp;</a>
                </td>
                <!-- td>{{ tmpRec.id }}</td -->
                <!-- td>{{ tmpRec.idClient }}</td -->
                <td align="center">{{ tmpRec.dtmCreate }}</td>
                <td align="center">{{ tmpRec.sOrdCode }}</td>
                <td align="right">{{ tmpRec.iOrdTotal }}</td>
                <td align="center">{{ tmpRec.sEtkInvCode }}</td>
                <td align="center">{{ refOrdStatus[ tmpRec.iStatus ] }}</td>
                <td align="center" nowrap>
                  <span v-if="tmpRec.dtmCancel" >
                    &nbsp;{{ tmpRec.dtmCancel }}&nbsp;
                  </span>
                  <span v-else>
                    <span v-if="(tmpRec.iStatus >= 1 && tmpRec.iStatus < 4)" >
                      <a class="btnStd01" v-on:click="cancelOrder(tmpRec.id);" ><i class="fa fa-remove fa-1x"></i> {{ refDico.lblOrdCancel }}</a>
                    </span>
                  </span>
                </td>
                <td align="center" nowrap>
                  <span v-if="tmpRec.dtmReturn2" >
                    &nbsp;{{ tmpRec.dtmReturn2 }}&nbsp;
                  </span>
                  <span v-else>
                    <span v-if="(tmpRec.iStatus >= 6 && tmpRec.iStatus < 9)" >
                      &nbsp;
                      <a class="btnStd01" v-on:click="returnOrder(tmpRec.id);" ><i class="fa fa-reply fa-1x"></i> {{ refDico.lblOrdReturn }}</a>
                    </span>
                  </span>
                </td>
            </tr>
          </tbody>
      </table>

      <br>&nbsp;
        </center>
    
      <br>&nbsp;

    </span>
    
    </center>

  </div>
  
</template>

<script>
  import { ref, computed, onBeforeMount, onMounted } from 'vue';
  import router from '@/router/index.js';
  import { useRoute } from 'vue-router';
  import useReferential from '@/modules/mdlReferential.js'; 
  import useClientApi from '@/services/svcClientApi.js';
  import useOrdersApi from '@/services/svcOrdersApi.js';

  export default {
    methods: {
      openForm: function(recId) {
        console.log('vwsOrderLst.openForm: recId='+recId);
        this.$router.push({name:'pgReturnFrm',params:{pgAction:'init',prmId:recId}});
      }
    },
    setup() {
      const route = useRoute();
      const { refDicoLang, refDicoAttr, refPageAttr, refItmTypeAttr, ascSetLang, refSlctMenu, fctExtractMenu, fctExtractSubmenu } = useReferential();
      let { ascClientFindRec, ascClientSaveRec, ascClientDeleteRec, refClientResp, refClientLoading, refClientRec } = useClientApi();
      let { ascOrdersGetRecs, ascOrdersGetItemRecs, ascOrdersFindRec, ascOrdersSaveRec, ascOrdersDeleteRec, refOrdersResp, refOrdersLoading, refOrdersRecs } = useOrdersApi();
      
      let refDico = ref({});
      let refResp = ref({});
      let refRec = ref({});
      let refOrdStatus = ref([]);
      
      onBeforeMount(() => {
        // this.$router.push({name:'pgItemFrm',params:{pgAction:'init',itmLstFilter:'myItmLstFilter'}});
        
        console.log('vwsOrderLst.onBeforeMount: DEBUG: refClientRecId='+refClientRec.value.id);  // prov_debug
        if (refClientRec.value.id) {
          refPageAttr.value.pgAction = 'init';
          refPageAttr.value.pgName = 'pgOrderLst';
        } else {
          // Client need to login before displaying orders
          openPage('pgClientLogin');
        }
      });
      
      onMounted(async () => {
        refDico.value = refDicoAttr.value;
        refPageAttr.value.title = refDico.value.tleCliOrders;
        
        refOrdStatus.value.length = 0;
        refOrdStatus.value.push(refDico.value.lblOrdStatus0);
        refOrdStatus.value.push(refDico.value.lblOrdStatus1);
        refOrdStatus.value.push(refDico.value.lblOrdStatus2);
        refOrdStatus.value.push(refDico.value.lblOrdStatus3);
        refOrdStatus.value.push(refDico.value.lblOrdStatus4);
        refOrdStatus.value.push(refDico.value.lblOrdStatus5);
        refOrdStatus.value.push(refDico.value.lblOrdStatus6);
        refOrdStatus.value.push(refDico.value.lblOrdStatus7);
        refOrdStatus.value.push(refDico.value.lblOrdStatus8);
        refOrdStatus.value.push(refDico.value.lblOrdStatus9);
        
        console.log('vwsOrderLst.onMounted: DEBUG: CliId='+refClientRec.value.id+', CliMobile='+refClientRec.value.sMobile);  // prov_debug
        let reqParams = { 
              criteria: "idClient = "+refClientRec.value.id, 
              orderby: "dtmCreate DESC",
              };
        ascOrdersGetRecs(reqParams, reqCallback);  // Callback is required if altering the record is required at loading
      });
      
      /**** Without callback (begin) ****
      // Without callback, the record can not be altered at loading
      refResp = computed(() => {
        console.log('vwsOrderLst.computed: DEBUG: refResp=refItemRespStr='+JSON.stringify(refItemResp.value));  // prov_debug
        return refItemResp.value;
      });
      **** Without callback (end) ****/
      
      /**** With callback (begin) ****/
      // Callback is required if altering the record is required at loading
      function reqCallback(myResp) {
        console.log('vwsOrderLst.reqCallback: DEBUG: respStr='+JSON.stringify(myResp.resp));  // prov_debug
        // ==> respStr=
        
        
      }
      /**** With callback (end) ****/
      
      /*
      function delOrderDone(myResp) {
        console.log('vwsOrderLst.delOrdersDone: myRespStr='+JSON.stringify(myResp)); // prov_debug
        // ==> myRespStr={"resp":{"errCode":501,"errMsg":"Invalide function"}}
        // ==> myRespStr={"resp":{"errCode":502,"errMsg":"502:Could not save record"}}
        // ==> myRespStr={"resp":{"errCode":1,"errMsg":"OK"}}
        
        console.log('vwsOrderLst.delOrderDone: DEBUG: CliId='+refClientRec.value.id+', CliMobile='+refClientRec.value.sMobile);  // prov_debug
        let reqParams = { 
              criteria: "idParent = "+refClientRec.value.id, 
              orderby: "dtmCreate DESC",
              };
        switch(myResp.resp.errCode) {
          case 1: // OK: done successfully
            refResp.value.errCode = myResp.resp.errCode;
            refResp.value.errMsg = myResp.resp.errMsg;
            
            ascOrdersGetRecs(reqParams, reqCallback);  // Callback is required if altering the record is required at loading
        
            alert(refDico.value.msgOrdersDeleted);
            break;
          case 501: // errMsg501 = 'Invalide function';       // Unexpected function
            alert(refDico.value.errOrdersDel);
            break;
        }
      } // delOrderDone
      function delOrder(orderId) {
        console.log('vwsOrderLst.delOrder: DEBUG: orderId='+orderId+', clientId='+refClientRec.value.id);  // prov_debug
        
        refRec.value.errCode = 0; // Reset errCode
        refRec.value.id = orderId;
        // Submit request to remote REST API services to get record matching the provided id
        let myParams = {
            fct: 'delete',
            rec: refRec.value,
        };
        
        ascOrderDeleteRec(myParams, delOrderDone); // Callback is required if altering the record is required at loading
      } // delOrder
      */
      
      
      function cancelOrderDone(myResp) {
        console.log('cpnItmLst.cancelOrderDone: myRespStr='+JSON.stringify(myResp)); // prov_debug
        // ==> myRespStr={"resp":{"errCode":501,"errMsg":"Invalide function"}}
        // ==> myRespStr={"resp":{"errCode":502,"errMsg":"502:Could not save record"}}
        // ==> myRespStr={"resp":{"errCode":1,"errMsg":"OK"}}
        
        switch(myResp.resp.errCode) {
          case 1: // OK: done successfully
            refResp.value.errCode = myResp.resp.errCode;
            refResp.value.errMsg = myResp.resp.errMsg;
            alert(refDicoAttr.value.msgCancelOrder);
            break;
          case 501: // errMsg501 = 'Invalide function';       // Unexpected function
            alert(refDicoAttr.value.errCancelOrder);
            break;
        }
      } // cancelOrderDone
      
      function cancelOrder(orderId) {
        console.log('!!!!vwsOrderLst.cancelOrder: DEBUG: orderId='+orderId+', clientId='+refClientRec.value.id);  // prov_debug
        
        confirm(refDicoAttr.value.msgRequestCancelOrder);
        /*ascOrdersDeleteRec(myParams, cancelOrderDone);*/
        
      } // cancelOrder

      function returnOrder(orderId) {
        console.log('vwsOrderLst.returnOrder: DEBUG: orderId='+orderId+', clientId='+refClientRec.value.id);  // prov_debug
        
        confirm(refDicoAttr.value.msgRequestReturnlOrder);
      } // returnOrder

      function openPage(pgName) {
        console.log('vwsOrderLst.openPage: pgName='+pgName);
        // this.$router.push({name:pgName,params:{pgAction:'init',prmId:''}});
        router.push({name:pgName,params:{pgAction:'init',prmId:''}});
      }
      
      return {
        refDico,
        refOrdStatus,
        refPageAttr,
        refItmTypeAttr,
        refOrdersResp,
        refOrdersLoading,
        refOrdersRecs,
        cancelOrder,
        returnOrder,
      }
    },

  };

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>